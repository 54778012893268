.f-full-windows-block {
    .f-single-project__slide {
        height: 100vh;
    }
    height: 100vh;
    .swiper-container-horizontal > .swiper-pagination-bullets .swiper-pagination-bullet {
        margin: 0 15px;
    }
    .swiper-pagination-fraction,
    .swiper-pagination-custom,
    .swiper-container-horizontal > .swiper-pagination-bullets {
        margin-bottom: 50px;
    }
    .f-video-wrap {
        height: 100vh;
    }
    .f-audio-wrap {
        height: 100vh;
    }
}
