/* DEFAULT */

.f-navigation {
    position: relative;
    padding: 30px 75px;
    box-sizing: border-box;
    min-height: 40px;
    background-color: #fff;
    &:after {
        content: '';
        display: table;
        clear: both;
    }
    nav{
        z-index: 2;
    }
}

.nav-menu-icon-close{
    position: absolute!important;
    z-index: 9999999;
    color:#808080;
    top: 50px!important;
    right: 50px!important;
}

.main-menu {
    margin: 0;
}

.main-menu li {
    font-weight: 500;
    display: inline-block;
    margin-right: 52px;
    position: relative;
    ul {
        padding: 30px 30px;
        background-color: #fff;
    }
    li {
        padding: 0;
        margin: 0;
        a {
            line-height: 35px;
            font-size: 14px;
        }
    }
    a {
        color: #808080;
        letter-spacing: .5px;
        font-size: 16px;
        line-height: 40px;
        text-transform: uppercase;
        font-family: 'Poppins', sans-serif;
        &:hover {
            color: #ff5a00;
        }
    }
}

.main-menu .sub-menu {
    display: none;
    position: absolute;
    white-space: nowrap;
    z-index: 3;
}


/* Active menu items */

.main-menu .current-menu-item a {
    color: #808080;
}

.main-menu .current-menu-parent > a {
    color: #ff5a00;
}


/* Menu logo */

.full-logo {
    a {
        vertical-align: middle;
        color: #333;
        display: inline-flex;
        font-family: 'Poppins', sans-serif;
        font-weight: 400;
    }
    img {
        max-height: 55px;
    }
}


/* Menu search  */

.search-form {
    position: relative;
    a {
        color: grey;
        vertical-align: middle;
        font-size: 16px;
        font-weight: 500;
        letter-spacing: .5px;
        text-transform: uppercase;
        line-height: 1;
        @media only screen and (max-width: 767px) {
            font: 0/0 serif;
            text-shadow: none;
            color: transparent;
        }
    }
    i {
        color: grey;
        vertical-align: middle;
        font-size: 36px;
        font-weight: 400;
        @media only screen and (max-width: 1200px) {
            font-size: 32px;
        }
        @media only screen and (max-width: 767px) {
            font-family: "Ionicons";
            font-size: 36px;
            color: grey;
            padding-left: 10px;
            padding-right: 10px;
        }
    }
    transition: color .1s ease;
    &:hover {
        a,
        i {
            color: #ff5a00;
        }
    }
}

.search-form-body {
    position: absolute;
    top: 78px;
    display: none;
    width: 425px;
    form {
        position: relative;
    }
    input {
        padding: 25px;
        padding-right: 75px;
        color: #999;
        font-family: 'Lato', sans-serif;
        font-size: 14px;
        font-weight: 400;
        line-height: 25px;
        display: inline-block;
        letter-spacing: .53px;
        width: 100%;
    }
    input[type='submit'],
    button {
        position: absolute;
        top: 0;
        right: 0;
        width: 75px;
        height: 100%;
        color: transparent;
        font-size: 24px;
        text-align: center;
        line-height: 75px;
        transition: color .1s ease;
        background: transparent url(../img/arrow2.png) center center no-repeat;
        background-size: 17px 17px;
    }
    @media only screen and (max-width: 767px) {
        width: 100%;
        height: 100%;
        background-color: #fff;
        padding-top: 100px;
        position: fixed;
        top: 0;
        left: 0;
        form {
            border: 1px solid #ccc;
            border-left: none;
            margin-left: 15px;
            margin-right: 15px;
            box-sizing: border-box;
            border-right: none;
        }
    }
}

.nav-menu__tray-body {
    width: 420px;
    padding: 40px 35px;
    position: absolute;
    display: none;
    top: 73px;
    left: 0;
    background-color: #fff;
    z-index: 3;
    li {
        width: 100%;
        position: relative;
        margin-bottom: 25px;
        &:last-child {
            margin-bottom: 40px;
        }
        &:after {
            content: '';
            display: table;
            clear: both;
        }
    }
    @media only screen and (max-width: 767px) {
        width: 100%;
        height: 100%;
        position: fixed;
        top: 0;
        left: 0;
        padding-left: 30px;
        padding-right: 30px;
    }
}

.nav-menu__tray-body_right {
    left: auto;
    right: 0;
}

.nav-menu__tray-img {
    float: left;
    @media only screen and (max-width: 479px) {
        display: none;
    }
}

.nav-menu__tray-info {
    float: left;
    padding: 20px 25px;
    .f-head-h5 {
        color: #333;
        font-weight: 500;
        letter-spacing: -1px;
    }
    span {
        color: grey;
        font-size: 14px;
        font-weight: 600;
    }
}

.nav-menu__tray-close {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    width: 20px;
    height: 20px;
    color: #333;
    font-size: 18px;
    font-weight: 400;
    cursor: pointer;
}

.nav-menu-total {
    border-top: 2px solid #f2f2f2;
    border-bottom: 2px solid #f2f2f2;
    &:after {
        content: '';
        display: table;
        clear: both;
    }
    span {
        line-height: 75px;
        float: left;
        color: #333;
        font-family: 'Poppins', sans-serif;
        font-size: 18px;
        font-weight: 600;
    }
    .nav-menu-total-price {
        float: right;
    }
}


/* log in and tray buttons */

.nav-menu__tray,
.nav-menu__log-in {
    position: relative;
    padding-right: 10px;
    @media only screen and (max-width: 767px) {
        padding-right: 0;
    }
    a {
        color: grey;
        font-size: 16px;
        font-weight: 500;
        line-height: 36px;
        text-transform: uppercase;
        vertical-align: middle;
        letter-spacing: .5px;
        @media only screen and (max-width: 767px) {
            font: 0/0 serif;
            text-shadow: none;
            color: transparent;
        }
        &:before {
            padding-left: 50px;
            padding-right: 20px;
            float: left;
            font-size: 36px;
            font-weight: 400;
            line-height: 1;
            vertical-align: middle;
            text-transform: uppercase;
            @media only screen and (max-width: 767px) {
                font-family: "Ionicons";
                font-size: 36px;
                color: grey;
                padding-left: 10px;
                padding-right: 10px;
            }
        }
    }
    transition: color .1s ease;
    &:hover {
        > a,
        i {
            color: #ff5a00;
        }
        .f-close-tray-body {
            color: #000;
        }
    }
}

a.f-close-nav-pop-up,
.f-close-nav-pop-up {
    display: none;
    position: absolute;
    top: 15px;
    right: 30px;
    width: 30px;
    height: 30px;
    z-index: 9;
    line-height: 30px;
    text-align: center;
    color: black;
    @media only screen and (max-width: 767px) {
        display: block;
    }
    &:before {
        content: "\f129";
        font-family: "Ionicons";
        display: inline-block;
        position: absolute;
        top: 15px;
        left: 0;
        font-size: 30px;
        font-weight: 400;
        color: black;
        cursor: pointer;
    }
}

.nav-menu__log-in-body {
    position: absolute;
    top: 73px;
    text-align: left;
    display: none;
    left: 0;
    width: 425px;
    z-index: 3;
    background-color: #fff;
    padding: 35px 30px 20px;
    @media only screen and (max-width: 767px) {
        width: 100%;
        height: 100%;
        position: fixed;
        top: 0;
        left: 0;
    }
    a {
        text-transform: none;
        color: #ff5a00;
        font-size: 14px;
    }
    .f-head-h5 {
        letter-spacing: .5px;
    }
    p {
        margin-top: 10px;
        margin-bottom: 10px;
        letter-spacing: .7px;
    }
    form {
        width: 100%;
        margin-top: 15px;
        display: inline-block;
        button {
            width: 100%;
            margin-top: 15px;
            line-height: 50px;
            margin-bottom: 15px;
        }
        input {
            background-color: transparent;
            padding: 0 20px;
            width: 100%;
            border-bottom: 1px solid #bfbdbd;
            line-height: 55px;
            margin-bottom: 25px;
            color: #999;
            font-family: 'Lato', sans-serif;
            font-size: 14px;
            font-weight: 400;
            letter-spacing: .5px;
            &:nth-child(2) {
                margin-bottom: 5px;
            }
        }
        p {
            &:after {
                clear: both;
                content: '';
                display: table;
            }
            label {
                float: left;
                transition: color .1s ease;
                &:before {
                    text-align: center;
                    content: "\f3fd";
                    display: inline-block;
                    line-height: 12px;
                    display: inline-block;
                    font-family: "Ionicons";
                    color: transparent;
                    width: 14px;
                    margin-right: 15px;
                    height: 14px;
                    border: 1px solid #bfbdbd;
                    vertical-align: middle;
                }
                @media only screen and (max-width: 767px) {
                    float: none;
                    margin-top: 10px;
                }
            }
            a {
                float: right;
                color: grey;
                font-family: 'Lato', sans-serif;
                font-size: 14px;
                font-weight: 400;
                text-transform: none;
                vertical-align: middle;
                transition: color .1s ease;
                @media only screen and (max-width: 767px) {
                    float: none;
                    display: block;
                    margin-top: 30px;
                    text-align: left;
                }
                &:hover {
                    color: #ff5a00;
                }
            }
            input[type='checkbox'] {
                display: none;
            }
            input[type='checkbox']:checked + label {
                color: #ff5a00;
            }
            input[type='checkbox']:checked + label:before {
                border-color: #ff5a00;
                color: #ff5a00;
            }
        }
    }
}

.nav-menu__tray-body_style2 {
    width: 460px;
    height: 100%;
    padding-left: 50px;
    padding-right: 40px;
    position: fixed;
    top: 0;
    right: 0;
    left: auto;
    display: block;
    transform: translateX(100%);
    transition: all .1s ease;
    text-align: left;
    &.slide-right {
        transform: translateX(0);
    }
    @media only screen and (max-width: 767px) {
        width: 100%;
        padding-left: 15px;
        padding-right: 15px;
    }
    .nav-menu-total {
        margin-top: 31vh;
        @media only screen and (max-width: 767px) {
            margin-top: 30px;
        }
    }
    .f-head-h5 {
        letter-spacing: 1px;
    }
    .f-head-h3 {
        position: relative;
        display: inline-block;
        @media only screen and (max-width: 600px) {
            display: block;
        }
        &:after {
            content: '';
            display: inline-block;
            width: 120px;
            height: 2px;
            background-color: #e6e6e6;
            position: absolute;
            line-height: 100%;
            margin-left: 25px;
            padding-left: 30px;
            top: 0;
            margin: auto;
            left: calc(100% + 25px);
            bottom: 0;
        }
    }
    .nav-menu__tray-info {
        text-align: left;
    }
}

.f-close-tray-body {
    position: absolute;
    z-index: 4;
    top: 45px;
    right: 50px;
    width: 20px;
    height: 20px;
    font-size: 30px;
    font-weight: 400;
    color: black;
    cursor: pointer;
    @media only screen and (max-width: 767px) {
        top: 15px;
        right: 15px;
    }
}

.nav-menu__log-in-body_right {
    left: auto;
    right: 0;
}

.nav-menu__tray-btns {
    margin-top: 35px;
    text-align: center;
    &:after {
        content: '';
        display: table;
        clear: both;
    }
    a {
        float: left;
        color: #fffdfc;
        text-transform: none;
        font-size: 14px;
        width: 45%;
        line-height: 50px;
        font-weight: 100;
        letter-spacing: -1px;
        margin-right: 10%;
        @media only screen and (max-width: 767px) {
            float: none;
            display: block;
            width: 100%;
            margin-bottom: 30px;
        }
        &:last-child {
            margin-right: 0;
        }
    }
}


/* log in and tray buttons */

.nav-menu__log-in2 {
    float: right;
    transition: color .1s ease;
    @media only screen and (max-width: 480px) {
        float: none;
        vertical-align: top;
        display: inline-block;
    }
    &:hover {
        i,
        .dropdown button,
        span {
            color: #ff5a00;
        }
    }
    a {
        display: inline-block;
        vertical-align: middle;
        width: 36px;
        height: 36px;
        img {
            border-radius: 100%;
            max-width: 100%;
        }
        @media only screen and (max-width: 767px) {
            margin-left: 20px;
            margin-right: 20px;
        }
    }
    transition: color .1s ease;
    .dropdown-menu>li>a:focus,
    .dropdown-menu>li>a:hover {
        color: #ff5a00;
    }
    .dropdown-menu>li>a {
        line-height: 36px;
        height: auto;
    }
    .dropdown {
        display: inline-block;
        vertical-align: middle;
        @media only screen and (max-width: 767px) {
            display: none;
        }
        .dropdown-menu {
            padding: 0;
            margin: 0;
        }
        button {
            padding-right: 5px;
            margin-left: 10px;
            color: grey;
            text-transform: uppercase;
            letter-spacing: .5px;
            font-family: 'Poppins', sans-serif;
            line-height: 36px;
            font-size: 16px;
            font-weight: 500;
            border: none;
            outline: none;
            background-color: transparent;
            &:hover,
            &:focus {
                border: none;
                outline: none;
            }
        }
    }
    .dropdown-menu>li>a {
        width: 100%;
    }
}


/* social icons */

.nav-menu__social {
    text-align: center;
    li {
        display: inline-block;
        padding: 0 14px;
        a {
            transition: color .1s ease;
            &:hover {
                color: #ff5a00;
            }
            font-size: 23px;
            font-weight: 400;
            line-height: 40px;
            vertical-align: middle;
            color: grey;
        }
    }
}


/* Show second and third menu levels */

@media screen and (min-width:992px) {
    .main-menu .sub-menu li {
        display: block;
    }
    .main-menu li:hover > .sub-menu {
        display: block;
    }
    nav .main-menu .sub-menu .sub-menu {
        left: calc(100% + 35px);
        top: 0;
        &:after {
            position: absolute;
            content: '';
            display: inline-block;
            width: 40px;
            height: 100%;
            top: 0;
            left: -40px;
            background-color: transparent;
        }
    }
    /* First level menu arrows */
    .main-menu > .menu-item-has-children > a:after {
        padding-left: 10px;
        content: '\f3d0';
        font-family: "Ionicons";
    }
    /* Second level menu arrows */
    .main-menu .sub-menu .menu-item-has-children > a:after {
        content: '\f3d1';
        font-family: "Ionicons";
    }
}


/* Responsive menu button */

.nav-menu-icon {
    position: absolute;
    right: 0px;
    top: 0px;
    width: 32px;
    height: 32px;
    display: none;
    cursor: pointer;
    transition: color .1s ease;
    &:hover {
        color: #ff5a00;
        i,
        i:before,
        i:after {
            background-color: #ff5a00;
        }
    }
    @media only screen and (max-width: 767px) {
        font: 0/18px serif!important;
        text-shadow: none;
        color: transparent;
    }
}


/* Don`t reqiured */

.f-navigation.active .nav-menu-icon i {
    background: none;
}

.f-navigation.active .nav-menu-icon i:before {
    top: 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    -moz-transform: rotate(45deg);
    -o-transform: rotate(45deg);
    transform: rotate(45deg);
}

.f-navigation.active .nav-menu-icon i:after {
    top: 0;
    -webkit-transform: rotate(135deg);
    -ms-transform: rotate(135deg);
    -moz-transform: rotate(135deg);
    -o-transform: rotate(135deg);
    transform: rotate(135deg);
}


/* End Don`t reqiured */

.nav-menu-icon i:before,
.nav-menu-icon i:after {
    content: '';
    width: 24px;
    height: 2px;
    background: #808080;
    position: absolute;
    left: 0;
    -moz-transition: all 0.15s ease-out;
    -o-transition: all 0.15s ease-out;
    -webkit-transition: all 0.15s ease-out;
    transition: all 0.15s ease-out;
    -ms-transition: all 0.15s ease-out;
}

.nav-menu-icon {
    width: auto;
    line-height: 40px;
    color: #808080;
    font-family: 'Poppins', sans-serif;
    font-size: 16px;
    font-weight: 500;
    text-transform: uppercase;
    vertical-align: middle;
}

.nav-menu-icon i:before {
    top: -8px;
}

.nav-menu-icon i:after {
    bottom: -8px;
}

.nav-menu-icon i {
    position: relative;
    display: inline-block;
    vertical-align: middle;
    margin-right: 20px;
    width: 24px;
    height: 2px;
    background: #808080;
    @media only screen and (max-width: 767px) {
        margin-right: 0;
    }
}

.menu-item-has-children > a span {
    display: none;
}


/* Responsive menu */

@media screen and (max-width:992px) {
    .nav-menu-icon {
        display: block;
    }
    .main-menu {
        display: none;
    }
    .f-navigation.active .main-menu {
        display: block;
        width: 100%;
        background-color: #fff;
        padding: 0;
        height: auto;
    }
    .main-menu li {
        display: block;
    }
    .main-menu .sub-menu {
        position: static;
        padding: 5px 15px;
    }
    .menu-item-has-children > a span {
        display: inline-block;
        position: relative;
        transform: translateX(20px);
    }
    .menu-item-has-children.active > .sub-menu {
        display: block;
        position: static;
    }
    .f-navigation {
        &.active {
            nav {
                position: fixed;
                width: 100%;
                height: 100%;
                z-index: 3;
                text-align: center;
                top: 0;
                left: 0;
                padding-top: 70px;
                overflow-y: auto;
                background-color: #fff;
            }
            .main-menu li {
                margin-right: 0;
                padding: 0;
                li a {
                    font-size: 12px;
                }
            }
        }
    }
}

.menu_style-fixed {
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 999;
}

.menu_style-border {
    border-bottom: 1px solid #f2f2f2;
}

.menu_style-transparent {
    background-color: transparent;
    @media screen and (max-width: 992px) {
        background-color: #333;
    }
    .search-form i,
    .main-menu li {
        a {
            color: #ccc;
            transition: color .1s ease;
            &:hover {
                color: #ff5a00;
            }
        }
        li {
            a {
                color: #333;
            }
        }
    }
    .main-menu .current-menu-parent > a {
        color: #ff5a00;
    }
    &.active {
        .main-menu li {
            a {
                color: #808080;
                &:hover {
                    color: #ff5a00;
                }
            }
            li a {
                color: #808080;
            }
        }
    }
}

.menu_style-scroll {
    background-color: #333;
    transition: background .1s ease-in;
}

// Header style 1
.menu_style1 {
    .nav-menu-icon-close{
        display: none;
    }


    &.active{
        .nav-menu-icon-close{
        display: block;
    }
    }
    .full-logo {
        float: left;
    }
    > nav {
        float: right;
    }
    .search-form {
        float: right;
    }
    .nav-menu__tray {
        float: right;
    }
    .main-menu .sub-menu {
        &:before {
            content: '';
            display: inline-block;
            position: absolute;
            height: 35px;
            top: -33px;
            left: 0;
            width: 100%;
        }
        top: 73px;
        @media only screen and (max-width: 992px) {
            &:before {
                display: none;
            }
        }
    }
    @media only screen and (max-width: 1600px) {
        .main-menu li {
            margin-right: 20px;
        }
    }
    @media only screen and (max-width: 1100px) {
        .main-menu li a {
            font-size: 14px;
        }
    }
}


/*==========  Desktop First Method  ==========*/


/* Large Devices, Wide Screens */

@media only screen and (max-width: 1200px) {
    .f-navigation {
        padding: 30px 15px;
    }
}


/* Medium Devices, Desktops */

.search-form a {
    @media only screen and (max-width: 1200px) {
        line-height: 35px;
    }
}

@media only screen and (max-width: 992px) {
    .f-navigation {
        padding: 10px 15px;
    }
    .nav-menu-icon {
        line-height: 1;
        top: 20px;
        right: 70px;
    }
    .f-navigation.active .nav-menu-icon {
        right: 15px;
        z-index: 1;
    }
}

// Header style 2
.menu_style2 {
    .nav-menu__tray {
        float: right;
    }
    nav {
        position: fixed;
        display: block;
        width: 320px;
        right: 0;
        top: 0;
        background-color: #fff;
        height: 100%;
        transform: translateX(100%);
        transition: all .1s ease;
        padding: 130px 50px;
         @media only screen and (max-width: 992px) {
            width: 100%;
            padding-top: 70px;
            padding-left: 0;padding-right: 0;
         }
    }
    &.active {
        z-index: 999;
        nav {
            transform: translateX(0);
            display: block;
            overflow-x: hidden;
            overflow-y: auto;
            &:after {
                content: '';
                display: inline-block;
                position: fixed;
                top: 0;
                right: 0;
                width: 320px;
                height: 100px;
                background-color: #fff;
            }
        }
        .main-menu li:hover > .sub-menu {
            display: none;
        }
        .main-menu {
            display: block;
            li {
                margin-right: 0;
                display: block;
                a {
                    letter-spacing: -1px;
                }
            }
            .sub-menu {
                position: static;
                padding: 0 30px;
            }
            li li {
                padding: 0;
            }
        }
        .nav-menu__social {
            text-align: left;
            li {
                padding: 0 10px;
                &:first-child {
                    padding-left: 0;
                }
                a {
                    font-size: 18px;
                }
            }
        }
        .f-newsletter-form {
            .f-head-h5 {
                font-size: 18px;
                position: relative;
                display: inline-block;
                &:after {
                    content: '';
                    display: inline-block;
                    width: 80px;
                    height: 2px;
                    background-color: #f2f2f2;
                    position: absolute;
                    top: 0;
                    bottom: 0;
                    margin: auto;
                    margin-left: 20px;
                }
            }
            p {
                letter-spacing: .5px;
                margin-top: 5px;
                margin-bottom: 25px;
            }
            form {
                position: relative;
                max-width: 220px;
            }
            button {
                position: absolute;
                top: 0;
                right: 5px;
                border: none;
                outline: none;
                color: #595959;
                background-color: transparent;
                font-weight: 100;
            }
            input {
                width: 100%;
                padding: 0 25px 15px 5px;
                letter-spacing: .5px;
                border-bottom: 1px solid #bfbfbf;
            }
        }
    }
    // .main-menu {
    //     display: none;
    // }
    .nav-menu-icon {
        position: static;
        display: block;
        float: right;
        padding-right: 10px;
        @media only screen and (max-width: 992px) {
            padding-right: 0;
            position: absolute;
            right: 30px;
        }
        @media only screen and (max-width: 480px) {
            right: 15px;
        }
    }
    .full-logo {
        float: left;
    }
}

// Header style 3
.menu_style3 {
    .nav-menu__tray {
        float: right;
    }
    @media only screen and (max-width: 480px) {
        text-align: center;
    }
    .main-menu {
        display: none;
    }
    .nav-menu-icon {
        margin-left: 140px;
        position: static;
        display: block;
        float: left;
        @media only screen and (max-width: 992px) {
            position: absolute;
            right: 30px;
            top: 20px;
        }
        @media only screen and (max-width: 480px) {
            right: 15px;
            margin-left: 0;
        }
    }
    .full-logo {
        float: left;
        @media only screen and (max-width: 480px) {
            width: 100%;
            text-align: left;
            margin-bottom: 20px;
        }
    }
    .nav-menu__tray {
        @media only screen and (max-width: 992px) {
            margin-right: 150px;
        }
        @media only screen and (max-width: 767px) {
            margin-right: 70px;
        }
        @media only screen and (max-width: 480px) {
            margin-right: 0;
        }
    }
    .nav-menu__tray,
    .nav-menu__log-in {
        float: right;
        @media only screen and (max-width: 480px) {
            float: none;
            display: inline-block;
        }
    }
    nav {
        display: none;
        position: fixed;
        width: 320px;
        left: 0;
        right: auto;
        top: 0;
        background-color: #fff;
        height: 100%;
        display: block;
        padding: 130px 50px;
        transition: all .1s ease;
        transform: translateX(-100%);
    }
    &.active {
        z-index: 999;
        nav {
            transition: all .1s ease;
            transform: translateX(0);
            overflow-x: hidden;
            overflow-y: auto;
            z-index: 4;
            &:after {
                content: '';
                display: inline-block;
                position: fixed;
                top: 0;
                right: 0;
                width: 320px;
                height: 100px;
                background-color: #fff;
            }
        }
        .main-menu li:hover > .sub-menu {
            display: none;
        }
        .main-menu {
            display: block;
            li {
                margin-right: 0;
                display: block;
                a {
                    letter-spacing: -1px;
                }
            }
            .sub-menu {
                position: static;
                padding: 0 30px;
            }
            li li {
                padding: 0;
            }
        }
        .nav-menu__social {
            text-align: left;
            li {
                padding: 0 10px;
                &:first-child {
                    padding-left: 0;
                }
                a {
                    font-size: 18px;
                }
            }
        }
        .f-newsletter-form {
            .f-head-h5 {
                font-size: 18px;
                position: relative;
                display: inline-block;
                &:after {
                    content: '';
                    display: inline-block;
                    width: 80px;
                    height: 2px;
                    background-color: #f2f2f2;
                    position: absolute;
                    top: 0;
                    bottom: 0;
                    margin: auto;
                    margin-left: 20px;
                }
            }
            p {
                letter-spacing: .5px;
                margin-top: 5px;
                margin-bottom: 25px;
            }
            form {
                position: relative;
                max-width: 220px;
            }
            button {
                position: absolute;
                top: 0;
                right: 5px;
                border: none;
                outline: none;
                color: #595959;
                background-color: transparent;
                font-weight: 100;
            }
            input {
                width: 100%;
                padding: 0 25px 15px 5px;
                letter-spacing: .5px;
                border-bottom: 1px solid #bfbfbf;
            }
        }
    }
}

// Header style 4
.menu_style4 {
    .nav-menu__tray {
        float: right;
    }
    @media only screen and (max-width: 480px) {
        text-align: center;
    }
    .main-menu {
        display: none;
    }
    .nav-menu-icon {
        margin-left: 0;
        position: relative;
        z-index: 2;
        display: block;
        float: left;
        @media only screen and (max-width: 992px) {
            margin-left: 0;
            position: absolute;
            z-index: 2;
            display: block;
            top: 20px;
            right: 30px;
            float: none;
        }
        @media only screen and (max-width: 480px) {
            right: 15px;
        }
    }
    .full-logo {
        float: none;
        position: absolute;
        left: 0;
        right: 0;
        margin: auto;
        width: auto;
        text-align: center;
        display: inline;
        @media only screen and (max-width: 992px) {
            position: static;
            float: left;
        }
        @media only screen and (max-width: 480px) {
            width: 100%;
            text-align: left;
        }
    }
    .nav-menu__tray {
        @media only screen and (max-width: 992px) {
            margin-right: 150px;
        }
        @media only screen and (max-width: 767px) {
            margin-right: 70px;
        }
        @media only screen and (max-width: 480px) {
            margin-right: 0;
        }
    }
    .nav-menu__tray,
    .nav-menu__log-in {
        float: right;
        position: relative;
        z-index: 2;
        @media only screen and (max-width: 480px) {
            float: none;
            display: inline-block;
        }
    }
    nav {
        position: fixed;
        width: 320px;
        left: 0;
        top: 0;
        padding: 130px 50px;
        background-color: #fff;
        height: 100%;
        display: block;
        padding: 130px 50px;
        transition: all .1s ease;
        transform: translateX(-100%);
    }
    &.active {
        z-index: 999;
        nav {
            transform: translateX(0);
            overflow-x: hidden;
            overflow-y: auto;
            z-index: 5;
            &:after {
                content: '';
                display: inline-block;
                position: fixed;
                top: 0;
                right: 0;
                width: 320px;
                height: 100px;
                background-color: #fff;
            }
        }
        .main-menu li:hover > .sub-menu {
            display: none;
        }
        .main-menu {
            display: block;
            li {
                margin-right: 0;
                display: block;
                a {
                    letter-spacing: -1px;
                }
            }
            .sub-menu {
                position: static;
                padding: 0 30px;
            }
            li li {
                padding: 0;
            }
        }
        .nav-menu__social {
            text-align: left;
            li {
                padding: 0 10px;
                &:first-child {
                    padding-left: 0;
                }
                a {
                    font-size: 18px;
                }
            }
        }
        .f-newsletter-form {
            .f-head-h5 {
                font-size: 18px;
                position: relative;
                display: inline-block;
                &:after {
                    content: '';
                    display: inline-block;
                    width: 80px;
                    height: 2px;
                    background-color: #f2f2f2;
                    position: absolute;
                    top: 0;
                    bottom: 0;
                    margin: auto;
                    margin-left: 20px;
                }
            }
            p {
                letter-spacing: .5px;
                margin-top: 5px;
                margin-bottom: 25px;
            }
            form {
                position: relative;
                max-width: 220px;
            }
            button {
                position: absolute;
                top: 0;
                right: 5px;
                border: none;
                outline: none;
                color: #595959;
                background-color: transparent;
                font-weight: 100;
            }
            input {
                width: 100%;
                padding: 0 25px 15px 5px;
                letter-spacing: .5px;
                border-bottom: 1px solid #bfbfbf;
            }
        }
    }
}

// Header style 5
.menu_style5 {
    .nav-menu__tray {
        float: right;
    }
    nav {
        position: fixed;
        width: 320px;
        right: 0;
        top: 0;
        background-color: #fff;
        height: 100%;
        padding: 130px 50px;
        display: block;
        transition: all .1s ease;
        transform: translateX(100%);
         @media only screen and (max-width: 992px) {
                width: 100%;
            }
    }
            .f-newsletter-form {
            @media only screen and (max-width: 992px) {
                margin-top: 50px;
            }
            .f-head-h5 {
                font-size: 18px;
                position: relative;
                display: inline-block;
                &:after {
                    content: '';
                    display: inline-block;
                    width: 80px;
                    height: 2px;
                    background-color: #f2f2f2;
                    position: absolute;
                    top: 0;
                    bottom: 0;
                    margin: auto;
                    margin-left: 20px;
                    @media only screen and (max-width: 992px) {
                        display: none;
                    }
                }
            }
            p {
                letter-spacing: .5px;
                margin-top: 5px;
                margin-bottom: 25px;
            }
            form,
            .mc4wp-form {
                display: block;
                box-sizing: border-box;
                position: relative;
                padding-left: 0px;
                max-width: 100%;
                &:after {
                    content: "\f10b";
                    font-family: "Ionicons";
                    width: 17px;
                    color: #595959;
                    line-height: 20px;
                    font-size: 14px;
                    display: inline-block;
                    position: absolute;
                    margin: auto;
                    top: 0;
                    right: 5px;
                }
                input[type="email"] {
                    float: none;
                    background-color: transparent;
                    display: block;
                    line-height: 1;
                    min-width: 0;
                    max-width: 100%;
                    box-sizing: border-box;
                    border-radius: 0;
                    width: 100%;
                    font-family: 'Lato', sans-serif;
                    font-size: 14px;
                    font-weight: 400;
                    transition: color .1s ease;
                    padding: 0 25px 15px 5px;
                    letter-spacing: .5px;
                    border-bottom: 1px solid #bfbfbf;
                    @media only screen and (max-width: 768px) {
                        padding-left: 0;
                    }
                    &:focus {
                        border-color: #ff5a00;
                    }
                    @media only screen and (max-width: 1200px) {
                        min-width: 0;
                        width: 100%;
                    }
                }
                input[type="submit"] {
                    float: none;
                    z-index: 2;
                    border-radius: 0;
                    background-color: transparent;
                    letter-spacing: 1px;
                    right: 0;
                    top: 0;
                    bottom: 0;
                    line-height: 58px;
                    font-size: 24px;
                    padding: 0 20px;
                    margin: auto;
                    font-weight: 100;
                    position: absolute;
                    background-color: transparent;
                    width: 60px;
                    border: none;
                    background-size: auto;
                    transition: color .1s ease;
                    @media only screen and (max-width: 1200px) {
                        padding: 0;
                        width: 35%;
                    }
                }
                @media only screen and (max-width: 991px) {
                    padding-left: 0;
                    input {
                        padding: 14px 15px;
                    }
                    button {
                        padding: 0 15px;
                    }
                }
            }
        }
    &.active {
        z-index: 999;
        nav {
            transform: translateX(0);
            overflow-x: hidden;
            overflow-y: auto;
            &:after {
                content: '';
                display: inline-block;
                position: fixed;
                top: 0;
                right: 0;
                width: 320px;
                height: 100px;
                background-color: #fff;
            }
        }
        .main-menu li:hover > .sub-menu {
            display: none;
        }
        .main-menu {
            li {
                margin-right: 0;
                display: block;
                a {
                    letter-spacing: -1px;
                }
            }
            .sub-menu {
                position: static;
                padding: 0 30px;
            }
            li li {
                padding: 0;
            }
        }
        .nav-menu__social {
            text-align: left;
            li {
                padding: 0 10px;
                &:first-child {
                    padding-left: 0;
                }
                a {
                    font-size: 18px;
                }
            }
        }
        .f-newsletter-form {
            @media only screen and (max-width: 992px) {
                margin-top: 50px;
            }
            .f-head-h5 {
                font-size: 18px;
                position: relative;
                display: inline-block;
                &:after {
                    content: '';
                    display: inline-block;
                    width: 80px;
                    height: 2px;
                    background-color: #f2f2f2;
                    position: absolute;
                    top: 0;
                    bottom: 0;
                    margin: auto;
                    margin-left: 20px;
                    @media only screen and (max-width: 992px) {
                        display: none;
                    }
                }
            }
            p {
                letter-spacing: .5px;
                margin-top: 5px;
                margin-bottom: 25px;
            }
            form,
            .mc4wp-form {
                display: block;
                box-sizing: border-box;
                position: relative;
                padding-left: 0px;
                max-width: 100%;
                &:after {
                    content: "\f10b";
                    font-family: "Ionicons";
                    width: 17px;
                    color: #595959;
                    line-height: 20px;
                    font-size: 14px;
                    display: inline-block;
                    position: absolute;
                    margin: auto;
                    top: 0;
                    right: 5px;
                }
                input[type="email"] {
                    float: none;
                    background-color: transparent;
                    display: block;
                    line-height: 1;
                    min-width: 0;
                    max-width: 100%;
                    box-sizing: border-box;
                    border-radius: 0;
                    width: 100%;
                    font-family: 'Lato', sans-serif;
                    font-size: 14px;
                    font-weight: 400;
                    transition: color .1s ease;
                    padding: 0 25px 15px 5px;
                    letter-spacing: .5px;
                    border-bottom: 1px solid #bfbfbf;
                    @media only screen and (max-width: 768px) {
                        padding-left: 0;
                    }
                    &:focus {
                        border-color: #ff5a00;
                    }
                    @media only screen and (max-width: 1200px) {
                        min-width: 0;
                        width: 100%;
                    }
                }
                input[type="submit"] {
                    float: none;
                    z-index: 2;
                    border-radius: 0;
                    background-color: transparent;
                    letter-spacing: 1px;
                    right: 0;
                    top: 0;
                    bottom: 0;
                    line-height: 58px;
                    font-size: 24px;
                    padding: 0 20px;
                    margin: auto;
                    font-weight: 100;
                    position: absolute;
                    background-color: transparent;
                    width: 60px;
                    border: none;
                    background-size: auto;
                    transition: color .1s ease;
                    @media only screen and (max-width: 1200px) {
                        padding: 0;
                        width: 35%;
                    }
                }
                @media only screen and (max-width: 991px) {
                    padding-left: 0;
                    input {
                        padding: 14px 15px;
                    }
                    button {
                        padding: 0 15px;
                    }
                }
            }
        }
    }
    .nav-menu-icon {
        margin-left: 0;
        position: relative;
        display: block;
        float: right;
        padding-right: 10px;
        @media only screen and (max-width: 992px) {
            top: 10px;
            right: 10px;
            padding-right: 0;
        }
        @media only screen and (max-width: 480px) {
            right: 0;
        }
    }
    .full-logo {
        float: none;
        position: absolute;
        left: 0;
        right: 0;
        margin: auto;
        width: auto;
        text-align: center;
        display: inline;
    }
    .nav-menu__tray,
    .nav-menu__log-in {
        display: none;
        position: relative;
        z-index: 2;
    }
    .search-form {
        float: left;
        position: relative;
        z-index: 2;
        i {
            line-height: 20px;
            padding-right: 15px;
        }
    }
}

// Header style 6
.menu_style6 {
    .nav-menu-icon-close{
        display: none;
    }
    .nav-menu__tray {
        float: right;
    }
    .full-logo {
        float: none;
        position: absolute;
        left: 0;
        right: 0;
        margin: auto;
        width: auto;
        text-align: center;
        display: inline;
        @media only screen and (max-width: 600px) {
            position: static;
        }
    }
    .nav-menu__tray,
    .nav-menu__log-in {
        float: right;
        display: none;
    }
    nav {
        position: relative;
    }
    .search-form {
        float: right;
        position: relative;
        @media only screen and (max-width: 600px) {
            display: none;
        }
    }
    .main-menu li {
        @media only screen and (max-width: 1200px) {
            margin-right: 20px;
        }
    }
    .nav-menu-icon {
        @media only screen and (max-width: 600px) {
            right: 15px;
        }
    }
    &.active {
        .nav-menu-icon-close{
            display: block;
        }
    }
    .main-menu .sub-menu {
        &:before {
            content: '';
            display: inline-block;
            position: absolute;
            height: 35px;
            top: -33px;
            left: 0;
            width: 100%;
        }
        top: 73px;
        @media only screen and (max-width: 992px) {
            &:before {
                display: none;
            }
        }
    }
}

// Header style 7
.menu_style7 {
    .nav-menu__tray {
        float: right;
    }
    text-align: center;
    .main-menu .sub-menu {
        &:before {
            content: '';
            display: inline-block;
            position: absolute;
            height: 35px;
            top: -33px;
            left: 0;
            width: 100%;
        }
        top: 73px;
        @media only screen and (max-width:1300px) {
            &:before {
                display: none;
            }
            text-align: center;
        }
    }
    .main-menu li ul {
        text-align: left;
    }
    nav {
        display: inline-block;
        @media only screen and (max-width: 1300px) {
            z-index: 6;
                    transition: all .1s ease;
            transform:translateX(100%);
            position: fixed;
            width: 320px;
            right: 0;
            top: 0;
            background-color: #fff;
            height: 100%;
            padding: 130px 50px;
        }
    }
    .full-logo {
        float: left;
        left: 0;
        right: 0;
        margin: auto;
        width: auto;
        text-align: center;
        display: inline;
    }
    .nav-menu__tray,
    .nav-menu__log-in {
        float: right;
        display: none;
    }
    .nav-menu__social,
    .search-form {
        float: right;
    }
    .search-form {
        margin-left: 40px;
        @media only screen and (max-width: 1300px) {
            margin-right: 30px;
        }
        @media only screen and (max-width: 1200px) {
            margin-right: 100px;
        }
    }
    @media only screen and (max-width: 600px) {
        .search-form,
        .nav-menu__social {
            display: none;
        }
    }
    .main-menu li {
        &:last-child {
            margin-right: 0;
        }
    }
    .main-menu li {
        @media only screen and (max-width: 1600px) {
            margin-right: 20px;
        }
    }
    @media only screen and (max-width: 992px) {
        .nav-menu-icon {
            position: static;
            float: right;
            margin-left: 40px;
            margin-top: 15px;
        }
    }
    .nav-menu-icon {
        @media only screen and (max-width: 1300px) {
            display: inline-block;
            top: 28px;
            right: 30px;
            z-index: 3;
        }
        @media only screen and (max-width: 480px) {
            right: 0;
        }
    }
    &.active {
        z-index: 999;
        .main-menu {
            display: block;
        }
        nav {
            display: block;
            padding: 130px 50px;
            overflow-x: hidden;
            overflow-y: auto;
            transform:translateX(0);
            &:after {
                content: '';
                display: inline-block;
                position: fixed;
                top: 0;
                right: 0;
                width: 320px;
                height: 100px;
                background-color: #fff;
            }
        }
        .main-menu li:hover > .sub-menu {
            display: none;
        }
        .main-menu {
            li {
                margin-right: 0;
                display: block;
                a {
                    letter-spacing: -1px;
                }
            }
            .sub-menu {
                position: static;
                padding: 0 30px;
            }
            li li {
                padding: 0;
            }
        }
        .nav-menu__social {
            text-align: left;
            li {
                padding: 0 10px;
                &:first-child {
                    padding-left: 0;
                }
                a {
                    font-size: 18px;
                }
            }
        }
        .f-newsletter-form {
            .f-head-h5 {
                font-size: 18px;
                position: relative;
                display: inline-block;
                &:after {
                    content: '';
                    display: inline-block;
                    width: 80px;
                    height: 2px;
                    background-color: #f2f2f2;
                    position: absolute;
                    top: 0;
                    bottom: 0;
                    margin: auto;
                    margin-left: 20px;
                }
            }
            p {
                letter-spacing: .5px;
                margin-top: 5px;
                margin-bottom: 25px;
            }
            form {
                position: relative;
                max-width: 220px;
            }
            button {
                position: absolute;
                top: 0;
                right: 5px;
                border: none;
                outline: none;
                color: #595959;
                background-color: transparent;
                font-weight: 100;
            }
            input {
                width: 100%;
                padding: 0 25px 15px 5px;
                letter-spacing: .5px;
                border-bottom: 1px solid #bfbfbf;
            }
        }
    }
}

// Header style 8
.menu_style8 {
    .nav-menu__tray {
        float: right;
    }
    text-align: center;
    .nav-menu-icon {
        top: 28px;
        right: 30px;
        @media only screen and (max-width: 1500px) {
            display: block;
        }
        @media only screen and (max-width: 992px) {
            top: 20px;
        }
        @media only screen and (max-width: 480px) {
            right: 15px;
        }
    }
    nav {
        display: inline-block;
        @media only screen and (max-width: 1500px) {
                        transition: all .1s ease;
            transform:translateX(100%);
            position: fixed;
            width: 320px;
            right: 0;
            top: 0;
            background-color: #fff;
            height: 100%;
            padding: 130px 50px;
        }
    }
    &.active {
        z-index: 999;
        nav {
            display: block;
            padding: 130px 50px;
            overflow-x: hidden;
            overflow-y: auto;
            z-index: 5;
            transform:translateX(0);
            &:after {
                content: '';
                display: inline-block;
                position: fixed;
                top: 0;
                right: 0;
                width: 320px;
                height: 100px;
                background-color: #fff;
            }
        }
        .main-menu li:hover > .sub-menu {
            display: none;
        }
        .main-menu {
            display: block;
            li {
                margin-right: 0;
                display: block;
                a {
                    letter-spacing: -1px;
                }
            }
            .sub-menu {
                position: static;
                padding: 0 30px;
            }
            li li {
                padding: 0;
            }
        }
        .nav-menu__social {
            text-align: left;
            li {
                padding: 0 10px;
                &:first-child {
                    padding-left: 0;
                }
                a {
                    font-size: 18px;
                }
            }
        }
        .f-newsletter-form {
            .f-head-h5 {
                font-size: 18px;
                position: relative;
                display: inline-block;
                &:after {
                    content: '';
                    display: inline-block;
                    width: 80px;
                    height: 2px;
                    background-color: #f2f2f2;
                    position: absolute;
                    top: 0;
                    bottom: 0;
                    margin: auto;
                    margin-left: 20px;
                }
            }
            p {
                letter-spacing: .5px;
                margin-top: 5px;
                margin-bottom: 25px;
            }
            form {
                position: relative;
                max-width: 220px;
            }
            button {
                position: absolute;
                top: 0;
                right: 5px;
                border: none;
                outline: none;
                color: #595959;
                background-color: transparent;
                font-weight: 100;
            }
            input {
                width: 100%;
                padding: 0 25px 15px 5px;
                letter-spacing: .5px;
                border-bottom: 1px solid #bfbfbf;
            }
        }
    }
    .full-logo {
        float: left;
        left: 0;
        right: 0;
        margin: auto;
        width: auto;
        text-align: center;
        display: inline;
        @media only screen and (max-width: 480px) {
            margin-bottom: 0;
        }
    }
    .nav-menu__social,
    .search-form {
        display: none;
    }
    .search-form {
        margin-left: 40px;
    }
    .main-menu li {
        @media only screen and (max-width: 1860px) {
            margin-right: 20px;
        }
        &:last-child {
            margin-right: 0;
        }
    }
    .nav-menu__tray {
        @media only screen and (max-width: 1860px) {
            padding-right: 50px;
        }
        @media only screen and (max-width: 1200px) {
            padding-right: 100px;
        }
    }
    .nav-menu__tray,
    .nav-menu__log-in {
        float: right;
    }
    .main-menu .sub-menu {
        &:before {
            content: '';
            display: inline-block;
            position: absolute;
            height: 35px;
            top: -33px;
            left: 0;
            width: 100%;
        }
        top: 73px;
        @media only screen and (max-width: 1500px) {
            &:before {
                display: none;
            }
        }
    }
}

// Header style 9
.menu_style9 {
    .nav-menu__tray {
        float: right;
    }
    text-align: center;
    .main-menu {
        display: none;
    }
    nav {
        position: fixed;
        width: 320px;
        right: 0;
        top: 0;
        background-color: #fff;
        padding: 130px 50px;
        height: 100%;
        display: block;
        transition: all .1s ease;
        transform: translateX(100%);
            @media only screen and (max-width: 992px) {
            padding-top: 70px;
            width: 100%;
        }
    }
    &.active {
        z-index: 999;
        .main-menu {
            display: block;
        }
        nav {
            transform: translateX(0);
            overflow-x: hidden;
            overflow-y: auto;
            &:after {
                content: '';
                display: inline-block;
                position: fixed;
                top: 0;
                right: 0;
                width: 320px;
                height: 100px;
                background-color: #fff;
            }
        }
        .main-menu li:hover > .sub-menu {
            display: none;
        }
        .main-menu {
            li {
                margin-right: 0;
                display: block;
                a {
                    letter-spacing: -1px;
                }
            }
            .sub-menu {
                position: static;
                padding: 0 30px;
            }
            li li {
                padding: 0;
            }
        }
        .nav-menu__social {
            text-align: left;
            li {
                padding: 0 10px;
                &:first-child {
                    padding-left: 0;
                }

            }
        }
        .f-newsletter-form {
            .f-head-h5 {
                font-size: 18px;
                position: relative;
                display: inline-block;
                &:after {
                    content: '';
                    display: inline-block;
                    width: 80px;
                    height: 2px;
                    background-color: #f2f2f2;
                    position: absolute;
                    top: 0;
                    bottom: 0;
                    margin: auto;
                    margin-left: 20px;
                }
            }
            p {
                letter-spacing: .5px;
                margin-top: 5px;
                margin-bottom: 25px;
            }
            form {
                position: relative;
                max-width: 220px;
            }
            button {
                position: absolute;
                top: 0;
                right: 5px;
                border: none;
                outline: none;
                color: #595959;
                background-color: transparent;
                font-weight: 100;
            }
            input {
                width: 100%;
                padding: 0 25px 15px 5px;
                letter-spacing: .5px;
                border-bottom: 1px solid #bfbfbf;
            }
        }
    }
    .nav-menu__social {
        display: inline-block;
        @media only screen and (max-width: 600px) {
            display: none;
        }
    }
    .nav-menu-icon {
        position: static;
        display: block;
        float: right;
        padding-right: 10px;
        @media only screen and (max-width: 992px) {
            position: absolute;
            top: 20px;
            right: 30px;
            padding-right: 0;
        }
        @media only screen and (max-width: 480px) {
            right: 15px;
        }
    }
    .full-logo {
        float: left;
    }
}

// Header style 10
.menu_style10 {
    .nav-menu__tray {
        float: right;
    }
    .main-menu {
        display: none;
    }
    .nav-menu-icon {
        position: static;
        display: block;
        float: left;
        padding-right: 10px;
        @media only screen and (max-width: 992px) {
            line-height: 40px;
        }
        @media only screen and (max-width: 767px) {
            position: absolute;
            right: 30px;
        }
        @media only screen and (max-width: 480px) {
            right: 15px;
            padding-right: 0;
        }
    }
    .full-logo {
        float: left;
    }
    .search-form {
        float: left;
        margin-left: 145px;
        margin-right: 55px;
        i {
            line-height: 44px;
            padding-right: 15px;
        }
        @media only screen and (max-width: 992px) {
            margin-left: 55px;
        }
    }
    .nav-menu__social {
        float: right;
    }
    @media only screen and (max-width: 768px) {
        .search-form,
        .nav-menu__social {
            display: none;
        }
        .nav-menu-icon {
            float: right;
        }
    }
    &.f-navigation {
        &.active {
            nav {
                position: fixed;
                width: 100%;
                height: 100%;
                text-align: center;
                top: 0;
                left: 0;
                padding-top: 70px;
                background-color: #fff;
            }
            .main-menu {
                display: block;
                li {
                    display: block;
                    margin-right: 0;
                    padding: 0;
                    li a {
                        font-size: 12px;
                    }
                }
            }
        }
    }
    nav {
        display: none;
        position: fixed;
        width: 320px;
        right: 0;
        top: 0;
        background-color: #fff;
        height: 100%;
    }
    &.active {
        z-index: 999;
        .main-menu {
            display: block;
        }
        nav {
            display: block;
            padding: 130px 50px;
            overflow-x: hidden;
            overflow-y: auto;
            &:after {
                content: '';
                display: inline-block;
                position: fixed;
                top: 0;
                right: 0;
                width: 320px;
                height: 100px;
                background-color: #fff;
                @media only screen and (max-width: 992px) {
                    display: none;
                }
            }
        }
        .main-menu li:hover > .sub-menu {
            display: none;
        }
        .main-menu {
            li {
                margin-right: 0;
                display: block;
                a {
                    letter-spacing: -1px;
                }
            }
            .sub-menu {
                position: static;
                padding: 0 30px;
            }
            li li {
                padding: 0;
            }
        }
        .nav-menu__social {
            text-align: left;
            li {
                padding: 0 10px;
                &:first-child {
                    padding-left: 0;
                }
                a {
                    font-size: 18px;
                }
            }
        }
        .f-newsletter-form {
            .f-head-h5 {
                font-size: 18px;
                position: relative;
                display: inline-block;
                &:after {
                    content: '';
                    display: inline-block;
                    width: 80px;
                    height: 2px;
                    background-color: #f2f2f2;
                    position: absolute;
                    top: 0;
                    bottom: 0;
                    margin: auto;
                    margin-left: 20px;
                }
            }
            p {
                letter-spacing: .5px;
                margin-top: 5px;
                margin-bottom: 25px;
            }
            form {
                position: relative;
                max-width: 220px;
            }
            button {
                position: absolute;
                top: 0;
                right: 5px;
                border: none;
                outline: none;
                color: #595959;
                background-color: transparent;
                font-weight: 100;
            }
            input {
                width: 100%;
                padding: 0 25px 15px 5px;
                letter-spacing: .5px;
                border-bottom: 1px solid #bfbfbf;
            }
        }
    }
}

// Header style 11
.menu_style11 {
    .nav-menu__tray {
        float: right;
    }
    @media only screen and (max-width: 480px) {
        text-align: center;
    }
    .nav-menu-icon {
        top: 28px;
        right: 30px;
        @media only screen and (max-width: 1500px) {
            display: block;
        }
        @media only screen and (max-width: 992px) {
            top: 20px;
        }
        @media only screen and (max-width: 480px) {
            right: 15px;
        }
    }
    .full-logo {
        float: left;
        @media only screen and (max-width: 480px) {
            width: 100%;
            text-align: left;
            margin-bottom: 20px;
        }
    }
    .nav-menu__log-in {
        margin-left: 95px;
        @media only screen and (max-width: 1860px) {
            margin-left: 20px;
        }
        margin-right: 0;
    }
    .nav-menu__tray,
    .nav-menu__log-in {
        float: left;
        a:before {
            @media only screen and (max-width: 1860px) {
                padding-left: 0;
            }
        }
        @media only screen and (max-width: 480px) {
            float: none;
            display: inline-block;
        }
    }
    .search-form {
        @media only screen and (max-width: 1860px) {
            padding-right: 50px;
        }
        @media only screen and (max-width: 1200px) {
            padding-right: 150px;
        }
        @media only screen and (max-width: 767px) {
            padding-right: 100px;
            float: left;
        }
        @media only screen and (max-width: 480px) {
            padding-right: 0;
        }
    }
   
    .search-form {
        float: right;
        margin-right: 10px;
    }
    .search-form {
        @media only screen and (max-width: 767px) {
            float: left;
        }
        @media only screen and (max-width: 480px) {
            float: none;
            display: inline-block;
            vertical-align: bottom;
        }
    }
    .main-menu li {
        @media only screen and (max-width: 1860px) {
            margin-right: 20px;
        }
    }
    nav {
        @media only screen and (max-width: 1500px) {
                        transition: all .1s ease;
            transform:translateX(100%);
            position: fixed;
            width: 320px;
            right: 0;
            top: 0;
            background-color: #fff;
            height: 100%;
            padding: 130px 50px;
        }
    }
    &.active {
        z-index: 999;
        nav {
            display: block;
            padding: 130px 50px;
            overflow-x: hidden;
            overflow-y: auto;
            z-index: 5;
            transform:translateX(0);
            &:after {
                content: '';
                display: inline-block;
                position: fixed;
                top: 0;
                right: 0;
                width: 320px;
                height: 100px;
                background-color: #fff;
            }
        }
        .main-menu li:hover > .sub-menu {
            display: none;
        }
        .main-menu {
            display: block;
            li {
                margin-right: 0;
                display: block;
                a {
                    letter-spacing: -1px;
                }
            }
            .sub-menu {
                position: static;
                padding: 0 30px;
            }
            li li {
                padding: 0;
            }
        }
        .nav-menu__social {
            text-align: left;
            li {
                padding: 0 10px;
                &:first-child {
                    padding-left: 0;
                }
                a {
                    font-size: 18px;
                }
            }
        }
        .f-newsletter-form {
            .f-head-h5 {
                font-size: 18px;
                position: relative;
                display: inline-block;
                &:after {
                    content: '';
                    display: inline-block;
                    width: 80px;
                    height: 2px;
                    background-color: #f2f2f2;
                    position: absolute;
                    top: 0;
                    bottom: 0;
                    margin: auto;
                    margin-left: 20px;
                }
            }
            p {
                letter-spacing: .5px;
                margin-top: 5px;
                margin-bottom: 25px;
            }
            form {
                position: relative;
                max-width: 220px;
            }
            button {
                position: absolute;
                top: 0;
                right: 5px;
                border: none;
                outline: none;
                color: #595959;
                background-color: transparent;
                font-weight: 100;
            }
            input {
                width: 100%;
                padding: 0 25px 15px 5px;
                letter-spacing: .5px;
                border-bottom: 1px solid #bfbfbf;
            }
        }
    }
    .main-menu .sub-menu {
        &:before {
            content: '';
            display: inline-block;
            position: absolute;
            height: 35px;
            top: -33px;
            left: 0;
            width: 100%;
        }
        top: 73px;
        @media only screen and (max-width: 1500px) {
            &:before {
                display: none;
            }
        }
    }
}

// Header style 12
.menu_style12 {
    .nav-menu__tray {
        float: right;
    }
    text-align: center;
    @media only screen and (max-width: 480px) {
        text-align: center;
    }
    .full-logo {
        float: left;
        @media only screen and (max-width: 480px) {
            width: 100%;
            text-align: left;
            margin-bottom: 20px;
        }
    }
    .nav-menu__log-in {
        margin-left: 0;
        margin-right: 0px;
    }
    .nav-menu__tray,
    .nav-menu__log-in {
        float: right;
        @media only screen and (max-width: 767px) {
            float: left;
        }
        a:before {
            @media only screen and (max-width: 1860px) {
                padding-left: 0;
            }
            @media only screen and (max-width: 1500px) {
                padding-left: 30px;
            }
            @media only screen and (max-width: 767px) {
                padding-left: 20px;
            }
        }
        @media only screen and (max-width: 480px) {
            float: none;
            display: inline-block;
            vertical-align: top;
        }
    }
    nav {
        display: inline-block;
        text-align: left;
        @media only screen and (max-width: 1500px) {
            display: block;
            position: fixed;
            width: 320px;
            right: auto;
            left: 0;
            top: 0;
            background-color: #fff;
            height: 100%;
            transition: all .1s ease;
            transform:translateX(-100%);
            padding: 130px 50px;
            li{
                display: block;
            }
        }
    }
    .search-form {
        float: right;
        margin-right: 10px;
        margin-left: 25px;
        @media only screen and (max-width: 992px) {
            margin-right: 150px;
        }
        @media only screen and (max-width: 767px) {
            margin-right: 0;
            float: left;
        }
        @media only screen and (max-width: 480px) {
            float: none;
            display: inline-block;
            vertical-align: top;
        }
    }
    .main-menu li {
        @media only screen and (max-width: 1860px) {
            margin-right: 20px;
        }
    }
    .nav-menu-icon {
        position: static;
        @media only screen and (max-width: 1500px) {
            display: inline-block;
            float: left;
            margin-left: 140px;
        }
        @media only screen and (max-width: 992px) {
            position: absolute;
            right: 30px;
        }
        @media only screen and (max-width: 480px) {
            right: 15px;
            margin-left: 0;
        }
    }
    &.active {
        z-index: 999;
        .main-menu {
            display: block;
        }
        nav {
            display: block;
            padding: 130px 50px;
            overflow-x: hidden;
            overflow-y: auto;
            @media only screen and (max-width: 1500px) {
                transform:translateX(0);
            }
            &:after {
                content: '';
                display: inline-block;
                position: fixed;
                top: 0;
                right: 0;
                width: 320px;
                height: 100px;
                background-color: #fff;
            }
        }
        .main-menu li:hover > .sub-menu {
            display: none;
        }
        .main-menu {
            li {
                margin-right: 0;
                display: block;
                a {
                    letter-spacing: -1px;
                }
            }
            .sub-menu {
                position: static;
                padding: 0 30px;
            }
            li li {
                padding: 0;
            }
        }
        .nav-menu__social {
            text-align: left;
            li {
                padding: 0 10px;
                &:first-child {
                    padding-left: 0;
                }
                a {
                    font-size: 18px;
                }
            }
        }
        .f-newsletter-form {
            .f-head-h5 {
                font-size: 18px;
                position: relative;
                display: inline-block;
                &:after {
                    content: '';
                    display: inline-block;
                    width: 80px;
                    height: 2px;
                    background-color: #f2f2f2;
                    position: absolute;
                    top: 0;
                    bottom: 0;
                    margin: auto;
                    margin-left: 20px;
                }
            }
            p {
                letter-spacing: .5px;
                margin-top: 5px;
                margin-bottom: 25px;
            }
            form {
                position: relative;
                max-width: 220px;
            }
            button {
                position: absolute;
                top: 0;
                right: 5px;
                border: none;
                outline: none;
                color: #595959;
                background-color: transparent;
                font-weight: 100;
            }
            input {
                width: 100%;
                padding: 0 25px 15px 5px;
                letter-spacing: .5px;
                border-bottom: 1px solid #bfbfbf;
            }
        }
    }
    .main-menu .sub-menu {
        &:before {
            content: '';
            display: inline-block;
            position: absolute;
            height: 35px;
            top: -33px;
            left: 0;
            width: 100%;
        }
        top: 73px;
        @media only screen and (max-width: 1500px) {
            &:before {
                display: none;
            }
        }
    }
}

// Header style 13
.menu_style13 {
    .nav-menu__tray {
        float: right;
    }
    @media only screen and (max-width: 480px) {
        text-align: center;
    }
    .full-logo {
        float: left;
        @media only screen and (max-width: 480px) {
            width: 100%;
            text-align: left;
        }
    }
    .nav-menu__tray {
        padding-left: 0;
        @media only screen and (max-width: 992px) {
            margin-right: 150px;
        }
        @media only screen and (max-width: 480px) {
            margin-right: 0;
        }
    }
    .nav-menu__tray,
    .nav-menu__log-in {
        float: right;
        @media only screen and (max-width: 480px) {
            float: none;
            display: inline-block;
        }
    }
    nav {
        display: inline-block;
    }
    .search-form {
        float: right;
    }

    .nav-menu-icon {
        position: static;
        display: block;
        float: left;
        margin-left: 140px;
        @media only screen and (max-width: 992px) {
            position: absolute;
            right: 30px;
        }
        @media only screen and (max-width: 480px) {
            right: 15px;
            margin-left: 0;
        }
    }
    nav {
        position: fixed;
        width: 320px;
        left: 0;
        top: 0;
        background-color: #fff;
        height: 100%;
        display: block;
        padding: 130px 50px;
        transition: all .1s ease;
        transform: translateX(-100%);
    }
    &.active {
        z-index: 999;
        .main-menu {
            display: block;
        }
        nav {
            transform: translateX(0);
            overflow-x: hidden;
            overflow-y: auto;
            z-index: 3;
            &:after {
                content: '';
                display: inline-block;
                position: fixed;
                top: 0;
                right: 0;
                width: 320px;
                height: 100px;
                background-color: #fff;
            }
        }
        .main-menu li:hover > .sub-menu {
            display: none;
        }
        .main-menu {
            li {
                margin-right: 0;
                display: block;
                a {
                    letter-spacing: -1px;
                }
            }
            .sub-menu {
                position: static;
                padding: 0 30px;
            }
            li li {
                padding: 0;
            }
        }
        .nav-menu__social {
            text-align: left;
            li {
                padding: 0 10px;
                &:first-child {
                    padding-left: 0;
                }
                a {
                    font-size: 18px;
                }
            }
        }
        .f-newsletter-form {
            .f-head-h5 {
                font-size: 18px;
                position: relative;
                display: inline-block;
                &:after {
                    content: '';
                    display: inline-block;
                    width: 80px;
                    height: 2px;
                    background-color: #f2f2f2;
                    position: absolute;
                    top: 0;
                    bottom: 0;
                    margin: auto;
                    margin-left: 20px;
                }
            }
            p {
                letter-spacing: .5px;
                margin-top: 5px;
                margin-bottom: 25px;
            }
            form {
                position: relative;
                max-width: 220px;
            }
            button {
                position: absolute;
                top: 0;
                right: 5px;
                border: none;
                outline: none;
                color: #595959;
                background-color: transparent;
                font-weight: 100;
            }
            input {
                width: 100%;
                padding: 0 25px 15px 5px;
                letter-spacing: .5px;
                border-bottom: 1px solid #bfbfbf;
            }
        }
    }
}

.menu_style14 {
    .nav-menu__tray {
        float: right;
    }
    position: absolute;
    height: 101%;
    overflow-y: auto;
    overflow-x: hidden;
    z-index: 2;
    max-width: 340px;
    padding-right: 30px;
    nav {
        margin-top: 37px;
        margin-bottom: 35px;
    }
    .main-menu li:hover > .sub-menu {
        display: none;
    }
    .main-menu {
        li {
            margin-right: 0;
            display: block;
            a {
                letter-spacing: -1px;
            }
        }
        .sub-menu {
            position: static;
            padding: 0 30px;
        }
        li li {
            padding: 0;
        }
    }
    .nav-menu__social {
        text-align: left;
        li {
            padding: 0 10px;
            &:first-child {
                padding-left: 0;
            }
            a {
                font-size: 18px;
            }
        }
    }
    .f-newsletter-form {
        .f-head-h5 {
            font-size: 18px;
            position: relative;
            display: inline-block;
            &:after {
                content: '';
                display: inline-block;
                width: 80px;
                height: 2px;
                background-color: #f2f2f2;
                position: absolute;
                top: 0;
                bottom: 0;
                margin: auto;
                margin-left: 20px;
            }
        }
        p {
            letter-spacing: .5px;
            margin-top: 5px;
            margin-bottom: 25px;
        }
        form,
        .mc4wp-form {
            display: block;
            box-sizing: border-box;
            position: relative;
            padding-left: 0px;
            max-width: 100%;
            &:after {
                content: "\f10b";
                font-family: "Ionicons";
                width: 17px;
                color: #595959;
                line-height: 20px;
                font-size: 14px;
                display: inline-block;
                position: absolute;
                margin: auto;
                top: 0;
                right: 5px;
            }
            input[type="email"] {
                float: none;
                background-color: transparent;
                display: block;
                line-height: 1;
                min-width: 0;
                max-width: 100%;
                box-sizing: border-box;
                border-radius: 0;
                width: 100%;
                font-family: 'Lato', sans-serif;
                font-size: 14px;
                font-weight: 400;
                transition: color .1s ease;
                padding: 0 25px 15px 5px;
                letter-spacing: .5px;
                border-bottom: 1px solid #bfbfbf;
                @media only screen and (max-width: 768px) {
                    padding-left: 0;
                }
                &:focus {
                    border-color: #ff5a00;
                }
                @media only screen and (max-width: 1200px) {
                    min-width: 0;
                    width: 100%;
                }
            }
            input[type="submit"] {
                float: none;
                z-index: 2;
                border-radius: 0;
                background-color: transparent;
                letter-spacing: 1px;
                right: 0;
                top: 0;
                bottom: 0;
                line-height: 58px;
                font-size: 24px;
                padding: 0 20px;
                margin: auto;
                font-weight: 100;
                position: absolute;
                background-color: transparent;
                width: 60px;
                border: none;
                background-size: auto;
                transition: color .1s ease;
                @media only screen and (max-width: 1200px) {
                    padding: 0;
                    width: 35%;
                }
            }
            @media only screen and (max-width: 991px) {
                padding-left: 0;
                input {
                    padding: 14px 15px;
                }
                button {
                    padding: 0 15px;
                }
            }
        }
    }
    .nav-menu-icon {
        @media only screen and (max-width: 992px) {
            right: 30px;
        }
    }
    &.active {
        nav {
            height: auto;
            position: static;
        }
    }
    @media only screen and (max-width: 1200px) {
        padding-left: 30px;
        padding-right: 30px;
        max-width: 300px;
    }
    @media only screen and (max-width: 768px) {
        position: fixed;
        top: 0;
        left: 0;
        z-index: 3;
        width: 100%;
        height: auto;
        max-width: 100%;
        .nav-menu-icon {
            right: 10px;
        }
        .nav-menu__social,
        .f-newsletter-form,
        nav {
            display: none;
        }
        .f-start-block__content-wrap {
            padding-left: 0;
        }
        &.active {
            height: 100%;
            position: fixed;
            .nav-menu__social,
            .f-newsletter-form,
            nav {
                padding-top: 0;
                display: block;
                text-align: center;
                margin-top: 30px;
                margin-bottom: 30px;
            }
            .f-newsletter-form {
                .f-head-h5 {
                    &:after {
                        display: none;
                    }
                }
                form {
                    max-width: 320px;
                    margin-left: auto;
                    margin-right: auto;
                }
            }
        }
    }
}
