.f-portfolio {
    width: 100%;
    &:after {
        clear: both;
        display: table;
        content: '';
    }
}

.f-portfolio__item {
    height: 500px;
    float: left;
    width: 33.33%;
    @media only screen and (max-width: 992px) {
        height: 300px;
    }
    @media only screen and (max-width:600px) {
        width: 100%;
    }
}

.f-portfolio__content-wrap {
    width: 100%;
    height: 100%;
    display: table;
}

.f-portfolio_style2 {
    .f-portfolio__item {
        position: relative;
        padding-left: 15px;
        padding-right: 15px;
        &:after {
            position: absolute;
            top: 0;
            left: 0;
            content: '';
            display: inline-block;
            width: 100%;
            height: 100%;
            opacity: 0.8;
            background-color: #333;
        }
        @media only screen and (max-width:767px) {
            width: 100%;
            border-bottom: 1px solid #fff;
        }
    }
}

.f-portfolio__text {
    display: table-cell;
    position: relative;
    z-index: 2;
    vertical-align: middle;
    text-align: center;
    .f-head-h3 {
        color: white;
        line-height: 28px;
    }
    p {
        margin-bottom: 35px;
        margin-top: 25px;
        letter-spacing: .7px;
        color: #ccc;
        max-width: 310px;
        margin-left: auto;
        margin-right: auto;
    }
}
