.f-counter-wrap {
    text-align: center;
    position: relative;
    &:before {
        content: '';
        display: inline-block;
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        background-color: rgba( 255, 90, 0, .75);
    }
}

.f-counter {
    display: inline-block;
    width: 24%;
    position: relative;
    z-index: 2;
    @media only screen and (max-width: 768px) {
        width: 49%;
        padding-top: 15px;
        padding-bottom: 15px;
    }
    @media only screen and (max-width:600px) {
        width: 100%;
        padding-top: 15px;
        padding-bottom: 15px;
    }
    .f-head-h3 {
        color: #fff;
        font-family: 'Lato', sans-serif;
        font-size: 25px;
        font-weight: 700;
        text-transform: uppercase;
        line-height: 1.5;
        margin-bottom: 10px;
        letter-spacing: 3px;
        @media only screen and (max-width: 991px) {
            font-size: 20px;
        }
    }
    .f-head-h2 {
        color: white;
        font-size: 55px;
        letter-spacing: 3px;
        font-weight: 900;
        @media only screen and (max-width: 991px) {
            font-size: 30px;
        }
    }
}
