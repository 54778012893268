a.f-view-more-btn,
.f-view-more-btn {
    color: white;
    font-size: 14px;
    font-weight: 700;
    line-height: 36px;
    position: relative;
    letter-spacing: 1.5px;
    z-index: 2;
    transition: color .3s ease;
    &:after {
        left: 0;
        content: '';
        display: inline-block;
        width: 50px;
        height: 2px;
        background-color: #f26522;
        border-radius: 1px;
        position: absolute;
        bottom: -5px;
        transition: width .3s ease;
    }
    &:hover {
        &:after {
            width: 100%;
        }
    }
}

a.f-dark-btn,
.f-dark-btn {
    background-color: #333;
    line-height: 44px;
    padding: 0 30px;
    text-align: center;
    display: inline-block;
    font-size: 14px;
    letter-spacing: .5px;
    color: #fff;
    font-family: 'Poppins', sans-serif;
    transition: opacity .5s ease;
    &:hover {
        opacity: .9;
    }
}

a.f-orang-btn,
.f-orang-btn {
    background-color: #ff5a00;
    line-height: 50px;
    color: #fffdfc;
    font-family: 'Lato', sans-serif;
    font-size: 14px;
    font-weight: 700;
    text-transform: uppercase;
    padding: 0 35px;
    transition: opacity .5s ease;
    &:hover {
        opacity: .9;
    }
}
