.f-team {
    max-width: 1200px;
    margin-left: auto;
    margin-right: auto;
    @media only screen and (max-width: 767px) {
        text-align: center;
    }
    &:after {
        clear: both;
        content: '';
        display: table;
    }
}

.f-team-head {
    width: 50%;
    padding-left: 15px;
    float: left;
    @media only screen and (max-width: 1200px) {
        width: 100%;
        padding-left: 15px;
        padding-right: 15px;
    }
    p {
        letter-spacing: .5px;
        max-width: 370px;
        @media only screen and (max-width: 1200px) {
            max-width: 100%;
        }
    }
}

.f-team-item {
    float: left;
    width: calc(25% - 30px);
    margin-left: 15px;
    margin-right: 15px;
    position: relative;
    height: 270px;
    margin-bottom: 30px;
    @media only screen and (max-width: 992px) {
        width: calc(33% - 30px);
    }
    @media only screen and (max-width: 767px) {
        width: 100%;
        max-width: 320px;
        display: inline-block;
        float: none;
        margin-left: 0;
    }
    &:hover {
        .f-team-hide {
            opacity: 1;
        }
    }
}

.f-team-hide {
    opacity: 0;
    position: absolute;
    transition: opacity .3s ease;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #f2f2f2;
    text-align: center;
    a {
        left: 0;
        right: 0;
        position: relative;
        top: 50%;
        transform: translateY(-50%);
        width: auto;
        margin: auto;
        height: 44px;
    }
}
