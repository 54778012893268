.f-single-progect-content {
    max-width: 1440px;
    margin-left: auto;
    margin-right: auto;
}

.f-single-progect-text {
    max-width: 700px;
    padding-top: 45px;
    @media only screen and (max-width: 1199px) {
        padding-top: 0;
        max-width: 100%;
    }
    p {
        letter-spacing: .5px;
        margin-bottom: 25px;
    }
    .f-head-h2 {
        letter-spacing: 1px;
    }
    .breadcrumb {
        margin-top: 0;
        padding-left: 0;
        padding-top: 5px;
        margin-bottom: 40px;
        a,
        li {
            text-transform: none;
        }
        > li + li:before {
            padding: 0;
        }
    }
}

.f-progect-info {
    margin-bottom: 15px;
    padding: 45px 50px;
    @media only screen and (max-width: 767px) {
        padding: 30px;
    }
   .f-head-h5 {
        font-family: 'Lato', sans-serif;
        text-transform: uppercase;
        margin-bottom: 8px;
        letter-spacing: 1px;
    }
    a,
    span {
        color: grey;
        font-size: 14px;
        line-height: 28px;
        letter-spacing: .5px;
    }
    a{
        transition: color .3s ease;
        &:hover{
            color:#ff5a00;
        }
    }
    li {
        margin-bottom: 20px;
        &:last-child {
            margin-bottom: 0px;
        }
    }
    .nav-menu__social {
        text-align: left;
        li {
            padding: 0 10px;
            margin-bottom: 0;
            &:first-child {
                padding-left: 0;
            }
            a {
                font-size: 18px;
            }
        }
    }
}

.f-progect-info_style2 {
    padding: 0;
    ol {
        display: inline-block;
        li {}
    }
    ul {
        li {
            margin-bottom: 5px;
        }
       .f-head-h5 {
            vertical-align: top;
            text-transform: none;
            font-size: 14px;
            display: inline-block;
            max-width: 170px;
            width: 100%;
        }
        span {
            font-size: 14px;
        }
        margin-top: 10px;
    }
}

.f-progect-info_style3 {
    padding: 55px 0 35px;
    li {
        margin-bottom: 35px;
    }
}

// f-single-project__item
.f-single-project-items {
    position: relative;
    width: 66%;
    max-width: 1270px;
    margin-left: auto;
    margin-right: auto;
    .swiper-pagination-clickable {
        .swiper-pagination-bullet {
            width: 15px;
            height: 15px;
            background-color: white;
            transition: background .5s ease;
            opacity: 1;
        }
        .swiper-pagination-bullet-active {
            background-color: #ff5a00;
        }
    }
    @media only screen and (max-width: 1600px) {
        width: 50%;
    }
    @media only screen and (max-width: 1199px) {
        width: 100%;
    }
    @media only screen and (max-width: 992px) {
        padding-bottom: 100px;
    }
}

.f-single-project__item {
    height: 500px;
    .swiper-pagination-fraction,
    .swiper-pagination-custom,
    .swiper-container-horizontal > .swiper-pagination-bullets {
        bottom: 40px;
    }
    .swiper-container-horizontal > .swiper-pagination-bullets .swiper-pagination-bullet {
        margin: 0 15px;
    }
}

.f-poject-btn {
    position: absolute;
    z-index: 2;
    color: white;
    font-family: 'Poppins', sans-serif;
    font-size: 14px;
    font-weight: 600;
    line-height: 44px;
    top: 0;
    bottom: 0;
    margin: auto;
    letter-spacing: .5px;
    height: 44px;
    left: 60px;
    &:before {
        content: "\f3d2";
        margin-left: 15px;
        margin-right: 15px;
        display: inline-block;
        font-family: "Ionicons";
        width: 44px;
        height: 44px;
        background-color: #ff5a00;
        text-align: center;
        line-height: 44px;
    }
    &:after {
        content: "\f3d1";
        margin-left: 15px;
        margin-right: 15px;
        display: inline-block;
        font-family: "Ionicons";
        width: 44px;
        height: 44px;
        background-color: #ff5a00;
        text-align: center;
        line-height: 44px;
    }
    @media only screen and (max-width: 992px) {
        color: #333;
        &:hover {
            opacity: .8;
        }
        &:after,
        &:before {
            color: #fff;
        }
    }
    @media only screen and (max-width: 480px) {
        &:after,
        &:before {
            width: 30px;
            height: 30px;
            line-height: 30px;
            margin-left: 5px;
            margin-right: 5px;
        }
    }
}

.f-single-project__item-next {
    &:after {
        content: '';
        display: inline-block;
        width: 100%;
        height: 100%;
        background-color: rgba(51, 51, 51, .8);
        position: absolute;
        top: 0;
        left: 0;
        transition: opacity .3s ease;
    }
    &:hover {
        &:after {
            opacity: .8;
        }
    }
    position: absolute;
    top: 0;
    width: 100%;
    left: calc(100% + 30px);
    .f-poject-btn {
        &:before {
            display: none;
        }
    }
    @media only screen and (max-width: 1199px) {
        left: calc( 100% - 180px);
        background: none!important;
        .f-poject-btn {
            left: 15px;
        }
    }
    @media only screen and (max-width: 992px) {
        height: auto;
        top: calc( 100% - 50px);
    }
    @media only screen and (max-width: 480px) {
        .f-poject-btn {
            left: 35px;
        }
    }
}

.f-single-project__item-prev {
    &:after {
        content: '';
        display: inline-block;
        width: 100%;
        height: 100%;
        background-color: rgba(51, 51, 51, .8);
        position: absolute;
        top: 0;
        left: 0;
        transition: opacity .3s ease;
    }
    &:hover {
        &:after {
            opacity: .8;
        }
    }
    position: absolute;
    top: 0;
    width: 100%;
    right: calc(100% + 30px);
    .f-poject-btn {
        left: auto;
        right: 60px;
        &:after {
            display: none;
        }
    }
    @media only screen and (max-width: 1199px) {
        right: calc( 100% - 180px);
        background: none!important;
        .f-poject-btn {
            right: 15px;
        }
    }
    @media only screen and (max-width: 992px) {
        height: auto;
        top: calc( 100% - 50px);
    }
    @media only screen and (max-width: 480px) {
        .f-poject-btn {
            right: 35px;
        }
    }
}

.f-single-project__slide {
    height: 500px;
}

// single-project-items_style1
.f-single-project-items_style1 {
    &:after {
        clear: both;
        display: table;
        content: '';
    }
    max-width: 1440px;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    @media only screen and (max-width: 1470px) {
        padding-left: 15px;
        padding-right: 15px;
    }
    .f-single-project__item {
        float: left;
    }
    .f-audio-wrap,
    .f-video-wrap {
        height: 100%;
    }
    .f-single-project__item_style1 {
        width: 80%;
        height: 560px;
        @media only screen and (max-width: 991px) {
            width: 70%;
            height: 500px;
        }
        @media only screen and (max-width: 768px) {
            width: 100%;
        }
        .f-single-project__slide {
            height: 100%;
        }
    }
    .f-single-project__item_style2 {
        width: calc(20% - 30px);
        height: 265px;
        margin-left: 30px;
        margin-bottom: 30px;
        @media only screen and (max-width: 991px) {
            height: 237.5px;
            margin-left: 25px;
            width: calc(30% - 25px);
            margin-bottom: 25px;
        }
        @media only screen and (max-width: 768px) {
            display: none;
        }
    }
}

// single-project-items_style2
.f-single-project-items_style2 {
    .f-audio-wrap,
    .f-video-wrap,
    .f-single-project__slide {
        height: 100%;
    }
    width: 100%;
    .f-single-project__item {
        float: left;
        width: 100%;
        height: 600px;
        margin-bottom: 30px;
        @media only screen and (max-width: 600px) {
            height: 300px;
        }
    }
    .f-single-project__item_style3 {
        height: 1230px;
        width: 50%;
        @media only screen and (max-width: 1199px) {
            height: 630px;
        }
        @media only screen and (max-width: 600px) {
            display: none;
        }
    }
    .f-single-project__item_style2 {
        width: calc( 50% - 30px);
        margin-left: 30px;
        @media only screen and (max-width: 1199px) {
            height: 300px;
        }
        @media only screen and (max-width: 600px) {
            display: none;
        }
    }
}

// single-project-items_style3
.f-single-project-items_style3 {
    width: 100%;
    .f-audio-wrap,
    .f-video-wrap,
    .f-single-project__slide {
        height: 100%;
    }
    .f-single-project__item {
        height: 1250px;
        @media only screen and (max-width: 1400px) {
            padding-right: 0;
        }
        @media only screen and (max-width: 1199px) {
            height: 800px;
        }
        @media only screen and (max-width: 768px) {
            height: 500px;
        }
    }
    .f-single-project__item_style2 {
        height: 600px;
        margin-top: 50px;
        @media only screen and (max-width: 1199px) {
            height: 300px;
        }
        @media only screen and (max-width: 768px) {
            margin-top: 30px;
            height: 150px;
        }
    }
}

.f-single-project__item_iframe-style {
    .f-audio-wrap,
    .f-video-wrap {
        height: 100%;
    }
}

// f-single-progect-content_left
.f-single-progect-content_left {
    border: none;
    header {
        text-align: left;
    }
    .f-head-h4 {
        letter-spacing: 0;
        font-weight: 900;
    }
    .f-single-progect-text {
        p {
            margin-top: 10px;
            letter-spacing: .5px;
        }
    }
    .breadcrumb {
        padding-left: 0;
    }
    max-width: 570px;
    float: right;
    padding-right: 100px;
    @media only screen and (max-width: 1400px) {
        padding-right: 0;
    }
    @media only screen and (max-width: 767px) {
        max-width: 100%;
    }
}

// .f-single-progect-content_right 
.f-single-progect-content_right {
    border: none;
    header {
        text-align: left;
    }
   .f-head-h4 {
        letter-spacing: 0;
        font-weight: 900;
    }
    .f-single-progect-text {
        p {
            margin-top: 10px;
            letter-spacing: .5px;
        }
    }
    .breadcrumb {
        padding-left: 0;
    }
    max-width: 570px;
    float: left;
    padding-left: 100px;
    @media only screen and (max-width: 1400px) {
        padding-left: 0;
    }
    @media only screen and (max-width: 767px) {
        max-width: 100%;
    }
}
