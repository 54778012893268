/*-------------------------------------------------------------------------------------------------------*/
/*This is main CSS file that contains custom style rules used in this template*/
/*-------------------------------------------------------------------------------------------------------*/
/* Template Name: Fuller.*/
/* Version: 1.0 Initial Release*/
/* Build Date: 02-03-2016*/
/* Author: */
/* Website: */
/* Copyright: (C) 2016 */
/*------------------------------------------------------------------------------------------------------*/
/*--------------------------------------------------------*/
/* TABLE OF CONTENTS: */
/*--------------------------------------------------------*/
/*--------------------------------------------------------
Global  settings
Text settings
Headers
start-swiper
about
feature
portfolio
choose-me
testimonial
blog
subscribefooter
start-block
portfolio-slider
portfolio-slider
content-block
blog-posts
isotope
count
contact
contact-form
partrers
full-screen-swiper
single-progect
video-wrap
audio-wrap
full-windows-block
team
skills
full-slider
blog-widgets
single-post
coming-soon
map
services
404
pagination
buttons
start-header
popUp
Paddings and margins
--------------------------------------------------------*/
/*---------------------------------------------------------------------------------------*/
/* Global  settings */
/*--------------------------------------------------------------------------------------*/
html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}

/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}

ol,
ul {
  list-style: none;
}

blockquote,
q {
  quotes: none;
}

blockquote:before,
blockquote:after,
q:before,
q:after {
  content: '';
  content: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

a {
  text-decoration: none;
}

a:hover,
a:active,
a:focus,
a:visited {
  text-decoration: none;
  color: #333;
}

a:active,
a:focus {
  outline: none;
}

html {
  box-sizing: border-box;
  overflow-x: hidden;
}

.text-left {
  text-align: left !important;
}

.text-right {
  text-align: right !important;
}

.text-center {
  text-align: center !important;
}

/* background image settings */
.js-bg-img_with-height,
.js-bg-img {
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}

/* background colors */
.bg-c-1 {
  background-color: #e6e6e6;
}

.bg-c-2 {
  background-color: #fafafa;
}

.bg-c-3 {
  background-color: #fffdfc;
}

/* color */
.c-1 {
  color: grey;
}

.c-2 {
  color: #ff5a00;
}

.f-header_clearfix .f-head-h2 {
  float: left;
}

.f-header_clearfix .f-view-more-btn {
  float: right;
}

.f-header {
  position: relative;
  z-index: 2;
}

.f-header .f-head-h2 {
  letter-spacing: 1px;
  font-weight: 900;
}

.f-header:after {
  clear: both;
  content: '';
  display: table;
}

.f-header .f-view-more-btn {
  color: #595959;
}

@media only screen and (max-width: 600px) {
  .f-header .f-head-h2 {
    float: none;
    margin-bottom: 0;
  }
  .f-header .f-head-h2:after {
    display: none;
  }
  .f-header .f-view-more-btn {
    float: none;
  }
}

.f-header_after .f-head-h2 {
  position: relative;
  display: inline-block;
}

@media only screen and (max-width: 600px) {
  .f-header_after .f-head-h2 {
    display: block;
  }
}

.f-header_after .f-head-h2:after {
  content: '';
  display: inline-block;
  width: 120px;
  height: 2px;
  background-color: #e6e6e6;
  position: absolute;
  line-height: 100%;
  margin-left: 25px;
  padding-left: 30px;
  top: 0;
  margin: auto;
  left: calc(100% + 50px);
  bottom: 0;
}

.f-header_style2 .f-view-more-btn {
  color: #fff;
}

.f-header_style2 .f-head-h2 {
  color: #fff;
}

.f-header_style2 .f-head-h2:after {
  background-color: #ff5a00;
}

.f-header_style3 .f-head-h2 {
  color: #333;
  font-weight: 900;
  font-size: 36px;
}

.f-header_style3 .f-head-h5 {
  color: #eb4800;
  font-family: 'Lato', sans-serif;
  font-size: 16px;
  font-weight: 900;
  text-transform: uppercase;
  letter-spacing: 2.5px;
  line-height: 36px;
}

.border-b-dark {
  border-bottom: 1px solid #f2f2f2;
}

@media only screen and (max-width: 1400px) {
  .f-max-w-block .container {
    max-width: 90%;
  }
}

@media only screen and (max-width: 600px) {
  .f-max-w-block .container {
    max-width: 100%;
  }
}

/*---------------------------------------------------------------------------------------*/
/* preload */
/*--------------------------------------------------------------------------------------*/
.pace {
  background-color: #fff;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1000;
}

.pace-inactive {
  display: none;
}

.pace .pace-progress {
  background: #29d;
  position: fixed;
  z-index: 2000;
  top: 0;
  right: 100%;
  width: 100%;
  height: 2px;
}

/*--------------------------------------------------------------------------------------*/
/* Text settings */
/*--------------------------------------------------------------------------------------*/
/* fonts */
*,
h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: 'Poppins', sans-serif;
}

p,
span,
li,
a {
  font-family: 'Lato', sans-serif;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin-top: 0;
  margin-bottom: 0;
}

h1 {
  color: #333;
  font-size: 48px;
  font-weight: 900;
}

h2 {
  color: #333;
  font-size: 36px;
  font-weight: 900;
}

h3 {
  color: #333;
  font-size: 24px;
  font-weight: 900;
}

h4 {
  color: #333;
  font-size: 20px;
  font-weight: 600;
}

h5 {
  color: #333;
  font-size: 16px;
  font-weight: 600;
  line-height: 28px;
}

h6 {
  font-size: 14px;
  color: #595959;
  font-weight: 600;
  text-transform: uppercase;
}

p {
  font-size: 14px;
  font-weight: 400;
  line-height: 25px;
  color: grey;
}

textarea,
input {
  color: #999;
  font-family: 'Lato', sans-serif;
  font-size: 14px;
  border: none;
  outline: none;
}

textarea:hover, textarea:focus,
input:hover,
input:focus {
  border: none;
  outline: none;
}

button {
  color: white;
  font-family: 'Poppins', sans-serif;
  font-size: 14px;
  border: none;
  outline: none;
}

button:hover, button:focus {
  border: none;
  outline: none;
}

@media only screen and (max-width: 768px) {
  h1 {
    font-size: 36px;
  }
  h2 {
    font-size: 30px;
  }
}

/*--------------------------------------------------------------------------------------*/
/* Headers */
/*--------------------------------------------------------------------------------------*/
/* DEFAULT */
.f-navigation {
  position: relative;
  padding: 30px 75px;
  box-sizing: border-box;
  min-height: 40px;
  background-color: #fff;
}

.f-navigation:after {
  content: '';
  display: table;
  clear: both;
}

.f-navigation nav {
  z-index: 2;
}

.nav-menu-icon-close {
  position: absolute !important;
  z-index: 9999999;
  color: #808080;
  top: 50px !important;
  right: 50px !important;
}

.main-menu {
  margin: 0;
}

.main-menu li {
  font-weight: 500;
  display: inline-block;
  margin-right: 52px;
  position: relative;
}

.main-menu li ul {
  padding: 30px 30px;
  background-color: #fff;
}

.main-menu li li {
  padding: 0;
  margin: 0;
}

.main-menu li li a {
  line-height: 35px;
  font-size: 14px;
}

.main-menu li a {
  color: #808080;
  letter-spacing: .5px;
  font-size: 16px;
  line-height: 40px;
  text-transform: uppercase;
  font-family: 'Poppins', sans-serif;
}

.main-menu li a:hover {
  color: #ff5a00;
}

.main-menu .sub-menu {
  display: none;
  position: absolute;
  white-space: nowrap;
  z-index: 3;
}

/* Active menu items */
.main-menu .current-menu-item a {
  color: #808080;
}

.main-menu .current-menu-parent > a {
  color: #ff5a00;
}

/* Menu logo */
.full-logo a {
  vertical-align: middle;
  color: #333;
  display: inline-flex;
  font-family: 'Poppins', sans-serif;
  font-weight: 400;
}

.full-logo img {
  max-height: 55px;
}

/* Menu search  */
.search-form {
  position: relative;
  transition: color .1s ease;
}

.search-form a {
  color: grey;
  vertical-align: middle;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: .5px;
  text-transform: uppercase;
  line-height: 1;
}

@media only screen and (max-width: 767px) {
  .search-form a {
    font: 0/0 serif;
    text-shadow: none;
    color: transparent;
  }
}

.search-form i {
  color: grey;
  vertical-align: middle;
  font-size: 36px;
  font-weight: 400;
}

@media only screen and (max-width: 1200px) {
  .search-form i {
    font-size: 32px;
  }
}

@media only screen and (max-width: 767px) {
  .search-form i {
    font-family: "Ionicons";
    font-size: 36px;
    color: grey;
    padding-left: 10px;
    padding-right: 10px;
  }
}

.search-form:hover a,
.search-form:hover i {
  color: #ff5a00;
}

.search-form-body {
  position: absolute;
  top: 78px;
  display: none;
  width: 425px;
}

.search-form-body form {
  position: relative;
}

.search-form-body input {
  padding: 25px;
  padding-right: 75px;
  color: #999;
  font-family: 'Lato', sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 25px;
  display: inline-block;
  letter-spacing: .53px;
  width: 100%;
}

.search-form-body input[type='submit'],
.search-form-body button {
  position: absolute;
  top: 0;
  right: 0;
  width: 75px;
  height: 100%;
  color: transparent;
  font-size: 24px;
  text-align: center;
  line-height: 75px;
  transition: color .1s ease;
  background: transparent url(../img/arrow2.png) center center no-repeat;
  background-size: 17px 17px;
}

@media only screen and (max-width: 767px) {
  .search-form-body {
    width: 100%;
    height: 100%;
    background-color: #fff;
    padding-top: 100px;
    position: fixed;
    top: 0;
    left: 0;
  }
  .search-form-body form {
    border: 1px solid #ccc;
    border-left: none;
    margin-left: 15px;
    margin-right: 15px;
    box-sizing: border-box;
    border-right: none;
  }
}

.nav-menu__tray-body {
  width: 420px;
  padding: 40px 35px;
  position: absolute;
  display: none;
  top: 73px;
  left: 0;
  background-color: #fff;
  z-index: 3;
}

.nav-menu__tray-body li {
  width: 100%;
  position: relative;
  margin-bottom: 25px;
}

.nav-menu__tray-body li:last-child {
  margin-bottom: 40px;
}

.nav-menu__tray-body li:after {
  content: '';
  display: table;
  clear: both;
}

@media only screen and (max-width: 767px) {
  .nav-menu__tray-body {
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    padding-left: 30px;
    padding-right: 30px;
  }
}

.nav-menu__tray-body_right {
  left: auto;
  right: 0;
}

.nav-menu__tray-img {
  float: left;
}

@media only screen and (max-width: 479px) {
  .nav-menu__tray-img {
    display: none;
  }
}

.nav-menu__tray-info {
  float: left;
  padding: 20px 25px;
}

.nav-menu__tray-info .f-head-h5 {
  color: #333;
  font-weight: 500;
  letter-spacing: -1px;
}

.nav-menu__tray-info span {
  color: grey;
  font-size: 14px;
  font-weight: 600;
}

.nav-menu__tray-close {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  width: 20px;
  height: 20px;
  color: #333;
  font-size: 18px;
  font-weight: 400;
  cursor: pointer;
}

.nav-menu-total {
  border-top: 2px solid #f2f2f2;
  border-bottom: 2px solid #f2f2f2;
}

.nav-menu-total:after {
  content: '';
  display: table;
  clear: both;
}

.nav-menu-total span {
  line-height: 75px;
  float: left;
  color: #333;
  font-family: 'Poppins', sans-serif;
  font-size: 18px;
  font-weight: 600;
}

.nav-menu-total .nav-menu-total-price {
  float: right;
}

/* log in and tray buttons */
.nav-menu__tray,
.nav-menu__log-in {
  position: relative;
  padding-right: 10px;
  transition: color .1s ease;
}

@media only screen and (max-width: 767px) {
  .nav-menu__tray,
  .nav-menu__log-in {
    padding-right: 0;
  }
}

.nav-menu__tray a,
.nav-menu__log-in a {
  color: grey;
  font-size: 16px;
  font-weight: 500;
  line-height: 36px;
  text-transform: uppercase;
  vertical-align: middle;
  letter-spacing: .5px;
}

@media only screen and (max-width: 767px) {
  .nav-menu__tray a,
  .nav-menu__log-in a {
    font: 0/0 serif;
    text-shadow: none;
    color: transparent;
  }
}

.nav-menu__tray a:before,
.nav-menu__log-in a:before {
  padding-left: 50px;
  padding-right: 20px;
  float: left;
  font-size: 36px;
  font-weight: 400;
  line-height: 1;
  vertical-align: middle;
  text-transform: uppercase;
}

@media only screen and (max-width: 767px) {
  .nav-menu__tray a:before,
  .nav-menu__log-in a:before {
    font-family: "Ionicons";
    font-size: 36px;
    color: grey;
    padding-left: 10px;
    padding-right: 10px;
  }
}

.nav-menu__tray:hover > a,
.nav-menu__tray:hover i,
.nav-menu__log-in:hover > a,
.nav-menu__log-in:hover i {
  color: #ff5a00;
}

.nav-menu__tray:hover .f-close-tray-body,
.nav-menu__log-in:hover .f-close-tray-body {
  color: #000;
}

a.f-close-nav-pop-up,
.f-close-nav-pop-up {
  display: none;
  position: absolute;
  top: 15px;
  right: 30px;
  width: 30px;
  height: 30px;
  z-index: 9;
  line-height: 30px;
  text-align: center;
  color: black;
}

@media only screen and (max-width: 767px) {
  a.f-close-nav-pop-up,
  .f-close-nav-pop-up {
    display: block;
  }
}

a.f-close-nav-pop-up:before,
.f-close-nav-pop-up:before {
  content: "\f129";
  font-family: "Ionicons";
  display: inline-block;
  position: absolute;
  top: 15px;
  left: 0;
  font-size: 30px;
  font-weight: 400;
  color: black;
  cursor: pointer;
}

.nav-menu__log-in-body {
  position: absolute;
  top: 73px;
  text-align: left;
  display: none;
  left: 0;
  width: 425px;
  z-index: 3;
  background-color: #fff;
  padding: 35px 30px 20px;
}

@media only screen and (max-width: 767px) {
  .nav-menu__log-in-body {
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
  }
}

.nav-menu__log-in-body a {
  text-transform: none;
  color: #ff5a00;
  font-size: 14px;
}

.nav-menu__log-in-body .f-head-h5 {
  letter-spacing: .5px;
}

.nav-menu__log-in-body p {
  margin-top: 10px;
  margin-bottom: 10px;
  letter-spacing: .7px;
}

.nav-menu__log-in-body form {
  width: 100%;
  margin-top: 15px;
  display: inline-block;
}

.nav-menu__log-in-body form button {
  width: 100%;
  margin-top: 15px;
  line-height: 50px;
  margin-bottom: 15px;
}

.nav-menu__log-in-body form input {
  background-color: transparent;
  padding: 0 20px;
  width: 100%;
  border-bottom: 1px solid #bfbdbd;
  line-height: 55px;
  margin-bottom: 25px;
  color: #999;
  font-family: 'Lato', sans-serif;
  font-size: 14px;
  font-weight: 400;
  letter-spacing: .5px;
}

.nav-menu__log-in-body form input:nth-child(2) {
  margin-bottom: 5px;
}

.nav-menu__log-in-body form p:after {
  clear: both;
  content: '';
  display: table;
}

.nav-menu__log-in-body form p label {
  float: left;
  transition: color .1s ease;
}

.nav-menu__log-in-body form p label:before {
  text-align: center;
  content: "\f3fd";
  display: inline-block;
  line-height: 12px;
  display: inline-block;
  font-family: "Ionicons";
  color: transparent;
  width: 14px;
  margin-right: 15px;
  height: 14px;
  border: 1px solid #bfbdbd;
  vertical-align: middle;
}

@media only screen and (max-width: 767px) {
  .nav-menu__log-in-body form p label {
    float: none;
    margin-top: 10px;
  }
}

.nav-menu__log-in-body form p a {
  float: right;
  color: grey;
  font-family: 'Lato', sans-serif;
  font-size: 14px;
  font-weight: 400;
  text-transform: none;
  vertical-align: middle;
  transition: color .1s ease;
}

@media only screen and (max-width: 767px) {
  .nav-menu__log-in-body form p a {
    float: none;
    display: block;
    margin-top: 30px;
    text-align: left;
  }
}

.nav-menu__log-in-body form p a:hover {
  color: #ff5a00;
}

.nav-menu__log-in-body form p input[type='checkbox'] {
  display: none;
}

.nav-menu__log-in-body form p input[type='checkbox']:checked + label {
  color: #ff5a00;
}

.nav-menu__log-in-body form p input[type='checkbox']:checked + label:before {
  border-color: #ff5a00;
  color: #ff5a00;
}

.nav-menu__tray-body_style2 {
  width: 460px;
  height: 100%;
  padding-left: 50px;
  padding-right: 40px;
  position: fixed;
  top: 0;
  right: 0;
  left: auto;
  display: block;
  transform: translateX(100%);
  transition: all .1s ease;
  text-align: left;
}

.nav-menu__tray-body_style2.slide-right {
  transform: translateX(0);
}

@media only screen and (max-width: 767px) {
  .nav-menu__tray-body_style2 {
    width: 100%;
    padding-left: 15px;
    padding-right: 15px;
  }
}

.nav-menu__tray-body_style2 .nav-menu-total {
  margin-top: 31vh;
}

@media only screen and (max-width: 767px) {
  .nav-menu__tray-body_style2 .nav-menu-total {
    margin-top: 30px;
  }
}

.nav-menu__tray-body_style2 .f-head-h5 {
  letter-spacing: 1px;
}

.nav-menu__tray-body_style2 .f-head-h3 {
  position: relative;
  display: inline-block;
}

@media only screen and (max-width: 600px) {
  .nav-menu__tray-body_style2 .f-head-h3 {
    display: block;
  }
}

.nav-menu__tray-body_style2 .f-head-h3:after {
  content: '';
  display: inline-block;
  width: 120px;
  height: 2px;
  background-color: #e6e6e6;
  position: absolute;
  line-height: 100%;
  margin-left: 25px;
  padding-left: 30px;
  top: 0;
  margin: auto;
  left: calc(100% + 25px);
  bottom: 0;
}

.nav-menu__tray-body_style2 .nav-menu__tray-info {
  text-align: left;
}

.f-close-tray-body {
  position: absolute;
  z-index: 4;
  top: 45px;
  right: 50px;
  width: 20px;
  height: 20px;
  font-size: 30px;
  font-weight: 400;
  color: black;
  cursor: pointer;
}

@media only screen and (max-width: 767px) {
  .f-close-tray-body {
    top: 15px;
    right: 15px;
  }
}

.nav-menu__log-in-body_right {
  left: auto;
  right: 0;
}

.nav-menu__tray-btns {
  margin-top: 35px;
  text-align: center;
}

.nav-menu__tray-btns:after {
  content: '';
  display: table;
  clear: both;
}

.nav-menu__tray-btns a {
  float: left;
  color: #fffdfc;
  text-transform: none;
  font-size: 14px;
  width: 45%;
  line-height: 50px;
  font-weight: 100;
  letter-spacing: -1px;
  margin-right: 10%;
}

@media only screen and (max-width: 767px) {
  .nav-menu__tray-btns a {
    float: none;
    display: block;
    width: 100%;
    margin-bottom: 30px;
  }
}

.nav-menu__tray-btns a:last-child {
  margin-right: 0;
}

/* log in and tray buttons */
.nav-menu__log-in2 {
  float: right;
  transition: color .1s ease;
  transition: color .1s ease;
}

@media only screen and (max-width: 480px) {
  .nav-menu__log-in2 {
    float: none;
    vertical-align: top;
    display: inline-block;
  }
}

.nav-menu__log-in2:hover i,
.nav-menu__log-in2:hover .dropdown button,
.nav-menu__log-in2:hover span {
  color: #ff5a00;
}

.nav-menu__log-in2 a {
  display: inline-block;
  vertical-align: middle;
  width: 36px;
  height: 36px;
}

.nav-menu__log-in2 a img {
  border-radius: 100%;
  max-width: 100%;
}

@media only screen and (max-width: 767px) {
  .nav-menu__log-in2 a {
    margin-left: 20px;
    margin-right: 20px;
  }
}

.nav-menu__log-in2 .dropdown-menu > li > a:focus,
.nav-menu__log-in2 .dropdown-menu > li > a:hover {
  color: #ff5a00;
}

.nav-menu__log-in2 .dropdown-menu > li > a {
  line-height: 36px;
  height: auto;
}

.nav-menu__log-in2 .dropdown {
  display: inline-block;
  vertical-align: middle;
}

@media only screen and (max-width: 767px) {
  .nav-menu__log-in2 .dropdown {
    display: none;
  }
}

.nav-menu__log-in2 .dropdown .dropdown-menu {
  padding: 0;
  margin: 0;
}

.nav-menu__log-in2 .dropdown button {
  padding-right: 5px;
  margin-left: 10px;
  color: grey;
  text-transform: uppercase;
  letter-spacing: .5px;
  font-family: 'Poppins', sans-serif;
  line-height: 36px;
  font-size: 16px;
  font-weight: 500;
  border: none;
  outline: none;
  background-color: transparent;
}

.nav-menu__log-in2 .dropdown button:hover, .nav-menu__log-in2 .dropdown button:focus {
  border: none;
  outline: none;
}

.nav-menu__log-in2 .dropdown-menu > li > a {
  width: 100%;
}

/* social icons */
.nav-menu__social {
  text-align: center;
}

.nav-menu__social li {
  display: inline-block;
  padding: 0 14px;
}

.nav-menu__social li a {
  transition: color .1s ease;
  font-size: 23px;
  font-weight: 400;
  line-height: 40px;
  vertical-align: middle;
  color: grey;
}

.nav-menu__social li a:hover {
  color: #ff5a00;
}

/* Show second and third menu levels */
@media screen and (min-width: 992px) {
  .main-menu .sub-menu li {
    display: block;
  }
  .main-menu li:hover > .sub-menu {
    display: block;
  }
  nav .main-menu .sub-menu .sub-menu {
    left: calc(100% + 35px);
    top: 0;
  }
  nav .main-menu .sub-menu .sub-menu:after {
    position: absolute;
    content: '';
    display: inline-block;
    width: 40px;
    height: 100%;
    top: 0;
    left: -40px;
    background-color: transparent;
  }
  /* First level menu arrows */
  .main-menu > .menu-item-has-children > a:after {
    padding-left: 10px;
    content: '\f3d0';
    font-family: "Ionicons";
  }
  /* Second level menu arrows */
  .main-menu .sub-menu .menu-item-has-children > a:after {
    content: '\f3d1';
    font-family: "Ionicons";
  }
}

/* Responsive menu button */
.nav-menu-icon {
  position: absolute;
  right: 0px;
  top: 0px;
  width: 32px;
  height: 32px;
  display: none;
  cursor: pointer;
  transition: color .1s ease;
}

.nav-menu-icon:hover {
  color: #ff5a00;
}

.nav-menu-icon:hover i,
.nav-menu-icon:hover i:before,
.nav-menu-icon:hover i:after {
  background-color: #ff5a00;
}

@media only screen and (max-width: 767px) {
  .nav-menu-icon {
    font: 0/18px serif !important;
    text-shadow: none;
    color: transparent;
  }
}

/* Don`t reqiured */
.f-navigation.active .nav-menu-icon i {
  background: none;
}

.f-navigation.active .nav-menu-icon i:before {
  top: 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  -o-transform: rotate(45deg);
  transform: rotate(45deg);
}

.f-navigation.active .nav-menu-icon i:after {
  top: 0;
  -webkit-transform: rotate(135deg);
  -ms-transform: rotate(135deg);
  -moz-transform: rotate(135deg);
  -o-transform: rotate(135deg);
  transform: rotate(135deg);
}

/* End Don`t reqiured */
.nav-menu-icon i:before,
.nav-menu-icon i:after {
  content: '';
  width: 24px;
  height: 2px;
  background: #808080;
  position: absolute;
  left: 0;
  -moz-transition: all 0.15s ease-out;
  -o-transition: all 0.15s ease-out;
  -webkit-transition: all 0.15s ease-out;
  transition: all 0.15s ease-out;
  -ms-transition: all 0.15s ease-out;
}

.nav-menu-icon {
  width: auto;
  line-height: 40px;
  color: #808080;
  font-family: 'Poppins', sans-serif;
  font-size: 16px;
  font-weight: 500;
  text-transform: uppercase;
  vertical-align: middle;
}

.nav-menu-icon i:before {
  top: -8px;
}

.nav-menu-icon i:after {
  bottom: -8px;
}

.nav-menu-icon i {
  position: relative;
  display: inline-block;
  vertical-align: middle;
  margin-right: 20px;
  width: 24px;
  height: 2px;
  background: #808080;
}

@media only screen and (max-width: 767px) {
  .nav-menu-icon i {
    margin-right: 0;
  }
}

.menu-item-has-children > a span {
  display: none;
}

/* Responsive menu */
@media screen and (max-width: 992px) {
  .nav-menu-icon {
    display: block;
  }
  .main-menu {
    display: none;
  }
  .f-navigation.active .main-menu {
    display: block;
    width: 100%;
    background-color: #fff;
    padding: 0;
    height: auto;
  }
  .main-menu li {
    display: block;
  }
  .main-menu .sub-menu {
    position: static;
    padding: 5px 15px;
  }
  .menu-item-has-children > a span {
    display: inline-block;
    position: relative;
    transform: translateX(20px);
  }
  .menu-item-has-children.active > .sub-menu {
    display: block;
    position: static;
  }
  .f-navigation.active nav {
    position: fixed;
    width: 100%;
    height: 100%;
    z-index: 3;
    text-align: center;
    top: 0;
    left: 0;
    padding-top: 70px;
    overflow-y: auto;
    background-color: #fff;
  }
  .f-navigation.active .main-menu li {
    margin-right: 0;
    padding: 0;
  }
  .f-navigation.active .main-menu li li a {
    font-size: 12px;
  }
}

.menu_style-fixed {
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 999;
}

.menu_style-border {
  border-bottom: 1px solid #f2f2f2;
}

.menu_style-transparent {
  background-color: transparent;
}

@media screen and (max-width: 992px) {
  .menu_style-transparent {
    background-color: #333;
  }
}

.menu_style-transparent .search-form i a,
.menu_style-transparent .main-menu li a {
  color: #ccc;
  transition: color .1s ease;
}

.menu_style-transparent .search-form i a:hover,
.menu_style-transparent .main-menu li a:hover {
  color: #ff5a00;
}

.menu_style-transparent .search-form i li a,
.menu_style-transparent .main-menu li li a {
  color: #333;
}

.menu_style-transparent .main-menu .current-menu-parent > a {
  color: #ff5a00;
}

.menu_style-transparent.active .main-menu li a {
  color: #808080;
}

.menu_style-transparent.active .main-menu li a:hover {
  color: #ff5a00;
}

.menu_style-transparent.active .main-menu li li a {
  color: #808080;
}

.menu_style-scroll {
  background-color: #333;
  transition: background .1s ease-in;
}

.menu_style1 .nav-menu-icon-close {
  display: none;
}

.menu_style1.active .nav-menu-icon-close {
  display: block;
}

.menu_style1 .full-logo {
  float: left;
}

.menu_style1 > nav {
  float: right;
}

.menu_style1 .search-form {
  float: right;
}

.menu_style1 .nav-menu__tray {
  float: right;
}

.menu_style1 .main-menu .sub-menu {
  top: 73px;
}

.menu_style1 .main-menu .sub-menu:before {
  content: '';
  display: inline-block;
  position: absolute;
  height: 35px;
  top: -33px;
  left: 0;
  width: 100%;
}

@media only screen and (max-width: 992px) {
  .menu_style1 .main-menu .sub-menu:before {
    display: none;
  }
}

@media only screen and (max-width: 1600px) {
  .menu_style1 .main-menu li {
    margin-right: 20px;
  }
}

@media only screen and (max-width: 1100px) {
  .menu_style1 .main-menu li a {
    font-size: 14px;
  }
}

/*==========  Desktop First Method  ==========*/
/* Large Devices, Wide Screens */
@media only screen and (max-width: 1200px) {
  .f-navigation {
    padding: 30px 15px;
  }
}

/* Medium Devices, Desktops */
@media only screen and (max-width: 1200px) {
  .search-form a {
    line-height: 35px;
  }
}

@media only screen and (max-width: 992px) {
  .f-navigation {
    padding: 10px 15px;
  }
  .nav-menu-icon {
    line-height: 1;
    top: 20px;
    right: 70px;
  }
  .f-navigation.active .nav-menu-icon {
    right: 15px;
    z-index: 1;
  }
}

.menu_style2 .nav-menu__tray {
  float: right;
}

.menu_style2 nav {
  position: fixed;
  display: block;
  width: 320px;
  right: 0;
  top: 0;
  background-color: #fff;
  height: 100%;
  transform: translateX(100%);
  transition: all .1s ease;
  padding: 130px 50px;
}

@media only screen and (max-width: 992px) {
  .menu_style2 nav {
    width: 100%;
    padding-top: 70px;
    padding-left: 0;
    padding-right: 0;
  }
}

.menu_style2.active {
  z-index: 999;
}

.menu_style2.active nav {
  transform: translateX(0);
  display: block;
  overflow-x: hidden;
  overflow-y: auto;
}

.menu_style2.active nav:after {
  content: '';
  display: inline-block;
  position: fixed;
  top: 0;
  right: 0;
  width: 320px;
  height: 100px;
  background-color: #fff;
}

.menu_style2.active .main-menu li:hover > .sub-menu {
  display: none;
}

.menu_style2.active .main-menu {
  display: block;
}

.menu_style2.active .main-menu li {
  margin-right: 0;
  display: block;
}

.menu_style2.active .main-menu li a {
  letter-spacing: -1px;
}

.menu_style2.active .main-menu .sub-menu {
  position: static;
  padding: 0 30px;
}

.menu_style2.active .main-menu li li {
  padding: 0;
}

.menu_style2.active .nav-menu__social {
  text-align: left;
}

.menu_style2.active .nav-menu__social li {
  padding: 0 10px;
}

.menu_style2.active .nav-menu__social li:first-child {
  padding-left: 0;
}

.menu_style2.active .nav-menu__social li a {
  font-size: 18px;
}

.menu_style2.active .f-newsletter-form .f-head-h5 {
  font-size: 18px;
  position: relative;
  display: inline-block;
}

.menu_style2.active .f-newsletter-form .f-head-h5:after {
  content: '';
  display: inline-block;
  width: 80px;
  height: 2px;
  background-color: #f2f2f2;
  position: absolute;
  top: 0;
  bottom: 0;
  margin: auto;
  margin-left: 20px;
}

.menu_style2.active .f-newsletter-form p {
  letter-spacing: .5px;
  margin-top: 5px;
  margin-bottom: 25px;
}

.menu_style2.active .f-newsletter-form form {
  position: relative;
  max-width: 220px;
}

.menu_style2.active .f-newsletter-form button {
  position: absolute;
  top: 0;
  right: 5px;
  border: none;
  outline: none;
  color: #595959;
  background-color: transparent;
  font-weight: 100;
}

.menu_style2.active .f-newsletter-form input {
  width: 100%;
  padding: 0 25px 15px 5px;
  letter-spacing: .5px;
  border-bottom: 1px solid #bfbfbf;
}

.menu_style2 .nav-menu-icon {
  position: static;
  display: block;
  float: right;
  padding-right: 10px;
}

@media only screen and (max-width: 992px) {
  .menu_style2 .nav-menu-icon {
    padding-right: 0;
    position: absolute;
    right: 30px;
  }
}

@media only screen and (max-width: 480px) {
  .menu_style2 .nav-menu-icon {
    right: 15px;
  }
}

.menu_style2 .full-logo {
  float: left;
}

.menu_style3 .nav-menu__tray {
  float: right;
}

@media only screen and (max-width: 480px) {
  .menu_style3 {
    text-align: center;
  }
}

.menu_style3 .main-menu {
  display: none;
}

.menu_style3 .nav-menu-icon {
  margin-left: 140px;
  position: static;
  display: block;
  float: left;
}

@media only screen and (max-width: 992px) {
  .menu_style3 .nav-menu-icon {
    position: absolute;
    right: 30px;
    top: 20px;
  }
}

@media only screen and (max-width: 480px) {
  .menu_style3 .nav-menu-icon {
    right: 15px;
    margin-left: 0;
  }
}

.menu_style3 .full-logo {
  float: left;
}

@media only screen and (max-width: 480px) {
  .menu_style3 .full-logo {
    width: 100%;
    text-align: left;
    margin-bottom: 20px;
  }
}

@media only screen and (max-width: 992px) {
  .menu_style3 .nav-menu__tray {
    margin-right: 150px;
  }
}

@media only screen and (max-width: 767px) {
  .menu_style3 .nav-menu__tray {
    margin-right: 70px;
  }
}

@media only screen and (max-width: 480px) {
  .menu_style3 .nav-menu__tray {
    margin-right: 0;
  }
}

.menu_style3 .nav-menu__tray,
.menu_style3 .nav-menu__log-in {
  float: right;
}

@media only screen and (max-width: 480px) {
  .menu_style3 .nav-menu__tray,
  .menu_style3 .nav-menu__log-in {
    float: none;
    display: inline-block;
  }
}

.menu_style3 nav {
  display: none;
  position: fixed;
  width: 320px;
  left: 0;
  right: auto;
  top: 0;
  background-color: #fff;
  height: 100%;
  display: block;
  padding: 130px 50px;
  transition: all .1s ease;
  transform: translateX(-100%);
}

.menu_style3.active {
  z-index: 999;
}

.menu_style3.active nav {
  transition: all .1s ease;
  transform: translateX(0);
  overflow-x: hidden;
  overflow-y: auto;
  z-index: 4;
}

.menu_style3.active nav:after {
  content: '';
  display: inline-block;
  position: fixed;
  top: 0;
  right: 0;
  width: 320px;
  height: 100px;
  background-color: #fff;
}

.menu_style3.active .main-menu li:hover > .sub-menu {
  display: none;
}

.menu_style3.active .main-menu {
  display: block;
}

.menu_style3.active .main-menu li {
  margin-right: 0;
  display: block;
}

.menu_style3.active .main-menu li a {
  letter-spacing: -1px;
}

.menu_style3.active .main-menu .sub-menu {
  position: static;
  padding: 0 30px;
}

.menu_style3.active .main-menu li li {
  padding: 0;
}

.menu_style3.active .nav-menu__social {
  text-align: left;
}

.menu_style3.active .nav-menu__social li {
  padding: 0 10px;
}

.menu_style3.active .nav-menu__social li:first-child {
  padding-left: 0;
}

.menu_style3.active .nav-menu__social li a {
  font-size: 18px;
}

.menu_style3.active .f-newsletter-form .f-head-h5 {
  font-size: 18px;
  position: relative;
  display: inline-block;
}

.menu_style3.active .f-newsletter-form .f-head-h5:after {
  content: '';
  display: inline-block;
  width: 80px;
  height: 2px;
  background-color: #f2f2f2;
  position: absolute;
  top: 0;
  bottom: 0;
  margin: auto;
  margin-left: 20px;
}

.menu_style3.active .f-newsletter-form p {
  letter-spacing: .5px;
  margin-top: 5px;
  margin-bottom: 25px;
}

.menu_style3.active .f-newsletter-form form {
  position: relative;
  max-width: 220px;
}

.menu_style3.active .f-newsletter-form button {
  position: absolute;
  top: 0;
  right: 5px;
  border: none;
  outline: none;
  color: #595959;
  background-color: transparent;
  font-weight: 100;
}

.menu_style3.active .f-newsletter-form input {
  width: 100%;
  padding: 0 25px 15px 5px;
  letter-spacing: .5px;
  border-bottom: 1px solid #bfbfbf;
}

.menu_style4 .nav-menu__tray {
  float: right;
}

@media only screen and (max-width: 480px) {
  .menu_style4 {
    text-align: center;
  }
}

.menu_style4 .main-menu {
  display: none;
}

.menu_style4 .nav-menu-icon {
  margin-left: 0;
  position: relative;
  z-index: 2;
  display: block;
  float: left;
}

@media only screen and (max-width: 992px) {
  .menu_style4 .nav-menu-icon {
    margin-left: 0;
    position: absolute;
    z-index: 2;
    display: block;
    top: 20px;
    right: 30px;
    float: none;
  }
}

@media only screen and (max-width: 480px) {
  .menu_style4 .nav-menu-icon {
    right: 15px;
  }
}

.menu_style4 .full-logo {
  float: none;
  position: absolute;
  left: 0;
  right: 0;
  margin: auto;
  width: auto;
  text-align: center;
  display: inline;
}

@media only screen and (max-width: 992px) {
  .menu_style4 .full-logo {
    position: static;
    float: left;
  }
}

@media only screen and (max-width: 480px) {
  .menu_style4 .full-logo {
    width: 100%;
    text-align: left;
  }
}

@media only screen and (max-width: 992px) {
  .menu_style4 .nav-menu__tray {
    margin-right: 150px;
  }
}

@media only screen and (max-width: 767px) {
  .menu_style4 .nav-menu__tray {
    margin-right: 70px;
  }
}

@media only screen and (max-width: 480px) {
  .menu_style4 .nav-menu__tray {
    margin-right: 0;
  }
}

.menu_style4 .nav-menu__tray,
.menu_style4 .nav-menu__log-in {
  float: right;
  position: relative;
  z-index: 2;
}

@media only screen and (max-width: 480px) {
  .menu_style4 .nav-menu__tray,
  .menu_style4 .nav-menu__log-in {
    float: none;
    display: inline-block;
  }
}

.menu_style4 nav {
  position: fixed;
  width: 320px;
  left: 0;
  top: 0;
  padding: 130px 50px;
  background-color: #fff;
  height: 100%;
  display: block;
  padding: 130px 50px;
  transition: all .1s ease;
  transform: translateX(-100%);
}

.menu_style4.active {
  z-index: 999;
}

.menu_style4.active nav {
  transform: translateX(0);
  overflow-x: hidden;
  overflow-y: auto;
  z-index: 5;
}

.menu_style4.active nav:after {
  content: '';
  display: inline-block;
  position: fixed;
  top: 0;
  right: 0;
  width: 320px;
  height: 100px;
  background-color: #fff;
}

.menu_style4.active .main-menu li:hover > .sub-menu {
  display: none;
}

.menu_style4.active .main-menu {
  display: block;
}

.menu_style4.active .main-menu li {
  margin-right: 0;
  display: block;
}

.menu_style4.active .main-menu li a {
  letter-spacing: -1px;
}

.menu_style4.active .main-menu .sub-menu {
  position: static;
  padding: 0 30px;
}

.menu_style4.active .main-menu li li {
  padding: 0;
}

.menu_style4.active .nav-menu__social {
  text-align: left;
}

.menu_style4.active .nav-menu__social li {
  padding: 0 10px;
}

.menu_style4.active .nav-menu__social li:first-child {
  padding-left: 0;
}

.menu_style4.active .nav-menu__social li a {
  font-size: 18px;
}

.menu_style4.active .f-newsletter-form .f-head-h5 {
  font-size: 18px;
  position: relative;
  display: inline-block;
}

.menu_style4.active .f-newsletter-form .f-head-h5:after {
  content: '';
  display: inline-block;
  width: 80px;
  height: 2px;
  background-color: #f2f2f2;
  position: absolute;
  top: 0;
  bottom: 0;
  margin: auto;
  margin-left: 20px;
}

.menu_style4.active .f-newsletter-form p {
  letter-spacing: .5px;
  margin-top: 5px;
  margin-bottom: 25px;
}

.menu_style4.active .f-newsletter-form form {
  position: relative;
  max-width: 220px;
}

.menu_style4.active .f-newsletter-form button {
  position: absolute;
  top: 0;
  right: 5px;
  border: none;
  outline: none;
  color: #595959;
  background-color: transparent;
  font-weight: 100;
}

.menu_style4.active .f-newsletter-form input {
  width: 100%;
  padding: 0 25px 15px 5px;
  letter-spacing: .5px;
  border-bottom: 1px solid #bfbfbf;
}

.menu_style5 .nav-menu__tray {
  float: right;
}

.menu_style5 nav {
  position: fixed;
  width: 320px;
  right: 0;
  top: 0;
  background-color: #fff;
  height: 100%;
  padding: 130px 50px;
  display: block;
  transition: all .1s ease;
  transform: translateX(100%);
}

@media only screen and (max-width: 992px) {
  .menu_style5 nav {
    width: 100%;
  }
}

@media only screen and (max-width: 992px) {
  .menu_style5 .f-newsletter-form {
    margin-top: 50px;
  }
}

.menu_style5 .f-newsletter-form .f-head-h5 {
  font-size: 18px;
  position: relative;
  display: inline-block;
}

.menu_style5 .f-newsletter-form .f-head-h5:after {
  content: '';
  display: inline-block;
  width: 80px;
  height: 2px;
  background-color: #f2f2f2;
  position: absolute;
  top: 0;
  bottom: 0;
  margin: auto;
  margin-left: 20px;
}

@media only screen and (max-width: 992px) {
  .menu_style5 .f-newsletter-form .f-head-h5:after {
    display: none;
  }
}

.menu_style5 .f-newsletter-form p {
  letter-spacing: .5px;
  margin-top: 5px;
  margin-bottom: 25px;
}

.menu_style5 .f-newsletter-form form,
.menu_style5 .f-newsletter-form .mc4wp-form {
  display: block;
  box-sizing: border-box;
  position: relative;
  padding-left: 0px;
  max-width: 100%;
}

.menu_style5 .f-newsletter-form form:after,
.menu_style5 .f-newsletter-form .mc4wp-form:after {
  content: "\f10b";
  font-family: "Ionicons";
  width: 17px;
  color: #595959;
  line-height: 20px;
  font-size: 14px;
  display: inline-block;
  position: absolute;
  margin: auto;
  top: 0;
  right: 5px;
}

.menu_style5 .f-newsletter-form form input[type="email"],
.menu_style5 .f-newsletter-form .mc4wp-form input[type="email"] {
  float: none;
  background-color: transparent;
  display: block;
  line-height: 1;
  min-width: 0;
  max-width: 100%;
  box-sizing: border-box;
  border-radius: 0;
  width: 100%;
  font-family: 'Lato', sans-serif;
  font-size: 14px;
  font-weight: 400;
  transition: color .1s ease;
  padding: 0 25px 15px 5px;
  letter-spacing: .5px;
  border-bottom: 1px solid #bfbfbf;
}

@media only screen and (max-width: 768px) {
  .menu_style5 .f-newsletter-form form input[type="email"],
  .menu_style5 .f-newsletter-form .mc4wp-form input[type="email"] {
    padding-left: 0;
  }
}

.menu_style5 .f-newsletter-form form input[type="email"]:focus,
.menu_style5 .f-newsletter-form .mc4wp-form input[type="email"]:focus {
  border-color: #ff5a00;
}

@media only screen and (max-width: 1200px) {
  .menu_style5 .f-newsletter-form form input[type="email"],
  .menu_style5 .f-newsletter-form .mc4wp-form input[type="email"] {
    min-width: 0;
    width: 100%;
  }
}

.menu_style5 .f-newsletter-form form input[type="submit"],
.menu_style5 .f-newsletter-form .mc4wp-form input[type="submit"] {
  float: none;
  z-index: 2;
  border-radius: 0;
  background-color: transparent;
  letter-spacing: 1px;
  right: 0;
  top: 0;
  bottom: 0;
  line-height: 58px;
  font-size: 24px;
  padding: 0 20px;
  margin: auto;
  font-weight: 100;
  position: absolute;
  background-color: transparent;
  width: 60px;
  border: none;
  background-size: auto;
  transition: color .1s ease;
}

@media only screen and (max-width: 1200px) {
  .menu_style5 .f-newsletter-form form input[type="submit"],
  .menu_style5 .f-newsletter-form .mc4wp-form input[type="submit"] {
    padding: 0;
    width: 35%;
  }
}

@media only screen and (max-width: 991px) {
  .menu_style5 .f-newsletter-form form,
  .menu_style5 .f-newsletter-form .mc4wp-form {
    padding-left: 0;
  }
  .menu_style5 .f-newsletter-form form input,
  .menu_style5 .f-newsletter-form .mc4wp-form input {
    padding: 14px 15px;
  }
  .menu_style5 .f-newsletter-form form button,
  .menu_style5 .f-newsletter-form .mc4wp-form button {
    padding: 0 15px;
  }
}

.menu_style5.active {
  z-index: 999;
}

.menu_style5.active nav {
  transform: translateX(0);
  overflow-x: hidden;
  overflow-y: auto;
}

.menu_style5.active nav:after {
  content: '';
  display: inline-block;
  position: fixed;
  top: 0;
  right: 0;
  width: 320px;
  height: 100px;
  background-color: #fff;
}

.menu_style5.active .main-menu li:hover > .sub-menu {
  display: none;
}

.menu_style5.active .main-menu li {
  margin-right: 0;
  display: block;
}

.menu_style5.active .main-menu li a {
  letter-spacing: -1px;
}

.menu_style5.active .main-menu .sub-menu {
  position: static;
  padding: 0 30px;
}

.menu_style5.active .main-menu li li {
  padding: 0;
}

.menu_style5.active .nav-menu__social {
  text-align: left;
}

.menu_style5.active .nav-menu__social li {
  padding: 0 10px;
}

.menu_style5.active .nav-menu__social li:first-child {
  padding-left: 0;
}

.menu_style5.active .nav-menu__social li a {
  font-size: 18px;
}

@media only screen and (max-width: 992px) {
  .menu_style5.active .f-newsletter-form {
    margin-top: 50px;
  }
}

.menu_style5.active .f-newsletter-form .f-head-h5 {
  font-size: 18px;
  position: relative;
  display: inline-block;
}

.menu_style5.active .f-newsletter-form .f-head-h5:after {
  content: '';
  display: inline-block;
  width: 80px;
  height: 2px;
  background-color: #f2f2f2;
  position: absolute;
  top: 0;
  bottom: 0;
  margin: auto;
  margin-left: 20px;
}

@media only screen and (max-width: 992px) {
  .menu_style5.active .f-newsletter-form .f-head-h5:after {
    display: none;
  }
}

.menu_style5.active .f-newsletter-form p {
  letter-spacing: .5px;
  margin-top: 5px;
  margin-bottom: 25px;
}

.menu_style5.active .f-newsletter-form form,
.menu_style5.active .f-newsletter-form .mc4wp-form {
  display: block;
  box-sizing: border-box;
  position: relative;
  padding-left: 0px;
  max-width: 100%;
}

.menu_style5.active .f-newsletter-form form:after,
.menu_style5.active .f-newsletter-form .mc4wp-form:after {
  content: "\f10b";
  font-family: "Ionicons";
  width: 17px;
  color: #595959;
  line-height: 20px;
  font-size: 14px;
  display: inline-block;
  position: absolute;
  margin: auto;
  top: 0;
  right: 5px;
}

.menu_style5.active .f-newsletter-form form input[type="email"],
.menu_style5.active .f-newsletter-form .mc4wp-form input[type="email"] {
  float: none;
  background-color: transparent;
  display: block;
  line-height: 1;
  min-width: 0;
  max-width: 100%;
  box-sizing: border-box;
  border-radius: 0;
  width: 100%;
  font-family: 'Lato', sans-serif;
  font-size: 14px;
  font-weight: 400;
  transition: color .1s ease;
  padding: 0 25px 15px 5px;
  letter-spacing: .5px;
  border-bottom: 1px solid #bfbfbf;
}

@media only screen and (max-width: 768px) {
  .menu_style5.active .f-newsletter-form form input[type="email"],
  .menu_style5.active .f-newsletter-form .mc4wp-form input[type="email"] {
    padding-left: 0;
  }
}

.menu_style5.active .f-newsletter-form form input[type="email"]:focus,
.menu_style5.active .f-newsletter-form .mc4wp-form input[type="email"]:focus {
  border-color: #ff5a00;
}

@media only screen and (max-width: 1200px) {
  .menu_style5.active .f-newsletter-form form input[type="email"],
  .menu_style5.active .f-newsletter-form .mc4wp-form input[type="email"] {
    min-width: 0;
    width: 100%;
  }
}

.menu_style5.active .f-newsletter-form form input[type="submit"],
.menu_style5.active .f-newsletter-form .mc4wp-form input[type="submit"] {
  float: none;
  z-index: 2;
  border-radius: 0;
  background-color: transparent;
  letter-spacing: 1px;
  right: 0;
  top: 0;
  bottom: 0;
  line-height: 58px;
  font-size: 24px;
  padding: 0 20px;
  margin: auto;
  font-weight: 100;
  position: absolute;
  background-color: transparent;
  width: 60px;
  border: none;
  background-size: auto;
  transition: color .1s ease;
}

@media only screen and (max-width: 1200px) {
  .menu_style5.active .f-newsletter-form form input[type="submit"],
  .menu_style5.active .f-newsletter-form .mc4wp-form input[type="submit"] {
    padding: 0;
    width: 35%;
  }
}

@media only screen and (max-width: 991px) {
  .menu_style5.active .f-newsletter-form form,
  .menu_style5.active .f-newsletter-form .mc4wp-form {
    padding-left: 0;
  }
  .menu_style5.active .f-newsletter-form form input,
  .menu_style5.active .f-newsletter-form .mc4wp-form input {
    padding: 14px 15px;
  }
  .menu_style5.active .f-newsletter-form form button,
  .menu_style5.active .f-newsletter-form .mc4wp-form button {
    padding: 0 15px;
  }
}

.menu_style5 .nav-menu-icon {
  margin-left: 0;
  position: relative;
  display: block;
  float: right;
  padding-right: 10px;
}

@media only screen and (max-width: 992px) {
  .menu_style5 .nav-menu-icon {
    top: 10px;
    right: 10px;
    padding-right: 0;
  }
}

@media only screen and (max-width: 480px) {
  .menu_style5 .nav-menu-icon {
    right: 0;
  }
}

.menu_style5 .full-logo {
  float: none;
  position: absolute;
  left: 0;
  right: 0;
  margin: auto;
  width: auto;
  text-align: center;
  display: inline;
}

.menu_style5 .nav-menu__tray,
.menu_style5 .nav-menu__log-in {
  display: none;
  position: relative;
  z-index: 2;
}

.menu_style5 .search-form {
  float: left;
  position: relative;
  z-index: 2;
}

.menu_style5 .search-form i {
  line-height: 20px;
  padding-right: 15px;
}

.menu_style6 .nav-menu-icon-close {
  display: none;
}

.menu_style6 .nav-menu__tray {
  float: right;
}

.menu_style6 .full-logo {
  float: none;
  position: absolute;
  left: 0;
  right: 0;
  margin: auto;
  width: auto;
  text-align: center;
  display: inline;
}

@media only screen and (max-width: 600px) {
  .menu_style6 .full-logo {
    position: static;
  }
}

.menu_style6 .nav-menu__tray,
.menu_style6 .nav-menu__log-in {
  float: right;
  display: none;
}

.menu_style6 nav {
  position: relative;
}

.menu_style6 .search-form {
  float: right;
  position: relative;
}

@media only screen and (max-width: 600px) {
  .menu_style6 .search-form {
    display: none;
  }
}

@media only screen and (max-width: 1200px) {
  .menu_style6 .main-menu li {
    margin-right: 20px;
  }
}

@media only screen and (max-width: 600px) {
  .menu_style6 .nav-menu-icon {
    right: 15px;
  }
}

.menu_style6.active .nav-menu-icon-close {
  display: block;
}

.menu_style6 .main-menu .sub-menu {
  top: 73px;
}

.menu_style6 .main-menu .sub-menu:before {
  content: '';
  display: inline-block;
  position: absolute;
  height: 35px;
  top: -33px;
  left: 0;
  width: 100%;
}

@media only screen and (max-width: 992px) {
  .menu_style6 .main-menu .sub-menu:before {
    display: none;
  }
}

.menu_style7 {
  text-align: center;
}

.menu_style7 .nav-menu__tray {
  float: right;
}

.menu_style7 .main-menu .sub-menu {
  top: 73px;
}

.menu_style7 .main-menu .sub-menu:before {
  content: '';
  display: inline-block;
  position: absolute;
  height: 35px;
  top: -33px;
  left: 0;
  width: 100%;
}

@media only screen and (max-width: 1300px) {
  .menu_style7 .main-menu .sub-menu {
    text-align: center;
  }
  .menu_style7 .main-menu .sub-menu:before {
    display: none;
  }
}

.menu_style7 .main-menu li ul {
  text-align: left;
}

.menu_style7 nav {
  display: inline-block;
}

@media only screen and (max-width: 1300px) {
  .menu_style7 nav {
    z-index: 6;
    transition: all .1s ease;
    transform: translateX(100%);
    position: fixed;
    width: 320px;
    right: 0;
    top: 0;
    background-color: #fff;
    height: 100%;
    padding: 130px 50px;
  }
}

.menu_style7 .full-logo {
  float: left;
  left: 0;
  right: 0;
  margin: auto;
  width: auto;
  text-align: center;
  display: inline;
}

.menu_style7 .nav-menu__tray,
.menu_style7 .nav-menu__log-in {
  float: right;
  display: none;
}

.menu_style7 .nav-menu__social,
.menu_style7 .search-form {
  float: right;
}

.menu_style7 .search-form {
  margin-left: 40px;
}

@media only screen and (max-width: 1300px) {
  .menu_style7 .search-form {
    margin-right: 30px;
  }
}

@media only screen and (max-width: 1200px) {
  .menu_style7 .search-form {
    margin-right: 100px;
  }
}

@media only screen and (max-width: 600px) {
  .menu_style7 .search-form,
  .menu_style7 .nav-menu__social {
    display: none;
  }
}

.menu_style7 .main-menu li:last-child {
  margin-right: 0;
}

@media only screen and (max-width: 1600px) {
  .menu_style7 .main-menu li {
    margin-right: 20px;
  }
}

@media only screen and (max-width: 992px) {
  .menu_style7 .nav-menu-icon {
    position: static;
    float: right;
    margin-left: 40px;
    margin-top: 15px;
  }
}

@media only screen and (max-width: 1300px) {
  .menu_style7 .nav-menu-icon {
    display: inline-block;
    top: 28px;
    right: 30px;
    z-index: 3;
  }
}

@media only screen and (max-width: 480px) {
  .menu_style7 .nav-menu-icon {
    right: 0;
  }
}

.menu_style7.active {
  z-index: 999;
}

.menu_style7.active .main-menu {
  display: block;
}

.menu_style7.active nav {
  display: block;
  padding: 130px 50px;
  overflow-x: hidden;
  overflow-y: auto;
  transform: translateX(0);
}

.menu_style7.active nav:after {
  content: '';
  display: inline-block;
  position: fixed;
  top: 0;
  right: 0;
  width: 320px;
  height: 100px;
  background-color: #fff;
}

.menu_style7.active .main-menu li:hover > .sub-menu {
  display: none;
}

.menu_style7.active .main-menu li {
  margin-right: 0;
  display: block;
}

.menu_style7.active .main-menu li a {
  letter-spacing: -1px;
}

.menu_style7.active .main-menu .sub-menu {
  position: static;
  padding: 0 30px;
}

.menu_style7.active .main-menu li li {
  padding: 0;
}

.menu_style7.active .nav-menu__social {
  text-align: left;
}

.menu_style7.active .nav-menu__social li {
  padding: 0 10px;
}

.menu_style7.active .nav-menu__social li:first-child {
  padding-left: 0;
}

.menu_style7.active .nav-menu__social li a {
  font-size: 18px;
}

.menu_style7.active .f-newsletter-form .f-head-h5 {
  font-size: 18px;
  position: relative;
  display: inline-block;
}

.menu_style7.active .f-newsletter-form .f-head-h5:after {
  content: '';
  display: inline-block;
  width: 80px;
  height: 2px;
  background-color: #f2f2f2;
  position: absolute;
  top: 0;
  bottom: 0;
  margin: auto;
  margin-left: 20px;
}

.menu_style7.active .f-newsletter-form p {
  letter-spacing: .5px;
  margin-top: 5px;
  margin-bottom: 25px;
}

.menu_style7.active .f-newsletter-form form {
  position: relative;
  max-width: 220px;
}

.menu_style7.active .f-newsletter-form button {
  position: absolute;
  top: 0;
  right: 5px;
  border: none;
  outline: none;
  color: #595959;
  background-color: transparent;
  font-weight: 100;
}

.menu_style7.active .f-newsletter-form input {
  width: 100%;
  padding: 0 25px 15px 5px;
  letter-spacing: .5px;
  border-bottom: 1px solid #bfbfbf;
}

.menu_style8 {
  text-align: center;
}

.menu_style8 .nav-menu__tray {
  float: right;
}

.menu_style8 .nav-menu-icon {
  top: 28px;
  right: 30px;
}

@media only screen and (max-width: 1500px) {
  .menu_style8 .nav-menu-icon {
    display: block;
  }
}

@media only screen and (max-width: 992px) {
  .menu_style8 .nav-menu-icon {
    top: 20px;
  }
}

@media only screen and (max-width: 480px) {
  .menu_style8 .nav-menu-icon {
    right: 15px;
  }
}

.menu_style8 nav {
  display: inline-block;
}

@media only screen and (max-width: 1500px) {
  .menu_style8 nav {
    transition: all .1s ease;
    transform: translateX(100%);
    position: fixed;
    width: 320px;
    right: 0;
    top: 0;
    background-color: #fff;
    height: 100%;
    padding: 130px 50px;
  }
}

.menu_style8.active {
  z-index: 999;
}

.menu_style8.active nav {
  display: block;
  padding: 130px 50px;
  overflow-x: hidden;
  overflow-y: auto;
  z-index: 5;
  transform: translateX(0);
}

.menu_style8.active nav:after {
  content: '';
  display: inline-block;
  position: fixed;
  top: 0;
  right: 0;
  width: 320px;
  height: 100px;
  background-color: #fff;
}

.menu_style8.active .main-menu li:hover > .sub-menu {
  display: none;
}

.menu_style8.active .main-menu {
  display: block;
}

.menu_style8.active .main-menu li {
  margin-right: 0;
  display: block;
}

.menu_style8.active .main-menu li a {
  letter-spacing: -1px;
}

.menu_style8.active .main-menu .sub-menu {
  position: static;
  padding: 0 30px;
}

.menu_style8.active .main-menu li li {
  padding: 0;
}

.menu_style8.active .nav-menu__social {
  text-align: left;
}

.menu_style8.active .nav-menu__social li {
  padding: 0 10px;
}

.menu_style8.active .nav-menu__social li:first-child {
  padding-left: 0;
}

.menu_style8.active .nav-menu__social li a {
  font-size: 18px;
}

.menu_style8.active .f-newsletter-form .f-head-h5 {
  font-size: 18px;
  position: relative;
  display: inline-block;
}

.menu_style8.active .f-newsletter-form .f-head-h5:after {
  content: '';
  display: inline-block;
  width: 80px;
  height: 2px;
  background-color: #f2f2f2;
  position: absolute;
  top: 0;
  bottom: 0;
  margin: auto;
  margin-left: 20px;
}

.menu_style8.active .f-newsletter-form p {
  letter-spacing: .5px;
  margin-top: 5px;
  margin-bottom: 25px;
}

.menu_style8.active .f-newsletter-form form {
  position: relative;
  max-width: 220px;
}

.menu_style8.active .f-newsletter-form button {
  position: absolute;
  top: 0;
  right: 5px;
  border: none;
  outline: none;
  color: #595959;
  background-color: transparent;
  font-weight: 100;
}

.menu_style8.active .f-newsletter-form input {
  width: 100%;
  padding: 0 25px 15px 5px;
  letter-spacing: .5px;
  border-bottom: 1px solid #bfbfbf;
}

.menu_style8 .full-logo {
  float: left;
  left: 0;
  right: 0;
  margin: auto;
  width: auto;
  text-align: center;
  display: inline;
}

@media only screen and (max-width: 480px) {
  .menu_style8 .full-logo {
    margin-bottom: 0;
  }
}

.menu_style8 .nav-menu__social,
.menu_style8 .search-form {
  display: none;
}

.menu_style8 .search-form {
  margin-left: 40px;
}

@media only screen and (max-width: 1860px) {
  .menu_style8 .main-menu li {
    margin-right: 20px;
  }
}

.menu_style8 .main-menu li:last-child {
  margin-right: 0;
}

@media only screen and (max-width: 1860px) {
  .menu_style8 .nav-menu__tray {
    padding-right: 50px;
  }
}

@media only screen and (max-width: 1200px) {
  .menu_style8 .nav-menu__tray {
    padding-right: 100px;
  }
}

.menu_style8 .nav-menu__tray,
.menu_style8 .nav-menu__log-in {
  float: right;
}

.menu_style8 .main-menu .sub-menu {
  top: 73px;
}

.menu_style8 .main-menu .sub-menu:before {
  content: '';
  display: inline-block;
  position: absolute;
  height: 35px;
  top: -33px;
  left: 0;
  width: 100%;
}

@media only screen and (max-width: 1500px) {
  .menu_style8 .main-menu .sub-menu:before {
    display: none;
  }
}

.menu_style9 {
  text-align: center;
}

.menu_style9 .nav-menu__tray {
  float: right;
}

.menu_style9 .main-menu {
  display: none;
}

.menu_style9 nav {
  position: fixed;
  width: 320px;
  right: 0;
  top: 0;
  background-color: #fff;
  padding: 130px 50px;
  height: 100%;
  display: block;
  transition: all .1s ease;
  transform: translateX(100%);
}

@media only screen and (max-width: 992px) {
  .menu_style9 nav {
    padding-top: 70px;
    width: 100%;
  }
}

.menu_style9.active {
  z-index: 999;
}

.menu_style9.active .main-menu {
  display: block;
}

.menu_style9.active nav {
  transform: translateX(0);
  overflow-x: hidden;
  overflow-y: auto;
}

.menu_style9.active nav:after {
  content: '';
  display: inline-block;
  position: fixed;
  top: 0;
  right: 0;
  width: 320px;
  height: 100px;
  background-color: #fff;
}

.menu_style9.active .main-menu li:hover > .sub-menu {
  display: none;
}

.menu_style9.active .main-menu li {
  margin-right: 0;
  display: block;
}

.menu_style9.active .main-menu li a {
  letter-spacing: -1px;
}

.menu_style9.active .main-menu .sub-menu {
  position: static;
  padding: 0 30px;
}

.menu_style9.active .main-menu li li {
  padding: 0;
}

.menu_style9.active .nav-menu__social {
  text-align: left;
}

.menu_style9.active .nav-menu__social li {
  padding: 0 10px;
}

.menu_style9.active .nav-menu__social li:first-child {
  padding-left: 0;
}

.menu_style9.active .f-newsletter-form .f-head-h5 {
  font-size: 18px;
  position: relative;
  display: inline-block;
}

.menu_style9.active .f-newsletter-form .f-head-h5:after {
  content: '';
  display: inline-block;
  width: 80px;
  height: 2px;
  background-color: #f2f2f2;
  position: absolute;
  top: 0;
  bottom: 0;
  margin: auto;
  margin-left: 20px;
}

.menu_style9.active .f-newsletter-form p {
  letter-spacing: .5px;
  margin-top: 5px;
  margin-bottom: 25px;
}

.menu_style9.active .f-newsletter-form form {
  position: relative;
  max-width: 220px;
}

.menu_style9.active .f-newsletter-form button {
  position: absolute;
  top: 0;
  right: 5px;
  border: none;
  outline: none;
  color: #595959;
  background-color: transparent;
  font-weight: 100;
}

.menu_style9.active .f-newsletter-form input {
  width: 100%;
  padding: 0 25px 15px 5px;
  letter-spacing: .5px;
  border-bottom: 1px solid #bfbfbf;
}

.menu_style9 .nav-menu__social {
  display: inline-block;
}

@media only screen and (max-width: 600px) {
  .menu_style9 .nav-menu__social {
    display: none;
  }
}

.menu_style9 .nav-menu-icon {
  position: static;
  display: block;
  float: right;
  padding-right: 10px;
}

@media only screen and (max-width: 992px) {
  .menu_style9 .nav-menu-icon {
    position: absolute;
    top: 20px;
    right: 30px;
    padding-right: 0;
  }
}

@media only screen and (max-width: 480px) {
  .menu_style9 .nav-menu-icon {
    right: 15px;
  }
}

.menu_style9 .full-logo {
  float: left;
}

.menu_style10 .nav-menu__tray {
  float: right;
}

.menu_style10 .main-menu {
  display: none;
}

.menu_style10 .nav-menu-icon {
  position: static;
  display: block;
  float: left;
  padding-right: 10px;
}

@media only screen and (max-width: 992px) {
  .menu_style10 .nav-menu-icon {
    line-height: 40px;
  }
}

@media only screen and (max-width: 767px) {
  .menu_style10 .nav-menu-icon {
    position: absolute;
    right: 30px;
  }
}

@media only screen and (max-width: 480px) {
  .menu_style10 .nav-menu-icon {
    right: 15px;
    padding-right: 0;
  }
}

.menu_style10 .full-logo {
  float: left;
}

.menu_style10 .search-form {
  float: left;
  margin-left: 145px;
  margin-right: 55px;
}

.menu_style10 .search-form i {
  line-height: 44px;
  padding-right: 15px;
}

@media only screen and (max-width: 992px) {
  .menu_style10 .search-form {
    margin-left: 55px;
  }
}

.menu_style10 .nav-menu__social {
  float: right;
}

@media only screen and (max-width: 768px) {
  .menu_style10 .search-form,
  .menu_style10 .nav-menu__social {
    display: none;
  }
  .menu_style10 .nav-menu-icon {
    float: right;
  }
}

.menu_style10.f-navigation.active nav {
  position: fixed;
  width: 100%;
  height: 100%;
  text-align: center;
  top: 0;
  left: 0;
  padding-top: 70px;
  background-color: #fff;
}

.menu_style10.f-navigation.active .main-menu {
  display: block;
}

.menu_style10.f-navigation.active .main-menu li {
  display: block;
  margin-right: 0;
  padding: 0;
}

.menu_style10.f-navigation.active .main-menu li li a {
  font-size: 12px;
}

.menu_style10 nav {
  display: none;
  position: fixed;
  width: 320px;
  right: 0;
  top: 0;
  background-color: #fff;
  height: 100%;
}

.menu_style10.active {
  z-index: 999;
}

.menu_style10.active .main-menu {
  display: block;
}

.menu_style10.active nav {
  display: block;
  padding: 130px 50px;
  overflow-x: hidden;
  overflow-y: auto;
}

.menu_style10.active nav:after {
  content: '';
  display: inline-block;
  position: fixed;
  top: 0;
  right: 0;
  width: 320px;
  height: 100px;
  background-color: #fff;
}

@media only screen and (max-width: 992px) {
  .menu_style10.active nav:after {
    display: none;
  }
}

.menu_style10.active .main-menu li:hover > .sub-menu {
  display: none;
}

.menu_style10.active .main-menu li {
  margin-right: 0;
  display: block;
}

.menu_style10.active .main-menu li a {
  letter-spacing: -1px;
}

.menu_style10.active .main-menu .sub-menu {
  position: static;
  padding: 0 30px;
}

.menu_style10.active .main-menu li li {
  padding: 0;
}

.menu_style10.active .nav-menu__social {
  text-align: left;
}

.menu_style10.active .nav-menu__social li {
  padding: 0 10px;
}

.menu_style10.active .nav-menu__social li:first-child {
  padding-left: 0;
}

.menu_style10.active .nav-menu__social li a {
  font-size: 18px;
}

.menu_style10.active .f-newsletter-form .f-head-h5 {
  font-size: 18px;
  position: relative;
  display: inline-block;
}

.menu_style10.active .f-newsletter-form .f-head-h5:after {
  content: '';
  display: inline-block;
  width: 80px;
  height: 2px;
  background-color: #f2f2f2;
  position: absolute;
  top: 0;
  bottom: 0;
  margin: auto;
  margin-left: 20px;
}

.menu_style10.active .f-newsletter-form p {
  letter-spacing: .5px;
  margin-top: 5px;
  margin-bottom: 25px;
}

.menu_style10.active .f-newsletter-form form {
  position: relative;
  max-width: 220px;
}

.menu_style10.active .f-newsletter-form button {
  position: absolute;
  top: 0;
  right: 5px;
  border: none;
  outline: none;
  color: #595959;
  background-color: transparent;
  font-weight: 100;
}

.menu_style10.active .f-newsletter-form input {
  width: 100%;
  padding: 0 25px 15px 5px;
  letter-spacing: .5px;
  border-bottom: 1px solid #bfbfbf;
}

.menu_style11 .nav-menu__tray {
  float: right;
}

@media only screen and (max-width: 480px) {
  .menu_style11 {
    text-align: center;
  }
}

.menu_style11 .nav-menu-icon {
  top: 28px;
  right: 30px;
}

@media only screen and (max-width: 1500px) {
  .menu_style11 .nav-menu-icon {
    display: block;
  }
}

@media only screen and (max-width: 992px) {
  .menu_style11 .nav-menu-icon {
    top: 20px;
  }
}

@media only screen and (max-width: 480px) {
  .menu_style11 .nav-menu-icon {
    right: 15px;
  }
}

.menu_style11 .full-logo {
  float: left;
}

@media only screen and (max-width: 480px) {
  .menu_style11 .full-logo {
    width: 100%;
    text-align: left;
    margin-bottom: 20px;
  }
}

.menu_style11 .nav-menu__log-in {
  margin-left: 95px;
  margin-right: 0;
}

@media only screen and (max-width: 1860px) {
  .menu_style11 .nav-menu__log-in {
    margin-left: 20px;
  }
}

.menu_style11 .nav-menu__tray,
.menu_style11 .nav-menu__log-in {
  float: left;
}

@media only screen and (max-width: 1860px) {
  .menu_style11 .nav-menu__tray a:before,
  .menu_style11 .nav-menu__log-in a:before {
    padding-left: 0;
  }
}

@media only screen and (max-width: 480px) {
  .menu_style11 .nav-menu__tray,
  .menu_style11 .nav-menu__log-in {
    float: none;
    display: inline-block;
  }
}

@media only screen and (max-width: 1860px) {
  .menu_style11 .search-form {
    padding-right: 50px;
  }
}

@media only screen and (max-width: 1200px) {
  .menu_style11 .search-form {
    padding-right: 150px;
  }
}

@media only screen and (max-width: 767px) {
  .menu_style11 .search-form {
    padding-right: 100px;
    float: left;
  }
}

@media only screen and (max-width: 480px) {
  .menu_style11 .search-form {
    padding-right: 0;
  }
}

.menu_style11 .search-form {
  float: right;
  margin-right: 10px;
}

@media only screen and (max-width: 767px) {
  .menu_style11 .search-form {
    float: left;
  }
}

@media only screen and (max-width: 480px) {
  .menu_style11 .search-form {
    float: none;
    display: inline-block;
    vertical-align: bottom;
  }
}

@media only screen and (max-width: 1860px) {
  .menu_style11 .main-menu li {
    margin-right: 20px;
  }
}

@media only screen and (max-width: 1500px) {
  .menu_style11 nav {
    transition: all .1s ease;
    transform: translateX(100%);
    position: fixed;
    width: 320px;
    right: 0;
    top: 0;
    background-color: #fff;
    height: 100%;
    padding: 130px 50px;
  }
}

.menu_style11.active {
  z-index: 999;
}

.menu_style11.active nav {
  display: block;
  padding: 130px 50px;
  overflow-x: hidden;
  overflow-y: auto;
  z-index: 5;
  transform: translateX(0);
}

.menu_style11.active nav:after {
  content: '';
  display: inline-block;
  position: fixed;
  top: 0;
  right: 0;
  width: 320px;
  height: 100px;
  background-color: #fff;
}

.menu_style11.active .main-menu li:hover > .sub-menu {
  display: none;
}

.menu_style11.active .main-menu {
  display: block;
}

.menu_style11.active .main-menu li {
  margin-right: 0;
  display: block;
}

.menu_style11.active .main-menu li a {
  letter-spacing: -1px;
}

.menu_style11.active .main-menu .sub-menu {
  position: static;
  padding: 0 30px;
}

.menu_style11.active .main-menu li li {
  padding: 0;
}

.menu_style11.active .nav-menu__social {
  text-align: left;
}

.menu_style11.active .nav-menu__social li {
  padding: 0 10px;
}

.menu_style11.active .nav-menu__social li:first-child {
  padding-left: 0;
}

.menu_style11.active .nav-menu__social li a {
  font-size: 18px;
}

.menu_style11.active .f-newsletter-form .f-head-h5 {
  font-size: 18px;
  position: relative;
  display: inline-block;
}

.menu_style11.active .f-newsletter-form .f-head-h5:after {
  content: '';
  display: inline-block;
  width: 80px;
  height: 2px;
  background-color: #f2f2f2;
  position: absolute;
  top: 0;
  bottom: 0;
  margin: auto;
  margin-left: 20px;
}

.menu_style11.active .f-newsletter-form p {
  letter-spacing: .5px;
  margin-top: 5px;
  margin-bottom: 25px;
}

.menu_style11.active .f-newsletter-form form {
  position: relative;
  max-width: 220px;
}

.menu_style11.active .f-newsletter-form button {
  position: absolute;
  top: 0;
  right: 5px;
  border: none;
  outline: none;
  color: #595959;
  background-color: transparent;
  font-weight: 100;
}

.menu_style11.active .f-newsletter-form input {
  width: 100%;
  padding: 0 25px 15px 5px;
  letter-spacing: .5px;
  border-bottom: 1px solid #bfbfbf;
}

.menu_style11 .main-menu .sub-menu {
  top: 73px;
}

.menu_style11 .main-menu .sub-menu:before {
  content: '';
  display: inline-block;
  position: absolute;
  height: 35px;
  top: -33px;
  left: 0;
  width: 100%;
}

@media only screen and (max-width: 1500px) {
  .menu_style11 .main-menu .sub-menu:before {
    display: none;
  }
}

.menu_style12 {
  text-align: center;
}

.menu_style12 .nav-menu__tray {
  float: right;
}

@media only screen and (max-width: 480px) {
  .menu_style12 {
    text-align: center;
  }
}

.menu_style12 .full-logo {
  float: left;
}

@media only screen and (max-width: 480px) {
  .menu_style12 .full-logo {
    width: 100%;
    text-align: left;
    margin-bottom: 20px;
  }
}

.menu_style12 .nav-menu__log-in {
  margin-left: 0;
  margin-right: 0px;
}

.menu_style12 .nav-menu__tray,
.menu_style12 .nav-menu__log-in {
  float: right;
}

@media only screen and (max-width: 767px) {
  .menu_style12 .nav-menu__tray,
  .menu_style12 .nav-menu__log-in {
    float: left;
  }
}

@media only screen and (max-width: 1860px) {
  .menu_style12 .nav-menu__tray a:before,
  .menu_style12 .nav-menu__log-in a:before {
    padding-left: 0;
  }
}

@media only screen and (max-width: 1500px) {
  .menu_style12 .nav-menu__tray a:before,
  .menu_style12 .nav-menu__log-in a:before {
    padding-left: 30px;
  }
}

@media only screen and (max-width: 767px) {
  .menu_style12 .nav-menu__tray a:before,
  .menu_style12 .nav-menu__log-in a:before {
    padding-left: 20px;
  }
}

@media only screen and (max-width: 480px) {
  .menu_style12 .nav-menu__tray,
  .menu_style12 .nav-menu__log-in {
    float: none;
    display: inline-block;
    vertical-align: top;
  }
}

.menu_style12 nav {
  display: inline-block;
  text-align: left;
}

@media only screen and (max-width: 1500px) {
  .menu_style12 nav {
    display: block;
    position: fixed;
    width: 320px;
    right: auto;
    left: 0;
    top: 0;
    background-color: #fff;
    height: 100%;
    transition: all .1s ease;
    transform: translateX(-100%);
    padding: 130px 50px;
  }
  .menu_style12 nav li {
    display: block;
  }
}

.menu_style12 .search-form {
  float: right;
  margin-right: 10px;
  margin-left: 25px;
}

@media only screen and (max-width: 992px) {
  .menu_style12 .search-form {
    margin-right: 150px;
  }
}

@media only screen and (max-width: 767px) {
  .menu_style12 .search-form {
    margin-right: 0;
    float: left;
  }
}

@media only screen and (max-width: 480px) {
  .menu_style12 .search-form {
    float: none;
    display: inline-block;
    vertical-align: top;
  }
}

@media only screen and (max-width: 1860px) {
  .menu_style12 .main-menu li {
    margin-right: 20px;
  }
}

.menu_style12 .nav-menu-icon {
  position: static;
}

@media only screen and (max-width: 1500px) {
  .menu_style12 .nav-menu-icon {
    display: inline-block;
    float: left;
    margin-left: 140px;
  }
}

@media only screen and (max-width: 992px) {
  .menu_style12 .nav-menu-icon {
    position: absolute;
    right: 30px;
  }
}

@media only screen and (max-width: 480px) {
  .menu_style12 .nav-menu-icon {
    right: 15px;
    margin-left: 0;
  }
}

.menu_style12.active {
  z-index: 999;
}

.menu_style12.active .main-menu {
  display: block;
}

.menu_style12.active nav {
  display: block;
  padding: 130px 50px;
  overflow-x: hidden;
  overflow-y: auto;
}

@media only screen and (max-width: 1500px) {
  .menu_style12.active nav {
    transform: translateX(0);
  }
}

.menu_style12.active nav:after {
  content: '';
  display: inline-block;
  position: fixed;
  top: 0;
  right: 0;
  width: 320px;
  height: 100px;
  background-color: #fff;
}

.menu_style12.active .main-menu li:hover > .sub-menu {
  display: none;
}

.menu_style12.active .main-menu li {
  margin-right: 0;
  display: block;
}

.menu_style12.active .main-menu li a {
  letter-spacing: -1px;
}

.menu_style12.active .main-menu .sub-menu {
  position: static;
  padding: 0 30px;
}

.menu_style12.active .main-menu li li {
  padding: 0;
}

.menu_style12.active .nav-menu__social {
  text-align: left;
}

.menu_style12.active .nav-menu__social li {
  padding: 0 10px;
}

.menu_style12.active .nav-menu__social li:first-child {
  padding-left: 0;
}

.menu_style12.active .nav-menu__social li a {
  font-size: 18px;
}

.menu_style12.active .f-newsletter-form .f-head-h5 {
  font-size: 18px;
  position: relative;
  display: inline-block;
}

.menu_style12.active .f-newsletter-form .f-head-h5:after {
  content: '';
  display: inline-block;
  width: 80px;
  height: 2px;
  background-color: #f2f2f2;
  position: absolute;
  top: 0;
  bottom: 0;
  margin: auto;
  margin-left: 20px;
}

.menu_style12.active .f-newsletter-form p {
  letter-spacing: .5px;
  margin-top: 5px;
  margin-bottom: 25px;
}

.menu_style12.active .f-newsletter-form form {
  position: relative;
  max-width: 220px;
}

.menu_style12.active .f-newsletter-form button {
  position: absolute;
  top: 0;
  right: 5px;
  border: none;
  outline: none;
  color: #595959;
  background-color: transparent;
  font-weight: 100;
}

.menu_style12.active .f-newsletter-form input {
  width: 100%;
  padding: 0 25px 15px 5px;
  letter-spacing: .5px;
  border-bottom: 1px solid #bfbfbf;
}

.menu_style12 .main-menu .sub-menu {
  top: 73px;
}

.menu_style12 .main-menu .sub-menu:before {
  content: '';
  display: inline-block;
  position: absolute;
  height: 35px;
  top: -33px;
  left: 0;
  width: 100%;
}

@media only screen and (max-width: 1500px) {
  .menu_style12 .main-menu .sub-menu:before {
    display: none;
  }
}

.menu_style13 .nav-menu__tray {
  float: right;
}

@media only screen and (max-width: 480px) {
  .menu_style13 {
    text-align: center;
  }
}

.menu_style13 .full-logo {
  float: left;
}

@media only screen and (max-width: 480px) {
  .menu_style13 .full-logo {
    width: 100%;
    text-align: left;
  }
}

.menu_style13 .nav-menu__tray {
  padding-left: 0;
}

@media only screen and (max-width: 992px) {
  .menu_style13 .nav-menu__tray {
    margin-right: 150px;
  }
}

@media only screen and (max-width: 480px) {
  .menu_style13 .nav-menu__tray {
    margin-right: 0;
  }
}

.menu_style13 .nav-menu__tray,
.menu_style13 .nav-menu__log-in {
  float: right;
}

@media only screen and (max-width: 480px) {
  .menu_style13 .nav-menu__tray,
  .menu_style13 .nav-menu__log-in {
    float: none;
    display: inline-block;
  }
}

.menu_style13 nav {
  display: inline-block;
}

.menu_style13 .search-form {
  float: right;
}

.menu_style13 .nav-menu-icon {
  position: static;
  display: block;
  float: left;
  margin-left: 140px;
}

@media only screen and (max-width: 992px) {
  .menu_style13 .nav-menu-icon {
    position: absolute;
    right: 30px;
  }
}

@media only screen and (max-width: 480px) {
  .menu_style13 .nav-menu-icon {
    right: 15px;
    margin-left: 0;
  }
}

.menu_style13 nav {
  position: fixed;
  width: 320px;
  left: 0;
  top: 0;
  background-color: #fff;
  height: 100%;
  display: block;
  padding: 130px 50px;
  transition: all .1s ease;
  transform: translateX(-100%);
}

.menu_style13.active {
  z-index: 999;
}

.menu_style13.active .main-menu {
  display: block;
}

.menu_style13.active nav {
  transform: translateX(0);
  overflow-x: hidden;
  overflow-y: auto;
  z-index: 3;
}

.menu_style13.active nav:after {
  content: '';
  display: inline-block;
  position: fixed;
  top: 0;
  right: 0;
  width: 320px;
  height: 100px;
  background-color: #fff;
}

.menu_style13.active .main-menu li:hover > .sub-menu {
  display: none;
}

.menu_style13.active .main-menu li {
  margin-right: 0;
  display: block;
}

.menu_style13.active .main-menu li a {
  letter-spacing: -1px;
}

.menu_style13.active .main-menu .sub-menu {
  position: static;
  padding: 0 30px;
}

.menu_style13.active .main-menu li li {
  padding: 0;
}

.menu_style13.active .nav-menu__social {
  text-align: left;
}

.menu_style13.active .nav-menu__social li {
  padding: 0 10px;
}

.menu_style13.active .nav-menu__social li:first-child {
  padding-left: 0;
}

.menu_style13.active .nav-menu__social li a {
  font-size: 18px;
}

.menu_style13.active .f-newsletter-form .f-head-h5 {
  font-size: 18px;
  position: relative;
  display: inline-block;
}

.menu_style13.active .f-newsletter-form .f-head-h5:after {
  content: '';
  display: inline-block;
  width: 80px;
  height: 2px;
  background-color: #f2f2f2;
  position: absolute;
  top: 0;
  bottom: 0;
  margin: auto;
  margin-left: 20px;
}

.menu_style13.active .f-newsletter-form p {
  letter-spacing: .5px;
  margin-top: 5px;
  margin-bottom: 25px;
}

.menu_style13.active .f-newsletter-form form {
  position: relative;
  max-width: 220px;
}

.menu_style13.active .f-newsletter-form button {
  position: absolute;
  top: 0;
  right: 5px;
  border: none;
  outline: none;
  color: #595959;
  background-color: transparent;
  font-weight: 100;
}

.menu_style13.active .f-newsletter-form input {
  width: 100%;
  padding: 0 25px 15px 5px;
  letter-spacing: .5px;
  border-bottom: 1px solid #bfbfbf;
}

.menu_style14 {
  position: absolute;
  height: 101%;
  overflow-y: auto;
  overflow-x: hidden;
  z-index: 2;
  max-width: 340px;
  padding-right: 30px;
}

.menu_style14 .nav-menu__tray {
  float: right;
}

.menu_style14 nav {
  margin-top: 37px;
  margin-bottom: 35px;
}

.menu_style14 .main-menu li:hover > .sub-menu {
  display: none;
}

.menu_style14 .main-menu li {
  margin-right: 0;
  display: block;
}

.menu_style14 .main-menu li a {
  letter-spacing: -1px;
}

.menu_style14 .main-menu .sub-menu {
  position: static;
  padding: 0 30px;
}

.menu_style14 .main-menu li li {
  padding: 0;
}

.menu_style14 .nav-menu__social {
  text-align: left;
}

.menu_style14 .nav-menu__social li {
  padding: 0 10px;
}

.menu_style14 .nav-menu__social li:first-child {
  padding-left: 0;
}

.menu_style14 .nav-menu__social li a {
  font-size: 18px;
}

.menu_style14 .f-newsletter-form .f-head-h5 {
  font-size: 18px;
  position: relative;
  display: inline-block;
}

.menu_style14 .f-newsletter-form .f-head-h5:after {
  content: '';
  display: inline-block;
  width: 80px;
  height: 2px;
  background-color: #f2f2f2;
  position: absolute;
  top: 0;
  bottom: 0;
  margin: auto;
  margin-left: 20px;
}

.menu_style14 .f-newsletter-form p {
  letter-spacing: .5px;
  margin-top: 5px;
  margin-bottom: 25px;
}

.menu_style14 .f-newsletter-form form,
.menu_style14 .f-newsletter-form .mc4wp-form {
  display: block;
  box-sizing: border-box;
  position: relative;
  padding-left: 0px;
  max-width: 100%;
}

.menu_style14 .f-newsletter-form form:after,
.menu_style14 .f-newsletter-form .mc4wp-form:after {
  content: "\f10b";
  font-family: "Ionicons";
  width: 17px;
  color: #595959;
  line-height: 20px;
  font-size: 14px;
  display: inline-block;
  position: absolute;
  margin: auto;
  top: 0;
  right: 5px;
}

.menu_style14 .f-newsletter-form form input[type="email"],
.menu_style14 .f-newsletter-form .mc4wp-form input[type="email"] {
  float: none;
  background-color: transparent;
  display: block;
  line-height: 1;
  min-width: 0;
  max-width: 100%;
  box-sizing: border-box;
  border-radius: 0;
  width: 100%;
  font-family: 'Lato', sans-serif;
  font-size: 14px;
  font-weight: 400;
  transition: color .1s ease;
  padding: 0 25px 15px 5px;
  letter-spacing: .5px;
  border-bottom: 1px solid #bfbfbf;
}

@media only screen and (max-width: 768px) {
  .menu_style14 .f-newsletter-form form input[type="email"],
  .menu_style14 .f-newsletter-form .mc4wp-form input[type="email"] {
    padding-left: 0;
  }
}

.menu_style14 .f-newsletter-form form input[type="email"]:focus,
.menu_style14 .f-newsletter-form .mc4wp-form input[type="email"]:focus {
  border-color: #ff5a00;
}

@media only screen and (max-width: 1200px) {
  .menu_style14 .f-newsletter-form form input[type="email"],
  .menu_style14 .f-newsletter-form .mc4wp-form input[type="email"] {
    min-width: 0;
    width: 100%;
  }
}

.menu_style14 .f-newsletter-form form input[type="submit"],
.menu_style14 .f-newsletter-form .mc4wp-form input[type="submit"] {
  float: none;
  z-index: 2;
  border-radius: 0;
  background-color: transparent;
  letter-spacing: 1px;
  right: 0;
  top: 0;
  bottom: 0;
  line-height: 58px;
  font-size: 24px;
  padding: 0 20px;
  margin: auto;
  font-weight: 100;
  position: absolute;
  background-color: transparent;
  width: 60px;
  border: none;
  background-size: auto;
  transition: color .1s ease;
}

@media only screen and (max-width: 1200px) {
  .menu_style14 .f-newsletter-form form input[type="submit"],
  .menu_style14 .f-newsletter-form .mc4wp-form input[type="submit"] {
    padding: 0;
    width: 35%;
  }
}

@media only screen and (max-width: 991px) {
  .menu_style14 .f-newsletter-form form,
  .menu_style14 .f-newsletter-form .mc4wp-form {
    padding-left: 0;
  }
  .menu_style14 .f-newsletter-form form input,
  .menu_style14 .f-newsletter-form .mc4wp-form input {
    padding: 14px 15px;
  }
  .menu_style14 .f-newsletter-form form button,
  .menu_style14 .f-newsletter-form .mc4wp-form button {
    padding: 0 15px;
  }
}

@media only screen and (max-width: 992px) {
  .menu_style14 .nav-menu-icon {
    right: 30px;
  }
}

.menu_style14.active nav {
  height: auto;
  position: static;
}

@media only screen and (max-width: 1200px) {
  .menu_style14 {
    padding-left: 30px;
    padding-right: 30px;
    max-width: 300px;
  }
}

@media only screen and (max-width: 768px) {
  .menu_style14 {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 3;
    width: 100%;
    height: auto;
    max-width: 100%;
  }
  .menu_style14 .nav-menu-icon {
    right: 10px;
  }
  .menu_style14 .nav-menu__social,
  .menu_style14 .f-newsletter-form,
  .menu_style14 nav {
    display: none;
  }
  .menu_style14 .f-start-block__content-wrap {
    padding-left: 0;
  }
  .menu_style14.active {
    height: 100%;
    position: fixed;
  }
  .menu_style14.active .nav-menu__social,
  .menu_style14.active .f-newsletter-form,
  .menu_style14.active nav {
    padding-top: 0;
    display: block;
    text-align: center;
    margin-top: 30px;
    margin-bottom: 30px;
  }
  .menu_style14.active .f-newsletter-form .f-head-h5:after {
    display: none;
  }
  .menu_style14.active .f-newsletter-form form {
    max-width: 320px;
    margin-left: auto;
    margin-right: auto;
  }
}

/*--------------------------------------------------------------------------------------*/
/* start-swiper*/
/*--------------------------------------------------------------------------------------*/
.swiper-button-prev {
  left: 60px;
}

.swiper-button-prev:before {
  content: "\f3d2";
  display: inline-block;
  font-family: "Ionicons";
  font-size: 20px;
  line-height: 44px;
  color: white;
}

@media only screen and (max-width: 991px) {
  .swiper-button-prev {
    left: 15px;
  }
}

.swiper-button-next {
  right: 60px;
}

.swiper-button-next:before {
  content: "\f3d1";
  display: inline-block;
  font-family: "Ionicons";
  font-size: 20px;
  line-height: 44px;
  color: white;
}

@media only screen and (max-width: 991px) {
  .swiper-button-next {
    right: 15px;
  }
}

.swiper-button-prev,
.swiper-button-next {
  z-index: 3;
  width: 40px;
  height: 44px;
  background-color: #ff5a00;
  background-image: none;
  text-align: center;
  transition: background .3s ease;
  transition: opacity .3s ease;
}

.swiper-button-prev.swiper-button-disabled,
.swiper-button-next.swiper-button-disabled {
  cursor: pointer;
  opacity: 1;
}

.swiper-button-prev:hover,
.swiper-button-next:hover {
  background-color: #333;
}

.swiper-button-prev:hover.swiper-button-disabled,
.swiper-button-next:hover.swiper-button-disabled {
  cursor: pointer;
  opacity: .7;
}

.f-start-swiper {
  width: 100%;
  height: 100vh;
}

@media only screen and (max-width: 992px) {
  .f-start-swiper {
    height: calc(100vh - 70px);
  }
}

.f-start-swiper .swiper-pagination {
  text-align: right;
  position: absolute;
  left: auto;
  width: 50%;
  right: 0;
  padding: 0 75px;
  bottom: 40px;
}

@media only screen and (max-width: 600px) {
  .f-start-swiper .swiper-pagination {
    width: 100%;
    text-align: center;
  }
}

.f-start-swiper .swiper-pagination-bullet {
  border-radius: 0;
  transition: height .3s ease;
  width: 2px;
  height: 15px;
  background-color: #ccc;
}

@media only screen and (max-width: 991px) {
  .f-start-swiper .swiper-pagination-bullet {
    opacity: 1;
    width: 15px;
    height: 15px;
    border-radius: 7px;
    transition: width .3s ease;
    background-color: rgba(51, 51, 51, 0.5);
  }
}

.f-start-swiper .swiper-pagination-bullet-active {
  border-radius: 0;
  width: 2px;
  height: 45px;
  background-color: #ff5a00;
}

@media only screen and (max-width: 991px) {
  .f-start-swiper .swiper-pagination-bullet-active {
    opacity: 1;
    border-radius: 7px;
    background-color: #333333;
    width: 30px;
    height: 15px;
  }
}

.swiper-container {
  height: 100%;
}

.f-start-swiper__item {
  height: 100%;
  position: relative;
}

.f-start-swiper__item:before {
  content: '';
  display: inline-block;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  opacity: 0.9;
  background-color: #333;
}

.f-start-swiper__item:after {
  content: '';
  display: inline-block;
  z-index: 2;
  width: 65%;
  height: 85%;
  bottom: 0;
  left: 0;
  position: absolute;
  background-color: rgba(255, 90, 0, 0.6);
}

@media only screen and (max-width: 992px) {
  .f-start-swiper__item:before {
    display: none;
  }
  .f-start-swiper__item:after {
    width: 100%;
    height: 100%;
  }
}

.f-start-swiper__content {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  z-index: 3;
}

.f-start-swiper__content-wrap {
  font-weight: 600;
  padding: 40px 40px 40px 200px;
  position: relative;
}

.f-start-swiper__content-wrap .f-view-more-btn:after {
  bottom: -15px;
}

.f-start-swiper__content-wrap .f-head-h1 {
  max-width: 600px;
  font-size: 72px;
  line-height: 72px;
  color: white;
  letter-spacing: 5px;
  position: relative;
}

.f-start-swiper__content-wrap .f-head-h1:before {
  content: '';
  display: inline-block;
  width: 120px;
  height: 2px;
  position: absolute;
  left: -200px;
  top: 30px;
  background-color: #fff;
}

@media only screen and (max-width: 768px) {
  .f-start-swiper__content-wrap .f-head-h1 {
    font-size: 42px;
  }
  .f-start-swiper__content-wrap .f-head-h1:before {
    display: none;
  }
}

.f-start-swiper__content-wrap .f-head-h3 {
  max-width: 600px;
  color: white;
  margin-top: 15px;
  margin-bottom: 24vh;
  font-size: 24px;
  letter-spacing: 1.4px;
  line-height: 37px;
}

@media only screen and (max-width: 600px) {
  .f-start-swiper__content-wrap .f-head-h3 {
    margin-bottom: 15vh;
  }
}

@media only screen and (max-width: 600px) {
  .f-start-swiper__content-wrap a {
    display: inline-block;
    margin-bottom: 10vh;
  }
}

@media only screen and (max-width: 768px) {
  .f-start-swiper__content-wrap {
    padding: 40px 15px;
  }
}

/* swiper_style2*/
.swiper_style2 {
  max-width: 1760px;
  margin-left: auto;
  margin-right: auto;
  transition: color .3s ease;
}

.swiper_style2 .f-start-block__content-wrap {
  padding-top: 44vh;
  max-width: 950px;
  padding-left: 30vh;
  left: 0;
}

@media only screen and (max-width: 1600px) {
  .swiper_style2 .f-start-block__content-wrap {
    max-width: 600px;
  }
}

.swiper_style2 .f-start-block__content-wrap .f-head-h1 {
  text-transform: uppercase;
  letter-spacing: 12px;
  font-weight: 400;
}

.swiper_style2 .f-start-block__content-wrap p {
  max-width: 635px;
  line-height: 32px;
  letter-spacing: 0.9px;
  margin-top: 15px;
  margin-bottom: 25px;
}

@media only screen and (max-width: 1500px) {
  .swiper_style2 .f-start-block__content-wrap {
    padding-left: 15vh;
  }
}

@media only screen and (max-width: 1200px) {
  .swiper_style2 .f-start-block__content-wrap {
    padding-left: 15px;
    padding-right: 15px;
  }
}

.swiper_style2 .f-start-swiper__item:before {
  width: 100%;
  opacity: 0.8;
  background-color: #fafafa;
}

@media only screen and (max-width: 1200px) {
  .swiper_style2 .f-start-swiper__item:before {
    display: none;
  }
}

.swiper_style2 .f-start-swiper__item:after {
  width: 66%;
  opacity: 0.8;
  height: 100%;
  z-index: 1;
  background-color: #ff5a00;
}

@media only screen and (max-width: 1200px) {
  .swiper_style2 .f-start-swiper__item:after {
    width: 100%;
  }
}

.swiper_style2 .swiper-button-next,
.swiper_style2 .swiper-button-prev {
  transition: background .3s ease;
}

.swiper_style2 .swiper-button-next:hover,
.swiper_style2 .swiper-button-prev:hover {
  opacity: 1;
  background-color: #333;
}

.swiper_style2 .swiper-button-next:hover.swiper-button-disabled,
.swiper_style2 .swiper-button-prev:hover.swiper-button-disabled {
  cursor: pointer;
  opacity: 1;
  background-color: #333;
}

.swiper_style2 .swiper-button-prev {
  right: 40px;
  bottom: 0;
  left: auto;
  top: auto;
}

.swiper_style2 .swiper-button-next {
  right: 0;
  bottom: 0;
  left: auto;
  top: auto;
}

.swiper_style2 .swiper-button-disabled {
  cursor: pointer;
  opacity: 1;
  background-color: #333;
}

.swiper_style2 .f-start-swiper__item {
  z-index: 2;
}

/*--------------------------------------------------------------------------------------*/
/* f-about*/
/*--------------------------------------------------------------------------------------*/
.f-about {
  position: relative;
}

.f-about:after {
  content: '';
  display: inline-block;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background: url("../img/fuller_svg_template_good.svg") 0 0 no-repeat;
}

@media only screen and (max-width: 991px) {
  .f-about {
    background: none;
  }
}

.f-about__text {
  position: relative;
  z-index: 2;
}

.f-about__text .f-head-h2 {
  line-height: 36px;
  letter-spacing: 0.5px;
}

.f-about__text p {
  margin-top: 25px;
  line-height: 25px;
  letter-spacing: .6px;
  margin-bottom: 55px;
}

.f-about__img {
  position: relative;
  z-index: 2;
  box-sizing: border-box;
  display: inline-block;
  max-width: 420px;
}

.f-about__img img {
  position: relative;
  max-width: 370px;
  bottom: 85px;
  left: 100px;
}

@media only screen and (max-width: 991px) {
  .f-about__img {
    display: none;
  }
}

.f-about-content {
  position: relative;
  z-index: 2;
  text-align: center;
}

.f-about-content img {
  display: inline-block;
  margin-bottom: 65px;
  max-width: 100%;
}

.f-about-content .f-head-h3 {
  letter-spacing: .5px;
  margin-bottom: 10px;
}

.f-about-content p {
  max-width: 630px;
  margin-left: auto;
  margin-right: auto;
}

/* f-about1_style2 */
.f-about1_style2 .f-head-h2 {
  font-weight: 900;
}

.f-about1_style2 .f-about__img {
  text-align: center;
  max-width: 100%;
  width: 100%;
}

.f-about1_style2 .f-about__img img {
  position: static;
}

.f-about1_style3:after {
  background: url("../img/about_mask.svg") center no-repeat;
  background-size: cover;
}

.f-about1_style3 header {
  position: relative;
  z-index: 2;
}

.f-about2 .f-head-h2:after {
  display: none;
}

.f-about2 header .f-head-h2 {
  color: #333;
  font-size: 36px;
  line-height: 60px;
  letter-spacing: 4px;
  text-transform: uppercase;
  font-weight: 900;
  margin-bottom: 10px;
}

@media only screen and (max-width: 767px) {
  .f-about2 header .f-head-h2 {
    line-height: 1;
    font-size: 30px;
  }
}

.f-about2 header .f-head-h5 {
  color: #ff5a00;
  font-size: 16px;
  letter-spacing: 2px;
  text-transform: uppercase;
}

.f-about2 header p {
  line-height: 25px;
  letter-spacing: .5px;
  max-width: 630px;
  margin-left: auto;
  margin-right: auto;
}

.f-about2_style2 header .f-head-h2 {
  text-transform: none;
  float: none;
  letter-spacing: 1px;
}

.f-about2_style2 .f-skills__item:nth-child(4) {
  padding-left: 100px;
}

.f-about2_style2 .f-skills__item:nth-child(4) i {
  left: 0;
}

.f-about2_style2 .f-skills__item:nth-child(5) {
  padding-left: 115px;
}

.f-about2_style2 .f-skills__item:nth-child(5) i {
  left: 15px;
}

.f-about2_style2 .f-skills__item_3col:nth-child(-n+100) {
  padding-left: 100px;
}

.f-about2_style2 .f-skills__item_3col:nth-child(-n+100) i {
  left: 0;
}

@media only screen and (max-width: 480px) {
  .f-about2_style2 .f-skills__item_3col:nth-child(-n+100) {
    padding-left: 0;
  }
}

/*--------------------------------------------------------------------------------------*/
/* f-feature */
/*--------------------------------------------------------------------------------------*/
.f-feature {
  position: relative;
  z-index: 2;
}

.f-feature:after {
  clear: both;
  content: '';
  display: table;
}

.f-feature__item {
  padding-left: 60px;
  width: 50%;
  float: left;
  position: relative;
}

.f-feature__item i {
  position: absolute;
  left: 0;
  top: 0;
  color: #ff5a00;
  font-size: 48px;
  font-weight: 400;
}

.f-feature__item .f-head-h5 {
  line-height: 28px;
  letter-spacing: .5px;
  margin-bottom: 5px;
}

.f-feature__item p {
  margin: 0;
}

@media only screen and (max-width: 991px) {
  .f-feature__item {
    padding-right: 15px;
  }
}

@media only screen and (max-width: 600px) {
  .f-feature__item {
    width: 100%;
    margin-bottom: 50px;
    padding-right: 0;
  }
  .f-feature__item:last-child {
    margin-bottom: 0;
  }
}

/*--------------------------------------------------------------------------------------*/
/* portfolio*/
/*--------------------------------------------------------------------------------------*/
.f-portfolio {
  width: 100%;
}

.f-portfolio:after {
  clear: both;
  display: table;
  content: '';
}

.f-portfolio__item {
  height: 500px;
  float: left;
  width: 33.33%;
}

@media only screen and (max-width: 992px) {
  .f-portfolio__item {
    height: 300px;
  }
}

@media only screen and (max-width: 600px) {
  .f-portfolio__item {
    width: 100%;
  }
}

.f-portfolio__content-wrap {
  width: 100%;
  height: 100%;
  display: table;
}

.f-portfolio_style2 .f-portfolio__item {
  position: relative;
  padding-left: 15px;
  padding-right: 15px;
}

.f-portfolio_style2 .f-portfolio__item:after {
  position: absolute;
  top: 0;
  left: 0;
  content: '';
  display: inline-block;
  width: 100%;
  height: 100%;
  opacity: 0.8;
  background-color: #333;
}

@media only screen and (max-width: 767px) {
  .f-portfolio_style2 .f-portfolio__item {
    width: 100%;
    border-bottom: 1px solid #fff;
  }
}

.f-portfolio__text {
  display: table-cell;
  position: relative;
  z-index: 2;
  vertical-align: middle;
  text-align: center;
}

.f-portfolio__text .f-head-h3 {
  color: white;
  line-height: 28px;
}

.f-portfolio__text p {
  margin-bottom: 35px;
  margin-top: 25px;
  letter-spacing: .7px;
  color: #ccc;
  max-width: 310px;
  margin-left: auto;
  margin-right: auto;
}

/*--------------------------------------------------------------------------------------*/
/* choose-me*/
/*--------------------------------------------------------------------------------------*/
.f-skills:after {
  clear: both;
  content: '';
  display: table;
}

.f-skills__item {
  padding-left: 95px;
  position: relative;
  float: left;
  width: 50%;
  margin-bottom: 55px;
}

.f-skills__item:nth-child(2n) {
  padding-left: 115px;
}

.f-skills__item:nth-child(2n) i {
  left: 15px;
}

.f-skills__item i {
  position: absolute;
  top: 0;
  left: 0;
  background-color: #ff5a00;
  display: inline-block;
  width: 70px;
  height: 70px;
  text-align: center;
  line-height: 70px;
  color: white;
  font-size: 40px;
}

.f-skills__item .f-head-h5 {
  line-height: 20px;
  letter-spacing: .4px;
  margin-bottom: 5px;
}

.f-skills__item p {
  letter-spacing: .6px;
  max-width: 275px;
}

@media only screen and (max-width: 991px) {
  .f-skills__item {
    padding-left: 95px;
    padding-right: 15px;
  }
}

@media only screen and (max-width: 767px) {
  .f-skills__item {
    width: 100%;
    padding-right: 0;
  }
  .f-skills__item p {
    max-width: 100%;
  }
  .f-skills__item:nth-child(2n) {
    padding-left: 95px;
  }
  .f-skills__item:nth-child(2n) i {
    left: 0;
  }
}

@media only screen and (max-width: 480px) {
  .f-skills__item {
    padding-left: 0;
    text-align: center;
  }
  .f-skills__item:nth-child(2n) {
    padding-left: 0;
  }
  .f-skills__item i {
    margin-bottom: 20px;
    position: static;
  }
}

.f-choose-me-img-wrap {
  position: relative;
  width: 100%;
}

@media only screen and (max-width: 1199px) {
  .f-choose-me-img-wrap {
    display: none;
  }
}

.f-choose-me__img {
  position: absolute;
  top: -30px;
  right: -30px;
  height: 670px;
  width: 405px;
  border: 25px solid #ff5a00;
  box-sizing: border-box;
}

@media only screen and (max-width: 1200px) {
  .f-choose-me__img {
    right: -150px;
  }
}

@media only screen and (max-width: 991px) {
  .f-choose-me__img {
    display: none;
  }
}

.f-choose-me__img img {
  position: absolute;
  bottom: 55px;
  left: -220px;
  max-height: 405px;
}

.f-choose-me__img_style2 {
  float: right;
  top: -235px;
  border-top: none;
  height: 645px;
  right: auto;
  left: 100%;
  border-right: none;
}

@media only screen and (max-width: 1600px) {
  .f-choose-me__img_style2 {
    left: 75%;
  }
}

.f-skills__item_3col {
  width: 33%;
}

.f-skills__item_3col:nth-child(3n) {
  padding-left: 130px;
}

.f-skills__item_3col:nth-child(3n) i {
  left: 30px;
}

@media only screen and (max-width: 992px) {
  .f-skills__item_3col {
    width: 100%;
    max-width: 600px;
    margin-left: auto;
    margin-right: auto;
    float: none;
  }
  .f-skills__item_3col:nth-child(2n), .f-skills__item_3col:nth-child(3n) {
    padding-left: 100px;
  }
  .f-skills__item_3col:nth-child(2n) i, .f-skills__item_3col:nth-child(3n) i {
    left: 0;
  }
  .f-skills__item_3col p {
    max-width: 100%;
  }
}

@media only screen and (max-width: 992px) {
  .f-skills__item_3col:nth-child(2n), .f-skills__item_3col:nth-child(3n) {
    padding-left: 0;
  }
}

.f-choose-me_style2 .f-choose-me__img {
  top: -50px;
  right: auto;
  left: calc(100% + 85px);
  width: 295px;
  background-color: #ff5a00;
}

@media only screen and (max-width: 1600px) {
  .f-choose-me_style2 .f-choose-me__img {
    left: 100%;
  }
}

.f-choose-me_style2 .f-choose-me__img img {
  bottom: auto;
  top: 25px;
  left: calc( -100% - 40px);
}

@media only screen and (max-width: 1600px) {
  .f-choose-me_style2 .f-choose-me__img img {
    left: auto;
    right: 0;
  }
}

.f-choose-me__description p {
  letter-spacing: .6px;
  max-width: 800px;
}

/*--------------------------------------------------------------------------------------*/
/* testimonial*/
/*--------------------------------------------------------------------------------------*/
.f-testimonial__item {
  padding: 80px 0 75px;
  position: relative;
  text-align: center;
}

.f-testimonial__item:after {
  content: '';
  display: inline-block;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0.9;
  background-color: #333;
}

@media only screen and (max-width: 991px) {
  .f-testimonial__item {
    padding: 50px 0;
  }
}

.f-testimonial__user {
  position: relative;
  z-index: 2;
  display: inline-block;
}

.f-testimonial__user:after {
  content: '';
  display: table;
  clear: both;
}

.f-testimonial__user-logo {
  float: left;
}

.f-testimonial__user-logo img {
  max-width: 60px;
  max-height: 60px;
}

.f-testimonial__user-info {
  text-align: left;
  margin: 10px 15px 0 30px;
  float: left;
  font-weight: 600;
}

.f-testimonial__user-info .f-head-h5 {
  color: #fff;
  letter-spacing: 1px;
  line-height: 25px;
}

.f-testimonial__user-info span {
  color: #fff;
  font-size: 12px;
  line-height: 25px;
  letter-spacing: .5px;
}

.f-testimonial__text {
  position: relative;
  margin-top: 40px;
  margin-bottom: 10px;
  z-index: 2;
}

.f-testimonial__text p {
  color: #ccc;
  font-size: 14px;
  line-height: 25px;
  max-width: 735px;
  display: inline-block;
  letter-spacing: .6px;
}

@media only screen and (max-width: 991px) {
  .f-testimonial__text p {
    max-width: 500px;
  }
}

@media only screen and (max-width: 767px) {
  .f-testimonial__text p {
    max-width: 100%;
    padding-left: 60px;
    padding-right: 60px;
  }
}

.f-testimonial__rating {
  position: relative;
  z-index: 2;
}

.f-testimonial__rating i {
  padding: 0 2px;
  font-size: 20px;
}

/* testimonial_style2*/
.swiper-pagination-bullet {
  width: 15px;
  height: 15px;
  margin: 0 15px;
  background-color: #ccc;
  opacity: 1;
}

.swiper-pagination-bullet-active {
  background-color: #ff5a00;
}

.f-testimonial_style2 {
  position: relative;
}

.f-testimonial_style2:after {
  content: '';
  opacity: 0.9;
  background-color: #333;
  display: inline-block;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
}

.f-testimonial_style2 .f-head-h2 {
  line-height: 1;
}

.f-testimonial_style2 .f-head-h5 {
  color: #ff5a00;
  font-family: 'Lato', sans-serif;
  font-size: 16px;
  margin-top: 15px;
  margin-bottom: 20px;
  text-transform: uppercase;
  letter-spacing: 2.2px;
}

.f-testimonial_style2 p {
  letter-spacing: .5px;
  color: #ccc;
}

.f-testimonial_style2 .swiper-pagination {
  text-align: left;
  margin-left: -50%;
  bottom: 0;
}

@media only screen and (max-width: 991px) {
  .f-testimonial_style2 .swiper-pagination {
    margin-left: 0;
    position: absolute;
    text-align: center;
    width: 100%;
    bottom: -50px;
  }
}

.swiper-container_style-arrow2 .swiper-button-prev {
  left: 0;
}

.swiper-container_style-arrow2 .swiper-button-next {
  right: 0;
}

.f-testimonial__item_style2 {
  background-color: white;
  padding: 50px;
}

.f-testimonial__item_style2:after {
  display: none;
}

.f-testimonial__item_style2 .f-testimonial__user {
  display: block;
}

.f-testimonial__item_style2 .f-testimonial__user-logo {
  margin: 0;
  margin-right: 25px;
  text-align: left;
}

.f-testimonial__item_style2 .f-testimonial__user-logo img {
  max-width: 70px;
  max-height: 70px;
  width: 70px;
  height: 70px;
}

.f-testimonial__item_style2 .f-testimonial__user-info {
  max-width: 180px;
  width: auto;
  margin: 0;
  float: none;
  display: table;
  min-height: 70px;
}

.f-testimonial__item_style2 .f-testimonial__user-info .f-head-h5 {
  margin: 0;
  margin-top: 10px;
  display: table-cell;
  color: #333;
  text-transform: none;
  vertical-align: middle;
  font-size: 16px;
}

.f-testimonial__item_style2 .f-testimonial__text {
  text-align: left;
  margin-top: 25px;
  margin-bottom: 15px;
}

@media only screen and (max-width: 767px) {
  .f-testimonial__item_style2 .f-testimonial__text p {
    padding-left: 0;
    padding-right: 0;
  }
}

.f-testimonial__item_style2 .f-testimonial__rating {
  margin-top: 10px;
  text-align: left;
}

.f-testimonial__item_style3 {
  text-align: center;
}

.f-testimonial__item_style3:after {
  display: none;
}

.f-testimonial__item_style3 .f-head-h2 {
  color: #333;
  letter-spacing: 1px;
}

.f-testimonial__item_style3 .f-testimonial__text {
  margin-top: 30px;
  margin-bottom: 30px;
}

.f-testimonial__item_style3 .f-testimonial__text p {
  color: grey;
  max-width: 750px;
}

.f-testimonial__item_style3 .f-testimonial__user-info .f-head-h5 {
  color: #333;
}

.f-testimonial__item_style3 .f-testimonial__user-info span {
  color: grey;
}

.f-testimonial__item_style3 .f-testimonial__user {
  margin-top: 80px;
}

/*--------------------------------------------------------------------------------------*/
/* blog*/
/*--------------------------------------------------------------------------------------*/
.f-blog1-wrap {
  max-width: 1170px;
  margin-left: auto;
  margin-right: auto;
  padding-left: 100px;
  padding-right: 100px;
}

@media only screen and (max-width: 1200px) {
  .f-blog1-wrap {
    padding-left: 15px;
    padding-right: 15px;
  }
}

.f-blog-item {
  margin-bottom: 80px;
}

.f-blog-item-content {
  height: 370px;
}

.f-blog-item-content .f-audio-wrap,
.f-blog-item-content .f-video-wrap {
  height: 100%;
}

.f-blog-item-content .swiper-pagination-fraction,
.f-blog-item-content .swiper-pagination-custom,
.f-blog-item-content .swiper-container-horizontal > .swiper-pagination-bullets {
  bottom: 35px;
}

.f-blog-item-content .swiper-container-horizontal > .swiper-pagination-bullets .swiper-pagination-bullet {
  margin: 0 15px;
}

.f-blog-item-head {
  margin-top: 25px;
  text-align: center;
  max-width: 730px;
  margin-left: auto;
  margin-right: auto;
}

.f-blog-item-head .f-head-h4 {
  line-height: 32px;
  letter-spacing: .8px;
  margin-bottom: 10px;
  display: block;
  font-size: 20px;
  color: #333;
  font-weight: 900;
  transition: color .3s ease;
}

.f-blog-item-head .f-head-h4:hover {
  color: #ff5a00;
}

.f-content-info {
  display: inline-block;
  margin-left: auto;
  margin-right: auto;
}

.f-content-info li {
  display: inline-block;
}

.f-content-info li a,
.f-content-info li span {
  color: #999;
  padding: 0 10px;
  letter-spacing: .5px;
  border-left: 1px solid #999;
  font-size: 14px;
  font-weight: 400;
  line-height: 14px;
  transition: color .3s ease;
}

.f-content-info li a:hover {
  color: #ff5a00;
}

.f-content-info li a {
  border: none;
}

.f-content-info li:first-child span {
  border: none;
  padding-left: 0;
}

.f-blog-style2:after {
  clear: both;
  display: table;
  content: '';
}

.f-blog-style2 .f-blog-item {
  width: calc( 50% - 15px);
  margin-right: 30px;
  margin-bottom: 30px;
  float: left;
}

.f-blog-style2 .f-blog-item:nth-child(2n) {
  margin-right: 0;
}

@media only screen and (max-width: 768px) {
  .f-blog-style2 .f-blog-item {
    width: calc( 100% - 30px);
    margin-right: 15px;
    margin-left: 15px;
  }
}

.f-blog-style2 .f-blog-item,
.f-blog-style2 .f-blog-item-head {
  text-align: left;
}

.f-blog-style2 .f-blog-item-content {
  height: 260px;
}

.f-blog-style2 .f-blog-item-head li:first-child a {
  padding-left: 0;
}

.f-blog-style2 .f-blog-item-head a {
  transition: color .5s ease;
}

.f-blog-style2 .f-blog-item-head a:hover {
  color: #ff5a00;
}

.f-blog-style2 .f-blog-item-text {
  margin-top: 20px;
  margin-bottom: 20px;
}

.f-blog-style2 .f-blog-item-text p {
  letter-spacing: .6px;
}

.f-blog-style3 .f-blog-item {
  margin-bottom: 30px;
}

@media only screen and (max-width: 768px) {
  .f-blog-style3 .f-blog-item {
    width: calc( 100% - 30px);
    margin-right: 15px;
    margin-left: 15px;
  }
}

.f-blog-style3 .f-blog-item,
.f-blog-style3 .f-blog-item-head {
  text-align: left;
}

.f-blog-style3 .f-content-info {
  text-align: left;
}

.f-blog-style3 .f-blog-item-content {
  height: 500px;
}

@media only screen and (max-width: 768px) {
  .f-blog-style3 .f-blog-item-content {
    height: 370px;
  }
}

.f-blog-style3 .f-blog-item-head li:first-child a {
  padding-left: 0;
}

.f-blog-style3 .f-blog-item-head a {
  transition: color .5s ease;
}

.f-blog-style3 .f-blog-item-head a:hover {
  color: #ff5a00;
}

.f-blog-style3 .f-blog-item-text {
  margin-top: 20px;
  margin-bottom: 20px;
  height: 135px;
  overflow: hidden;
}

.f-blog-style3 .f-blog-item-text p {
  letter-spacing: .6px;
}

.f-blog-style5 .f-blog-item__img {
  height: 250px;
}

.f-blog-style5 .f-blog-item__head {
  margin-top: 20px;
}

.f-blog-style5 .f-blog-item__head .f-head-h6 {
  text-transform: none;
  letter-spacing: .5px;
}

.f-blog-items_left-style {
  text-align: left;
}

.f-blog-items_left-style .f-blog-item-head {
  text-align: left;
  margin-top: 30px;
}

.f-blog-items_left-style .f-blog-item-head .f-head-h4 {
  letter-spacing: 1.8px;
}

.f-blog-items_left-style .f-content-info li:first-child a, .f-blog-items_left-style .f-content-info li:first-child span, .f-blog-items_left-style .f-content-info li:first-child a, .f-blog-items_left-style .f-content-info li:first-child span {
  padding-left: 0;
}

.f-blog-items_left-style .f-blog-item {
  margin-bottom: 60px;
}

.f-blog-items_right-padd {
  padding-right: 50px;
}

@media only screen and (max-width: 991px) {
  .f-blog-items_right-padd {
    padding-right: 0;
  }
}

.f-blog-item__img {
  height: 260px;
}

.f-blog-item__head {
  margin-top: 30px;
}

.f-blog-item__head .f-head-h6 {
  color: #595959;
  font-size: 20px;
  text-transform: none;
  font-weight: 600;
  line-height: 32px;
  transition: color .3s ease;
}

.f-blog-item__head .f-head-h6:hover {
  color: #ff5a00;
}

.f-blog-item__head ul {
  margin-top: 5px;
  margin-bottom: 20px;
}

.f-blog-item__head li {
  color: #999;
  font-size: 14px;
  display: inline-block;
  font-weight: 400;
  letter-spacing: .5px;
  padding: 0 10px;
  border-left: 1px solid #999;
}

.f-blog-item__head li:first-child {
  padding-left: 0;
  border: none;
}

.f-blog-item__head p {
  color: grey;
  letter-spacing: .6px;
  font-size: 14px;
  font-weight: 400;
  line-height: 25px;
}

/* Medium Devices, Desktops */
@media only screen and (max-width: 991px) {
  .f-blog-item {
    margin-bottom: 50px;
  }
}

/*--------------------------------------------------------------------------------------*/
/* subscribe*/
/*--------------------------------------------------------------------------------------*/
.f-subscribe {
  position: relative;
  max-width: 1760px;
  margin-left: auto;
  margin-right: auto;
}

.f-subscribe:before {
  content: '';
  display: inline-block;
  width: 100%;
  height: 100%;
  opacity: 0.9;
  background-color: #333;
  position: absolute;
  top: 0;
  left: 0;
}

.f-subscribe__info {
  position: relative;
  z-index: 2;
}

.f-subscribe__info .f-head-h3 {
  color: white;
  font-size: 24px;
  line-height: 28px;
  letter-spacing: 1px;
}

.f-subscribe__info span {
  color: #ccc;
  font-size: 14px;
  line-height: 28px;
  letter-spacing: .7px;
}

@media only screen and (max-width: 991px) {
  .f-subscribe__info {
    margin-bottom: 30px;
  }
}

.mc4wp-form {
  margin-top: 5px;
}

.f-subscribe__form,
.mc4wp-form {
  position: relative;
  z-index: 2;
  padding-left: 5px;
}

.f-subscribe__form:after,
.mc4wp-form:after {
  clear: both;
  content: '';
  display: table;
}

.f-subscribe__form input[type="email"],
.mc4wp-form input[type="email"] {
  float: left;
  background-color: white;
  border-radius: 5px 0px 0px 5px;
  padding: 14px 35px;
  width: 70%;
  letter-spacing: .6px;
}

@media only screen and (max-width: 1200px) {
  .f-subscribe__form input[type="email"],
  .mc4wp-form input[type="email"] {
    min-width: 0;
    width: 65%;
  }
}

.f-subscribe__form input[type="submit"],
.mc4wp-form input[type="submit"] {
  float: left;
  width: 30%;
  background-color: #ff5a00;
  border-radius: 0px 5px 5px 0px;
  line-height: 45px;
  padding: 0 35px;
  font-size: 15px;
  color: #fff;
  transition: opacity .3s ease;
  letter-spacing: 1px;
}

.f-subscribe__form input[type="submit"]:hover,
.mc4wp-form input[type="submit"]:hover {
  opacity: .7;
}

@media only screen and (max-width: 1200px) {
  .f-subscribe__form input[type="submit"],
  .mc4wp-form input[type="submit"] {
    padding: 0;
    width: 35%;
  }
}

@media only screen and (max-width: 991px) {
  .f-subscribe__form,
  .mc4wp-form {
    padding-left: 0;
  }
  .f-subscribe__form input,
  .mc4wp-form input {
    padding: 14px 15px;
  }
  .f-subscribe__form button,
  .mc4wp-form button {
    padding: 0 15px;
  }
}

.f-subscribe__form {
  position: relative;
  z-index: 2;
  padding-left: 5px;
}

.f-subscribe__form:after {
  clear: both;
  content: '';
  display: table;
}

.f-subscribe__form input {
  float: left;
  background-color: white;
  border-radius: 5px 0px 0px 5px;
  padding: 14px 35px;
  min-width: 310px;
  letter-spacing: .6px;
}

@media only screen and (max-width: 1200px) {
  .f-subscribe__form input {
    min-width: 0;
    width: 65%;
  }
}

.f-subscribe__form button {
  float: left;
  background-color: #ff5a00;
  border-radius: 0px 5px 5px 0px;
  line-height: 45px;
  padding: 0 35px;
  transition: opacity .3s ease;
  letter-spacing: 1px;
}

.f-subscribe__form button:hover {
  opacity: .7;
}

@media only screen and (max-width: 1200px) {
  .f-subscribe__form button {
    padding: 0;
    width: 35%;
  }
}

@media only screen and (max-width: 991px) {
  .f-subscribe__form {
    padding-left: 0;
  }
  .f-subscribe__form input {
    padding: 14px 15px;
  }
  .f-subscribe__form button {
    padding: 0 15px;
  }
}

/*--------------------------------------------------------------------------------------*/
/* footer*/
/*--------------------------------------------------------------------------------------*/
.f-footer__info-item {
  text-align: center;
  border: 1px solid #e6e6e6;
  padding: 35px 0 30px;
}

.f-footer__info-item i {
  color: #ff5a00;
  font-size: 40px;
  line-height: 40px;
  vertical-align: top;
}

.f-footer__info-item .f-head-h5 {
  color: #595959;
  font-size: 16px;
  line-height: 20px;
  transition: color .3s ease;
}

.f-footer__info-item span {
  color: grey;
  font-size: 14px;
  line-height: 28px;
  letter-spacing: .6px;
  transition: color .3s ease;
}

.f-footer__info-item .f-footer__info-text {
  display: inline-block;
  padding-left: 15px;
  text-align: left;
}

.f-footer__info-item i,
.f-footer__info-item a {
  display: inline-block;
}

.f-footer__info-item:hover span {
  color: #ff5a00;
}

@media only screen and (max-width: 1200px) {
  .f-footer__info-item {
    padding: 15px;
  }
}

@media only screen and (max-width: 991px) {
  .f-footer__info-item {
    border: none;
  }
}

.f-footer-content {
  text-align: center;
}

.f-footer-content p {
  margin-top: 20px;
  letter-spacing: .55px;
  max-width: 635px;
  line-height: 28px;
  margin-left: auto;
  margin-right: auto;
}

.f-footer-social {
  display: block;
  text-align: center;
  margin-left: auto;
  margin-right: auto;
}

.f-footer-social li {
  display: inline-block;
}

.f-footer-social a {
  padding: 0 15px;
  transition: color .3s ease;
  font-size: 23px;
  color: #a6a6a6;
}

.f-footer-social a:hover {
  color: #ff5a00;
}

@media only screen and (max-width: 767px) {
  .f-footer-social a {
    color: #ff5a00;
  }
  .f-footer-social a:hover {
    opacity: .8;
  }
}

.f-footer-copyright {
  position: relative;
  display: block;
  padding: 30px;
  background: rgba(51, 51, 51, 0.9);
  text-align: center;
}

.f-footer-copyright:after {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  margin: auto;
  content: '';
  display: inline-block;
  max-width: 1760px;
  width: 100%;
  height: 2px;
  background-color: #ff5a00;
}

.f-footer-copyright span {
  color: #ccc;
  font-size: 14px;
  line-height: 28px;
  letter-spacing: .5px;
}

.f-footer-copyright_style3 {
  padding-top: 45px;
  padding-bottom: 45px;
}

.f-footer-copyright_style3:after {
  display: none;
}

.f-footer-copyright_style4 {
  text-align: center;
  padding: 50px 0;
}

.f-footer-copyright_style4:after {
  display: table;
  clear: both;
  content: '';
  width: 0;
  height: 0;
}

.f-footer-copyright_style4 .nav-menu__social {
  float: right;
}

@media only screen and (max-width: 991px) {
  .f-footer-copyright_style4 .nav-menu__social {
    float: none;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
  }
}

.f-footer-copyright_style4 .full-logo {
  float: left;
}

@media only screen and (max-width: 991px) {
  .f-footer-copyright_style4 .full-logo {
    margin-bottom: 10px;
    float: none;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
  }
}

.f-footer-copyright_style4 span {
  line-height: 40px;
}

.f-footer-copyright_style2 {
  background-color: white;
  border-top: 2px solid #ff5a00;
}

.f-footer-copyright_style2 span {
  color: grey;
}

.f-footer-copyright_style2:after {
  display: none;
}

/* footer style 2 */
.f-footer2 {
  min-height: 520px;
  max-width: 1760px;
  background-color: rgba(51, 51, 51, 0.9);
  margin-left: auto;
  margin-right: auto;
}

.f-footer2 .f-head-h5 {
  color: white;
  letter-spacing: 1.5px;
  text-transform: uppercase;
}

.f-footer2 .f-info-widget .full-logo {
  margin-bottom: 30px;
}

/* .f-tweeter-widget */
.f-tweeter-item {
  position: relative;
  padding-left: 55px;
  border-bottom: 1px solid #595959;
  padding-bottom: 15px;
  padding-top: 15px;
}

.f-tweeter-item:last-child {
  border: none;
}

.f-tweeter-item i {
  position: absolute;
  left: 0;
  top: 25px;
  display: inline-block;
  width: 30px;
  height: 30px;
  background-color: #6e6e6e;
  text-align: center;
  line-height: 30px;
  color: #fff;
}

.f-tweeter-item p,
.f-tweeter-item span {
  font-family: Lato;
  font-size: 14px;
  font-weight: 400;
  line-height: 28px;
  color: #ccc;
  letter-spacing: .5px;
}

.f-tweeter-item a {
  color: #ff5a00;
}

/* f-post-widget */
.f-post-widget li {
  padding-top: 15px;
  padding-bottom: 20px;
  border-bottom: 1px solid #595959;
}

.f-post-widget li:last-child {
  border: none;
}

.f-post-widget li a,
.f-post-widget li p {
  display: block;
  color: #ccc;
  width: 100%;
  font-size: 14px;
  line-height: 34px;
  letter-spacing: .6px;
  transition: color .1s ease;
}

.f-post-widget li a:hover,
.f-post-widget li p:hover {
  color: #ff5a00;
}

.f-post-widget li ul {
  margin-top: 5px;
  margin-bottom: 20px;
}

.f-post-widget li span {
  color: #999;
  font-size: 12px;
  display: inline-block;
  font-weight: 400;
  letter-spacing: 0.5px;
  padding: 0 10px;
  border-left: 1px solid #999;
}

.f-post-widget li span:nth-child(2), .f-post-widget li span:first-child {
  padding-left: 0;
  border: none;
}

.f-post-widget li .f-blog-item__head {
  margin-top: 0;
}

/* f-instagram-widget__wrap */
.f-instagram-widget__wrap {
  margin-top: 25px;
}

.f-instagram-widget__wrap:after {
  content: '';
  display: table;
  clear: both;
}

.f-instagram-widget__wrap a {
  float: left;
  max-width: calc( 25% - 4px);
  max-height: calc( 25% - 4px);
  text-align: center;
  display: inline-block;
  margin: 0 10px 8px 0;
}

.f-instagram-widget__wrap a img {
  max-width: 100%;
}

.f-instagram-widget__wrap a:nth-child(4n) {
  margin-right: 0;
}

@media only screen and (max-width: 1200px) {
  .f-instagram-widget__wrap a {
    max-width: calc( 25% - 4px);
    height: auto;
    margin-right: 5px;
    margin-bottom: 1px;
  }
  .f-instagram-widget__wrap a:nth-child(4n) {
    margin-right: 0;
  }
}

@media only screen and (max-width: 991px) {
  .f-instagram-widget__wrap a {
    margin-bottom: 4px;
  }
}

@media only screen and (max-width: 1200px) {
  .f-instagram-widget__wrap {
    max-width: 380px;
  }
}

/* f-footer-copyright2 */
.f-footer-copyright2 {
  padding: 30px 15px;
  border-top: 2px solid #ff5a00;
  text-align: center;
}

.f-footer-copyright2 span {
  color: #ccc;
  font-size: 14px;
  line-height: 28px;
  letter-spacing: .5px;
}

@media only screen and (max-width: 991px) {
  .f-tweeter-widget,
  .f-post-widget {
    margin-bottom: 50px;
  }
}

.widget_pages,
.f-menu-widget {
  margin-bottom: 20px;
}

.widget_pages ul,
.f-menu-widget ul {
  margin-top: 15px;
}

.widget_pages ul a,
.f-menu-widget ul a {
  color: #ccc;
  font-size: 14px;
  letter-spacing: .5px;
  line-height: 35px;
  transition: color .3s ease;
}

.widget_pages ul a:hover,
.f-menu-widget ul a:hover {
  color: #ff5a00;
}

.f-info-widget {
  margin-bottom: 10px;
}

.f-info-widget .full-logo {
  margin-top: 5px;
  margin-bottom: 20px;
}

.f-info-widget p {
  color: #ccc;
  line-height: 28px;
  max-width: 270px;
  letter-spacing: .5px;
}

.f-info-widget .nav-menu__social {
  margin-top: 50px;
  display: block;
  text-align: left;
}

@media only screen and (max-width: 992px) {
  .f-info-widget .nav-menu__social {
    margin-bottom: 20px;
    margin-top: 20px;
  }
}

.f-footer2_style2 .f-info-widget p {
  max-width: 100%;
}

.f-footer2_style2 .nav-menu__social {
  text-align: center;
}

.f-footer2_style2 .f-info-widget,
.f-footer2_style2 h5 {
  text-align: center;
}

@media only screen and (max-width: 991px) {
  .f-footer2_style2 .f-instagram-widget__wrap {
    margin-left: auto;
    margin-right: auto;
  }
}

/*--------------------------------------------------------------------------------------*/
/* Home 2 start-block*/
/*--------------------------------------------------------------------------------------*/
.f-start-block {
  height: calc(100vh - 100px);
  position: relative;
}

.f-start-block:after {
  content: '';
  display: inline-block;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0.65;
  background-color: #ff5a00;
}

@media only screen and (max-width: 1200px) {
  .f-start-block {
    height: calc(100vh - 70px);
  }
}

.f-start-block__content-wrap {
  position: relative;
  padding-left: 110px;
  left: 50%;
  z-index: 2;
  padding-top: 56vh;
}

.f-start-block__content-wrap .f-head-h1 {
  color: white;
  font-size: 72px;
  letter-spacing: 3px;
  line-height: 1;
}

@media only screen and (max-width: 768px) {
  .f-start-block__content-wrap .f-head-h1 {
    font-size: 42px;
  }
}

.f-start-block__content-wrap p {
  margin-top: 25px;
  margin-bottom: 40px;
  color: white;
  line-height: 30px;
  font-size: 14px;
  letter-spacing: 1px;
  font-family: 'Poppins', sans-serif;
  max-width: 410px;
}

@media only screen and (max-width: 1600px) {
  .f-start-block__content-wrap {
    padding-left: 0;
    padding-top: 50vh;
  }
}

@media only screen and (max-width: 1200px) {
  .f-start-block__content-wrap {
    left: 0;
    right: 0;
    margin: auto;
    padding-top: 40vh;
    text-align: center;
  }
  .f-start-block__content-wrap p {
    margin-left: auto;
    margin-right: auto;
  }
}

.f-start-block_style2 {
  height: 100vh;
}

.f-start-block_style2 .f-start-block__content-wrap {
  left: 61%;
  padding-top: 62vh;
}

@media only screen and (max-width: 1600px) {
  .f-start-block_style2 .f-start-block__content-wrap {
    padding-left: 0;
    padding-top: 0;
    padding-bottom: 0;
    left: 400px;
    top: 50%;
    transform: translateY(-50%);
  }
}

@media only screen and (max-width: 1200px) {
  .f-start-block_style2 .f-start-block__content-wrap {
    left: 0;
    padding-left: 300px;
    right: 0;
    margin: auto;
    text-align: center;
  }
  .f-start-block_style2 .f-start-block__content-wrap p {
    margin-left: auto;
    margin-right: auto;
  }
}

@media only screen and (max-width: 1200px) {
  .f-start-block_style2 .f-start-block__content-wrap {
    padding-left: 0;
  }
}

/*--------------------------------------------------------------------------------------*/
/* Home 2 portfolio-slider*/
/*--------------------------------------------------------------------------------------*/
.f-portfolio-slider {
  max-width: 1760px;
  margin-left: auto;
  margin-right: auto;
  position: relative;
}

.f-portfolio-slider:after {
  position: absolute;
  top: 0;
  left: 0;
  content: '';
  display: inline-block;
  width: 100%;
  height: 100%;
  opacity: 0.9;
  background-color: #333;
}

.f-portfolio-slider_style2 {
  max-width: 100%;
}

.f-portfolio-slider_style2 .swiper-slide {
  margin: 0 15px;
  box-sizing: border-box;
  width: 50%;
}

@media only screen and (max-width: 991px) {
  .f-portfolio-slider_style2 .swiper-slide {
    width: 100%;
  }
}

.f-portfolio-slider_style2 .swiper-slide:nth-child(2n) {
  width: 25%;
}

@media only screen and (max-width: 991px) {
  .f-portfolio-slider_style2 .swiper-slide:nth-child(2n) {
    width: 50%;
  }
}

@media only screen and (max-width: 768px) {
  .f-portfolio-slider_style2 .swiper-slide:nth-child(2n) {
    width: 100%;
  }
}

.f-portfolio-slider_style2 .swiper-slide:nth-child(3n) {
  width: 25%;
}

@media only screen and (max-width: 991px) {
  .f-portfolio-slider_style2 .swiper-slide:nth-child(3n) {
    width: 50%;
  }
}

@media only screen and (max-width: 768px) {
  .f-portfolio-slider_style2 .swiper-slide:nth-child(3n) {
    width: 100%;
  }
}

.f-portfolio-slider_style2 .swiper-pagination {
  bottom: 0;
}

.f-portfolio-slider_style2 .swiper-pagination-bullets .swiper-pagination-bullet {
  margin: 0 15px;
}

/*--------------------------------------------------------------------------------------*/
/* Home 2 content-block */
/*--------------------------------------------------------------------------------------*/
.f-content-block .f-head-h2 {
  font-weight: 600;
  line-height: 50px;
  letter-spacing: 1px;
}

@media only screen and (max-width: 767px) {
  .f-content-block .f-head-h2 {
    line-height: 1;
  }
}

.f-content-block .f-head-h2:after {
  display: none;
}

.f-content-block p {
  margin-top: 25px;
  line-height: 25px;
  letter-spacing: .6px;
  margin-bottom: 45px;
}

.f-content-block__phone {
  background-size: auto;
  position: absolute;
  right: 0;
  top: -70px;
}

.f-content-block__phone img {
  max-width: 100%;
}

@media only screen and (max-width: 1200px) {
  .f-content-block__phone {
    left: 0;
    right: 0;
    margin: auto;
    text-align: center;
  }
}

@media only screen and (max-width: 767px) {
  .f-content-block__phone {
    display: none;
  }
}

.f-content-block_style2 .f-content-block__phone {
  right: auto;
  left: 0;
}

@media only screen and (max-width: 1200px) {
  .f-content-block_style2 .f-content-block__phone {
    right: 0;
  }
}

.f-content-block_style2 .f-header {
  padding-left: 70px;
}

@media only screen and (max-width: 1200px) {
  .f-content-block_style2 .f-header {
    padding-left: 0;
  }
}

.f-content-block_style2 .f-content-block__phone {
  top: -25px;
}

.f-start-block_style2 {
  height: 100vh;
}

/*--------------------------------------------------------------------------------------*/
/* Home 2 blog-posts */
/*--------------------------------------------------------------------------------------*/
.f-blog-posts__item-bg {
  transition: opacity .3s ease;
  position: absolute;
  width: 1100px;
  height: 100%;
  right: 0;
  top: 0;
  opacity: 0;
}

.f-blog-posts__item-bg:after {
  content: '';
  display: inline-block;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0.8;
  background-color: #ff5a00;
}

@media only screen and (max-width: 1199px) {
  .f-blog-posts__item-bg {
    right: auto;
    left: -30px;
  }
}

@media only screen and (max-width: 992px) {
  .f-blog-posts__item-bg {
    width: 100%;
    left: 0;
  }
}

.f-blog-posts__item-text {
  position: relative;
  z-index: 2;
}

.f-blog-posts__item-text .f-head-h4 {
  transition: color .3s ease;
  color: #333;
  letter-spacing: .3px;
}

@media only screen and (max-width: 992px) {
  .f-blog-posts__item-text {
    padding-left: 15px;
    padding-right: 15px;
  }
}

@media only screen and (max-width: 48px) {
  .f-blog-posts__item-text {
    padding-left: 0;
    padding-right: 0;
  }
}

.f-blog-posts .f-header {
  padding-left: 65px;
}

@media only screen and (max-width: 1199px) {
  .f-blog-posts .f-header {
    padding-left: 0;
  }
}

.f-blog-posts .f-blog-item__head {
  margin-top: 20px;
  margin-bottom: 0;
}

.f-blog-posts .f-blog-item__head li {
  color: #999;
  border-color: #999;
}

.f-blog-posts .f-head-h5 {
  color: #eb4800;
  font-family: 'Lato', sans-serif;
  font-size: 16px;
  text-transform: uppercase;
  letter-spacing: 2.5px;
  margin-top: 15px;
  margin-bottom: 20px;
}

.f-blog-posts .f-head-h2 {
  color: #333;
  font-size: 36px;
  font-weight: 900;
  line-height: 1;
}

@media only screen and (max-width: 767px) {
  .f-blog-posts .f-head-h2 {
    font-size: 30px;
  }
}

.f-blog-posts p {
  letter-spacing: .5px;
}

.f-blog-posts__item {
  padding: 50px 95px;
  position: relative;
}

.f-blog-posts__item.active_post .f-blog-posts__item-text .f-head-h4, .f-blog-posts__item:hover .f-blog-posts__item-text .f-head-h4 {
  color: #fff;
}

.f-blog-posts__item.active_post .f-blog-posts__item-text .f-blog-item__head li, .f-blog-posts__item:hover .f-blog-posts__item-text .f-blog-item__head li {
  color: #fff;
  border-color: #fff;
}

.f-blog-posts__item.active_post .f-blog-posts__item-bg, .f-blog-posts__item:hover .f-blog-posts__item-bg {
  opacity: 1;
}

@media only screen and (max-width: 1199px) {
  .f-blog-posts__item {
    padding-left: 0px;
    padding-right: 0px;
  }
}

/*--------------------------------------------------------------------------------------*/
/* Home 3 Isotope */
/*--------------------------------------------------------------------------------------*/
.f-isotope-wrap {
  max-width: 1440px;
  margin-left: auto;
  margin-right: auto;
}

.grid-sizer,
.grid-item {
  width: 16.66666%;
  box-sizing: border-box;
  height: 240px;
}

@media only screen and (max-width: 768px) {
  .grid-sizer,
  .grid-item {
    width: 33.333%;
  }
}

@media only screen and (max-width: 600px) {
  .grid-sizer,
  .grid-item {
    width: 100%;
  }
}

/* 2 columns wide */
.grid-item_width2 {
  width: 33.33333%;
}

@media only screen and (max-width: 768px) {
  .grid-item_width2 {
    width: 66.666%;
  }
}

@media only screen and (max-width: 600px) {
  .grid-item_width2 {
    width: 100%;
    margin: 0;
  }
}

.grid-item_height2 {
  height: 480px;
}

.filter-button-group {
  padding: 37px 80px;
  text-align: left;
}

@media only screen and (max-width: 767px) {
  .filter-button-group {
    padding: 15px;
  }
}

.filter-button-group a {
  float: right;
  vertical-align: middle;
  color: white;
  font-family: 'Poppins', sans-serif;
  font-size: 14px;
  font-weight: 500;
  line-height: 50px;
  margin-top: 5px;
}

@media only screen and (max-width: 860px) {
  .filter-button-group a {
    display: block;
    float: none;
    max-width: 360px;
    margin-left: auto;
    margin-right: auto;
  }
}

.filter-button-group button {
  display: inline-block;
  font-family: 'Lato', sans-serif;
  font-size: 14px;
  font-weight: 700;
  line-height: 55px;
  text-transform: uppercase;
  letter-spacing: .7px;
  padding-right: 60px;
  word-spacing: 5px;
  padding-left: 0;
  color: grey;
  background-color: transparent;
  border: none;
  outline: none;
  transition: color .3s ease;
}

.filter-button-group button:hover, .filter-button-group button:focus {
  background-color: transparent;
  border: none;
  outline: none;
  color: #ff5a00;
}

.filter-button-group button.active {
  color: #ff5a00;
}

@media only screen and (max-width: 991px) {
  .filter-button-group button {
    padding-right: 20px;
  }
}

@media only screen and (max-width: 860px) {
  .filter-button-group {
    text-align: center;
  }
  .filter-button-group button {
    padding-left: 20px;
    padding-right: 20px;
  }
}

.f-isotope-wrap_style2 {
  text-align: center;
  max-width: 1800px;
  margin-left: auto;
  margin-right: auto;
}

.f-isotope-wrap_style2 .grid-sizer,
.f-isotope-wrap_style2 .grid-item {
  position: relative;
  width: 23%;
  box-sizing: border-box;
  height: 410px;
  margin: 1%;
}

@media only screen and (max-width: 1500px) {
  .f-isotope-wrap_style2 .grid-sizer,
  .f-isotope-wrap_style2 .grid-item {
    width: 31%;
  }
}

@media only screen and (max-width: 1500px) {
  .f-isotope-wrap_style2 .grid-sizer,
  .f-isotope-wrap_style2 .grid-item {
    width: 48%;
  }
}

.f-isotope-wrap_style2 .grid-sizer .f-isotope__content,
.f-isotope-wrap_style2 .grid-item .f-isotope__content {
  padding: 45px 50px;
  position: absolute;
  top: 20px;
  text-align: left;
  bottom: 20px;
  right: 20px;
  left: 20px;
  background-color: rgba(255, 90, 0, 0.6);
  opacity: 0;
  transition: opacity .3s ease;
}

.f-isotope-wrap_style2 .grid-sizer .f-isotope__content p,
.f-isotope-wrap_style2 .grid-item .f-isotope__content p {
  position: relative;
  width: 100%;
  transition: all .2s ease;
  color: white;
  font-weight: 700;
  line-height: 24px;
  opacity: 0;
  letter-spacing: .5px;
  margin-left: -100px;
}

.f-isotope-wrap_style2 .grid-sizer .f-isotope__content .f-head-h5,
.f-isotope-wrap_style2 .grid-item .f-isotope__content .f-head-h5 {
  position: relative;
  width: 100%;
  transition: all .5s ease;
  color: white;
  font-weight: 600;
  line-height: 32px;
  letter-spacing: 1px;
  opacity: 0;
  margin-left: 100px;
}

.f-isotope-wrap_style2 .grid-sizer .f-isotope__content i,
.f-isotope-wrap_style2 .grid-item .f-isotope__content i {
  opacity: 0;
  transition: all .6s ease;
  color: white;
  display: inline-block;
  font-size: 24px;
  width: 67px;
  height: 67px;
  line-height: 67px;
  background-color: #ff5a00;
  font-weight: 400;
  position: absolute;
  bottom: 50px;
  right: 50px;
  text-align: center;
}

.f-isotope-wrap_style2 .grid-sizer .f-isotope__content:hover,
.f-isotope-wrap_style2 .grid-item .f-isotope__content:hover {
  opacity: 1;
}

.f-isotope-wrap_style2 .grid-sizer .f-isotope__content:hover p,
.f-isotope-wrap_style2 .grid-sizer .f-isotope__content:hover .f-head-h5,
.f-isotope-wrap_style2 .grid-sizer .f-isotope__content:hover i,
.f-isotope-wrap_style2 .grid-item .f-isotope__content:hover p,
.f-isotope-wrap_style2 .grid-item .f-isotope__content:hover .f-head-h5,
.f-isotope-wrap_style2 .grid-item .f-isotope__content:hover i {
  opacity: 1;
  margin: 0;
}

@media only screen and (max-width: 768px) {
  .f-isotope-wrap_style2 .grid-sizer,
  .f-isotope-wrap_style2 .grid-item {
    width: 48%;
  }
}

@media only screen and (max-width: 600px) {
  .f-isotope-wrap_style2 .grid-sizer,
  .f-isotope-wrap_style2 .grid-item {
    width: 100%;
    margin: 0;
  }
}

.button-group {
  text-align: center;
  padding: 15px 0;
  background-color: transparent;
}

.button-group button {
  padding: 0 23px;
  line-height: 1;
  letter-spacing: .5px;
  word-spacing: 1px;
}

@media only screen and (max-width: 992px) {
  .button-group button {
    padding-top: 30px;
  }
}

.f-isotope-wrap_style3 {
  text-align: center;
  max-width: 1440px;
  margin-left: auto;
  margin-right: auto;
}

.f-isotope-wrap_style3 .grid-sizer,
.f-isotope-wrap_style3 .grid-item {
  position: relative;
  width: 100%;
  box-sizing: border-box;
  height: 600px;
}

@media only screen and (max-width: 768px) {
  .f-isotope-wrap_style3 .grid-sizer,
  .f-isotope-wrap_style3 .grid-item {
    height: 300px;
  }
}

.f-isotope-wrap_style3 .grid-sizer:after,
.f-isotope-wrap_style3 .grid-item:after {
  content: '';
  display: inline-block;
  position: absolute;
  top: 20px;
  bottom: 20px;
  right: 20px;
  left: 20px;
  background-color: rgba(255, 90, 0, 0.6);
  transition: opacity .3s ease;
  opacity: 0;
}

.f-isotope-wrap_style3 .grid-sizer:hover:after,
.f-isotope-wrap_style3 .grid-item:hover:after {
  opacity: 1;
}

.f-isotope-wrap_style3 .grid-sizer .f-isotope__content-body,
.f-isotope-wrap_style3 .grid-item .f-isotope__content-body {
  text-align: left;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  display: inline-block;
  min-width: 500px;
  margin-left: -60px;
}

@media only screen and (max-width: 600px) {
  .f-isotope-wrap_style3 .grid-sizer .f-isotope__content-body,
  .f-isotope-wrap_style3 .grid-item .f-isotope__content-body {
    width: 100%;
    min-width: 1px;
    margin-left: 0;
    left: 0;
    text-align: center;
  }
}

.f-isotope-wrap_style3 .grid-sizer .f-isotope__content,
.f-isotope-wrap_style3 .grid-item .f-isotope__content {
  position: absolute;
  z-index: 3;
  display: block;
  text-align: center;
  opacity: 1;
  width: 100%;
  height: 100%;
}

.f-isotope-wrap_style3 .grid-sizer .f-isotope__content p,
.f-isotope-wrap_style3 .grid-item .f-isotope__content p {
  position: relative;
  width: 100%;
  transition: all .2s ease;
  color: white;
  font-weight: 700;
  line-height: 24px;
  opacity: 0;
  letter-spacing: .5px;
  margin-left: -100px;
}

.f-isotope-wrap_style3 .grid-sizer .f-isotope__content .f-head-h5,
.f-isotope-wrap_style3 .grid-item .f-isotope__content .f-head-h5 {
  position: relative;
  width: 100%;
  transition: all .5s ease;
  color: white;
  font-weight: 600;
  line-height: 32px;
  letter-spacing: 1px;
  opacity: 0;
  margin-left: 100px;
}

@media only screen and (max-width: 600px) {
  .f-isotope-wrap_style3 .grid-sizer .f-isotope__content .f-head-h5,
  .f-isotope-wrap_style3 .grid-item .f-isotope__content .f-head-h5 {
    margin-bottom: 20px;
  }
}

.f-isotope-wrap_style3 .grid-sizer .f-isotope__content i,
.f-isotope-wrap_style3 .grid-item .f-isotope__content i {
  opacity: 0;
  transition: all .6s ease;
  color: white;
  display: inline-block;
  font-size: 24px;
  width: 67px;
  height: 67px;
  line-height: 67px;
  background-color: #ff5a00;
  font-weight: 400;
  bottom: auto;
  right: auto;
  text-align: center;
  position: absolute;
  left: -95px;
  top: 0;
}

@media only screen and (max-width: 600px) {
  .f-isotope-wrap_style3 .grid-sizer .f-isotope__content i,
  .f-isotope-wrap_style3 .grid-item .f-isotope__content i {
    position: static;
  }
}

.f-isotope-wrap_style3 .grid-sizer .f-isotope__content:hover,
.f-isotope-wrap_style3 .grid-item .f-isotope__content:hover {
  opacity: 1;
}

.f-isotope-wrap_style3 .grid-sizer .f-isotope__content:hover p,
.f-isotope-wrap_style3 .grid-sizer .f-isotope__content:hover .f-head-h5,
.f-isotope-wrap_style3 .grid-sizer .f-isotope__content:hover i,
.f-isotope-wrap_style3 .grid-item .f-isotope__content:hover p,
.f-isotope-wrap_style3 .grid-item .f-isotope__content:hover .f-head-h5,
.f-isotope-wrap_style3 .grid-item .f-isotope__content:hover i {
  opacity: 1;
  margin: 0;
}

.f-isotope-wrap_style4 {
  max-width: 100%;
  width: 100%;
}

.f-isotope-wrap_style4 .f-isotope__content {
  position: absolute;
  width: 100%;
  height: 100%;
}

.f-isotope-wrap_style4 .grid-sizer,
.f-isotope-wrap_style4 .grid-item {
  width: 25%;
  box-sizing: border-box;
  height: 480px;
}

@media only screen and (max-width: 1200px) {
  .f-isotope-wrap_style4 .grid-sizer,
  .f-isotope-wrap_style4 .grid-item {
    height: 240px;
  }
}

@media only screen and (max-width: 768px) {
  .f-isotope-wrap_style4 .grid-sizer,
  .f-isotope-wrap_style4 .grid-item {
    width: 50%;
  }
}

@media only screen and (max-width: 600px) {
  .f-isotope-wrap_style4 .grid-sizer,
  .f-isotope-wrap_style4 .grid-item {
    width: 100%;
  }
}

.f-isotope-wrap_style4 .grid-item_width2 {
  width: 50%;
}

@media only screen and (max-width: 768px) {
  .f-isotope-wrap_style4 .grid-item_width2 {
    width: 100%;
  }
}

@media only screen and (max-width: 600px) {
  .f-isotope-wrap_style4 .grid-item_width2 {
    width: 100%;
  }
}

.f-isotope-wrap_style4 .grid-item_height2 {
  height: 960px;
}

@media only screen and (max-width: 1200px) {
  .f-isotope-wrap_style4 .grid-item_height2 {
    height: 480px;
  }
}

.f-isotope-wrap_style5 {
  max-width: 100%;
  width: 100%;
}

.f-isotope-wrap_style5.padding-lr-80 {
  padding-left: 80px;
  padding-right: 80px;
}

@media only screen and (max-width: 1200px) {
  .f-isotope-wrap_style5.padding-lr-80 {
    padding-left: 15px;
    padding-right: 15px;
  }
}

.f-isotope-wrap_style5 .f-isotope__content {
  position: absolute;
  width: 100%;
  height: 100%;
}

.f-isotope-wrap_style5 .grid-sizer,
.f-isotope-wrap_style5 .grid-item {
  width: 25%;
  box-sizing: border-box;
  height: 450px;
}

@media only screen and (max-width: 1200px) {
  .f-isotope-wrap_style5 .grid-sizer,
  .f-isotope-wrap_style5 .grid-item {
    height: 225px;
  }
}

@media only screen and (max-width: 768px) {
  .f-isotope-wrap_style5 .grid-sizer,
  .f-isotope-wrap_style5 .grid-item {
    width: 50%;
  }
}

@media only screen and (max-width: 600px) {
  .f-isotope-wrap_style5 .grid-sizer,
  .f-isotope-wrap_style5 .grid-item {
    width: 100%;
  }
}

.f-isotope-wrap_style5 .grid-item_width2 {
  width: 50%;
}

@media only screen and (max-width: 768px) {
  .f-isotope-wrap_style5 .grid-item_width2 {
    width: 100%;
  }
}

@media only screen and (max-width: 600px) {
  .f-isotope-wrap_style5 .grid-item_width2 {
    width: 100%;
  }
}

.f-isotope-wrap_style5 .grid-item_height2 {
  height: 900px;
}

@media only screen and (max-width: 1200px) {
  .f-isotope-wrap_style5 .grid-item_height2 {
    height: 450px;
  }
}

.f-isotope-wrap_style6 {
  max-width: 100%;
  width: 100%;
}

.f-isotope-wrap_style6 .f-isotope__content {
  padding: 45px 50px;
  position: absolute;
  top: 20px;
  text-align: left;
  bottom: 20px;
  right: 20px;
  left: 20px;
  background-color: rgba(255, 90, 0, 0.6);
  opacity: 0;
  transition: opacity .3s ease;
}

@media only screen and (max-width: 992px) {
  .f-isotope-wrap_style6 .f-isotope__content {
    padding: 15px;
  }
}

.f-isotope-wrap_style6 .f-isotope__content p {
  position: relative;
  width: 100%;
  transition: all .2s ease;
  color: white;
  font-weight: 700;
  line-height: 24px;
  opacity: 0;
  letter-spacing: .5px;
  margin-left: -100px;
}

.f-isotope-wrap_style6 .f-isotope__content .f-head-h5 {
  position: relative;
  width: 100%;
  transition: all .5s ease;
  color: white;
  font-weight: 600;
  line-height: 32px;
  letter-spacing: 1px;
  opacity: 0;
  margin-left: 100px;
}

.f-isotope-wrap_style6 .f-isotope__content i {
  opacity: 0;
  transition: all .6s ease;
  color: white;
  display: inline-block;
  font-size: 24px;
  width: 67px;
  height: 67px;
  line-height: 67px;
  background-color: #ff5a00;
  font-weight: 400;
  position: absolute;
  bottom: 50px;
  right: 50px;
  text-align: center;
}

@media only screen and (max-width: 992px) {
  .f-isotope-wrap_style6 .f-isotope__content i {
    bottom: 15px;
    right: 15px;
  }
}

.f-isotope-wrap_style6 .f-isotope__content:hover {
  opacity: 1;
}

.f-isotope-wrap_style6 .f-isotope__content:hover p,
.f-isotope-wrap_style6 .f-isotope__content:hover .f-head-h5,
.f-isotope-wrap_style6 .f-isotope__content:hover i {
  opacity: 1;
  margin: 0;
}

.f-isotope-wrap_style6 .grid-sizer,
.f-isotope-wrap_style6 .grid-item {
  width: 33.333333%;
  box-sizing: border-box;
  height: 500px;
}

@media only screen and (max-width: 1200px) {
  .f-isotope-wrap_style6 .grid-sizer,
  .f-isotope-wrap_style6 .grid-item {
    height: 300px;
  }
}

@media only screen and (max-width: 600px) {
  .f-isotope-wrap_style6 .grid-sizer,
  .f-isotope-wrap_style6 .grid-item {
    width: 100%;
  }
}

.f-isotope-wrap_white-border {
  padding-left: 60px;
  padding-right: 60px;
}

@media only screen and (max-width: 1200px) {
  .f-isotope-wrap_white-border {
    padding-left: 5px;
    padding-right: 5px;
  }
}

.f-isotope-wrap_white-border .grid-item {
  box-sizing: border-box;
  display: block;
  border: 20px solid #fff;
}

@media only screen and (max-width: 1200px) {
  .f-isotope-wrap_white-border .grid-item {
    border-width: 5px;
  }
}

.f-isotope-wrap_full-style .grid-item {
  width: 100%;
  height: 500px;
}

.f-isotope-wrap_full-style .grid-item .f-isotope__content {
  padding: 40px 25px;
}

.f-isotope-wrap_full-style .grid-item .f-isotope__content i {
  right: 30px;
  bottom: 30px;
}

@media only screen and (max-width: 992px) {
  .f-isotope-wrap_full-style .grid-item {
    height: 350px;
  }
}

@media only screen and (max-width: 600px) {
  .f-isotope-wrap_full-style .grid-item {
    margin-bottom: 10px;
  }
}

.button-group_style2 {
  padding: 37px 75px;
  background-color: #f2f2f2;
  text-align: left;
}

@media only screen and (max-width: 767px) {
  .button-group_style2 {
    padding: 15px;
  }
}

.button-group_style2 button {
  display: inline-block;
  font-family: 'Lato', sans-serif;
  font-size: 14px;
  font-weight: 700;
  line-height: 55px;
  text-transform: uppercase;
  letter-spacing: .7px;
  padding-right: 55px;
  word-spacing: 5px;
  padding-left: 0;
  color: grey;
  background-color: transparent;
  border: none;
  outline: none;
  -webkit-transition: color .3s ease;
  transition: color .3s ease;
}

/*--------------------------------------------------------------------------------------*/
/* Home 3 Isotope */
/*--------------------------------------------------------------------------------------*/
.f-counter-wrap {
  text-align: center;
  position: relative;
}

.f-counter-wrap:before {
  content: '';
  display: inline-block;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background-color: rgba(255, 90, 0, 0.75);
}

.f-counter {
  display: inline-block;
  width: 24%;
  position: relative;
  z-index: 2;
}

@media only screen and (max-width: 768px) {
  .f-counter {
    width: 49%;
    padding-top: 15px;
    padding-bottom: 15px;
  }
}

@media only screen and (max-width: 600px) {
  .f-counter {
    width: 100%;
    padding-top: 15px;
    padding-bottom: 15px;
  }
}

.f-counter .f-head-h3 {
  color: #fff;
  font-family: 'Lato', sans-serif;
  font-size: 25px;
  font-weight: 700;
  text-transform: uppercase;
  line-height: 1.5;
  margin-bottom: 10px;
  letter-spacing: 3px;
}

@media only screen and (max-width: 991px) {
  .f-counter .f-head-h3 {
    font-size: 20px;
  }
}

.f-counter .f-head-h2 {
  color: white;
  font-size: 55px;
  letter-spacing: 3px;
  font-weight: 900;
}

@media only screen and (max-width: 991px) {
  .f-counter .f-head-h2 {
    font-size: 30px;
  }
}

/*--------------------------------------------------------------------------------------*/
/* Contact */
/*--------------------------------------------------------------------------------------*/
.f-contact-info {
  max-width: 1170px;
  margin-left: auto;
  margin-right: auto;
}

.f-contact-info header p {
  max-width: 510px;
  margin-top: 10px;
  letter-spacing: .5px;
}

.f-contact-info-item i {
  color: #ff5a00;
  font-size: 60px;
  font-weight: 400;
  line-height: 35px;
}

.f-contact-info-item .f-head-h5 {
  color: #595959;
  margin-top: 20px;
  transition: color .3s ease;
}

.f-contact-info-item span {
  color: grey;
  line-height: 28px;
  display: block;
  font-size: 14px;
  letter-spacing: .5px;
  transition: color .3s ease;
}

.f-contact-info-item a:hover .f-head-h5,
.f-contact-info-item a:hover span {
  color: #ff5a00;
}

@media only screen and (max-width: 767px) {
  .f-contact-info-item {
    width: 100%;
    text-align: center;
    margin-bottom: 30px;
  }
}

/*--------------------------------------------------------------------------------------*/
/* Contact-form */
/*--------------------------------------------------------------------------------------*/
.f-contact-form header .f-head-h2 {
  font-weight: 900;
}

@media only screen and (max-width: 767px) {
  .f-contact-form header .f-head-h2 {
    font-size: 30px;
  }
}

.f-contact-form header .f-head-h5 {
  color: #ff5a00;
  font-family: 'Lato', sans-serif;
  font-size: 14px;
  text-transform: uppercase;
  letter-spacing: 3px;
}

.f-contact-form header p {
  max-width: 510px;
  margin-top: 10px;
  letter-spacing: .5px;
}

.wpcf7-form {
  padding: 60px 70px;
  text-align: center;
}

@media only screen and (max-width: 767px) {
  .wpcf7-form {
    padding: 50px 15px;
  }
}

.wpcf7-form:after {
  content: '';
  display: table;
  clear: both;
}

.wpcf7-form p {
  float: left;
  position: relative;
  text-align: center;
  width: 100%;
  display: block;
}

@media only screen and (max-width: 768px) {
  .wpcf7-form p {
    width: 100%;
    margin-top: 0;
  }
}

.wpcf7-form p:nth-child(-n+4) {
  width: 32%;
  display: inline-block;
  margin-right: 2%;
}

@media only screen and (max-width: 768px) {
  .wpcf7-form p:nth-child(-n+4) {
    width: 100%;
    margin-top: 0;
  }
}

.wpcf7-form p:nth-child(4) {
  margin-right: 0;
}

@media only screen and (max-width: 768px) {
  .wpcf7-form p:nth-child(4) {
    width: 100%;
    margin-top: 0;
  }
}

.wpcf7-form p input:not([type="submit"]),
.wpcf7-form p textarea {
  background-color: transparent;
  padding: 15px 0;
  width: 100%;
  border-bottom: 1px solid #e6e6e6;
  letter-spacing: 1.5px;
  position: relative;
  z-index: 2;
}

.wpcf7-form p textarea {
  resize: vertical;
  min-height: 155px;
}

.wpcf7-form p input:not([type="submit"]) {
  width: 100%;
  margin-bottom: 25px;
}

.wpcf7-form p input[type="submit"] {
  margin-left: auto;
  margin-right: auto;
  margin-top: 40px;
  margin-bottom: 20px;
  background-color: #333;
  line-height: 50px;
  padding: 0 35px;
  text-align: center;
  display: inline-block;
  font-size: 14px;
  letter-spacing: .5px;
  color: #fff;
  font-family: 'Poppins', sans-serif;
  transition: opacity .5s ease;
}

.wpcf7-form p input[type="submit"]:hover {
  opacity: .9;
}

.wpcf7-form p span {
  display: block;
}

.wpcf7-form .f-contact-form_placeholder {
  position: absolute;
  top: 5px;
  left: 15px;
  margin: auto;
  font-family: 'Lato', sans-serif;
  display: inline-block;
  text-align: left;
  width: 90%;
  line-height: 50px;
  letter-spacing: 1px;
  cursor: pointer;
  transition: all .5s ease;
}

.wpcf7-form .f-contact-form_placeholder:after {
  content: '*';
  display: inline-block;
  font-size: 14px;
  color: #ff5a00;
  position: absolute;
}

.wpcf7-form .f-contact-form_placeholder-no:after {
  display: none;
}

.wpcf7-form .f-filled {
  top: -15px;
  left: 0;
  font-size: 10px;
}

.comment-form {
  padding: 60px 70px;
}

@media only screen and (max-width: 767px) {
  .comment-form {
    padding: 50px 15px;
  }
}

.comment-form:after {
  content: '';
  display: table;
  clear: both;
}

.comment-form > div {
  position: relative;
  float: left;
  width: 49%;
  margin-right: 2%;
}

.comment-form > div span {
  position: absolute;
  top: 5px;
  left: 15px;
  color: gray;
  font-size: 14px;
  margin: auto;
  font-family: 'Lato', sans-serif;
  display: inline-block;
  text-align: left;
  width: 90%;
  line-height: 50px;
  letter-spacing: 1px;
  cursor: pointer;
  transition: all .5s ease;
}

.comment-form > div span:after {
  content: '*';
  display: inline-block;
  font-size: 14px;
  color: #ff5a00;
  position: absolute;
}

@media only screen and (max-width: 767px) {
  .comment-form > div {
    width: 100%;
    margin-right: 0;
  }
}

.comment-form > div:nth-child(2) {
  margin-right: 0;
}

.comment-form > div:nth-child(3) {
  width: 100%;
  margin-right: 0;
}

.comment-form textarea {
  width: 100%;
}

.comment-form input:not([type="submit"]),
.comment-form textarea {
  background-color: transparent;
  padding: 15px 0;
  width: 100%;
  border-bottom: 1px solid #e6e6e6;
  letter-spacing: 1.5px;
  position: relative;
  z-index: 2;
}

.comment-form textarea {
  resize: vertical;
  min-height: 155px;
}

.comment-form input:not([type="submit"]) {
  width: 100%;
  margin-bottom: 25px;
}

.comment-form button[type="submit"] {
  margin-left: auto;
  margin-right: auto;
  margin-top: 40px;
  margin-bottom: 20px;
  background-color: #333;
  line-height: 50px;
  padding: 0 35px;
  text-align: center;
  display: inline-block;
  font-size: 14px;
  letter-spacing: .5px;
  color: #fff;
  font-family: 'Poppins', sans-serif;
  transition: opacity .5s ease;
}

.comment-form button[type="submit"]:hover {
  opacity: .9;
}

.comment-form .f-filled span {
  top: -15px;
  left: 0;
  height: 0;
  font-size: 10px;
}

.wpcf7-form_style2 {
  padding-left: 0;
  padding-right: 0;
  background-color: transparent;
  max-width: 1030px;
  padding-top: 45px;
}

.f-coment-form .wpcf7-form p:nth-child(-n+4) {
  width: 46%;
  margin-right: 8%;
  text-align: left;
}

@media only screen and (max-width: 768px) {
  .f-coment-form .wpcf7-form p:nth-child(-n+4) {
    width: 100%;
    margin-right: 0;
  }
}

.f-coment-form .wpcf7-form p:nth-child(2n) {
  margin-right: 0;
}

.f-coment-form .wpcf7-form p:nth-child(3) {
  width: 100%;
}

/*--------------------------------------------------------------------------------------*/
/* partrers*/
/*--------------------------------------------------------------------------------------*/
.f-partrers {
  text-align: center;
}

.f-partrers li {
  display: inline-block;
  padding: 0 70px;
}

.f-partrers li a {
  transition: filter 1s ease;
  filter: saturate(0);
}

.f-partrers li a:hover {
  filter: saturate(1);
}

@media only screen and (max-width: 1300px) {
  .f-partrers li {
    padding: 0 50px;
  }
}

@media only screen and (max-width: 768px) {
  .f-partrers li {
    padding: 25px;
  }
}

@media only screen and (max-width: 480px) {
  .f-partrers li {
    width: 100%;
    text-align: center;
  }
}

/*--------------------------------------------------------------------------------------*/
/* full-screen-swiper*/
/*--------------------------------------------------------------------------------------*/
.f-full-swiper__item {
  height: calc(100vh - 195px);
}

@media only screen and (max-width: 991px) {
  .f-full-swiper__item {
    height: calc(100vh - 165px);
  }
}

.f-full-swiper__item:hover .f-full-swiper__content {
  opacity: 1;
}

.f-full-swiper__item_hide .f-full-swiper__content {
  opacity: 0;
  transition: opacity .3s ease;
}

.f-full-screen-swiper .swiper-slide {
  margin-right: 0 !important;
}

.f-full-screen-swiper .swiper-button-prev {
  left: 80px;
}

@media only screen and (max-width: 992px) {
  .f-full-screen-swiper .swiper-button-prev {
    left: 15px;
  }
}

.f-full-screen-swiper .swiper-button-next {
  right: 80px;
}

@media only screen and (max-width: 992px) {
  .f-full-screen-swiper .swiper-button-next {
    right: 15px;
  }
}

.f-full-screen-swiper .swiper-button-prev:hover, .f-full-screen-swiper .swiper-button-prev.swiper-button-disabled,
.f-full-screen-swiper .swiper-button-next:hover,
.f-full-screen-swiper .swiper-button-next.swiper-button-disabled {
  background-color: #333;
  opacity: 1;
}

.f-full-swiper__content {
  background-color: rgba(255, 90, 0, 0.6);
  max-width: 510px;
  box-sizing: border-box;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 80px;
  margin: auto;
  padding: 50px 30px 50px 105px;
}

.f-full-swiper__content i {
  display: inline-block;
  width: 67px;
  height: 67px;
  background-color: #ff5a00;
  color: white;
  font-size: 24px;
  line-height: 67px;
  text-align: center;
  float: left;
}

.f-full-swiper__content p {
  color: white;
  line-height: 32px;
  letter-spacing: 1px;
}

.f-full-swiper__content .f-head-h5 {
  color: white;
  line-height: 32px;
  letter-spacing: 1px;
}

@media only screen and (max-width: 991px) {
  .f-full-swiper__content {
    width: 100%;
    max-width: 100%;
    bottom: 0;
  }
}

@media only screen and (max-width: 480px) {
  .f-full-swiper__content {
    padding: 15px;
  }
  .f-full-swiper__content i {
    display: none;
  }
}

.f-full_text-wrap {
  float: left;
  padding-left: 20px;
}

/*--------------------------------------------------------------------------------------*/
/* single-project*/
/*--------------------------------------------------------------------------------------*/
.f-single-progect-content {
  max-width: 1440px;
  margin-left: auto;
  margin-right: auto;
}

.f-single-progect-text {
  max-width: 700px;
  padding-top: 45px;
}

@media only screen and (max-width: 1199px) {
  .f-single-progect-text {
    padding-top: 0;
    max-width: 100%;
  }
}

.f-single-progect-text p {
  letter-spacing: .5px;
  margin-bottom: 25px;
}

.f-single-progect-text .f-head-h2 {
  letter-spacing: 1px;
}

.f-single-progect-text .breadcrumb {
  margin-top: 0;
  padding-left: 0;
  padding-top: 5px;
  margin-bottom: 40px;
}

.f-single-progect-text .breadcrumb a,
.f-single-progect-text .breadcrumb li {
  text-transform: none;
}

.f-single-progect-text .breadcrumb > li + li:before {
  padding: 0;
}

.f-progect-info {
  margin-bottom: 15px;
  padding: 45px 50px;
}

@media only screen and (max-width: 767px) {
  .f-progect-info {
    padding: 30px;
  }
}

.f-progect-info .f-head-h5 {
  font-family: 'Lato', sans-serif;
  text-transform: uppercase;
  margin-bottom: 8px;
  letter-spacing: 1px;
}

.f-progect-info a,
.f-progect-info span {
  color: grey;
  font-size: 14px;
  line-height: 28px;
  letter-spacing: .5px;
}

.f-progect-info a {
  transition: color .3s ease;
}

.f-progect-info a:hover {
  color: #ff5a00;
}

.f-progect-info li {
  margin-bottom: 20px;
}

.f-progect-info li:last-child {
  margin-bottom: 0px;
}

.f-progect-info .nav-menu__social {
  text-align: left;
}

.f-progect-info .nav-menu__social li {
  padding: 0 10px;
  margin-bottom: 0;
}

.f-progect-info .nav-menu__social li:first-child {
  padding-left: 0;
}

.f-progect-info .nav-menu__social li a {
  font-size: 18px;
}

.f-progect-info_style2 {
  padding: 0;
}

.f-progect-info_style2 ol {
  display: inline-block;
}

.f-progect-info_style2 ul {
  margin-top: 10px;
}

.f-progect-info_style2 ul li {
  margin-bottom: 5px;
}

.f-progect-info_style2 ul .f-head-h5 {
  vertical-align: top;
  text-transform: none;
  font-size: 14px;
  display: inline-block;
  max-width: 170px;
  width: 100%;
}

.f-progect-info_style2 ul span {
  font-size: 14px;
}

.f-progect-info_style3 {
  padding: 55px 0 35px;
}

.f-progect-info_style3 li {
  margin-bottom: 35px;
}

.f-single-project-items {
  position: relative;
  width: 66%;
  max-width: 1270px;
  margin-left: auto;
  margin-right: auto;
}

.f-single-project-items .swiper-pagination-clickable .swiper-pagination-bullet {
  width: 15px;
  height: 15px;
  background-color: white;
  transition: background .5s ease;
  opacity: 1;
}

.f-single-project-items .swiper-pagination-clickable .swiper-pagination-bullet-active {
  background-color: #ff5a00;
}

@media only screen and (max-width: 1600px) {
  .f-single-project-items {
    width: 50%;
  }
}

@media only screen and (max-width: 1199px) {
  .f-single-project-items {
    width: 100%;
  }
}

@media only screen and (max-width: 992px) {
  .f-single-project-items {
    padding-bottom: 100px;
  }
}

.f-single-project__item {
  height: 500px;
}

.f-single-project__item .swiper-pagination-fraction,
.f-single-project__item .swiper-pagination-custom,
.f-single-project__item .swiper-container-horizontal > .swiper-pagination-bullets {
  bottom: 40px;
}

.f-single-project__item .swiper-container-horizontal > .swiper-pagination-bullets .swiper-pagination-bullet {
  margin: 0 15px;
}

.f-poject-btn {
  position: absolute;
  z-index: 2;
  color: white;
  font-family: 'Poppins', sans-serif;
  font-size: 14px;
  font-weight: 600;
  line-height: 44px;
  top: 0;
  bottom: 0;
  margin: auto;
  letter-spacing: .5px;
  height: 44px;
  left: 60px;
}

.f-poject-btn:before {
  content: "\f3d2";
  margin-left: 15px;
  margin-right: 15px;
  display: inline-block;
  font-family: "Ionicons";
  width: 44px;
  height: 44px;
  background-color: #ff5a00;
  text-align: center;
  line-height: 44px;
}

.f-poject-btn:after {
  content: "\f3d1";
  margin-left: 15px;
  margin-right: 15px;
  display: inline-block;
  font-family: "Ionicons";
  width: 44px;
  height: 44px;
  background-color: #ff5a00;
  text-align: center;
  line-height: 44px;
}

@media only screen and (max-width: 992px) {
  .f-poject-btn {
    color: #333;
  }
  .f-poject-btn:hover {
    opacity: .8;
  }
  .f-poject-btn:after, .f-poject-btn:before {
    color: #fff;
  }
}

@media only screen and (max-width: 480px) {
  .f-poject-btn:after, .f-poject-btn:before {
    width: 30px;
    height: 30px;
    line-height: 30px;
    margin-left: 5px;
    margin-right: 5px;
  }
}

.f-single-project__item-next {
  position: absolute;
  top: 0;
  width: 100%;
  left: calc(100% + 30px);
}

.f-single-project__item-next:after {
  content: '';
  display: inline-block;
  width: 100%;
  height: 100%;
  background-color: rgba(51, 51, 51, 0.8);
  position: absolute;
  top: 0;
  left: 0;
  transition: opacity .3s ease;
}

.f-single-project__item-next:hover:after {
  opacity: .8;
}

.f-single-project__item-next .f-poject-btn:before {
  display: none;
}

@media only screen and (max-width: 1199px) {
  .f-single-project__item-next {
    left: calc( 100% - 180px);
    background: none !important;
  }
  .f-single-project__item-next .f-poject-btn {
    left: 15px;
  }
}

@media only screen and (max-width: 992px) {
  .f-single-project__item-next {
    height: auto;
    top: calc( 100% - 50px);
  }
}

@media only screen and (max-width: 480px) {
  .f-single-project__item-next .f-poject-btn {
    left: 35px;
  }
}

.f-single-project__item-prev {
  position: absolute;
  top: 0;
  width: 100%;
  right: calc(100% + 30px);
}

.f-single-project__item-prev:after {
  content: '';
  display: inline-block;
  width: 100%;
  height: 100%;
  background-color: rgba(51, 51, 51, 0.8);
  position: absolute;
  top: 0;
  left: 0;
  transition: opacity .3s ease;
}

.f-single-project__item-prev:hover:after {
  opacity: .8;
}

.f-single-project__item-prev .f-poject-btn {
  left: auto;
  right: 60px;
}

.f-single-project__item-prev .f-poject-btn:after {
  display: none;
}

@media only screen and (max-width: 1199px) {
  .f-single-project__item-prev {
    right: calc( 100% - 180px);
    background: none !important;
  }
  .f-single-project__item-prev .f-poject-btn {
    right: 15px;
  }
}

@media only screen and (max-width: 992px) {
  .f-single-project__item-prev {
    height: auto;
    top: calc( 100% - 50px);
  }
}

@media only screen and (max-width: 480px) {
  .f-single-project__item-prev .f-poject-btn {
    right: 35px;
  }
}

.f-single-project__slide {
  height: 500px;
}

.f-single-project-items_style1 {
  max-width: 1440px;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
}

.f-single-project-items_style1:after {
  clear: both;
  display: table;
  content: '';
}

@media only screen and (max-width: 1470px) {
  .f-single-project-items_style1 {
    padding-left: 15px;
    padding-right: 15px;
  }
}

.f-single-project-items_style1 .f-single-project__item {
  float: left;
}

.f-single-project-items_style1 .f-audio-wrap,
.f-single-project-items_style1 .f-video-wrap {
  height: 100%;
}

.f-single-project-items_style1 .f-single-project__item_style1 {
  width: 80%;
  height: 560px;
}

@media only screen and (max-width: 991px) {
  .f-single-project-items_style1 .f-single-project__item_style1 {
    width: 70%;
    height: 500px;
  }
}

@media only screen and (max-width: 768px) {
  .f-single-project-items_style1 .f-single-project__item_style1 {
    width: 100%;
  }
}

.f-single-project-items_style1 .f-single-project__item_style1 .f-single-project__slide {
  height: 100%;
}

.f-single-project-items_style1 .f-single-project__item_style2 {
  width: calc(20% - 30px);
  height: 265px;
  margin-left: 30px;
  margin-bottom: 30px;
}

@media only screen and (max-width: 991px) {
  .f-single-project-items_style1 .f-single-project__item_style2 {
    height: 237.5px;
    margin-left: 25px;
    width: calc(30% - 25px);
    margin-bottom: 25px;
  }
}

@media only screen and (max-width: 768px) {
  .f-single-project-items_style1 .f-single-project__item_style2 {
    display: none;
  }
}

.f-single-project-items_style2 {
  width: 100%;
}

.f-single-project-items_style2 .f-audio-wrap,
.f-single-project-items_style2 .f-video-wrap,
.f-single-project-items_style2 .f-single-project__slide {
  height: 100%;
}

.f-single-project-items_style2 .f-single-project__item {
  float: left;
  width: 100%;
  height: 600px;
  margin-bottom: 30px;
}

@media only screen and (max-width: 600px) {
  .f-single-project-items_style2 .f-single-project__item {
    height: 300px;
  }
}

.f-single-project-items_style2 .f-single-project__item_style3 {
  height: 1230px;
  width: 50%;
}

@media only screen and (max-width: 1199px) {
  .f-single-project-items_style2 .f-single-project__item_style3 {
    height: 630px;
  }
}

@media only screen and (max-width: 600px) {
  .f-single-project-items_style2 .f-single-project__item_style3 {
    display: none;
  }
}

.f-single-project-items_style2 .f-single-project__item_style2 {
  width: calc( 50% - 30px);
  margin-left: 30px;
}

@media only screen and (max-width: 1199px) {
  .f-single-project-items_style2 .f-single-project__item_style2 {
    height: 300px;
  }
}

@media only screen and (max-width: 600px) {
  .f-single-project-items_style2 .f-single-project__item_style2 {
    display: none;
  }
}

.f-single-project-items_style3 {
  width: 100%;
}

.f-single-project-items_style3 .f-audio-wrap,
.f-single-project-items_style3 .f-video-wrap,
.f-single-project-items_style3 .f-single-project__slide {
  height: 100%;
}

.f-single-project-items_style3 .f-single-project__item {
  height: 1250px;
}

@media only screen and (max-width: 1400px) {
  .f-single-project-items_style3 .f-single-project__item {
    padding-right: 0;
  }
}

@media only screen and (max-width: 1199px) {
  .f-single-project-items_style3 .f-single-project__item {
    height: 800px;
  }
}

@media only screen and (max-width: 768px) {
  .f-single-project-items_style3 .f-single-project__item {
    height: 500px;
  }
}

.f-single-project-items_style3 .f-single-project__item_style2 {
  height: 600px;
  margin-top: 50px;
}

@media only screen and (max-width: 1199px) {
  .f-single-project-items_style3 .f-single-project__item_style2 {
    height: 300px;
  }
}

@media only screen and (max-width: 768px) {
  .f-single-project-items_style3 .f-single-project__item_style2 {
    margin-top: 30px;
    height: 150px;
  }
}

.f-single-project__item_iframe-style .f-audio-wrap,
.f-single-project__item_iframe-style .f-video-wrap {
  height: 100%;
}

.f-single-progect-content_left {
  border: none;
  max-width: 570px;
  float: right;
  padding-right: 100px;
}

.f-single-progect-content_left header {
  text-align: left;
}

.f-single-progect-content_left .f-head-h4 {
  letter-spacing: 0;
  font-weight: 900;
}

.f-single-progect-content_left .f-single-progect-text p {
  margin-top: 10px;
  letter-spacing: .5px;
}

.f-single-progect-content_left .breadcrumb {
  padding-left: 0;
}

@media only screen and (max-width: 1400px) {
  .f-single-progect-content_left {
    padding-right: 0;
  }
}

@media only screen and (max-width: 767px) {
  .f-single-progect-content_left {
    max-width: 100%;
  }
}

.f-single-progect-content_right {
  border: none;
  max-width: 570px;
  float: left;
  padding-left: 100px;
}

.f-single-progect-content_right header {
  text-align: left;
}

.f-single-progect-content_right .f-head-h4 {
  letter-spacing: 0;
  font-weight: 900;
}

.f-single-progect-content_right .f-single-progect-text p {
  margin-top: 10px;
  letter-spacing: .5px;
}

.f-single-progect-content_right .breadcrumb {
  padding-left: 0;
}

@media only screen and (max-width: 1400px) {
  .f-single-progect-content_right {
    padding-left: 0;
  }
}

@media only screen and (max-width: 767px) {
  .f-single-progect-content_right {
    max-width: 100%;
  }
}

/*--------------------------------------------------------------------------------------*/
/* video-banner*/
/*--------------------------------------------------------------------------------------*/
.play-btn {
  width: 96px;
  height: 96px;
  background-color: #ff5a00;
  border-radius: 50%;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  z-index: 2;
  cursor: pointer;
}

.play-btn:after {
  content: '';
  display: inline-block;
  width: 0;
  height: 0;
  border-style: solid;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 38px;
  margin: auto;
  border-width: 15px 0 15px 25px;
  border-color: transparent transparent transparent #fff;
}

.f-video-wrap {
  position: relative;
  height: 550px;
}

.f-video-wrap .f-video-overlay {
  height: 100%;
  position: relative;
}

.f-video-wrap .f-video-overlay:before {
  position: absolute;
  content: '';
  display: inline-block;
  width: 100%;
  height: 100%;
  background-color: rgba(51, 51, 51, 0.7);
  top: 0;
  left: 0;
}

.f-video-wrap .video_popup {
  position: absolute;
  top: 0;
  height: 100%;
  width: 100%;
}

.f-video-wrap .video_popup iframe {
  width: 100%;
  height: 100%;
}

/*--------------------------------------------------------------------------------------*/
/* audio-banner*/
/*--------------------------------------------------------------------------------------*/
.f-audio-wrap {
  position: relative;
  height: 550px;
}

.f-audio {
  bottom: 35px;
  max-width: 1170px;
  width: 100%;
  position: absolute;
  left: 0;
  right: 0;
  margin: auto;
}

.f-audio iframe {
  height: 150px;
  width: 100%;
}

/*--------------------------------------------------------------------------------------*/
/* single project start block*/
/*--------------------------------------------------------------------------------------*/
.f-full-windows-block {
  height: 100vh;
}

.f-full-windows-block .f-single-project__slide {
  height: 100vh;
}

.f-full-windows-block .swiper-container-horizontal > .swiper-pagination-bullets .swiper-pagination-bullet {
  margin: 0 15px;
}

.f-full-windows-block .swiper-pagination-fraction,
.f-full-windows-block .swiper-pagination-custom,
.f-full-windows-block .swiper-container-horizontal > .swiper-pagination-bullets {
  margin-bottom: 50px;
}

.f-full-windows-block .f-video-wrap {
  height: 100vh;
}

.f-full-windows-block .f-audio-wrap {
  height: 100vh;
}

/*--------------------------------------------------------------------------------------*/
/* team*/
/*--------------------------------------------------------------------------------------*/
.f-team {
  max-width: 1200px;
  margin-left: auto;
  margin-right: auto;
}

@media only screen and (max-width: 767px) {
  .f-team {
    text-align: center;
  }
}

.f-team:after {
  clear: both;
  content: '';
  display: table;
}

.f-team-head {
  width: 50%;
  padding-left: 15px;
  float: left;
}

@media only screen and (max-width: 1200px) {
  .f-team-head {
    width: 100%;
    padding-left: 15px;
    padding-right: 15px;
  }
}

.f-team-head p {
  letter-spacing: .5px;
  max-width: 370px;
}

@media only screen and (max-width: 1200px) {
  .f-team-head p {
    max-width: 100%;
  }
}

.f-team-item {
  float: left;
  width: calc(25% - 30px);
  margin-left: 15px;
  margin-right: 15px;
  position: relative;
  height: 270px;
  margin-bottom: 30px;
}

@media only screen and (max-width: 992px) {
  .f-team-item {
    width: calc(33% - 30px);
  }
}

@media only screen and (max-width: 767px) {
  .f-team-item {
    width: 100%;
    max-width: 320px;
    display: inline-block;
    float: none;
    margin-left: 0;
  }
}

.f-team-item:hover .f-team-hide {
  opacity: 1;
}

.f-team-hide {
  opacity: 0;
  position: absolute;
  transition: opacity .3s ease;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #f2f2f2;
  text-align: center;
}

.f-team-hide a {
  left: 0;
  right: 0;
  position: relative;
  top: 50%;
  transform: translateY(-50%);
  width: auto;
  margin: auto;
  height: 44px;
}

/*--------------------------------------------------------------------------------------*/
/* skills*/
/*--------------------------------------------------------------------------------------*/
.f-about-skills {
  text-align: center;
}

.f-about-skills p {
  max-width: 800px;
  margin-left: auto;
  margin-right: auto;
  letter-spacing: .5px;
}

.f-about-skills header .f-head-h5 {
  margin-bottom: 40px;
  line-height: 32px;
}

@media only screen and (max-width: 992px) {
  .f-about-skills {
    padding-left: 15px;
    padding-right: 15px;
  }
}

.f-about-skills-items {
  text-align: center;
}

.f-about-skills-item {
  margin: 10px 70px;
  width: 100%;
  display: inline-block;
  max-width: 370px;
  text-align: left;
}

@media only screen and (max-width: 1200px) {
  .f-about-skills-item {
    margin: 10px;
    max-width: 350px;
  }
}

.f-about-skills-item .f-head-h5 {
  text-transform: uppercase;
  letter-spacing: .5px;
}

.f-about-skills-item__progress-bar-wrap {
  background-color: #f2f2f2;
  height: 5px;
  width: 100%;
  margin-top: 40px;
  margin-bottom: 18px;
}

.f-about-skills-item__progress-bar {
  position: relative;
  height: 100%;
  background-color: #ff5a00;
  width: 0;
  transition: width 1s ease;
}

.f-about-skills-item__progress-bar span {
  position: absolute;
  top: -40px;
  right: -27px;
  min-width: 55px;
  display: inline-block;
  background-color: #404040;
  color: white;
  font-family: 'Lato', sans-serif;
  font-size: 14px;
  font-weight: 900;
  padding: 0 10px;
  line-height: 30px;
}

/*--------------------------------------------------------------------------------------*/
/* full page sliders*/
/*--------------------------------------------------------------------------------------*/
.swiper-container-v {
  height: calc( 100vh - 105px);
}

.swiper-container-v:after {
  content: '';
  display: inline-block;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.8);
  position: absolute;
  top: 0;
  left: 0;
  z-index: 2;
}

.swiper-container-v .swiper-wrapper {
  z-index: 3;
}

.swiper-container-v > .swiper-pagination-bullets {
  right: 80px;
  top: 50%;
  transform: translateY(-50%);
}

@media only screen and (max-width: 768px) {
  .swiper-container-v > .swiper-pagination-bullets {
    right: 30px;
  }
}

.swiper-container-v > .swiper-pagination-bullets .swiper-pagination-bullet {
  margin: 20px 0;
}

.swiper-container-v .swiper-pagination-bullet {
  background-color: transparent;
  color: grey;
}

.swiper-container-v .swiper-pagination-bullet-active {
  color: #ff5a00;
}

.swiper-container-v .f-soc-net {
  display: block;
  width: 100%;
  position: absolute;
  bottom: 55px;
  text-align: center;
}

@media only screen and (max-width: 768px) {
  .swiper-container-v .f-soc-net {
    bottom: 15px;
  }
}

@media only screen and (max-width: 380px) {
  .swiper-container-v .f-soc-net {
    display: none;
  }
}

.swiper-container-v .f-soc-net li {
  display: inline-block;
}

.swiper-container-v .f-soc-net li a {
  font-family: 'Poppins', sans-serif;
  font-size: 16px;
  font-weight: 500;
  line-height: 36px;
  text-transform: uppercase;
  color: #595959;
  transition: color .3s ease;
  padding: 0 20px;
  letter-spacing: .5px;
}

@media only screen and (max-width: 768px) {
  .swiper-container-v .f-soc-net li a {
    font-size: 12px;
    padding: 0 10px;
  }
}

.swiper-container-v .f-soc-net li a:hover {
  color: #ff5a00;
}

.swiper-container-h {
  height: calc( 100vh - 105px);
}

.swiper-container-h .swiper-pagination-bullet {
  background-color: #595959;
}

.swiper-container-h .swiper-pagination-bullet-active {
  background-color: #ff5a00;
}

.swiper-container-h > .swiper-pagination-bullets .swiper-pagination-bullet {
  margin: 0 15px;
}

.f-slide-wrap {
  text-align: center;
  position: relative;
  top: 50%;
  transform: translateY(-50%);
}

@media only screen and (max-width: 1400px) {
  .f-slide-wrap {
    padding-left: 110px;
    padding-right: 110px;
  }
}

@media only screen and (max-width: 768px) {
  .f-slide-wrap {
    padding-left: 80px;
    padding-right: 80px;
  }
}

@media only screen and (max-width: 600px) {
  .f-slide-wrap {
    padding-left: 15px;
    padding-right: 50px;
  }
  .f-slide-wrap .swiper-button-prev,
  .f-slide-wrap .swiper-button-next {
    top: auto;
    bottom: 0;
  }
  .f-slide-wrap .f-testimonial__user {
    margin-top: 20px;
  }
  .f-slide-wrap .f-testimonial__user div {
    float: none;
    text-align: center;
  }
}

@media only screen and (max-width: 600px) {
  .f-slide-wrap .f-blog-posts__item {
    padding: 15px 0;
  }
  .f-slide-wrap .f-blog-posts__item .f-blog-item__head {
    display: none;
  }
}

.f-slide-wrap .f-blog-posts header {
  text-align: left;
}

.f-slide-wrap .f-blog-posts__item-text {
  text-align: left;
}

@media only screen and (max-width: 600px) {
  .f-slide-wrap .f-blog-posts__item-text .f-head-h4 {
    line-height: 1;
    font-size: 16px;
  }
}

.f-slide-wrap .f-blog-posts__item-bg {
  max-width: 900px;
}

@media only screen and (max-width: 600px) {
  .f-slide-wrap .f-testimonial__text {
    margin-top: 15px;
  }
  .f-slide-wrap .f-testimonial__text p {
    padding-left: 0;
    padding-right: 0;
  }
}

@media only screen and (max-width: 380px) {
  .f-slide-wrap .f-testimonial__text {
    display: none;
  }
}

@media only screen and (max-width: 380px) {
  .f-slide-wrap .f-testimonial__rating {
    display: none;
  }
}

@media only screen and (max-width: 600px) {
  .f-slide-wrap .f-testimonial__item .f-head-h2 {
    line-height: 1;
  }
}

.f-first-slide {
  max-width: 720px;
  margin-left: auto;
  margin-right: auto;
}

.f-first-slide .f-head-h2 {
  display: inline-block;
  font-weight: 900;
  letter-spacing: 1px;
  background-color: #fff;
  position: relative;
  z-index: 2;
}

@media only screen and (max-width: 768px) {
  .f-first-slide .f-head-h2 {
    font-size: 24px;
  }
}

.f-first-slide .f-head-h1 {
  color: #ff5a00;
  font-size: 144px;
  line-height: 1.3;
}

@media only screen and (max-width: 768px) {
  .f-first-slide .f-head-h1 {
    font-size: 75px;
  }
}

@media only screen and (max-width: 600px) {
  .f-first-slide .f-head-h1 {
    font-size: 40px;
  }
}

@media only screen and (max-width: 600px) {
  .f-first-slide > div {
    text-align: center;
  }
}

.f-second-slide__item {
  width: 50%;
  float: left;
}

.f-slide-wrap .f-second-slide:after {
  clear: both;
  content: '';
  position: static;
  display: table;
}

.f-second-slide {
  max-width: 1170px;
  margin-left: auto;
  margin-right: auto;
}

.f-second-slide .f-about__img img {
  left: 70px;
}

.f-second-slide:after {
  clear: both;
  content: '';
  display: table;
}

.f-second-slide .f-about__img {
  box-sizing: border-box;
  border: 25px solid #ff7b33;
  max-height: 540px;
  max-width: 370px;
}

.f-second-slide .f-about__img img {
  bottom: 140px;
}

@media only screen and (max-width: 1200px) {
  .f-second-slide .f-about__img img {
    left: 0;
    bottom: 50px;
  }
}

.f-second-slide .f-about__text {
  padding-left: 15px;
}

@media only screen and (max-width: 1200px) {
  .f-second-slide .f-about__text {
    padding-left: 30px;
  }
}

@media only screen and (max-width: 991px) {
  .f-second-slide .f-about__text {
    padding-left: 0;
  }
}

@media only screen and (max-width: 768px) {
  .f-second-slide .f-about__text {
    text-align: center;
  }
}

.f-second-slide .f-about__text p {
  margin-top: 0;
  margin-bottom: 10px;
}

.f-second-slide .f-second-slide__item {
  text-align: left;
  width: 50%;
  float: left;
}

@media only screen and (max-width: 991px) {
  .f-second-slide .f-second-slide__item {
    width: 100%;
  }
}

.f-second-slide .f-feature__item {
  box-sizing: border-box;
}

@media only screen and (max-width: 1199px) {
  .f-second-slide .f-feature__item {
    width: 100%;
    margin-bottom: 10px;
  }
}

@media only screen and (max-width: 768px) {
  .f-second-slide .f-feature__item {
    text-align: left;
  }
}

@media only screen and (max-width: 600px) {
  .f-second-slide .f-feature__item {
    padding-left: 0;
  }
  .f-second-slide .f-feature__item i {
    display: none;
  }
}

.after-line {
  text-align: left;
  position: relative;
}

.after-line .f-head-h2 {
  padding-right: 80px;
}

@media only screen and (max-width: 600px) {
  .after-line .f-head-h2 {
    padding-right: 0;
  }
}

.after-line:after {
  top: 0;
  bottom: 0;
  right: 0;
  margin: auto;
  position: absolute;
  content: '';
  display: inline-block;
  width: 100%;
  vertical-align: middle;
  height: 2px;
  background-color: #f2f2f2;
}

@media only screen and (max-width: 600px) {
  .after-line:after {
    display: none;
  }
}

.before-line {
  text-align: right;
  position: relative;
}

.before-line .f-head-h2 {
  padding-left: 80px;
}

@media only screen and (max-width: 600px) {
  .before-line .f-head-h2 {
    padding-left: 0;
  }
}

.before-line:before {
  top: 0;
  bottom: 0;
  margin: auto;
  left: 0;
  position: absolute;
  content: '';
  display: inline-block;
  width: 100%;
  vertical-align: middle;
  height: 2px;
  background-color: #f2f2f2;
}

@media only screen and (max-width: 600px) {
  .before-line:before {
    display: none;
  }
}

.f-slide-h-wrap {
  max-width: 1200px;
  text-align: center;
  position: relative;
  top: 50%;
  transform: translateY(-50%);
  margin-left: auto;
  margin-right: auto;
}

@media only screen and (max-width: 1400px) {
  .f-slide-h-wrap {
    padding-left: 110px;
    padding-right: 110px;
  }
}

@media only screen and (max-width: 768px) {
  .f-slide-h-wrap {
    padding-left: 80px;
    padding-right: 80px;
  }
}

@media only screen and (max-width: 600px) {
  .f-slide-h-wrap {
    padding-left: 15px;
    padding-right: 50px;
  }
}

.f-slide-h-wrap:after {
  clear: both;
  content: '';
  display: table;
}

.f-page-name {
  position: absolute;
  left: 35px;
  margin: auto;
  width: 100px;
  text-align: center;
  height: 100%;
  top: 50%;
  line-height: 100%;
}

@media only screen and (max-width: 768px) {
  .f-page-name {
    left: -20px;
  }
}

@media only screen and (max-width: 600px) {
  .f-page-name {
    display: none;
  }
}

.f-page-name span {
  vertical-align: middle;
  text-transform: uppercase;
  display: inline-block;
  transform: rotate(-90deg);
  font-family: 'Lato', sans-serif;
  font-size: 16px;
  color: grey;
  letter-spacing: .5px;
  text-transform: uppercase;
}

.f-portfolio-slider-img {
  width: 55%;
}

@media only screen and (max-width: 768px) {
  .f-portfolio-slider-img {
    width: 100%;
  }
}

@media only screen and (max-width: 600px) {
  .f-portfolio-slider-img {
    display: none;
  }
}

.f-portfolio-slider-img > div {
  float: right;
}

.f-portfolio-slider-img .item-portfolio-height2 {
  width: calc( 58% - 30px);
  height: 560px;
  margin-left: 30px;
}

@media only screen and (max-width: 992px) {
  .f-portfolio-slider-img .item-portfolio-height2 {
    height: 315px;
    width: calc( 58% - 15px);
    margin-left: 15px;
  }
}

.f-portfolio-slider-img .item-portfolio {
  width: 41%;
  height: 265px;
  margin-bottom: 30px;
}

@media only screen and (max-width: 992px) {
  .f-portfolio-slider-img .item-portfolio {
    height: 150px;
    margin-bottom: 15px;
  }
}

.f-portfolio-slider-info,
.f-portfolio-slider-img {
  float: left;
}

.f-portfolio-slider-info {
  padding-left: 110px;
  width: 45%;
  box-sizing: border-box;
  text-align: left;
}

@media only screen and (max-width: 1200px) {
  .f-portfolio-slider-info {
    padding-left: 30px;
  }
}

@media only screen and (max-width: 768px) {
  .f-portfolio-slider-info {
    padding-left: 0;
    width: 100%;
    text-align: center;
  }
}

.f-portfolio-slider-info .f-head-h6 {
  color: #ff7400;
  font-family: 'Lato', sans-serif;
  font-size: 14px;
  text-transform: none;
  line-height: 30px;
  letter-spacing: 1px;
}

.f-portfolio-slider-info .f-head-h2 {
  color: #333;
  font-size: 31px;
  letter-spacing: 1px;
  margin-top: 10px;
  margin-bottom: 10px;
}

.f-portfolio-slider-info p {
  letter-spacing: .5px;
}

.f-portfolio-slider-info a.f-view-more-btn,
.f-portfolio-slider-info a {
  color: gray;
  display: inline-block;
  margin-top: 40px;
}

/*--------------------------------------------------------------------------------------*/
/*  blog widgets*/
/*--------------------------------------------------------------------------------------*/
.f-widgets,
.f-posts-widget,
.f-instagram-widget,
.widget_categories {
  margin-bottom: 60px;
}

.f-widgets .f-head-h4,
.f-posts-widget .f-head-h4,
.f-instagram-widget .f-head-h4,
.widget_categories .f-head-h4 {
  margin-bottom: 25px;
  position: relative;
  line-height: 24px;
  letter-spacing: .5px;
}

.f-widgets .f-head-h4:after,
.f-posts-widget .f-head-h4:after,
.f-instagram-widget .f-head-h4:after,
.widget_categories .f-head-h4:after {
  content: '';
  position: absolute;
  width: 160px;
  left: 0;
  bottom: -15px;
  height: 2px;
  background-color: #ff5a00;
}

.f-widgets .f-instagram-widget__wrap {
  margin-top: 60px;
}

.f-widgets .f-instagram-widget__wrap a {
  margin: 0 7px 7px 0;
  float: left;
}

.widget_categories li {
  padding-top: 15px;
  padding-bottom: 15px;
  border-bottom: 1px solid #f5f5f5;
}

.widget_categories li:last-child {
  border: none;
}

.widget_categories li:after {
  clear: both;
  content: '';
  display: table;
}

.widget_categories li:hover {
  color: #ff5a00;
}

.widget_categories li:hover a,
.widget_categories li:hover span {
  color: #ff5a00;
}

.widget_categories li,
.widget_categories span,
.widget_categories a {
  color: grey;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: .6px;
  transition: color .3s ease;
}

.widget_categories span {
  float: right;
}

.widget_categories span:after {
  content: ')';
  display: inline-block;
  font-family: 'Lato', sans-serif;
  padding-left: 3px;
}

.widget_categories span:before {
  padding-right: 3px;
  content: '(';
  display: inline-block;
  font-family: 'Lato', sans-serif;
}

.f-post-item__info {
  padding-left: 15px;
  width: 70%;
  margin-bottom: 30px;
}

.f-post-item__info .f-head-h5,
.f-post-item__info a {
  display: block;
  color: #333330;
  font-family: 'Poppins', sans-serif;
  font-size: 16px;
  font-weight: 600;
  line-height: 25px;
  letter-spacing: .3px;
  vertical-align: top;
  transition: color .3s ease;
}

.f-post-item__info .f-head-h5:hover,
.f-post-item__info a:hover {
  color: #ff5a00;
}

.f-post-item__info span {
  color: #999;
  font-family: 'Lato', sans-serif;
  font-size: 12px;
  font-weight: 400;
  line-height: 27px;
}

.f-post-item {
  margin-bottom: 25px;
}

@media only screen and (max-width: 992px) {
  .f-post-item {
    max-width: 380px;
  }
}

.f-post-item:after {
  clear: both;
  content: '';
  display: table;
}

.f-post-item__img {
  width: 30%;
}

.f-post-item__img img {
  max-width: 100%;
}

.f-post-item__info,
.f-post-item__img {
  float: left;
  display: inline-block;
}

.f-post-item_big {
  margin-bottom: 0;
}

.f-post-item_big .f-post-item__info,
.f-post-item_big .f-post-item__img {
  float: none;
  width: 100%;
  padding-left: 0;
}

/*--------------------------------------------------------------------------------------*/
/*  single-post*/
/*--------------------------------------------------------------------------------------*/
.f-single-post-items {
  height: 800px;
  overflow: hidden;
}

@media only screen and (max-width: 991px) {
  .f-single-post-items {
    height: 500px;
  }
}

.f-single-post-items_style2 {
  padding-top: 120px;
  height: 665px;
  width: 100%;
  max-width: 1440px;
  margin-left: auto;
  margin-right: auto;
}

@media only screen and (max-width: 1200px) {
  .f-single-post-items_style2 {
    padding-top: 50px;
    height: 500px;
  }
}

@media only screen and (max-width: 991px) {
  .f-single-post-items_style2 {
    padding-top: 0;
  }
}

.f-single-post-item {
  height: 100%;
}

.f-single-post-item .f-audio-wrap,
.f-single-post-item .f-video-wrap,
.f-single-post-item .f-single-project__slide {
  height: 100%;
}

.f-single-post-item .swiper-pagination-fraction,
.f-single-post-item .swiper-pagination-custom,
.f-single-post-item .swiper-container-horizontal > .swiper-pagination-bullets {
  bottom: 35px;
}

.f-single-post-item .swiper-container-horizontal > .swiper-pagination-bullets .swiper-pagination-bullet {
  margin: 0 15px;
}

.f-single-post__user:after {
  clear: both;
  content: '';
  display: table;
}

.f-single-post__user-logo {
  width: 60px;
  text-align: left;
}

.f-single-post__user-logo img {
  max-width: 60px;
}

.f-single-post__user-info {
  float: left;
  padding-left: 25px;
  width: calc( 100% - 60px);
}

.f-single-post__user-logo,
.f-single-post__user-info {
  float: left;
}

.f-comment {
  max-width: 920px;
  width: 100%;
  background-color: #fafafa;
  position: relative;
  margin-bottom: 50px;
}

.f-comment:after {
  clear: both;
  content: '';
  display: table;
}

.f-comment .f-comment-reply-link {
  color: #ff5a00;
  font-family: 'Lato', sans-serif;
  font-size: 14px;
  font-weight: 700;
  line-height: 32px;
  position: absolute;
  top: 35px;
  right: 55px;
}

.f-comment_style2 {
  margin-left: 170px;
}

@media only screen and (max-width: 1199px) {
  .f-comment_style2 {
    margin-left: 25px;
  }
}

.f-comment-text,
.f-person-img {
  float: left;
}

.f-comment-text {
  width: calc(100% - 100px);
  padding-left: 80px;
  padding-top: 35px;
  padding-bottom: 45px;
}

@media only screen and (max-width: 768px) {
  .f-comment-text {
    padding-left: 30px;
  }
}

@media only screen and (max-width: 600px) {
  .f-comment-text {
    padding: 30px 15px;
    width: 100%;
  }
}

.f-comment-text p {
  line-height: 30px;
  margin-top: 5px;
  margin-bottom: 15px;
  letter-spacing: .5px;
}

.f-author {
  color: #333;
  font-family: 'Poppins', sans-serif;
  font-size: 16px;
  font-weight: 600;
  line-height: 32px;
  transition: color .3s ease;
}

.f-author:hover {
  color: #ff5a00;
}

@media only screen and (max-width: 600px) {
  .f-author {
    display: block;
    text-align: center;
  }
}

.f-person-img {
  width: 100px;
}

.f-person-img img {
  max-width: 100px;
}

@media only screen and (max-width: 600px) {
  .f-person-img {
    width: 100%;
    text-align: center;
    padding-top: 30px;
  }
}

/*--------------------------------------------------------------------------------------*/
/*  coming soon*/
/*--------------------------------------------------------------------------------------*/
.f-default-block {
  position: relative;
  height: 100vh;
}

.f-default-block:after {
  position: absolute;
  content: '';
  display: inline-block;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: rgba(51, 51, 51, 0.9);
}

.f-default-block__content-wrap {
  position: relative;
  z-index: 2;
  top: 50%;
  transform: translateY(-50%);
  text-align: right;
}

.f-default-block__content-wrap header {
  text-align: right;
  display: inline-block;
  max-width: 510px;
  padding-right: 190px;
}

@media only screen and (max-width: 991px) {
  .f-default-block__content-wrap header {
    max-width: 100%;
    padding-right: 0;
    text-align: center;
    display: block;
    margin-bottom: 30px;
  }
}

@media only screen and (max-width: 991px) {
  .f-default-block__content-wrap header {
    margin-bottom: 0;
  }
}

.f-default-block__content-wrap .f-head-h1 {
  color: white;
  line-height: 67px;
}

.f-default-block__content-wrap .f-head-h5 {
  color: #ccc;
  line-height: 67px;
  font-weight: 100;
  letter-spacing: .5px;
}

@media only screen and (max-width: 767px) {
  .f-default-block__content-wrap .f-head-h5 {
    line-height: 1;
  }
}

.f-default-block__content-wrap .f-subscribe {
  text-align: left;
  margin-top: 65px;
  padding-left: 10px;
}

@media only screen and (max-width: 480px) {
  .f-default-block__content-wrap .f-subscribe {
    margin-top: 10px;
    text-align: center;
  }
  .f-default-block__content-wrap .f-subscribe .f-subscribe__info {
    margin-bottom: 10px;
  }
}

.f-default-block__content-wrap .f-subscribe:before {
  display: none;
}

.f-default-block__content-wrap .f-subscribe__form {
  padding-left: 0;
  margin-top: 20px;
}

.f-count-down-day {
  color: white;
  font-family: 'Poppins', sans-serif;
  font-size: 264px;
  font-weight: 500;
  line-height: 1;
  text-align: left;
}

.f-count-down-day span {
  color: #ccc;
  font-size: 60px;
  letter-spacing: 4px;
  padding-left: 10px;
  font-weight: 400;
  line-height: 86px;
  font-family: 'Poppins', sans-serif;
  vertical-align: top;
}

@media only screen and (max-width: 991px) {
  .f-count-down-day {
    font-size: 100px;
    text-align: center;
  }
}

.f-count-down-hour-wrap {
  text-align: left;
  color: white;
  margin-top: 25px;
  font-family: 'Poppins', sans-serif;
  font-size: 48px;
  font-weight: 500;
  padding-left: 5px;
}

@media only screen and (max-width: 991px) {
  .f-count-down-hour-wrap {
    font-size: 20px;
    line-height: 1;
    text-align: center;
  }
}

@media only screen and (max-width: 768px) {
  .f-count-down-hour-wrap {
    margin-top: 0;
  }
}

.f-count-down-hour-wrap:after {
  content: '';
  display: table;
  clear: both;
}

.f-count-down-hour-wrap > div {
  float: left;
  width: 33%;
  display: inline-block;
}

@media only screen and (max-width: 768px) {
  .f-count-down-hour-wrap > div {
    display: block;
    font-size: 14px;
  }
}

.f-count-down-hour-wrap span {
  color: #ccc;
  font-size: 16px;
  font-family: 'Poppins', sans-serif;
  font-weight: 400;
}

/*--------------------------------------------------------------------------------------*/
/*  coming soon*/
/*--------------------------------------------------------------------------------------*/
.f-map {
  height: 500px;
  width: 100%;
  max-width: 1440px;
  margin-left: auto;
  margin-right: auto;
  position: relative;
}

.f-map:after {
  content: '';
  display: inline-block;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: transparent;
  display: none;
}

@media only screen and (max-width: 992px) {
  .f-map:after {
    display: block;
  }
}

@media only screen and (max-width: 767px) {
  .f-map {
    height: 350px;
  }
}

/*--------------------------------------------------------------------------------------*/
/*  services*/
/*--------------------------------------------------------------------------------------*/
.f-service-img {
  margin-bottom: 80px;
}

.f-service-img:after {
  content: '';
  display: table;
  clear: both;
}

.f-service-img img {
  vertical-align: middle;
}

@media only screen and (max-width: 991px) {
  .f-service-img {
    margin-bottom: 50px;
  }
}

.f-service-img_left {
  padding-right: 100px;
}

@media only screen and (max-width: 1200px) {
  .f-service-img_left {
    padding-right: 30px;
  }
}

@media only screen and (max-width: 991px) {
  .f-service-img_left {
    padding-right: 0;
    text-align: center;
  }
}

.f-service-img_left img {
  float: right;
}

@media only screen and (max-width: 991px) {
  .f-service-img_left img {
    float: none;
    max-width: 100%;
  }
}

.f-service-img_right {
  padding-left: 100px;
}

@media only screen and (max-width: 1200px) {
  .f-service-img_right {
    padding-left: 30px;
  }
}

@media only screen and (max-width: 991px) {
  .f-service-img_right {
    padding-left: 0;
    text-align: center;
  }
}

.f-service-img_right img {
  float: left;
}

@media only screen and (max-width: 991px) {
  .f-service-img_right img {
    float: none;
    max-width: 100%;
  }
}

.f-service-content-wrap {
  height: 480px;
  margin-bottom: 80px;
}

@media only screen and (max-width: 991px) {
  .f-service-content-wrap {
    height: auto;
    margin-bottom: 50px;
  }
}

.f-service-content {
  position: relative;
  padding-bottom: 5px;
  top: 50%;
  transform: translateY(-50%);
}

@media only screen and (max-width: 991px) {
  .f-service-content {
    top: 0;
    transform: translateY(0);
  }
}

@media only screen and (max-width: 768px) {
  .f-service-content {
    text-align: center;
  }
}

.f-service-content .f-service-content__number {
  color: #ff7400;
  font-family: 'Poppins', sans-serif;
  font-size: 94px;
  line-height: 1;
  display: inline-block;
  margin-bottom: 10px;
  font-weight: 500;
  text-transform: uppercase;
}

@media only screen and (max-width: 768px) {
  .f-service-content .f-service-content__number {
    font-size: 40px;
  }
}

.f-service-content .f-head-h3 {
  color: #333;
  font-size: 31px;
  font-weight: 600;
  line-height: 1;
  letter-spacing: 1px;
}

@media only screen and (max-width: 768px) {
  .f-service-content .f-head-h3 {
    font-size: 24px;
  }
}

.f-service-content p {
  margin-top: 25px;
  letter-spacing: .5px;
}

.f-service-content a.f-view-more-btn {
  margin-top: 55px;
  display: inline-block;
  color: #595959;
}

.f-service-content a.f-view-more-btn:hover {
  color: #ff7400;
}

/*--------------------------------------------------------------------------------------*/
/*  404*/
/*--------------------------------------------------------------------------------------*/
.f-default-block_style2 .f-subscribe {
  margin-top: 0;
  padding-left: 30px;
}

.f-default-block_style2 .f-default-block-header {
  text-align: right;
  padding-right: 0;
  max-width: 100%;
}

@media only screen and (max-width: 991px) {
  .f-default-block_style2 .f-default-block-header {
    text-align: center;
  }
}

.f-default-block_style2 .f-default-block-header .f-head-h1 {
  color: white;
  font-size: 180px;
  font-weight: 500;
  line-height: 220px;
  letter-spacing: 10px;
}

@media only screen and (max-width: 768px) {
  .f-default-block_style2 .f-default-block-header .f-head-h1 {
    font-size: 100px;
    line-height: 1;
  }
}

.f-default-block_style2 .f-subscribe__info .f-head-h2 {
  color: white;
  font-weight: 500;
  letter-spacing: .5px;
  line-height: 60px;
  text-transform: uppercase;
}

@media only screen and (max-width: 768px) {
  .f-default-block_style2 .f-subscribe__info .f-head-h2 {
    font-size: 20px;
    line-height: 1;
  }
}

.f-default-block_style2 .f-subscribe__info p {
  font-size: 16px;
  font-weight: 400;
  line-height: 25px;
  letter-spacing: .5px;
  color: #ccc;
}

.f-default-block_style2 .f-subscribe__info a {
  color: #ff5a00;
  text-decoration: underline;
}

@media only screen and (max-width: 768px) {
  .f-default-block_style2 .f-subscribe__info {
    text-align: center;
  }
}

/*--------------------------------------------------------------------------------------*/
/* Pagination */
/*--------------------------------------------------------------------------------------*/
.f-pagination-wrap {
  text-align: center;
  display: block;
}

.f-pagination-wrap:after {
  clear: both;
  display: table;
  content: '';
}

.pagination a,
.pagination span {
  font-family: 'Lato', sans-serif;
  font-size: 20px;
  font-weight: 700;
  line-height: 44px;
  text-transform: uppercase;
  transition: color .3s ease;
  display: inline-block;
  text-align: center;
}

.pagination a:hover,
.pagination span:hover {
  color: #ff5a00;
}

.pagination a {
  color: #999;
}

.pagination span {
  color: #999;
}

.pagination .current {
  color: #333;
}

.pagination .prev,
.pagination .next {
  width: 44px;
  height: 44px;
  margin-left: 20px;
  margin-right: 20px;
  background-color: #333;
  color: #fff;
  transition: background .3s ease;
}

.pagination .prev:hover,
.pagination .next:hover {
  background-color: #ff5a00;
  color: #fff;
}

@media only screen and (max-width: 767px) {
  .pagination .prev {
    margin-left: 0;
    right: 10px;
  }
  .pagination .next {
    margin-right: 0;
    margin-left: 10px;
  }
}

.page-numbers {
  padding: 0 15px;
}

@media only screen and (max-width: 767px) {
  .page-numbers {
    padding: 0 5px;
  }
}

/*--------------------------------------------------------------------------------------*/
/* Buttons */
/*--------------------------------------------------------------------------------------*/
a.f-view-more-btn,
.f-view-more-btn {
  color: white;
  font-size: 14px;
  font-weight: 700;
  line-height: 36px;
  position: relative;
  letter-spacing: 1.5px;
  z-index: 2;
  transition: color .3s ease;
}

a.f-view-more-btn:after,
.f-view-more-btn:after {
  left: 0;
  content: '';
  display: inline-block;
  width: 50px;
  height: 2px;
  background-color: #f26522;
  border-radius: 1px;
  position: absolute;
  bottom: -5px;
  transition: width .3s ease;
}

a.f-view-more-btn:hover:after,
.f-view-more-btn:hover:after {
  width: 100%;
}

a.f-dark-btn,
.f-dark-btn {
  background-color: #333;
  line-height: 44px;
  padding: 0 30px;
  text-align: center;
  display: inline-block;
  font-size: 14px;
  letter-spacing: .5px;
  color: #fff;
  font-family: 'Poppins', sans-serif;
  transition: opacity .5s ease;
}

a.f-dark-btn:hover,
.f-dark-btn:hover {
  opacity: .9;
}

a.f-orang-btn,
.f-orang-btn {
  background-color: #ff5a00;
  line-height: 50px;
  color: #fffdfc;
  font-family: 'Lato', sans-serif;
  font-size: 14px;
  font-weight: 700;
  text-transform: uppercase;
  padding: 0 35px;
  transition: opacity .5s ease;
}

a.f-orang-btn:hover,
.f-orang-btn:hover {
  opacity: .9;
}

/*--------------------------------------------------------------------------------------*/
/* Start header */
/*--------------------------------------------------------------------------------------*/
.f-start-header {
  text-align: center;
}

.f-start-header .f-head-h1 {
  letter-spacing: 1px;
  line-height: 1;
}

.f-start-header li {
  color: #595959;
  text-transform: uppercase;
  font-size: 14px;
  letter-spacing: .3px;
}

.f-start-header a {
  color: #bfbfbf;
  font-size: 14px;
  line-height: 1;
  text-transform: uppercase;
}

.breadcrumb > li + li:before {
  padding: 0 1px;
}

.breadcrumb {
  background-color: transparent;
  margin-top: 10px;
}

.breadcrumb > li {
  text-transform: uppercase;
  font-size: 14px;
  letter-spacing: .3px;
  color: #bfbfbf;
  font-weight: 700;
}

.breadcrumb > li.active {
  color: #595959;
}

.breadcrumb a {
  color: #bfbfbf;
  font-size: 14px;
  line-height: 1;
  text-transform: uppercase;
}

.f-start-header_style2 .f-head-h1 {
  letter-spacing: 0;
  font-weight: 900;
}

/*--------------------------------------------------------------------------------------*/
/* Pop Up */
/*--------------------------------------------------------------------------------------*/
.mfp-close {
  font-size: 44px;
  font-weight: 100;
  top: 50px !important;
  right: 35px !important;
}

@media only screen and (max-width: 1500px) {
  .mfp-close {
    top: 0 !important;
    right: 0 !important;
  }
}

@media only screen and (max-width: 1500px) {
  .mfp-container {
    overflow-y: auto;
  }
}

.f-pop-up-form {
  max-width: 1000px;
  width: 100%;
  padding-left: 15px;
  padding-right: 15px;
  margin-left: auto;
  margin-right: auto;
}

.f-pop-up-form h1 {
  color: white;
  font-size: 60px;
  font-weight: 900;
  position: relative;
}

@media only screen and (max-width: 768px) {
  .f-pop-up-form h1 {
    font-size: 42px;
  }
}

.f-pop-up-form h1:after {
  content: '';
  display: inline-block;
  width: 150px;
  height: 2px;
  background-color: #e6e6e6;
  margin-left: 75px;
  vertical-align: middle;
}

@media only screen and (max-width: 768px) {
  .f-pop-up-form h1:after {
    display: none;
  }
}

.f-pop-up-form h3 {
  color: white;
  font-size: 24px;
  font-weight: 400;
  position: relative;
}

.f-pop-up-form h3:after {
  content: '';
  display: inline-block;
  width: 150px;
  height: 2px;
  background-color: #e6e6e6;
  margin-left: 40px;
  vertical-align: middle;
}

@media only screen and (max-width: 768px) {
  .f-pop-up-form h3:after {
    display: none;
  }
}

.f-pop-up-form p {
  color: white;
  letter-spacing: .31px;
}

.f-pop-up-form input {
  display: block;
  line-height: 50px;
  background-color: transparent;
  width: 100%;
  border-bottom: 1px solid gray;
  color: white;
  font-family: 'Lato', sans-serif;
  font-size: 14px;
  font-weight: 400;
  padding: 0 40px 0 20px;
  transition: color .3s ease;
  letter-spacing: .5px;
}

@media only screen and (max-width: 768px) {
  .f-pop-up-form input {
    padding-left: 0;
  }
}

.f-pop-up-form input:focus {
  border-color: #ff5a00;
}

.f-pop-up-form input::-webkit-input-placeholder {
  color: #fff;
  transition: color .3s ease;
}

.f-pop-up-form input::-moz-placeholder {
  color: #fff;
  transition: color .3s ease;
}

.f-pop-up-form input:-moz-placeholder {
  color: #fff;
  transition: color .3s ease;
}

.f-pop-up-form input:-ms-input-placeholder {
  color: #fff;
  transition: color .3s ease;
}

.f-pop-up-form input:focus::-webkit-input-placeholder {
  color: #ff5a00;
}

.f-pop-up-form input:focus::-moz-placeholder {
  color: #ff5a00;
}

.f-pop-up-form input:focus:-moz-placeholder {
  color: #ff5a00;
}

.f-pop-up-form input[type='submit'],
.f-pop-up-form button {
  right: 0;
  top: 0;
  color: transparent;
  bottom: 0;
  line-height: 58px;
  font-size: 24px;
  padding: 0 20px;
  margin: auto;
  font-weight: 100;
  position: absolute;
  background-color: transparent;
  background: transparent url(../img/arrow.png) center center no-repeat;
  background-size: 17px 17px;
  transition: color .3s ease;
}

@media only screen and (max-width: 768px) {
  .f-pop-up-form input[type='submit'],
  .f-pop-up-form button {
    padding-right: 0;
  }
}

.f-pop-up-form input[type='submit']:hover, .f-pop-up-form input[type='submit']:focus,
.f-pop-up-form button:hover,
.f-pop-up-form button:focus {
  color: #ff5a00;
}

.f-pop-up-form form {
  display: block;
  box-sizing: border-box;
  position: relative;
}

.f-pop-up-form label {
  width: 100%;
}

.f-form_style .f-head-h2 {
  color: white;
  line-height: 28px;
}

.f-form_style .f-head-h2:after {
  margin-left: 40px;
  position: static;
  vertical-align: middle;
  background-color: grey;
}

@media only screen and (max-width: 480px) {
  .f-form_style .f-head-h2:after {
    display: none;
  }
}

.f-form_style label {
  display: block;
  position: relative;
}

.f-form_style label a {
  position: absolute;
  line-height: 50px;
  right: 0;
  top: 0;
  transition: color .3s ease;
}

.f-form_style label a:hover {
  color: #ff5a00;
}

.f-form_style label span {
  display: inline-block;
  color: #ccc;
  font-size: 14px;
  font-weight: 400;
  line-height: 57px;
  letter-spacing: .5px;
}

@media only screen and (max-width: 768px) {
  .f-form_style label span {
    line-height: 1;
  }
}

.f-form_style label span:before {
  margin-right: 20px;
  content: "\f3fd";
  color: transparent;
  display: inline-block;
  text-align: center;
  width: 14px;
  height: 14px;
  border: 1px solid grey;
  line-height: 12px;
  font-size: 14px;
  font-family: "Ionicons";
}

.f-form_style label input[type=checkbox] {
  display: none;
}

.f-form_style label input[type=checkbox]:checked + span:before {
  border-color: #ff5a00;
  color: #ff5a00;
}

.f-form_style input:not([type='checkbox']) {
  line-height: 50px;
  margin-bottom: 30px;
  background-color: transparent;
  width: 100%;
  border-bottom: 1px solid gray;
  color: white;
  font-family: 'Lato', sans-serif;
  font-size: 14px;
  font-weight: 400;
  padding: 0 15px;
  transition: color .3s ease;
  letter-spacing: 1.9px;
}

@media only screen and (max-width: 768px) {
  .f-form_style input:not([type='checkbox']) {
    padding-left: 0;
  }
}

.f-form_style input:not([type='checkbox']):focus {
  border-color: #ff5a00;
}

.f-form_style input::-webkit-input-placeholder {
  color: #fff;
  transition: color .3s ease;
}

.f-form_style input::-moz-placeholder {
  color: #fff;
  transition: color .3s ease;
}

.f-form_style input:-moz-placeholder {
  color: #fff;
  transition: color .3s ease;
}

.f-form_style input:-ms-input-placeholder {
  color: #fff;
  transition: color .3s ease;
}

.f-form_style input:focus::-webkit-input-placeholder {
  color: #ff5a00;
}

.f-form_style input:focus::-moz-placeholder {
  color: #ff5a00;
}

.f-form_style input:focus:-moz-placeholder {
  color: #ff5a00;
}

.f-form_style button {
  margin-top: 60px;
}

.f-login-wrap {
  padding: 0 100px;
}

@media only screen and (max-width: 1500px) {
  .f-login-wrap {
    padding: 0;
    max-width: 470px;
    margin-left: auto;
    margin-right: auto;
  }
}

.f-login-wrap input:not([type='checkbox']) {
  padding-right: 70px;
}

.f-registr-wrap {
  max-width: 470px;
}

@media only screen and (max-width: 1500px) {
  .f-registr-wrap {
    margin-left: auto;
    margin-right: auto;
  }
}

.f-newsletter-form p {
  letter-spacing: .9px;
}

.f-form {
  display: table;
  max-width: 1170px;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
}

.f-form .main-menu li {
  margin-right: 0;
}

.f-form nav {
  display: block;
  width: 50%;
  display: table-cell;
}

@media only screen and (max-width: 1300px) {
  .f-form nav {
    padding-left: 100px;
  }
}

@media only screen and (max-width: 767px) {
  .f-form nav {
    width: 100%;
    padding: 0 15px;
    display: block;
    margin-bottom: 50px;
  }
}

.f-form nav ul {
  display: block;
}

.f-form nav ul li {
  display: block;
}

.f-form nav .main-menu li > a {
  transition: color .3s ease;
  color: white;
  font-size: 24px;
  font-weight: 500;
  line-height: 60px;
}

.f-form nav .main-menu li > a:hover {
  color: #ff5a00;
}

.f-form nav .main-menu li ul {
  background-color: transparent;
  position: static;
  padding: 0;
  padding-left: 35px;
}

.f-form nav .main-menu li:hover > .sub-menu {
  display: none;
}

.f-form nav .main-menu li.active > a {
  color: #ff5a00;
}

.f-form nav .main-menu li.active .sub-menu {
  display: block;
}

.f-form nav .main-menu li.active .sub-menu a {
  font-size: 16px;
  font-weight: 500;
  line-height: 17px;
}

.f-form nav .main-menu li.active .sub-menu .sub-menu {
  position: static;
}

.f-form .f-pop-up-form {
  width: 50%;
  display: table-cell;
  vertical-align: middle;
  padding-left: 115px;
  padding-top: 15px;
}

@media only screen and (max-width: 1300px) {
  .f-form .f-pop-up-form {
    padding-left: 0;
    padding-right: 100px;
  }
}

@media only screen and (max-width: 767px) {
  .f-form .f-pop-up-form {
    width: 100%;
    display: block;
    padding: 0 15px;
  }
}

.main-menu .sub-menu .menu-item-has-children > a:after {
  padding-left: 5px;
}

/* white popup */
.pop-up-hide {
  display: none;
}

.popUp {
  position: fixed;
  display: block;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.8);
}

.popUp .f-pop-up-form {
  position: relative;
  background-color: #fff;
  border: 2px solid #ff5a00;
  padding: 55px 100px 80px;
  top: 50%;
  max-width: 1170px;
  transform: translate(0, -50%);
}

@media only screen and (max-width: 767px) {
  .popUp .f-pop-up-form {
    padding: 30px 15px;
  }
}

.popUp .f-pop-up-form .f-head-h1 {
  color: #333;
}

.popUp .f-pop-up-form .f-head-h1:after {
  background-color: #e6e6e6;
}

.popUp .f-pop-up-form p {
  font-size: 16px;
  color: grey;
  letter-spacing: 0;
}

.popUp .f-pop-up-form input {
  color: #999;
  border-color: #e6e6e6;
}

.popUp .f-pop-up-form input::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */
  color: #999;
}

.popUp .f-pop-up-form input::-moz-placeholder {
  /* Firefox 19+ */
  color: #999;
}

.popUp .f-pop-up-form input:-ms-input-placeholder {
  /* IE 10+ */
  color: #999;
}

.popUp .f-pop-up-form input:-moz-placeholder {
  /* Firefox 18- */
  color: #999;
}

.popUp .f-pop-up-form button {
  color: #999;
}

.f-close-pop-up {
  width: 23px;
  height: 23px;
  cursor: pointer;
  display: inline-block;
  position: absolute;
  top: 50px;
  right: 50px;
}

@media only screen and (max-width: 767px) {
  .f-close-pop-up {
    top: 15px;
    right: 15px;
  }
}

.f-close-pop-up:after, .f-close-pop-up:before {
  top: 0;
  bottom: 0;
  margin: auto;
  content: '';
  display: inline-block;
  width: 25px;
  height: 1px;
  background-color: #000;
  transform: rotate(45deg);
  position: absolute;
}

.f-close-pop-up:after {
  transform: rotate(-45deg);
}

/*--------------------------------------------------------------------------------------*/
/* Paddings and margins*/
/*--------------------------------------------------------------------------------------*/
/*-------------------------------------------------------------------------------------------------------------------------------*/
/* Custom paddings/margins */
/*-------------------------------------------------------------------------------------------------------------------------------*/
.padding-lg-lr0 {
  padding-left: 0px;
  padding-right: 0px;
}

.margin-lg-t0 {
  margin-top: 0px;
}

.margin-lg-t5 {
  margin-top: 5px;
}

.margin-lg-t10 {
  margin-top: 10px;
}

.margin-lg-t15 {
  margin-top: 15px;
}

.margin-lg-t20 {
  margin-top: 20px;
}

.margin-lg-t25 {
  margin-top: 25px;
}

.margin-lg-t30 {
  margin-top: 30px;
}

.margin-lg-t35 {
  margin-top: 35px;
}

.margin-lg-t40 {
  margin-top: 40px;
}

.margin-lg-t45 {
  margin-top: 45px;
}

.margin-lg-t50 {
  margin-top: 50px;
}

.margin-lg-t55 {
  margin-top: 55px;
}

.margin-lg-t60 {
  margin-top: 60px;
}

.margin-lg-t65 {
  margin-top: 65px;
}

.margin-lg-t70 {
  margin-top: 70px;
}

.margin-lg-t75 {
  margin-top: 75px;
}

.margin-lg-t80 {
  margin-top: 80px;
}

.margin-lg-t85 {
  margin-top: 85px;
}

.margin-lg-t90 {
  margin-top: 90px;
}

.margin-lg-t95 {
  margin-top: 95px;
}

.margin-lg-t100 {
  margin-top: 100px;
}

.margin-lg-t105 {
  margin-top: 105px;
}

.margin-lg-t110 {
  margin-top: 110px;
}

.margin-lg-t115 {
  margin-top: 115px;
}

.margin-lg-t120 {
  margin-top: 120px;
}

.margin-lg-t125 {
  margin-top: 125px;
}

.margin-lg-t130 {
  margin-top: 130px;
}

.margin-lg-t135 {
  margin-top: 135px;
}

.margin-lg-t140 {
  margin-top: 140px;
}

.margin-lg-t145 {
  margin-top: 145px;
}

.margin-lg-t150 {
  margin-top: 150px;
}

.margin-lg-t155 {
  margin-top: 155px;
}

.margin-lg-t160 {
  margin-top: 160px;
}

.margin-lg-t165 {
  margin-top: 165px;
}

.margin-lg-t170 {
  margin-top: 170px;
}

.margin-lg-t175 {
  margin-top: 175px;
}

.margin-lg-t180 {
  margin-top: 180px;
}

.margin-lg-t185 {
  margin-top: 185px;
}

.margin-lg-t190 {
  margin-top: 190px;
}

.margin-lg-t195 {
  margin-top: 195px;
}

.margin-lg-t200 {
  margin-top: 200px;
}

.margin-lg-t205 {
  margin-top: 205px;
}

.margin-lg-t210 {
  margin-top: 210px;
}

.margin-lg-t215 {
  margin-top: 215px;
}

.margin-lg-t220 {
  margin-top: 220px;
}

.margin-lg-t225 {
  margin-top: 225px;
}

.margin-lg-t230 {
  margin-top: 230px;
}

.margin-lg-t235 {
  margin-top: 235px;
}

.margin-lg-t240 {
  margin-top: 240px;
}

.margin-lg-t245 {
  margin-top: 245px;
}

.margin-lg-t250 {
  margin-top: 250px;
}

.margin-lg-t255 {
  margin-top: 255px;
}

.margin-lg-t260 {
  margin-top: 260px;
}

.margin-lg-t265 {
  margin-top: 265px;
}

.margin-lg-t270 {
  margin-top: 270px;
}

.margin-lg-t275 {
  margin-top: 275px;
}

.margin-lg-t280 {
  margin-top: 280px;
}

.margin-lg-t285 {
  margin-top: 285px;
}

.margin-lg-t290 {
  margin-top: 290px;
}

.margin-lg-t295 {
  margin-top: 295px;
}

.margin-lg-t300 {
  margin-top: 300px;
}

.margin-lg-b0 {
  margin-bottom: 0px;
}

.margin-lg-b5 {
  margin-bottom: 5px;
}

.margin-lg-b10 {
  margin-bottom: 10px;
}

.margin-lg-b15 {
  margin-bottom: 15px;
}

.margin-lg-b20 {
  margin-bottom: 20px;
}

.margin-lg-b25 {
  margin-bottom: 25px;
}

.margin-lg-b30 {
  margin-bottom: 30px;
}

.margin-lg-b35 {
  margin-bottom: 35px;
}

.margin-lg-b40 {
  margin-bottom: 40px;
}

.margin-lg-b45 {
  margin-bottom: 45px;
}

.margin-lg-b50 {
  margin-bottom: 50px;
}

.margin-lg-b55 {
  margin-bottom: 55px;
}

.margin-lg-b60 {
  margin-bottom: 60px;
}

.margin-lg-b65 {
  margin-bottom: 65px;
}

.margin-lg-b70 {
  margin-bottom: 70px;
}

.margin-lg-b75 {
  margin-bottom: 75px;
}

.margin-lg-b80 {
  margin-bottom: 80px;
}

.margin-lg-b85 {
  margin-bottom: 85px;
}

.margin-lg-b90 {
  margin-bottom: 90px;
}

.margin-lg-b95 {
  margin-bottom: 95px;
}

.margin-lg-b100 {
  margin-bottom: 100px;
}

.margin-lg-b105 {
  margin-bottom: 105px;
}

.margin-lg-b110 {
  margin-bottom: 110px;
}

.margin-lg-b115 {
  margin-bottom: 115px;
}

.margin-lg-b120 {
  margin-bottom: 120px;
}

.margin-lg-b125 {
  margin-bottom: 125px;
}

.margin-lg-b130 {
  margin-bottom: 130px;
}

.margin-lg-b135 {
  margin-bottom: 135px;
}

.margin-lg-b140 {
  margin-bottom: 140px;
}

.margin-lg-b145 {
  margin-bottom: 145px;
}

.margin-lg-b150 {
  margin-bottom: 150px;
}

.margin-lg-b155 {
  margin-bottom: 155px;
}

.margin-lg-b160 {
  margin-bottom: 160px;
}

.margin-lg-b165 {
  margin-bottom: 165px;
}

.margin-lg-b170 {
  margin-bottom: 170px;
}

.margin-lg-b175 {
  margin-bottom: 175px;
}

.margin-lg-b180 {
  margin-bottom: 180px;
}

.margin-lg-b185 {
  margin-bottom: 185px;
}

.margin-lg-b190 {
  margin-bottom: 190px;
}

.margin-lg-b195 {
  margin-bottom: 195px;
}

.margin-lg-b200 {
  margin-bottom: 200px;
}

.margin-lg-b205 {
  margin-bottom: 205px;
}

.margin-lg-b210 {
  margin-bottom: 210px;
}

.margin-lg-b215 {
  margin-bottom: 215px;
}

.margin-lg-b220 {
  margin-bottom: 220px;
}

.margin-lg-b225 {
  margin-bottom: 225px;
}

.margin-lg-b230 {
  margin-bottom: 230px;
}

.margin-lg-b235 {
  margin-bottom: 235px;
}

.margin-lg-b240 {
  margin-bottom: 240px;
}

.margin-lg-b245 {
  margin-bottom: 245px;
}

.margin-lg-b250 {
  margin-bottom: 250px;
}

.margin-lg-b255 {
  margin-bottom: 255px;
}

.margin-lg-b260 {
  margin-bottom: 260px;
}

.margin-lg-b265 {
  margin-bottom: 265px;
}

.margin-lg-b270 {
  margin-bottom: 270px;
}

.margin-lg-b275 {
  margin-bottom: 275px;
}

.margin-lg-b280 {
  margin-bottom: 280px;
}

.margin-lg-b285 {
  margin-bottom: 285px;
}

.margin-lg-b290 {
  margin-bottom: 290px;
}

.margin-lg-b295 {
  margin-bottom: 295px;
}

.margin-lg-b300 {
  margin-bottom: 300px;
}

.padding-lg-t0 {
  padding-top: 0px;
}

.padding-lg-t5 {
  padding-top: 5px;
}

.padding-lg-t10 {
  padding-top: 10px;
}

.padding-lg-t15 {
  padding-top: 15px;
}

.padding-lg-t20 {
  padding-top: 20px;
}

.padding-lg-t25 {
  padding-top: 25px;
}

.padding-lg-t30 {
  padding-top: 30px;
}

.padding-lg-t35 {
  padding-top: 35px;
}

.padding-lg-t40 {
  padding-top: 40px;
}

.padding-lg-t45 {
  padding-top: 45px;
}

.padding-lg-t50 {
  padding-top: 50px;
}

.padding-lg-t55 {
  padding-top: 55px;
}

.padding-lg-t60 {
  padding-top: 60px;
}

.padding-lg-t65 {
  padding-top: 65px;
}

.padding-lg-t70 {
  padding-top: 70px;
}

.padding-lg-t75 {
  padding-top: 75px;
}

.padding-lg-t80 {
  padding-top: 80px;
}

.padding-lg-t85 {
  padding-top: 85px;
}

.padding-lg-t90 {
  padding-top: 90px;
}

.padding-lg-t95 {
  padding-top: 95px;
}

.padding-lg-t100 {
  padding-top: 100px;
}

.padding-lg-t105 {
  padding-top: 105px;
}

.padding-lg-t110 {
  padding-top: 110px;
}

.padding-lg-t115 {
  padding-top: 115px;
}

.padding-lg-t120 {
  padding-top: 120px;
}

.padding-lg-t125 {
  padding-top: 125px;
}

.padding-lg-t130 {
  padding-top: 130px;
}

.padding-lg-t135 {
  padding-top: 135px;
}

.padding-lg-t140 {
  padding-top: 140px;
}

.padding-lg-t145 {
  padding-top: 145px;
}

.padding-lg-t150 {
  padding-top: 150px;
}

.padding-lg-t155 {
  padding-top: 155px;
}

.padding-lg-t160 {
  padding-top: 160px;
}

.padding-lg-t165 {
  padding-top: 165px;
}

.padding-lg-t170 {
  padding-top: 170px;
}

.padding-lg-t175 {
  padding-top: 175px;
}

.padding-lg-t180 {
  padding-top: 180px;
}

.padding-lg-t185 {
  padding-top: 185px;
}

.padding-lg-t190 {
  padding-top: 190px;
}

.padding-lg-t195 {
  padding-top: 195px;
}

.padding-lg-t200 {
  padding-top: 200px;
}

.padding-lg-t205 {
  padding-top: 205px;
}

.padding-lg-t210 {
  padding-top: 210px;
}

.padding-lg-t215 {
  padding-top: 215px;
}

.padding-lg-t220 {
  padding-top: 220px;
}

.padding-lg-t225 {
  padding-top: 225px;
}

.padding-lg-t230 {
  padding-top: 230px;
}

.padding-lg-t235 {
  padding-top: 235px;
}

.padding-lg-t240 {
  padding-top: 240px;
}

.padding-lg-t245 {
  padding-top: 245px;
}

.padding-lg-t250 {
  padding-top: 250px;
}

.padding-lg-t255 {
  padding-top: 255px;
}

.padding-lg-t260 {
  padding-top: 260px;
}

.padding-lg-t265 {
  padding-top: 265px;
}

.padding-lg-t270 {
  padding-top: 270px;
}

.padding-lg-t275 {
  padding-top: 275px;
}

.padding-lg-t280 {
  padding-top: 280px;
}

.padding-lg-t285 {
  padding-top: 285px;
}

.padding-lg-t290 {
  padding-top: 290px;
}

.padding-lg-t295 {
  padding-top: 295px;
}

.padding-lg-t300 {
  padding-top: 300px;
}

.padding-lg-b0 {
  padding-bottom: 0px;
}

.padding-lg-b5 {
  padding-bottom: 5px;
}

.padding-lg-b10 {
  padding-bottom: 10px;
}

.padding-lg-b15 {
  padding-bottom: 15px;
}

.padding-lg-b20 {
  padding-bottom: 20px;
}

.padding-lg-b25 {
  padding-bottom: 25px;
}

.padding-lg-b30 {
  padding-bottom: 30px;
}

.padding-lg-b35 {
  padding-bottom: 35px;
}

.padding-lg-b40 {
  padding-bottom: 40px;
}

.padding-lg-b45 {
  padding-bottom: 45px;
}

.padding-lg-b50 {
  padding-bottom: 50px;
}

.padding-lg-b55 {
  padding-bottom: 55px;
}

.padding-lg-b60 {
  padding-bottom: 60px;
}

.padding-lg-b65 {
  padding-bottom: 65px;
}

.padding-lg-b70 {
  padding-bottom: 70px;
}

.padding-lg-b75 {
  padding-bottom: 75px;
}

.padding-lg-b80 {
  padding-bottom: 80px;
}

.padding-lg-b85 {
  padding-bottom: 85px;
}

.padding-lg-b90 {
  padding-bottom: 90px;
}

.padding-lg-b95 {
  padding-bottom: 95px;
}

.padding-lg-b100 {
  padding-bottom: 100px;
}

.padding-lg-b105 {
  padding-bottom: 105px;
}

.padding-lg-b110 {
  padding-bottom: 110px;
}

.padding-lg-b115 {
  padding-bottom: 115px;
}

.padding-lg-b120 {
  padding-bottom: 120px;
}

.padding-lg-b125 {
  padding-bottom: 125px;
}

.padding-lg-b130 {
  padding-bottom: 130px;
}

.padding-lg-b135 {
  padding-bottom: 135px;
}

.padding-lg-b140 {
  padding-bottom: 140px;
}

.padding-lg-b145 {
  padding-bottom: 145px;
}

.padding-lg-b150 {
  padding-bottom: 150px;
}

.padding-lg-b155 {
  padding-bottom: 155px;
}

.padding-lg-b160 {
  padding-bottom: 160px;
}

.padding-lg-b165 {
  padding-bottom: 165px;
}

.padding-lg-b170 {
  padding-bottom: 170px;
}

.padding-lg-b175 {
  padding-bottom: 175px;
}

.padding-lg-b180 {
  padding-bottom: 180px;
}

.padding-lg-b185 {
  padding-bottom: 185px;
}

.padding-lg-b190 {
  padding-bottom: 190px;
}

.padding-lg-b195 {
  padding-bottom: 195px;
}

.padding-lg-b200 {
  padding-bottom: 200px;
}

.padding-lg-b205 {
  padding-bottom: 205px;
}

.padding-lg-b210 {
  padding-bottom: 210px;
}

.padding-lg-b215 {
  padding-bottom: 215px;
}

.padding-lg-b220 {
  padding-bottom: 220px;
}

.padding-lg-b225 {
  padding-bottom: 225px;
}

.padding-lg-b230 {
  padding-bottom: 230px;
}

.padding-lg-b235 {
  padding-bottom: 235px;
}

.padding-lg-b240 {
  padding-bottom: 240px;
}

.padding-lg-b245 {
  padding-bottom: 245px;
}

.padding-lg-b250 {
  padding-bottom: 250px;
}

.padding-lg-b255 {
  padding-bottom: 255px;
}

.padding-lg-b260 {
  padding-bottom: 260px;
}

.padding-lg-b265 {
  padding-bottom: 265px;
}

.padding-lg-b270 {
  padding-bottom: 270px;
}

.padding-lg-b275 {
  padding-bottom: 275px;
}

.padding-lg-b280 {
  padding-bottom: 280px;
}

.padding-lg-b285 {
  padding-bottom: 285px;
}

.padding-lg-b290 {
  padding-bottom: 290px;
}

.padding-lg-b295 {
  padding-bottom: 295px;
}

.padding-lg-b300 {
  padding-bottom: 300px;
}

@media screen and (max-width: 1199px) {
  .padding-lg-lr0 {
    padding-left: 15px;
    padding-right: 15px;
  }
  .margin-md-t0 {
    margin-top: 0px;
  }
  .margin-md-t5 {
    margin-top: 5px;
  }
  .margin-md-t10 {
    margin-top: 10px;
  }
  .margin-md-t15 {
    margin-top: 15px;
  }
  .margin-md-t20 {
    margin-top: 20px;
  }
  .margin-md-t25 {
    margin-top: 25px;
  }
  .margin-md-t30 {
    margin-top: 30px;
  }
  .margin-md-t35 {
    margin-top: 35px;
  }
  .margin-md-t40 {
    margin-top: 40px;
  }
  .margin-md-t45 {
    margin-top: 45px;
  }
  .margin-md-t50 {
    margin-top: 50px;
  }
  .margin-md-t55 {
    margin-top: 55px;
  }
  .margin-md-t60 {
    margin-top: 60px;
  }
  .margin-md-t65 {
    margin-top: 65px;
  }
  .margin-md-t70 {
    margin-top: 70px;
  }
  .margin-md-t75 {
    margin-top: 75px;
  }
  .margin-md-t80 {
    margin-top: 80px;
  }
  .margin-md-t85 {
    margin-top: 85px;
  }
  .margin-md-t90 {
    margin-top: 90px;
  }
  .margin-md-t95 {
    margin-top: 95px;
  }
  .margin-md-t100 {
    margin-top: 100px;
  }
  .margin-md-t105 {
    margin-top: 105px;
  }
  .margin-md-t110 {
    margin-top: 110px;
  }
  .margin-md-t115 {
    margin-top: 115px;
  }
  .margin-md-t120 {
    margin-top: 120px;
  }
  .margin-md-t125 {
    margin-top: 125px;
  }
  .margin-md-t130 {
    margin-top: 130px;
  }
  .margin-md-t135 {
    margin-top: 135px;
  }
  .margin-md-t140 {
    margin-top: 140px;
  }
  .margin-md-t145 {
    margin-top: 145px;
  }
  .margin-md-t150 {
    margin-top: 150px;
  }
  .margin-md-t155 {
    margin-top: 155px;
  }
  .margin-md-t160 {
    margin-top: 160px;
  }
  .margin-md-t165 {
    margin-top: 165px;
  }
  .margin-md-t170 {
    margin-top: 170px;
  }
  .margin-md-t175 {
    margin-top: 175px;
  }
  .margin-md-t180 {
    margin-top: 180px;
  }
  .margin-md-t185 {
    margin-top: 185px;
  }
  .margin-md-t190 {
    margin-top: 190px;
  }
  .margin-md-t195 {
    margin-top: 195px;
  }
  .margin-md-t200 {
    margin-top: 200px;
  }
  .margin-md-t205 {
    margin-top: 205px;
  }
  .margin-md-t210 {
    margin-top: 210px;
  }
  .margin-md-t215 {
    margin-top: 215px;
  }
  .margin-md-t220 {
    margin-top: 220px;
  }
  .margin-md-t225 {
    margin-top: 225px;
  }
  .margin-md-t230 {
    margin-top: 230px;
  }
  .margin-md-t235 {
    margin-top: 235px;
  }
  .margin-md-t240 {
    margin-top: 240px;
  }
  .margin-md-t245 {
    margin-top: 245px;
  }
  .margin-md-t250 {
    margin-top: 250px;
  }
  .margin-md-t255 {
    margin-top: 255px;
  }
  .margin-md-t260 {
    margin-top: 260px;
  }
  .margin-md-t265 {
    margin-top: 265px;
  }
  .margin-md-t270 {
    margin-top: 270px;
  }
  .margin-md-t275 {
    margin-top: 275px;
  }
  .margin-md-t280 {
    margin-top: 280px;
  }
  .margin-md-t285 {
    margin-top: 285px;
  }
  .margin-md-t290 {
    margin-top: 290px;
  }
  .margin-md-t295 {
    margin-top: 295px;
  }
  .margin-md-t300 {
    margin-top: 300px;
  }
  .margin-md-b0 {
    margin-bottom: 0px;
  }
  .margin-md-b5 {
    margin-bottom: 5px;
  }
  .margin-md-b10 {
    margin-bottom: 10px;
  }
  .margin-md-b15 {
    margin-bottom: 15px;
  }
  .margin-md-b20 {
    margin-bottom: 20px;
  }
  .margin-md-b25 {
    margin-bottom: 25px;
  }
  .margin-md-b30 {
    margin-bottom: 30px;
  }
  .margin-md-b35 {
    margin-bottom: 35px;
  }
  .margin-md-b40 {
    margin-bottom: 40px;
  }
  .margin-md-b45 {
    margin-bottom: 45px;
  }
  .margin-md-b50 {
    margin-bottom: 50px;
  }
  .margin-md-b55 {
    margin-bottom: 55px;
  }
  .margin-md-b60 {
    margin-bottom: 60px;
  }
  .margin-md-b65 {
    margin-bottom: 65px;
  }
  .margin-md-b70 {
    margin-bottom: 70px;
  }
  .margin-md-b75 {
    margin-bottom: 75px;
  }
  .margin-md-b80 {
    margin-bottom: 80px;
  }
  .margin-md-b85 {
    margin-bottom: 85px;
  }
  .margin-md-b90 {
    margin-bottom: 90px;
  }
  .margin-md-b95 {
    margin-bottom: 95px;
  }
  .margin-md-b100 {
    margin-bottom: 100px;
  }
  .margin-md-b105 {
    margin-bottom: 105px;
  }
  .margin-md-b110 {
    margin-bottom: 110px;
  }
  .margin-md-b115 {
    margin-bottom: 115px;
  }
  .margin-md-b120 {
    margin-bottom: 120px;
  }
  .margin-md-b125 {
    margin-bottom: 125px;
  }
  .margin-md-b130 {
    margin-bottom: 130px;
  }
  .margin-md-b135 {
    margin-bottom: 135px;
  }
  .margin-md-b140 {
    margin-bottom: 140px;
  }
  .margin-md-b145 {
    margin-bottom: 145px;
  }
  .margin-md-b150 {
    margin-bottom: 150px;
  }
  .margin-md-b155 {
    margin-bottom: 155px;
  }
  .margin-md-b160 {
    margin-bottom: 160px;
  }
  .margin-md-b165 {
    margin-bottom: 165px;
  }
  .margin-md-b170 {
    margin-bottom: 170px;
  }
  .margin-md-b175 {
    margin-bottom: 175px;
  }
  .margin-md-b180 {
    margin-bottom: 180px;
  }
  .margin-md-b185 {
    margin-bottom: 185px;
  }
  .margin-md-b190 {
    margin-bottom: 190px;
  }
  .margin-md-b195 {
    margin-bottom: 195px;
  }
  .margin-md-b200 {
    margin-bottom: 200px;
  }
  .margin-md-b205 {
    margin-bottom: 205px;
  }
  .margin-md-b210 {
    margin-bottom: 210px;
  }
  .margin-md-b215 {
    margin-bottom: 215px;
  }
  .margin-md-b220 {
    margin-bottom: 220px;
  }
  .margin-md-b225 {
    margin-bottom: 225px;
  }
  .margin-md-b230 {
    margin-bottom: 230px;
  }
  .margin-md-b235 {
    margin-bottom: 235px;
  }
  .margin-md-b240 {
    margin-bottom: 240px;
  }
  .margin-md-b245 {
    margin-bottom: 245px;
  }
  .margin-md-b250 {
    margin-bottom: 250px;
  }
  .margin-md-b255 {
    margin-bottom: 255px;
  }
  .margin-md-b260 {
    margin-bottom: 260px;
  }
  .margin-md-b265 {
    margin-bottom: 265px;
  }
  .margin-md-b270 {
    margin-bottom: 270px;
  }
  .margin-md-b275 {
    margin-bottom: 275px;
  }
  .margin-md-b280 {
    margin-bottom: 280px;
  }
  .margin-md-b285 {
    margin-bottom: 285px;
  }
  .margin-md-b290 {
    margin-bottom: 290px;
  }
  .margin-md-b295 {
    margin-bottom: 295px;
  }
  .margin-md-b300 {
    margin-bottom: 300px;
  }
  .padding-md-t0 {
    padding-top: 0px;
  }
  .padding-md-t5 {
    padding-top: 5px;
  }
  .padding-md-t10 {
    padding-top: 10px;
  }
  .padding-md-t15 {
    padding-top: 15px;
  }
  .padding-md-t20 {
    padding-top: 20px;
  }
  .padding-md-t25 {
    padding-top: 25px;
  }
  .padding-md-t30 {
    padding-top: 30px;
  }
  .padding-md-t35 {
    padding-top: 35px;
  }
  .padding-md-t40 {
    padding-top: 40px;
  }
  .padding-md-t45 {
    padding-top: 45px;
  }
  .padding-md-t50 {
    padding-top: 50px;
  }
  .padding-md-t55 {
    padding-top: 55px;
  }
  .padding-md-t60 {
    padding-top: 60px;
  }
  .padding-md-t65 {
    padding-top: 65px;
  }
  .padding-md-t70 {
    padding-top: 70px;
  }
  .padding-md-t75 {
    padding-top: 75px;
  }
  .padding-md-t80 {
    padding-top: 80px;
  }
  .padding-md-t85 {
    padding-top: 85px;
  }
  .padding-md-t90 {
    padding-top: 90px;
  }
  .padding-md-t95 {
    padding-top: 95px;
  }
  .padding-md-t100 {
    padding-top: 100px;
  }
  .padding-md-t105 {
    padding-top: 105px;
  }
  .padding-md-t110 {
    padding-top: 110px;
  }
  .padding-md-t115 {
    padding-top: 115px;
  }
  .padding-md-t120 {
    padding-top: 120px;
  }
  .padding-md-t125 {
    padding-top: 125px;
  }
  .padding-md-t130 {
    padding-top: 130px;
  }
  .padding-md-t135 {
    padding-top: 135px;
  }
  .padding-md-t140 {
    padding-top: 140px;
  }
  .padding-md-t145 {
    padding-top: 145px;
  }
  .padding-md-t150 {
    padding-top: 150px;
  }
  .padding-md-t155 {
    padding-top: 155px;
  }
  .padding-md-t160 {
    padding-top: 160px;
  }
  .padding-md-t165 {
    padding-top: 165px;
  }
  .padding-md-t170 {
    padding-top: 170px;
  }
  .padding-md-t175 {
    padding-top: 175px;
  }
  .padding-md-t180 {
    padding-top: 180px;
  }
  .padding-md-t185 {
    padding-top: 185px;
  }
  .padding-md-t190 {
    padding-top: 190px;
  }
  .padding-md-t195 {
    padding-top: 195px;
  }
  .padding-md-t200 {
    padding-top: 200px;
  }
  .padding-md-t205 {
    padding-top: 205px;
  }
  .padding-md-t210 {
    padding-top: 210px;
  }
  .padding-md-t215 {
    padding-top: 215px;
  }
  .padding-md-t220 {
    padding-top: 220px;
  }
  .padding-md-t225 {
    padding-top: 225px;
  }
  .padding-md-t230 {
    padding-top: 230px;
  }
  .padding-md-t235 {
    padding-top: 235px;
  }
  .padding-md-t240 {
    padding-top: 240px;
  }
  .padding-md-t245 {
    padding-top: 245px;
  }
  .padding-md-t250 {
    padding-top: 250px;
  }
  .padding-md-t255 {
    padding-top: 255px;
  }
  .padding-md-t260 {
    padding-top: 260px;
  }
  .padding-md-t265 {
    padding-top: 265px;
  }
  .padding-md-t270 {
    padding-top: 270px;
  }
  .padding-md-t275 {
    padding-top: 275px;
  }
  .padding-md-t280 {
    padding-top: 280px;
  }
  .padding-md-t285 {
    padding-top: 285px;
  }
  .padding-md-t290 {
    padding-top: 290px;
  }
  .padding-md-t295 {
    padding-top: 295px;
  }
  .padding-md-t300 {
    padding-top: 300px;
  }
  .padding-md-b0 {
    padding-bottom: 0px;
  }
  .padding-md-b5 {
    padding-bottom: 5px;
  }
  .padding-md-b10 {
    padding-bottom: 10px;
  }
  .padding-md-b15 {
    padding-bottom: 15px;
  }
  .padding-md-b20 {
    padding-bottom: 20px;
  }
  .padding-md-b25 {
    padding-bottom: 25px;
  }
  .padding-md-b30 {
    padding-bottom: 30px;
  }
  .padding-md-b35 {
    padding-bottom: 35px;
  }
  .padding-md-b40 {
    padding-bottom: 40px;
  }
  .padding-md-b45 {
    padding-bottom: 45px;
  }
  .padding-md-b50 {
    padding-bottom: 50px;
  }
  .padding-md-b55 {
    padding-bottom: 55px;
  }
  .padding-md-b60 {
    padding-bottom: 60px;
  }
  .padding-md-b65 {
    padding-bottom: 65px;
  }
  .padding-md-b70 {
    padding-bottom: 70px;
  }
  .padding-md-b75 {
    padding-bottom: 75px;
  }
  .padding-md-b80 {
    padding-bottom: 80px;
  }
  .padding-md-b85 {
    padding-bottom: 85px;
  }
  .padding-md-b90 {
    padding-bottom: 90px;
  }
  .padding-md-b95 {
    padding-bottom: 95px;
  }
  .padding-md-b100 {
    padding-bottom: 100px;
  }
  .padding-md-b105 {
    padding-bottom: 105px;
  }
  .padding-md-b110 {
    padding-bottom: 110px;
  }
  .padding-md-b115 {
    padding-bottom: 115px;
  }
  .padding-md-b120 {
    padding-bottom: 120px;
  }
  .padding-md-b125 {
    padding-bottom: 125px;
  }
  .padding-md-b130 {
    padding-bottom: 130px;
  }
  .padding-md-b135 {
    padding-bottom: 135px;
  }
  .padding-md-b140 {
    padding-bottom: 140px;
  }
  .padding-md-b145 {
    padding-bottom: 145px;
  }
  .padding-md-b150 {
    padding-bottom: 150px;
  }
  .padding-md-b155 {
    padding-bottom: 155px;
  }
  .padding-md-b160 {
    padding-bottom: 160px;
  }
  .padding-md-b165 {
    padding-bottom: 165px;
  }
  .padding-md-b170 {
    padding-bottom: 170px;
  }
  .padding-md-b175 {
    padding-bottom: 175px;
  }
  .padding-md-b180 {
    padding-bottom: 180px;
  }
  .padding-md-b185 {
    padding-bottom: 185px;
  }
  .padding-md-b190 {
    padding-bottom: 190px;
  }
  .padding-md-b195 {
    padding-bottom: 195px;
  }
  .padding-md-b200 {
    padding-bottom: 200px;
  }
  .padding-md-b205 {
    padding-bottom: 205px;
  }
  .padding-md-b210 {
    padding-bottom: 210px;
  }
  .padding-md-b215 {
    padding-bottom: 215px;
  }
  .padding-md-b220 {
    padding-bottom: 220px;
  }
  .padding-md-b225 {
    padding-bottom: 225px;
  }
  .padding-md-b230 {
    padding-bottom: 230px;
  }
  .padding-md-b235 {
    padding-bottom: 235px;
  }
  .padding-md-b240 {
    padding-bottom: 240px;
  }
  .padding-md-b245 {
    padding-bottom: 245px;
  }
  .padding-md-b250 {
    padding-bottom: 250px;
  }
  .padding-md-b255 {
    padding-bottom: 255px;
  }
  .padding-md-b260 {
    padding-bottom: 260px;
  }
  .padding-md-b265 {
    padding-bottom: 265px;
  }
  .padding-md-b270 {
    padding-bottom: 270px;
  }
  .padding-md-b275 {
    padding-bottom: 275px;
  }
  .padding-md-b280 {
    padding-bottom: 280px;
  }
  .padding-md-b285 {
    padding-bottom: 285px;
  }
  .padding-md-b290 {
    padding-bottom: 290px;
  }
  .padding-md-b295 {
    padding-bottom: 295px;
  }
  .padding-md-b300 {
    padding-bottom: 300px;
  }
}

@media screen and (max-width: 991px) {
  .margin-sm-t0 {
    margin-top: 0px;
  }
  .margin-sm-t5 {
    margin-top: 5px;
  }
  .margin-sm-t10 {
    margin-top: 10px;
  }
  .margin-sm-t15 {
    margin-top: 15px;
  }
  .margin-sm-t20 {
    margin-top: 20px;
  }
  .margin-sm-t25 {
    margin-top: 25px;
  }
  .margin-sm-t30 {
    margin-top: 30px;
  }
  .margin-sm-t35 {
    margin-top: 35px;
  }
  .margin-sm-t40 {
    margin-top: 40px;
  }
  .margin-sm-t45 {
    margin-top: 45px;
  }
  .margin-sm-t50 {
    margin-top: 50px;
  }
  .margin-sm-t55 {
    margin-top: 55px;
  }
  .margin-sm-t60 {
    margin-top: 60px;
  }
  .margin-sm-t65 {
    margin-top: 65px;
  }
  .margin-sm-t70 {
    margin-top: 70px;
  }
  .margin-sm-t75 {
    margin-top: 75px;
  }
  .margin-sm-t80 {
    margin-top: 80px;
  }
  .margin-sm-t85 {
    margin-top: 85px;
  }
  .margin-sm-t90 {
    margin-top: 90px;
  }
  .margin-sm-t95 {
    margin-top: 95px;
  }
  .margin-sm-t100 {
    margin-top: 100px;
  }
  .margin-sm-t105 {
    margin-top: 105px;
  }
  .margin-sm-t110 {
    margin-top: 110px;
  }
  .margin-sm-t115 {
    margin-top: 115px;
  }
  .margin-sm-t120 {
    margin-top: 120px;
  }
  .margin-sm-t125 {
    margin-top: 125px;
  }
  .margin-sm-t130 {
    margin-top: 130px;
  }
  .margin-sm-t135 {
    margin-top: 135px;
  }
  .margin-sm-t140 {
    margin-top: 140px;
  }
  .margin-sm-t145 {
    margin-top: 145px;
  }
  .margin-sm-t150 {
    margin-top: 150px;
  }
  .margin-sm-t155 {
    margin-top: 155px;
  }
  .margin-sm-t160 {
    margin-top: 160px;
  }
  .margin-sm-t165 {
    margin-top: 165px;
  }
  .margin-sm-t170 {
    margin-top: 170px;
  }
  .margin-sm-t175 {
    margin-top: 175px;
  }
  .margin-sm-t180 {
    margin-top: 180px;
  }
  .margin-sm-t185 {
    margin-top: 185px;
  }
  .margin-sm-t190 {
    margin-top: 190px;
  }
  .margin-sm-t195 {
    margin-top: 195px;
  }
  .margin-sm-t200 {
    margin-top: 200px;
  }
  .margin-sm-t205 {
    margin-top: 205px;
  }
  .margin-sm-t210 {
    margin-top: 210px;
  }
  .margin-sm-t215 {
    margin-top: 215px;
  }
  .margin-sm-t220 {
    margin-top: 220px;
  }
  .margin-sm-t225 {
    margin-top: 225px;
  }
  .margin-sm-t230 {
    margin-top: 230px;
  }
  .margin-sm-t235 {
    margin-top: 235px;
  }
  .margin-sm-t240 {
    margin-top: 240px;
  }
  .margin-sm-t245 {
    margin-top: 245px;
  }
  .margin-sm-t250 {
    margin-top: 250px;
  }
  .margin-sm-t255 {
    margin-top: 255px;
  }
  .margin-sm-t260 {
    margin-top: 260px;
  }
  .margin-sm-t265 {
    margin-top: 265px;
  }
  .margin-sm-t270 {
    margin-top: 270px;
  }
  .margin-sm-t275 {
    margin-top: 275px;
  }
  .margin-sm-t280 {
    margin-top: 280px;
  }
  .margin-sm-t285 {
    margin-top: 285px;
  }
  .margin-sm-t290 {
    margin-top: 290px;
  }
  .margin-sm-t295 {
    margin-top: 295px;
  }
  .margin-sm-t300 {
    margin-top: 300px;
  }
  .margin-sm-b0 {
    margin-bottom: 0px;
  }
  .margin-sm-b5 {
    margin-bottom: 5px;
  }
  .margin-sm-b10 {
    margin-bottom: 10px;
  }
  .margin-sm-b15 {
    margin-bottom: 15px;
  }
  .margin-sm-b20 {
    margin-bottom: 20px;
  }
  .margin-sm-b25 {
    margin-bottom: 25px;
  }
  .margin-sm-b30 {
    margin-bottom: 30px;
  }
  .margin-sm-b35 {
    margin-bottom: 35px;
  }
  .margin-sm-b40 {
    margin-bottom: 40px;
  }
  .margin-sm-b45 {
    margin-bottom: 45px;
  }
  .margin-sm-b50 {
    margin-bottom: 50px;
  }
  .margin-sm-b55 {
    margin-bottom: 55px;
  }
  .margin-sm-b60 {
    margin-bottom: 60px;
  }
  .margin-sm-b65 {
    margin-bottom: 65px;
  }
  .margin-sm-b70 {
    margin-bottom: 70px;
  }
  .margin-sm-b75 {
    margin-bottom: 75px;
  }
  .margin-sm-b80 {
    margin-bottom: 80px;
  }
  .margin-sm-b85 {
    margin-bottom: 85px;
  }
  .margin-sm-b90 {
    margin-bottom: 90px;
  }
  .margin-sm-b95 {
    margin-bottom: 95px;
  }
  .margin-sm-b100 {
    margin-bottom: 100px;
  }
  .margin-sm-b105 {
    margin-bottom: 105px;
  }
  .margin-sm-b110 {
    margin-bottom: 110px;
  }
  .margin-sm-b115 {
    margin-bottom: 115px;
  }
  .margin-sm-b120 {
    margin-bottom: 120px;
  }
  .margin-sm-b125 {
    margin-bottom: 125px;
  }
  .margin-sm-b130 {
    margin-bottom: 130px;
  }
  .margin-sm-b135 {
    margin-bottom: 135px;
  }
  .margin-sm-b140 {
    margin-bottom: 140px;
  }
  .margin-sm-b145 {
    margin-bottom: 145px;
  }
  .margin-sm-b150 {
    margin-bottom: 150px;
  }
  .margin-sm-b155 {
    margin-bottom: 155px;
  }
  .margin-sm-b160 {
    margin-bottom: 160px;
  }
  .margin-sm-b165 {
    margin-bottom: 165px;
  }
  .margin-sm-b170 {
    margin-bottom: 170px;
  }
  .margin-sm-b175 {
    margin-bottom: 175px;
  }
  .margin-sm-b180 {
    margin-bottom: 180px;
  }
  .margin-sm-b185 {
    margin-bottom: 185px;
  }
  .margin-sm-b190 {
    margin-bottom: 190px;
  }
  .margin-sm-b195 {
    margin-bottom: 195px;
  }
  .margin-sm-b200 {
    margin-bottom: 200px;
  }
  .margin-sm-b205 {
    margin-bottom: 205px;
  }
  .margin-sm-b210 {
    margin-bottom: 210px;
  }
  .margin-sm-b215 {
    margin-bottom: 215px;
  }
  .margin-sm-b220 {
    margin-bottom: 220px;
  }
  .margin-sm-b225 {
    margin-bottom: 225px;
  }
  .margin-sm-b230 {
    margin-bottom: 230px;
  }
  .margin-sm-b235 {
    margin-bottom: 235px;
  }
  .margin-sm-b240 {
    margin-bottom: 240px;
  }
  .margin-sm-b245 {
    margin-bottom: 245px;
  }
  .margin-sm-b250 {
    margin-bottom: 250px;
  }
  .margin-sm-b255 {
    margin-bottom: 255px;
  }
  .margin-sm-b260 {
    margin-bottom: 260px;
  }
  .margin-sm-b265 {
    margin-bottom: 265px;
  }
  .margin-sm-b270 {
    margin-bottom: 270px;
  }
  .margin-sm-b275 {
    margin-bottom: 275px;
  }
  .margin-sm-b280 {
    margin-bottom: 280px;
  }
  .margin-sm-b285 {
    margin-bottom: 285px;
  }
  .margin-sm-b290 {
    margin-bottom: 290px;
  }
  .margin-sm-b295 {
    margin-bottom: 295px;
  }
  .margin-sm-b300 {
    margin-bottom: 300px;
  }
  .padding-sm-t0 {
    padding-top: 0px;
  }
  .padding-sm-t5 {
    padding-top: 5px;
  }
  .padding-sm-t10 {
    padding-top: 10px;
  }
  .padding-sm-t15 {
    padding-top: 15px;
  }
  .padding-sm-t20 {
    padding-top: 20px;
  }
  .padding-sm-t25 {
    padding-top: 25px;
  }
  .padding-sm-t30 {
    padding-top: 30px;
  }
  .padding-sm-t35 {
    padding-top: 35px;
  }
  .padding-sm-t40 {
    padding-top: 40px;
  }
  .padding-sm-t45 {
    padding-top: 45px;
  }
  .padding-sm-t50 {
    padding-top: 50px;
  }
  .padding-sm-t55 {
    padding-top: 55px;
  }
  .padding-sm-t60 {
    padding-top: 60px;
  }
  .padding-sm-t65 {
    padding-top: 65px;
  }
  .padding-sm-t70 {
    padding-top: 70px;
  }
  .padding-sm-t75 {
    padding-top: 75px;
  }
  .padding-sm-t80 {
    padding-top: 80px;
  }
  .padding-sm-t85 {
    padding-top: 85px;
  }
  .padding-sm-t90 {
    padding-top: 90px;
  }
  .padding-sm-t95 {
    padding-top: 95px;
  }
  .padding-sm-t100 {
    padding-top: 100px;
  }
  .padding-sm-t105 {
    padding-top: 105px;
  }
  .padding-sm-t110 {
    padding-top: 110px;
  }
  .padding-sm-t115 {
    padding-top: 115px;
  }
  .padding-sm-t120 {
    padding-top: 120px;
  }
  .padding-sm-t125 {
    padding-top: 125px;
  }
  .padding-sm-t130 {
    padding-top: 130px;
  }
  .padding-sm-t135 {
    padding-top: 135px;
  }
  .padding-sm-t140 {
    padding-top: 140px;
  }
  .padding-sm-t145 {
    padding-top: 145px;
  }
  .padding-sm-t150 {
    padding-top: 150px;
  }
  .padding-sm-t155 {
    padding-top: 155px;
  }
  .padding-sm-t160 {
    padding-top: 160px;
  }
  .padding-sm-t165 {
    padding-top: 165px;
  }
  .padding-sm-t170 {
    padding-top: 170px;
  }
  .padding-sm-t175 {
    padding-top: 175px;
  }
  .padding-sm-t180 {
    padding-top: 180px;
  }
  .padding-sm-t185 {
    padding-top: 185px;
  }
  .padding-sm-t190 {
    padding-top: 190px;
  }
  .padding-sm-t195 {
    padding-top: 195px;
  }
  .padding-sm-t200 {
    padding-top: 200px;
  }
  .padding-sm-t205 {
    padding-top: 205px;
  }
  .padding-sm-t210 {
    padding-top: 210px;
  }
  .padding-sm-t215 {
    padding-top: 215px;
  }
  .padding-sm-t220 {
    padding-top: 220px;
  }
  .padding-sm-t225 {
    padding-top: 225px;
  }
  .padding-sm-t230 {
    padding-top: 230px;
  }
  .padding-sm-t235 {
    padding-top: 235px;
  }
  .padding-sm-t240 {
    padding-top: 240px;
  }
  .padding-sm-t245 {
    padding-top: 245px;
  }
  .padding-sm-t250 {
    padding-top: 250px;
  }
  .padding-sm-t255 {
    padding-top: 255px;
  }
  .padding-sm-t260 {
    padding-top: 260px;
  }
  .padding-sm-t265 {
    padding-top: 265px;
  }
  .padding-sm-t270 {
    padding-top: 270px;
  }
  .padding-sm-t275 {
    padding-top: 275px;
  }
  .padding-sm-t280 {
    padding-top: 280px;
  }
  .padding-sm-t285 {
    padding-top: 285px;
  }
  .padding-sm-t290 {
    padding-top: 290px;
  }
  .padding-sm-t295 {
    padding-top: 295px;
  }
  .padding-sm-t300 {
    padding-top: 300px;
  }
  .padding-sm-b0 {
    padding-bottom: 0px;
  }
  .padding-sm-b5 {
    padding-bottom: 5px;
  }
  .padding-sm-b10 {
    padding-bottom: 10px;
  }
  .padding-sm-b15 {
    padding-bottom: 15px;
  }
  .padding-sm-b20 {
    padding-bottom: 20px;
  }
  .padding-sm-b25 {
    padding-bottom: 25px;
  }
  .padding-sm-b30 {
    padding-bottom: 30px;
  }
  .padding-sm-b35 {
    padding-bottom: 35px;
  }
  .padding-sm-b40 {
    padding-bottom: 40px;
  }
  .padding-sm-b45 {
    padding-bottom: 45px;
  }
  .padding-sm-b50 {
    padding-bottom: 50px;
  }
  .padding-sm-b55 {
    padding-bottom: 55px;
  }
  .padding-sm-b60 {
    padding-bottom: 60px;
  }
  .padding-sm-b65 {
    padding-bottom: 65px;
  }
  .padding-sm-b70 {
    padding-bottom: 70px;
  }
  .padding-sm-b75 {
    padding-bottom: 75px;
  }
  .padding-sm-b80 {
    padding-bottom: 80px;
  }
  .padding-sm-b85 {
    padding-bottom: 85px;
  }
  .padding-sm-b90 {
    padding-bottom: 90px;
  }
  .padding-sm-b95 {
    padding-bottom: 95px;
  }
  .padding-sm-b100 {
    padding-bottom: 100px;
  }
  .padding-sm-b105 {
    padding-bottom: 105px;
  }
  .padding-sm-b110 {
    padding-bottom: 110px;
  }
  .padding-sm-b115 {
    padding-bottom: 115px;
  }
  .padding-sm-b120 {
    padding-bottom: 120px;
  }
  .padding-sm-b125 {
    padding-bottom: 125px;
  }
  .padding-sm-b130 {
    padding-bottom: 130px;
  }
  .padding-sm-b135 {
    padding-bottom: 135px;
  }
  .padding-sm-b140 {
    padding-bottom: 140px;
  }
  .padding-sm-b145 {
    padding-bottom: 145px;
  }
  .padding-sm-b150 {
    padding-bottom: 150px;
  }
  .padding-sm-b155 {
    padding-bottom: 155px;
  }
  .padding-sm-b160 {
    padding-bottom: 160px;
  }
  .padding-sm-b165 {
    padding-bottom: 165px;
  }
  .padding-sm-b170 {
    padding-bottom: 170px;
  }
  .padding-sm-b175 {
    padding-bottom: 175px;
  }
  .padding-sm-b180 {
    padding-bottom: 180px;
  }
  .padding-sm-b185 {
    padding-bottom: 185px;
  }
  .padding-sm-b190 {
    padding-bottom: 190px;
  }
  .padding-sm-b195 {
    padding-bottom: 195px;
  }
  .padding-sm-b200 {
    padding-bottom: 200px;
  }
  .padding-sm-b205 {
    padding-bottom: 205px;
  }
  .padding-sm-b210 {
    padding-bottom: 210px;
  }
  .padding-sm-b215 {
    padding-bottom: 215px;
  }
  .padding-sm-b220 {
    padding-bottom: 220px;
  }
  .padding-sm-b225 {
    padding-bottom: 225px;
  }
  .padding-sm-b230 {
    padding-bottom: 230px;
  }
  .padding-sm-b235 {
    padding-bottom: 235px;
  }
  .padding-sm-b240 {
    padding-bottom: 240px;
  }
  .padding-sm-b245 {
    padding-bottom: 245px;
  }
  .padding-sm-b250 {
    padding-bottom: 250px;
  }
  .padding-sm-b255 {
    padding-bottom: 255px;
  }
  .padding-sm-b260 {
    padding-bottom: 260px;
  }
  .padding-sm-b265 {
    padding-bottom: 265px;
  }
  .padding-sm-b270 {
    padding-bottom: 270px;
  }
  .padding-sm-b275 {
    padding-bottom: 275px;
  }
  .padding-sm-b280 {
    padding-bottom: 280px;
  }
  .padding-sm-b285 {
    padding-bottom: 285px;
  }
  .padding-sm-b290 {
    padding-bottom: 290px;
  }
  .padding-sm-b295 {
    padding-bottom: 295px;
  }
  .padding-sm-b300 {
    padding-bottom: 300px;
  }
}

@media screen and (max-width: 767px) {
  .margin-xs-t0 {
    margin-top: 0px;
  }
  .margin-xs-t5 {
    margin-top: 5px;
  }
  .margin-xs-t10 {
    margin-top: 10px;
  }
  .margin-xs-t15 {
    margin-top: 15px;
  }
  .margin-xs-t20 {
    margin-top: 20px;
  }
  .margin-xs-t25 {
    margin-top: 25px;
  }
  .margin-xs-t30 {
    margin-top: 30px;
  }
  .margin-xs-t35 {
    margin-top: 35px;
  }
  .margin-xs-t40 {
    margin-top: 40px;
  }
  .margin-xs-t45 {
    margin-top: 45px;
  }
  .margin-xs-t50 {
    margin-top: 50px;
  }
  .margin-xs-t55 {
    margin-top: 55px;
  }
  .margin-xs-t60 {
    margin-top: 60px;
  }
  .margin-xs-t65 {
    margin-top: 65px;
  }
  .margin-xs-t70 {
    margin-top: 70px;
  }
  .margin-xs-t75 {
    margin-top: 75px;
  }
  .margin-xs-t80 {
    margin-top: 80px;
  }
  .margin-xs-t85 {
    margin-top: 85px;
  }
  .margin-xs-t90 {
    margin-top: 90px;
  }
  .margin-xs-t95 {
    margin-top: 95px;
  }
  .margin-xs-t100 {
    margin-top: 100px;
  }
  .margin-xs-t105 {
    margin-top: 105px;
  }
  .margin-xs-t110 {
    margin-top: 110px;
  }
  .margin-xs-t115 {
    margin-top: 115px;
  }
  .margin-xs-t120 {
    margin-top: 120px;
  }
  .margin-xs-t125 {
    margin-top: 125px;
  }
  .margin-xs-t130 {
    margin-top: 130px;
  }
  .margin-xs-t135 {
    margin-top: 135px;
  }
  .margin-xs-t140 {
    margin-top: 140px;
  }
  .margin-xs-t145 {
    margin-top: 145px;
  }
  .margin-xs-t150 {
    margin-top: 150px;
  }
  .margin-xs-t155 {
    margin-top: 155px;
  }
  .margin-xs-t160 {
    margin-top: 160px;
  }
  .margin-xs-t165 {
    margin-top: 165px;
  }
  .margin-xs-t170 {
    margin-top: 170px;
  }
  .margin-xs-t175 {
    margin-top: 175px;
  }
  .margin-xs-t180 {
    margin-top: 180px;
  }
  .margin-xs-t185 {
    margin-top: 185px;
  }
  .margin-xs-t190 {
    margin-top: 190px;
  }
  .margin-xs-t195 {
    margin-top: 195px;
  }
  .margin-xs-t200 {
    margin-top: 200px;
  }
  .margin-xs-t205 {
    margin-top: 205px;
  }
  .margin-xs-t210 {
    margin-top: 210px;
  }
  .margin-xs-t215 {
    margin-top: 215px;
  }
  .margin-xs-t220 {
    margin-top: 220px;
  }
  .margin-xs-t225 {
    margin-top: 225px;
  }
  .margin-xs-t230 {
    margin-top: 230px;
  }
  .margin-xs-t235 {
    margin-top: 235px;
  }
  .margin-xs-t240 {
    margin-top: 240px;
  }
  .margin-xs-t245 {
    margin-top: 245px;
  }
  .margin-xs-t250 {
    margin-top: 250px;
  }
  .margin-xs-t255 {
    margin-top: 255px;
  }
  .margin-xs-t260 {
    margin-top: 260px;
  }
  .margin-xs-t265 {
    margin-top: 265px;
  }
  .margin-xs-t270 {
    margin-top: 270px;
  }
  .margin-xs-t275 {
    margin-top: 275px;
  }
  .margin-xs-t280 {
    margin-top: 280px;
  }
  .margin-xs-t285 {
    margin-top: 285px;
  }
  .margin-xs-t290 {
    margin-top: 290px;
  }
  .margin-xs-t295 {
    margin-top: 295px;
  }
  .margin-xs-t300 {
    margin-top: 300px;
  }
  .margin-xs-b0 {
    margin-bottom: 0px;
  }
  .margin-xs-b5 {
    margin-bottom: 5px;
  }
  .margin-xs-b10 {
    margin-bottom: 10px;
  }
  .margin-xs-b15 {
    margin-bottom: 15px;
  }
  .margin-xs-b20 {
    margin-bottom: 20px;
  }
  .margin-xs-b25 {
    margin-bottom: 25px;
  }
  .margin-xs-b30 {
    margin-bottom: 30px;
  }
  .margin-xs-b35 {
    margin-bottom: 35px;
  }
  .margin-xs-b40 {
    margin-bottom: 40px;
  }
  .margin-xs-b45 {
    margin-bottom: 45px;
  }
  .margin-xs-b50 {
    margin-bottom: 50px;
  }
  .margin-xs-b55 {
    margin-bottom: 55px;
  }
  .margin-xs-b60 {
    margin-bottom: 60px;
  }
  .margin-xs-b65 {
    margin-bottom: 65px;
  }
  .margin-xs-b70 {
    margin-bottom: 70px;
  }
  .margin-xs-b75 {
    margin-bottom: 75px;
  }
  .margin-xs-b80 {
    margin-bottom: 80px;
  }
  .margin-xs-b85 {
    margin-bottom: 85px;
  }
  .margin-xs-b90 {
    margin-bottom: 90px;
  }
  .margin-xs-b95 {
    margin-bottom: 95px;
  }
  .margin-xs-b100 {
    margin-bottom: 100px;
  }
  .margin-xs-b105 {
    margin-bottom: 105px;
  }
  .margin-xs-b110 {
    margin-bottom: 110px;
  }
  .margin-xs-b115 {
    margin-bottom: 115px;
  }
  .margin-xs-b120 {
    margin-bottom: 120px;
  }
  .margin-xs-b125 {
    margin-bottom: 125px;
  }
  .margin-xs-b130 {
    margin-bottom: 130px;
  }
  .margin-xs-b135 {
    margin-bottom: 135px;
  }
  .margin-xs-b140 {
    margin-bottom: 140px;
  }
  .margin-xs-b145 {
    margin-bottom: 145px;
  }
  .margin-xs-b150 {
    margin-bottom: 150px;
  }
  .margin-xs-b155 {
    margin-bottom: 155px;
  }
  .margin-xs-b160 {
    margin-bottom: 160px;
  }
  .margin-xs-b165 {
    margin-bottom: 165px;
  }
  .margin-xs-b170 {
    margin-bottom: 170px;
  }
  .margin-xs-b175 {
    margin-bottom: 175px;
  }
  .margin-xs-b180 {
    margin-bottom: 180px;
  }
  .margin-xs-b185 {
    margin-bottom: 185px;
  }
  .margin-xs-b190 {
    margin-bottom: 190px;
  }
  .margin-xs-b195 {
    margin-bottom: 195px;
  }
  .margin-xs-b200 {
    margin-bottom: 200px;
  }
  .margin-xs-b205 {
    margin-bottom: 205px;
  }
  .margin-xs-b210 {
    margin-bottom: 210px;
  }
  .margin-xs-b215 {
    margin-bottom: 215px;
  }
  .margin-xs-b220 {
    margin-bottom: 220px;
  }
  .margin-xs-b225 {
    margin-bottom: 225px;
  }
  .margin-xs-b230 {
    margin-bottom: 230px;
  }
  .margin-xs-b235 {
    margin-bottom: 235px;
  }
  .margin-xs-b240 {
    margin-bottom: 240px;
  }
  .margin-xs-b245 {
    margin-bottom: 245px;
  }
  .margin-xs-b250 {
    margin-bottom: 250px;
  }
  .margin-xs-b255 {
    margin-bottom: 255px;
  }
  .margin-xs-b260 {
    margin-bottom: 260px;
  }
  .margin-xs-b265 {
    margin-bottom: 265px;
  }
  .margin-xs-b270 {
    margin-bottom: 270px;
  }
  .margin-xs-b275 {
    margin-bottom: 275px;
  }
  .margin-xs-b280 {
    margin-bottom: 280px;
  }
  .margin-xs-b285 {
    margin-bottom: 285px;
  }
  .margin-xs-b290 {
    margin-bottom: 290px;
  }
  .margin-xs-b295 {
    margin-bottom: 295px;
  }
  .margin-xs-b300 {
    margin-bottom: 300px;
  }
  .padding-xs-t0 {
    padding-top: 0px;
  }
  .padding-xs-t5 {
    padding-top: 5px;
  }
  .padding-xs-t10 {
    padding-top: 10px;
  }
  .padding-xs-t15 {
    padding-top: 15px;
  }
  .padding-xs-t20 {
    padding-top: 20px;
  }
  .padding-xs-t25 {
    padding-top: 25px;
  }
  .padding-xs-t30 {
    padding-top: 30px;
  }
  .padding-xs-t35 {
    padding-top: 35px;
  }
  .padding-xs-t40 {
    padding-top: 40px;
  }
  .padding-xs-t45 {
    padding-top: 45px;
  }
  .padding-xs-t50 {
    padding-top: 50px;
  }
  .padding-xs-t55 {
    padding-top: 55px;
  }
  .padding-xs-t60 {
    padding-top: 60px;
  }
  .padding-xs-t65 {
    padding-top: 65px;
  }
  .padding-xs-t70 {
    padding-top: 70px;
  }
  .padding-xs-t75 {
    padding-top: 75px;
  }
  .padding-xs-t80 {
    padding-top: 80px;
  }
  .padding-xs-t85 {
    padding-top: 85px;
  }
  .padding-xs-t90 {
    padding-top: 90px;
  }
  .padding-xs-t95 {
    padding-top: 95px;
  }
  .padding-xs-t100 {
    padding-top: 100px;
  }
  .padding-xs-t105 {
    padding-top: 105px;
  }
  .padding-xs-t110 {
    padding-top: 110px;
  }
  .padding-xs-t115 {
    padding-top: 115px;
  }
  .padding-xs-t120 {
    padding-top: 120px;
  }
  .padding-xs-t125 {
    padding-top: 125px;
  }
  .padding-xs-t130 {
    padding-top: 130px;
  }
  .padding-xs-t135 {
    padding-top: 135px;
  }
  .padding-xs-t140 {
    padding-top: 140px;
  }
  .padding-xs-t145 {
    padding-top: 145px;
  }
  .padding-xs-t150 {
    padding-top: 150px;
  }
  .padding-xs-t155 {
    padding-top: 155px;
  }
  .padding-xs-t160 {
    padding-top: 160px;
  }
  .padding-xs-t165 {
    padding-top: 165px;
  }
  .padding-xs-t170 {
    padding-top: 170px;
  }
  .padding-xs-t175 {
    padding-top: 175px;
  }
  .padding-xs-t180 {
    padding-top: 180px;
  }
  .padding-xs-t185 {
    padding-top: 185px;
  }
  .padding-xs-t190 {
    padding-top: 190px;
  }
  .padding-xs-t195 {
    padding-top: 195px;
  }
  .padding-xs-t200 {
    padding-top: 200px;
  }
  .padding-xs-t205 {
    padding-top: 205px;
  }
  .padding-xs-t210 {
    padding-top: 210px;
  }
  .padding-xs-t215 {
    padding-top: 215px;
  }
  .padding-xs-t220 {
    padding-top: 220px;
  }
  .padding-xs-t225 {
    padding-top: 225px;
  }
  .padding-xs-t230 {
    padding-top: 230px;
  }
  .padding-xs-t235 {
    padding-top: 235px;
  }
  .padding-xs-t240 {
    padding-top: 240px;
  }
  .padding-xs-t245 {
    padding-top: 245px;
  }
  .padding-xs-t250 {
    padding-top: 250px;
  }
  .padding-xs-t255 {
    padding-top: 255px;
  }
  .padding-xs-t260 {
    padding-top: 260px;
  }
  .padding-xs-t265 {
    padding-top: 265px;
  }
  .padding-xs-t270 {
    padding-top: 270px;
  }
  .padding-xs-t275 {
    padding-top: 275px;
  }
  .padding-xs-t280 {
    padding-top: 280px;
  }
  .padding-xs-t285 {
    padding-top: 285px;
  }
  .padding-xs-t290 {
    padding-top: 290px;
  }
  .padding-xs-t295 {
    padding-top: 295px;
  }
  .padding-xs-t300 {
    padding-top: 300px;
  }
  .padding-xs-b0 {
    padding-bottom: 0px;
  }
  .padding-xs-b5 {
    padding-bottom: 5px;
  }
  .padding-xs-b10 {
    padding-bottom: 10px;
  }
  .padding-xs-b15 {
    padding-bottom: 15px;
  }
  .padding-xs-b20 {
    padding-bottom: 20px;
  }
  .padding-xs-b25 {
    padding-bottom: 25px;
  }
  .padding-xs-b30 {
    padding-bottom: 30px;
  }
  .padding-xs-b35 {
    padding-bottom: 35px;
  }
  .padding-xs-b40 {
    padding-bottom: 40px;
  }
  .padding-xs-b45 {
    padding-bottom: 45px;
  }
  .padding-xs-b50 {
    padding-bottom: 50px;
  }
  .padding-xs-b55 {
    padding-bottom: 55px;
  }
  .padding-xs-b60 {
    padding-bottom: 60px;
  }
  .padding-xs-b65 {
    padding-bottom: 65px;
  }
  .padding-xs-b70 {
    padding-bottom: 70px;
  }
  .padding-xs-b75 {
    padding-bottom: 75px;
  }
  .padding-xs-b80 {
    padding-bottom: 80px;
  }
  .padding-xs-b85 {
    padding-bottom: 85px;
  }
  .padding-xs-b90 {
    padding-bottom: 90px;
  }
  .padding-xs-b95 {
    padding-bottom: 95px;
  }
  .padding-xs-b100 {
    padding-bottom: 100px;
  }
  .padding-xs-b105 {
    padding-bottom: 105px;
  }
  .padding-xs-b110 {
    padding-bottom: 110px;
  }
  .padding-xs-b115 {
    padding-bottom: 115px;
  }
  .padding-xs-b120 {
    padding-bottom: 120px;
  }
  .padding-xs-b125 {
    padding-bottom: 125px;
  }
  .padding-xs-b130 {
    padding-bottom: 130px;
  }
  .padding-xs-b135 {
    padding-bottom: 135px;
  }
  .padding-xs-b140 {
    padding-bottom: 140px;
  }
  .padding-xs-b145 {
    padding-bottom: 145px;
  }
  .padding-xs-b150 {
    padding-bottom: 150px;
  }
  .padding-xs-b155 {
    padding-bottom: 155px;
  }
  .padding-xs-b160 {
    padding-bottom: 160px;
  }
  .padding-xs-b165 {
    padding-bottom: 165px;
  }
  .padding-xs-b170 {
    padding-bottom: 170px;
  }
  .padding-xs-b175 {
    padding-bottom: 175px;
  }
  .padding-xs-b180 {
    padding-bottom: 180px;
  }
  .padding-xs-b185 {
    padding-bottom: 185px;
  }
  .padding-xs-b190 {
    padding-bottom: 190px;
  }
  .padding-xs-b195 {
    padding-bottom: 195px;
  }
  .padding-xs-b200 {
    padding-bottom: 200px;
  }
  .padding-xs-b205 {
    padding-bottom: 205px;
  }
  .padding-xs-b210 {
    padding-bottom: 210px;
  }
  .padding-xs-b215 {
    padding-bottom: 215px;
  }
  .padding-xs-b220 {
    padding-bottom: 220px;
  }
  .padding-xs-b225 {
    padding-bottom: 225px;
  }
  .padding-xs-b230 {
    padding-bottom: 230px;
  }
  .padding-xs-b235 {
    padding-bottom: 235px;
  }
  .padding-xs-b240 {
    padding-bottom: 240px;
  }
  .padding-xs-b245 {
    padding-bottom: 245px;
  }
  .padding-xs-b250 {
    padding-bottom: 250px;
  }
  .padding-xs-b255 {
    padding-bottom: 255px;
  }
  .padding-xs-b260 {
    padding-bottom: 260px;
  }
  .padding-xs-b265 {
    padding-bottom: 265px;
  }
  .padding-xs-b270 {
    padding-bottom: 270px;
  }
  .padding-xs-b275 {
    padding-bottom: 275px;
  }
  .padding-xs-b280 {
    padding-bottom: 280px;
  }
  .padding-xs-b285 {
    padding-bottom: 285px;
  }
  .padding-xs-b290 {
    padding-bottom: 290px;
  }
  .padding-xs-b295 {
    padding-bottom: 295px;
  }
  .padding-xs-b300 {
    padding-bottom: 300px;
  }
}

/*--------------------------------------------------------------------------------------*/
