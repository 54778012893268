.f-feature {
    position: relative;
    z-index: 2;
    &:after {
        clear: both;
        content: '';
        display: table;
    }
    &__item {
        padding-left: 60px;
        width: 50%;
        float: left;
        position: relative;
        i {
            position: absolute;
            left: 0;
            top: 0;
            color: #ff5a00;
            font-size: 48px;
            font-weight: 400;
        }
        .f-head-h5 {
            line-height: 28px;
            letter-spacing: .5px;
            margin-bottom: 5px;
        }
        p {
            margin: 0;
        }
        @media only screen and (max-width: 991px) {
            padding-right: 15px;
        }
        @media only screen and (max-width: 600px) {
            width: 100%;
            margin-bottom: 50px;
            padding-right: 0;
            &:last-child {
                margin-bottom: 0;
            }
        }
    }
}
