.f-map {
    height: 500px;
    width: 100%;
    max-width: 1440px;
    margin-left: auto;
    margin-right: auto;
    position: relative;
    &:after {
        content: '';
        display: inline-block;
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        background-color: transparent;
        display: none;
    }
    @media only screen and (max-width: 992px) {
        &:after {
            display: block;
        }
    }
    @media only screen and (max-width: 767px) {
        height: 350px;
    }
}
