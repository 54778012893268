.f-partrers {
    li {
        display: inline-block;
        padding: 0 70px;
  
        a {
            transition: filter 1s ease;
            filter: saturate(0);
            &:hover {
                filter: saturate(1);
            }
        }
    }
    text-align: center;
    @media only screen and (max-width: 1300px) {
        li {
            padding: 0 50px;
        }
    }
    @media only screen and (max-width: 768px) {
        li {
            padding: 25px;
        }
    }
    @media only screen and (max-width: 480px) {
        li {
            width: 100%;
            text-align: center;
        }
    }
}
