html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
    margin: 0;
    padding: 0;
    border: 0;
    font-size: 100%;
    font: inherit;
    vertical-align: baseline;
}


/* HTML5 display-role reset for older browsers */

article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
    display: block;
}

ol,
ul {
    list-style: none;
}

blockquote,
q {
    quotes: none;
}

blockquote:before,
blockquote:after,
q:before,
q:after {
    content: '';
    content: none;
}

table {
    border-collapse: collapse;
    border-spacing: 0;
}

a {
    text-decoration: none;
}

a:hover,
a:active,
a:focus,
a:visited {
    text-decoration: none;
    color: #333;
}

a:active,
a:focus {
    outline: none;
}

html {
    box-sizing: border-box;
    overflow-x: hidden;
}

.text-left {
    text-align: left!important;
}

.text-right {
    text-align: right!important;
}

.text-center {
    text-align: center!important;
}


/* background image settings */

.js-bg-img_with-height,
.js-bg-img {
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat
}


/* background colors */

.bg-c-1 {
    background-color: #e6e6e6;
}

.bg-c-2 {
    background-color: #fafafa;
}

.bg-c-3 {
    background-color: #fffdfc;
}


/* color */

.c-1 {
    color: grey;
}

.c-2 {
    color: #ff5a00;
}

.f-header_clearfix {
    .f-head-h2 {
        float: left;
    }
    .f-view-more-btn {
        float: right;
    }
}

.f-header {
    position: relative;
    z-index: 2;
    .f-head-h2 {
        letter-spacing: 1px;
        font-weight: 900;
    }
    &:after {
        clear: both;
        content: '';
        display: table;
    }
    .f-view-more-btn {
        color: #595959;
    }
    @media only screen and (max-width:600px) {
        .f-head-h2 {
            float: none;
            margin-bottom: 0;
            &:after {
                display: none;
            }
        }
        .f-view-more-btn {
            float: none;
        }
    }
}

.f-header_after {
    .f-head-h2 {
        position: relative;
        display: inline-block;
        @media only screen and (max-width: 600px) {
            display: block;
        }
        &:after {
            content: '';
            display: inline-block;
            width: 120px;
            height: 2px;
            background-color: #e6e6e6;
            position: absolute;
            line-height: 100%;
            margin-left: 25px;
            padding-left: 30px;
            top: 0;
            margin: auto;
            left: calc(100% + 50px);
            bottom: 0;
        }
    }
}

.f-header_style2 {
    .f-view-more-btn {
        color: #fff;
    }
    .f-head-h2 {
        color: #fff;
        &:after {
            background-color: #ff5a00;
        }
    }
}

.f-header_style3 {
    .f-head-h2 {
        color: #333;
        font-weight: 900;
        font-size: 36px;
    }
    .f-head-h5 {
        color: #eb4800;
        font-family: 'Lato', sans-serif;
        font-size: 16px;
        font-weight: 900;
        text-transform: uppercase;
        letter-spacing: 2.5px;
        line-height: 36px;
    }
}

.border-b-dark {
    border-bottom: 1px solid #f2f2f2;
}

.f-max-w-block .container {
    @media only screen and (max-width: 1400px) {
        max-width: 90%;
    }
    @media only screen and (max-width: 600px) {
        max-width: 100%;
    }
}
