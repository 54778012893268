.f-blog-posts__item-bg {
    transition: opacity .3s ease;
    position: absolute;
    width: 1100px;
    height: 100%;
    right: 0;
    top: 0;
    opacity: 0;
    &:after {
        content: '';
        display: inline-block;
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        opacity: 0.8;
        background-color: #ff5a00;
    }
    @media only screen and (max-width: 1199px) {
        right: auto;
        left: -30px;
    }
    @media only screen and (max-width: 992px) {
        width: 100%;
        left: 0;
    }
}

.f-blog-posts__item-text {
    position: relative;
    z-index: 2;
   .f-head-h4{
        transition: color .3s ease;
        color: #333;
        letter-spacing: .3px;
    }
    @media only screen and (max-width: 992px) {
        padding-left: 15px;
        padding-right: 15px;
    }
    @media only screen and (max-width: 48px) {
        padding-left: 0;
        padding-right: 0;
    }
}

.f-blog-posts {
    .f-header {
        padding-left: 65px;
        @media only screen and (max-width: 1199px) {
            padding-left: 0;
        }
    }
    .f-blog-item__head {
        margin-top: 20px;
            margin-bottom: 0;
      
        li {
            color: #999;
            border-color: #999;
        }
    }
   .f-head-h5{
        color: #eb4800;
        font-family: 'Lato', sans-serif;
        font-size: 16px;
        text-transform: uppercase;
        letter-spacing: 2.5px;
        margin-top: 15px;
        margin-bottom: 20px;
    }
   .f-head-h2{
        color: #333;
        font-size: 36px;
        font-weight: 900;
        line-height: 1;
        @media only screen and (max-width: 767px) {
            font-size: 30px;
        }
    }
    p {
        letter-spacing: .5px;
    }
    @media only screen and (max-width: 1199px) {}
}

.f-blog-posts__item {
    padding: 50px 95px;
    position: relative;
    &.active_post,
    &:hover {
        .f-blog-posts__item-text {
           .f-head-h4{
                color: #fff;
            }
            .f-blog-item__head {
                li {
                    color: #fff;
                    border-color: #fff;
                }
            }
        }
        .f-blog-posts__item-bg {
            opacity: 1;
        }
    }
    @media only screen and (max-width: 1199px) {
        padding-left: 0px;
        padding-right: 0px;
    }
}
