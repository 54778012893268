.swiper-container-v {
    height: calc( 100vh - 105px);
    &:after {
        content: '';
        display: inline-block;
        width: 100%;
        height: 100%;
        background-color: rgba(255, 255, 255, .8);
        position: absolute;
        top: 0;
        left: 0;
        z-index: 2;
    }
    .swiper-wrapper {
        z-index: 3;
    }
    > .swiper-pagination-bullets {
        right: 80px;
        top: 50%;
        transform: translateY(-50%);
        @media only screen and (max-width: 768px) {
            right: 30px;
        }
        .swiper-pagination-bullet {
            margin: 20px 0;
        }
    }
    .swiper-pagination-bullet {
        background-color: transparent;
        color: grey;
    }
    .swiper-pagination-bullet-active {
        color: #ff5a00;
    }
    .f-soc-net {
        display: block;
        width: 100%;
        position: absolute;
        bottom: 55px;
        text-align: center;
        @media only screen and (max-width: 768px) {
            bottom: 15px;
        }
        @media only screen and (max-width: 380px) {
            display: none;
        }
        li {
            display: inline-block;
            a {
                font-family: 'Poppins', sans-serif;
                font-size: 16px;
                font-weight: 500;
                line-height: 36px;
                text-transform: uppercase;
                color: #595959;
                transition: color .3s ease;
                padding: 0 20px;
                letter-spacing: .5px;
                @media only screen and (max-width: 768px) {
                    font-size: 12px;
                    padding: 0 10px;
                }
                &:hover {
                    color: #ff5a00;
                }
            }
        }
    }
}

.swiper-container-h {
    height: calc( 100vh - 105px);
    .swiper-pagination-bullet {
        background-color: #595959;
    }
    .swiper-pagination-bullet-active {
        background-color: #ff5a00;
    }
    > .swiper-pagination-bullets .swiper-pagination-bullet {
        margin: 0 15px;
    }
}

.f-slide-wrap {
    text-align: center;
    position: relative;
    top: 50%;
    transform: translateY(-50%);
    @media only screen and (max-width: 1400px) {
        padding-left: 110px;
        padding-right: 110px;
    }
    @media only screen and (max-width: 768px) {
        padding-left: 80px;
        padding-right: 80px;
    }
    @media only screen and (max-width: 600px) {
        padding-left: 15px;
        padding-right: 50px;
        .swiper-button-prev,
        .swiper-button-next {
            top: auto;
            bottom: 0;
        }
        .f-testimonial__user {
            margin-top: 20px;
            div {
                float: none;
                text-align: center;
            }
        }
    }
    .f-blog-posts__item {
        @media only screen and (max-width: 600px) {
            padding: 15px 0;
            .f-blog-item__head {
                display: none;
            }
        }
    }
    .f-blog-posts {
        header {
            text-align: left;
        }
    }
    .f-blog-posts__item-text {
        text-align: left;
        .f-head-h4 {
            @media only screen and (max-width: 600px) {
                line-height: 1;
                font-size: 16px;
            }
        }
    }
    .f-blog-posts__item-bg {
        max-width: 900px;
    }
    .f-testimonial__text {
        @media only screen and (max-width: 600px) {
            margin-top: 15px;
            p {
                padding-left: 0;
                padding-right: 0;
            }
        }
        @media only screen and (max-width: 380px) {
            display: none;
        }
    }
    .f-testimonial__rating {
        @media only screen and (max-width: 380px) {
            display: none;
        }
    }
    .f-testimonial__item {
        .f-head-h2 {
            @media only screen and (max-width: 600px) {
                line-height: 1;
            }
        }
    }
}

.f-first-slide {
    max-width: 720px;
    margin-left: auto;
    margin-right: auto;
    .f-head-h2 {
        display: inline-block;
        font-weight: 900;
        letter-spacing: 1px;
        background-color: #fff;
        position: relative;
        z-index: 2;
        @media only screen and (max-width: 768px) {
            font-size: 24px;
        }
    }
    .f-head-h1 {
        color: #ff5a00;
        font-size: 144px;
        line-height: 1.3;
        @media only screen and (max-width: 768px) {
            font-size: 75px;
        }
        @media only screen and (max-width: 600px) {
            font-size: 40px;
        }
    }
    > div {
        @media only screen and (max-width: 600px) {
            text-align: center;
        }
    }
}

.f-second-slide__item {
    width: 50%;
    float: left;
}

.f-slide-wrap .f-second-slide {
    &:after {
        clear: both;
        content: '';
        position: static;
        display: table;
    }
}

.f-second-slide {
    max-width: 1170px;
    margin-left: auto;
    margin-right: auto;
    .f-about__img img{
        left: 70px;
    }
    &:after {
        clear: both;
        content: '';
        display: table;
    }
    .f-about__img {
        box-sizing: border-box;
        border: 25px solid #ff7b33;
        max-height: 540px;
        max-width: 370px;
        img {
            bottom: 140px;
            @media only screen and (max-width: 1200px) {
                left: 0;
                bottom: 50px;
            }
        }
    }
    .f-about__text {
        padding-left: 15px;
        @media only screen and (max-width: 1200px) {
            padding-left: 30px;
        }
        @media only screen and (max-width: 991px) {
            padding-left: 0;
        }
        @media only screen and (max-width: 768px) {
            text-align: center;
        }
        p {
            margin-top: 0;
            margin-bottom: 10px;
        }
    }
    .f-second-slide__item {
        text-align: left;
        width: 50%;
        float: left;
        @media only screen and (max-width: 991px) {
            width: 100%;
        }
    }
    .f-feature__item {
        box-sizing: border-box;
        @media only screen and (max-width: 1199px) {
            width: 100%;
            margin-bottom: 10px;
        }
        @media only screen and (max-width: 768px) {
            text-align: left;
        }
        @media only screen and (max-width: 600px) {
            padding-left: 0;
            i {
                display: none;
            }
        }
    }
}

.after-line {
    text-align: left;
    position: relative;
    .f-head-h2 {
        padding-right: 80px;
        @media only screen and (max-width: 600px) {
            padding-right: 0;
        }
    }
    &:after {
        top: 0;
        bottom: 0;
        right: 0;
        margin: auto;
        position: absolute;
        content: '';
        display: inline-block;
        width: 100%;
        vertical-align: middle;
        height: 2px;
        background-color: #f2f2f2;
        @media only screen and (max-width: 600px) {
            display: none;
        }
    }
}

.before-line {
    text-align: right;
    position: relative;
    .f-head-h2 {
        padding-left: 80px;
        @media only screen and (max-width: 600px) {
            padding-left: 0;
        }
    }
    &:before {
        top: 0;
        bottom: 0;
        margin: auto;
        left: 0;
        position: absolute;
        content: '';
        display: inline-block;
        width: 100%;
        vertical-align: middle;
        height: 2px;
        background-color: #f2f2f2;
        @media only screen and (max-width: 600px) {
            display: none;
        }
    }
}

.f-slide-h-wrap {
    max-width: 1200px;
    text-align: center;
    position: relative;
    top: 50%;
    transform: translateY(-50%);
    margin-left: auto;
    margin-right: auto;
    @media only screen and (max-width: 1400px) {
        padding-left: 110px;
        padding-right: 110px;
    }
    @media only screen and (max-width: 768px) {
        padding-left: 80px;
        padding-right: 80px;
    }
    @media only screen and (max-width: 600px) {
        padding-left: 15px;
        padding-right: 50px;
    }
    &:after {
        clear: both;
        content: '';
        display: table;
    }
}

.f-page-name {
    position: absolute;
    left: 35px;
    margin: auto;
    width: 100px;
    text-align: center;
    height: 100%;
    top: 50%;
    line-height: 100%;
    @media only screen and (max-width: 768px) {
        left: -20px;
    }
    @media only screen and (max-width: 600px) {
        display: none;
    }
    span {
        vertical-align: middle;
        text-transform: uppercase;
        display: inline-block;
        transform: rotate(-90deg);
        font-family: 'Lato', sans-serif;
        font-size: 16px;
        color: grey;
        letter-spacing: .5px;
        text-transform: uppercase;
    }
}

.f-portfolio-slider-img {
    width: 55%;
    @media only screen and (max-width: 768px) {
        width: 100%;
    }
    @media only screen and (max-width: 600px) {
        display: none;
    }
    > div {
        float: right;
    }
    .item-portfolio-height2 {
        width: calc( 58% - 30px);
        height: 560px;
        margin-left: 30px;
        @media only screen and (max-width: 992px) {
            height: 315px;
            width: calc( 58% - 15px);
            margin-left: 15px;
        }
    }
    .item-portfolio {
        width: 41%;
        height: 265px;
        margin-bottom: 30px;
        @media only screen and (max-width: 992px) {
            height: 150px;
            margin-bottom: 15px;
        }
    }
}

.f-portfolio-slider-info,
.f-portfolio-slider-img {
    float: left;
}

.f-portfolio-slider-info {
    padding-left: 110px;
     width: 45%;
    box-sizing: border-box;
    text-align: left;
    @media only screen and (max-width: 1200px) {
        padding-left: 30px;
    }
    @media only screen and (max-width: 768px) {
        padding-left: 0;
        width: 100%;
        text-align: center;
    }
    .f-head-h6 {
        color: #ff7400;
        font-family: 'Lato', sans-serif;
        font-size: 14px;
        text-transform: none;
        line-height: 30px;
        letter-spacing: 1px;
    }
   .f-head-h2 {
        color: #333;
        font-size: 31px;
        letter-spacing: 1px;
        margin-top: 10px;
        margin-bottom: 10px;
    }
    p {
        letter-spacing: .5px;
    }
    a.f-view-more-btn,
    a {
        color: gray;
        display: inline-block;
        margin-top: 40px;
    }
}
