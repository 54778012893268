    .play-btn {
        width: 96px;
        height: 96px;
        background-color: #ff5a00;
        border-radius: 50%;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        margin: auto;
        z-index: 2;
        cursor: pointer;
        &:after {
            content: '';
            display: inline-block;
            width: 0;
            height: 0;
            border-style: solid;
            position: absolute;
            top: 0;
            bottom: 0;
            left: 38px;
            margin: auto;
            border-width: 15px 0 15px 25px;
            border-color: transparent transparent transparent #fff;
        }
    }
    
    .f-video-wrap {
        position: relative;
        height: 550px;
        .f-video-overlay {
            height: 100%;
            position: relative;
            &:before {
                position: absolute;
                content: '';
                display: inline-block;
                width: 100%;
                height: 100%;
                background-color: rgba(51, 51, 51, .7);
                top: 0;
                left: 0;
            }
        }
        .video_popup {
            position: absolute;
            top: 0;
            height: 100%;
            width: 100%;
            iframe {
                width: 100%;
                height: 100%;
            }
        }
    }
