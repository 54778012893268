.f-service-img {
    margin-bottom: 80px;
    &:after {
        content: '';
        display: table;
        clear: both;
    }
    img {
        vertical-align: middle;
    }
    @media only screen and (max-width: 991px) {
        margin-bottom: 50px;
    }
}

.f-service-img_left {
    padding-right: 100px;
    @media only screen and (max-width: 1200px) {
        padding-right: 30px;
    }
    @media only screen and (max-width: 991px) {
        padding-right: 0;
        text-align: center;
    }
    img {
        float: right;
        @media only screen and (max-width: 991px) {
            float: none;
            max-width: 100%;
        }
    }
}

.f-service-img_right {
    padding-left: 100px;
    @media only screen and (max-width: 1200px) {
        padding-left: 30px;
    }
    @media only screen and (max-width: 991px) {
        padding-left: 0;
        text-align: center;
    }
    img {
        float: left;
        @media only screen and (max-width: 991px) {
            float: none;
            max-width: 100%;
        }
    }
}

.f-service-content-wrap {
    height: 480px;
    margin-bottom: 80px;
         @media only screen and (max-width: 991px) {
           height: auto;
           margin-bottom: 50px;
        }
}

.f-service-content {
    position: relative;
    padding-bottom: 5px;
    top: 50%;
    transform: translateY(-50%);
         @media only screen and (max-width: 991px) {
          top: 0;
           transform: translateY(0);
        }
         @media only screen and (max-width: 768px) {
        text-align: center;
        }
    .f-service-content__number {
        color: #ff7400;
        font-family: 'Poppins', sans-serif;
        font-size: 94px;
        line-height: 1;
        display: inline-block;
        margin-bottom: 10px;
        font-weight: 500;
        text-transform: uppercase;
        @media only screen and (max-width: 768px) {
            font-size: 40px;
        }
    }
    .f-head-h3 {
        color: #333;
        font-size: 31px;
        font-weight: 600;
        line-height: 1;
        letter-spacing: 1px;
        @media only screen and (max-width: 768px) {
font-size: 24px;
        }
    }
    p {
        margin-top: 25px;
        letter-spacing: .5px;
    }
    a.f-view-more-btn {
        margin-top: 55px;
        display: inline-block;
        color: #595959;
        &:hover {
            color: #ff7400;
        }
    }
}
