.f-default-block_style2 {
    .f-subscribe {
        margin-top: 0;
        padding-left: 30px;
    }
    .f-default-block-header {
        text-align: right;
        padding-right: 0;
        max-width: 100%;
        @media only screen and (max-width: 991px) {
            text-align: center;
        }
       .f-head-h1{
            color: white;
            font-size: 180px;
            font-weight: 500;
            line-height: 220px;
            letter-spacing: 10px;
            @media only screen and (max-width: 768px) {
                font-size: 100px;
                line-height: 1;
            }
        }
    }
    .f-subscribe__info {
       .f-head-h2{
            color: white;
            font-weight: 500;
            letter-spacing: .5px;
            line-height: 60px;
            text-transform: uppercase;
            @media only screen and (max-width: 768px) {
                font-size: 20px;
                line-height: 1;
            }
        }
        p {
            font-size: 16px;
            font-weight: 400;
            line-height: 25px;
            letter-spacing: .5px;
            color: #ccc;
        }
        a {
            color: #ff5a00;
            text-decoration: underline;
        }
        @media only screen and (max-width: 768px) {
            text-align: center;
        }
    }
}
