.f-portfolio-slider {
    max-width: 1760px;
    margin-left: auto;
    margin-right: auto;
    position: relative;
    &:after {
        position: absolute;
        top: 0;
        left: 0;
        content: '';
        display: inline-block;
        width: 100%;
        height: 100%;
        opacity: 0.9;
        background-color: #333;
    }
}

.f-portfolio-slider_style2 {
    max-width: 100%;
    .swiper-slide {
        margin: 0 15px;
        box-sizing: border-box;
        width: 50%;
        @media only screen and (max-width: 991px) {
            width: 100%;
        }
    }
    .swiper-slide:nth-child(2n) {
        width: 25%;
        @media only screen and (max-width: 991px) {
            width: 50%;
        }
        @media only screen and (max-width: 768px) {
            width: 100%;
        }
    }
    .swiper-slide:nth-child(3n) {
        width: 25%;
        @media only screen and (max-width: 991px) {
            width: 50%;
        }
        @media only screen and (max-width: 768px) {
            width: 100%;
        }
    }
    .swiper-pagination {
        bottom: 0;
    }
    .swiper-pagination-bullets .swiper-pagination-bullet {
        margin: 0 15px;
    }
}
