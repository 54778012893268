.f-blog1-wrap {
    max-width: 1170px;
    margin-left: auto;
    margin-right: auto;
    padding-left: 100px;
    padding-right: 100px;
    @media only screen and (max-width: 1200px) {
        padding-left: 15px;
        padding-right: 15px;
    }
}

.f-blog-item {
    margin-bottom: 80px;
}

.f-blog-item-content {
    height: 370px;
    .f-audio-wrap,
    .f-video-wrap {
        height: 100%;
    }
    .swiper-pagination-fraction,
    .swiper-pagination-custom,
    .swiper-container-horizontal > .swiper-pagination-bullets {
        bottom: 35px;
    }
    .swiper-container-horizontal > .swiper-pagination-bullets .swiper-pagination-bullet {
        margin: 0 15px;
    }
}

.f-blog-item-head {
    margin-top: 25px;
    text-align: center;
    max-width: 730px;
    margin-left: auto;
    margin-right: auto;
    .f-head-h4 {
        line-height: 32px;
        letter-spacing: .8px;
        margin-bottom: 10px;
        display: block;
        font-size: 20px;
        color: #333;
        font-weight: 900;
        transition: color .3s ease;
        &:hover {
            color: #ff5a00;
        }
    }
}

.f-content-info {
    display: inline-block;
    margin-left: auto;
    margin-right: auto;
    // text-align: center;
    li {
        display: inline-block;
        a,
        span {
            color: #999;
            padding: 0 10px;
            letter-spacing: .5px;
            border-left: 1px solid #999;
            font-size: 14px;
            font-weight: 400;
            line-height: 14px;
            transition: color .3s ease;
        }
        a:hover {
            color: #ff5a00;
        }
        a {
            border: none;
        }
        &:first-child span {
            border: none;
            padding-left: 0;
        }
    }
}

.f-blog-style2 {
    &:after {
        clear: both;
        display: table;
        content: '';
    }
    .f-blog-item {
        width: calc( 50% - 15px);
        margin-right: 30px;
        margin-bottom: 30px;
        float: left;
        &:nth-child(2n) {
            margin-right: 0;
        }
        @media only screen and (max-width: 768px) {
            width: calc( 100% - 30px);
            margin-right: 15px;
            margin-left: 15px;
        }
    }
    .f-blog-item,
    .f-blog-item-head {
        text-align: left;
    }
    .f-blog-item-content {
        height: 260px;
    }
    .f-blog-item-head {
        li {
            &:first-child a {
                padding-left: 0;
            }
        }
        a {
            transition: color .5s ease;
            &:hover {
                color: #ff5a00;
            }
        }
    }
    .f-blog-item-text {
        margin-top: 20px;
        margin-bottom: 20px;
        p {
            letter-spacing: .6px;
        }
    }
}

.f-blog-style3 {
    .f-blog-item {
        margin-bottom: 30px;
        @media only screen and (max-width: 768px) {
            width: calc( 100% - 30px);
            margin-right: 15px;
            margin-left: 15px;
        }
    }
    .f-blog-item,
    .f-blog-item-head {
        text-align: left;
    }
    .f-content-info {
        text-align: left;
    }
    .f-blog-item-content {
        height: 500px;
        @media only screen and (max-width: 768px) {
            height: 370px;
        }
    }
    .f-blog-item-head {
        li {
            &:first-child a {
                padding-left: 0;
            }
        }
        a {
            transition: color .5s ease;
            &:hover {
                color: #ff5a00;
            }
        }
    }
    .f-blog-item-text {
        margin-top: 20px;
        margin-bottom: 20px;
        height: 135px;
        overflow: hidden;
        p {
            letter-spacing: .6px;
        }
    }
}

.f-blog-style5 {
    .f-blog-item__img {
        height: 250px;
    }
    .f-blog-item__head {
        margin-top: 20px;
        .f-head-h6 {
            text-transform: none;
            letter-spacing: .5px;
        }
    }
}

// f-blog-items_left-style
.f-blog-items_left-style {
    text-align: left;
    // max-width: 720px;
    .f-blog-item-head {
        text-align: left;
        margin-top: 30px;
        .f-head-h4{
            letter-spacing: 1.8px;
        }

    }
    .f-content-info , .f-content-info {
        li{
            &:first-child{
                a,span{
                    padding-left: 0;
                }
            }
        }
    }
    .f-blog-item{
        margin-bottom: 60px;
    }

}
.f-blog-items_right-padd{
    padding-right: 50px;
    @media only screen and (max-width: 991px) {
        padding-right: 0;
    }
}

///////////////////
.f-blog-item__img {
    height: 260px;
}

.f-blog-item__head {
    margin-top: 30px;
    .f-head-h6 {
        color: #595959;
        font-size: 20px;
        text-transform: none;
        font-weight: 600;
        line-height: 32px;
        transition: color .3s ease;
        &:hover {
            color: #ff5a00;
        }
    }
    ul {
        margin-top: 5px;
        margin-bottom: 20px;
    }
    li {
        color: #999;
        font-size: 14px;
        display: inline-block;
        font-weight: 400;
        letter-spacing: .5px;
        padding: 0 10px;
        border-left: 1px solid #999;
        &:first-child {
            padding-left: 0;
            border: none;
        }
    }
    p {
        color: grey;
        letter-spacing: .6px;
        font-size: 14px;
        font-weight: 400;
        line-height: 25px;
    }
}


/* Medium Devices, Desktops */

@media only screen and (max-width: 991px) {
    .f-blog-item {
        margin-bottom: 50px;
    }
}
