.f-footer__info-item {
    text-align: center;
    border: 1px solid #e6e6e6;
    padding: 35px 0 30px;
    i {
        color: #ff5a00;
        font-size: 40px;
        line-height: 40px;
        vertical-align: top;
    }
    .f-head-h5 {
        color: #595959;
        font-size: 16px;
        line-height: 20px;
        transition: color .3s ease;
    }
    span {
        color: grey;
        font-size: 14px;
        line-height: 28px;
        letter-spacing: .6px;
        transition: color .3s ease;
    }
    .f-footer__info-text {
        display: inline-block;
        padding-left: 15px;
        text-align: left;
    }
    i,
    a {
        display: inline-block;
    }
    &:hover {
        span {
            color: #ff5a00;
        }
    }
    @media only screen and (max-width: 1200px) {
        padding: 15px;
    }
    @media only screen and (max-width: 991px) {
        border: none;
    }
}

.f-footer-content {
    text-align: center;
    p {
        margin-top: 20px;
        letter-spacing: .55px;
        max-width: 635px;
        line-height: 28px;
        margin-left: auto;
        margin-right: auto;
    }
}

.f-footer-social {
    display: block;
    text-align: center;
    margin-left: auto;
    margin-right: auto;
    li {
        display: inline-block;
    }
    a {
        padding: 0 15px;
        transition: color .3s ease;
        font-size: 23px;
        color: #a6a6a6;
        &:hover {
            color: #ff5a00;
        }
        @media only screen and (max-width: 767px) {
            color: #ff5a00;
            &:hover {
                opacity: .8;
            }
        }
    }
}

.f-footer-copyright {
    position: relative;
    display: block;
    padding: 30px;
    background: rgba(51, 51, 51, .9);
    text-align: center;
    &:after {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        margin: auto;
        content: '';
        display: inline-block;
        max-width: 1760px;
        width: 100%;
        height: 2px;
        background-color: #ff5a00;
    }
    span {
        color: #ccc;
        font-size: 14px;
        line-height: 28px;
        letter-spacing: .5px;
    }
}

.f-footer-copyright_style3 {
    padding-top: 45px;
    padding-bottom: 45px;
    &:after {
        display: none;
    }
}

.f-footer-copyright_style4 {
    text-align: center;
    padding: 50px 0;
    &:after {
        display: table;
        clear: both;
        content: '';
        width: 0;
        height: 0;
    }
    .nav-menu__social {
        float: right;
        @media only screen and (max-width: 991px) {
            float: none;
            margin-left: auto;
            margin-right: auto;
            text-align: center;
        }
    }
    .full-logo {
        float: left;
        @media only screen and (max-width: 991px) {
            margin-bottom: 10px;
            float: none;
            margin-left: auto;
            margin-right: auto;
            text-align: center;
        }
    }
    span {
        line-height: 40px;
    }
}

.f-footer-copyright_style2 {
    background-color: white;
    border-top: 2px solid #ff5a00;
    span {
        color: grey;
    }
    &:after {
        display: none;
    }
}


/* footer style 2 */

.f-footer2 {
    min-height: 520px;
    max-width: 1760px;
    background-color: rgba(51, 51, 51, .9);
    margin-left: auto;
    margin-right: auto;
    .f-head-h5 {
        color: white;
        letter-spacing: 1.5px;
        text-transform: uppercase;
    }
    .f-info-widget .full-logo{
        margin-bottom: 30px;
    }
    .f-instagram-widget{
        // margin-bottom: 45px;
    }
}


/* .f-tweeter-widget */

.f-tweeter-item {
    position: relative;
    padding-left: 55px;
    border-bottom: 1px solid #595959;
    padding-bottom: 15px;
    padding-top: 15px;
    &:last-child {
        border: none;
    }
    i {
        position: absolute;
        left: 0;
        top: 25px;
        display: inline-block;
        width: 30px;
        height: 30px;
        background-color: #6e6e6e;
        text-align: center;
        line-height: 30px;
        color: #fff;
    }
    p,
    span {
        font-family: Lato;
        font-size: 14px;
        font-weight: 400;
        line-height: 28px;
        color: #ccc;
        letter-spacing: .5px;
    }
    a {
        color: #ff5a00;
    }
}


/* f-post-widget */
.f-post-widget{

li {
    padding-top: 15px;padding-bottom: 20px;
    border-bottom: 1px solid #595959;
    &:last-child {
        border: none;
    }
    a,
    p {
        display: block;
        color: #ccc;
        width: 100%;
        font-size: 14px;
        line-height: 34px;
        letter-spacing: .6px;
        transition: color .1s ease;
        &:hover{
            color:#ff5a00;
        }
    }
    ul {
        margin-top: 5px;
        margin-bottom: 20px;
    }
    li {}
    span {
        color: #999;
        font-size: 12px;
        display: inline-block;
        font-weight: 400;
        letter-spacing: 0.5px;
        padding: 0 10px;
        border-left: 1px solid #999;
        &:nth-child(2),
        &:first-child {
            padding-left: 0;
            border: none;
        }
    }
    .f-blog-item__head {
        margin-top: 0;
    }
}
}



/* f-instagram-widget__wrap */

.f-instagram-widget__wrap {
    margin-top: 25px;
    &:after {
        content: '';
        display: table;
        clear: both;
    }
    a {
        float: left;
        max-width:calc( 25% - 4px);
        max-height:calc( 25% - 4px);
        text-align: center;
        display: inline-block;

        margin: 0 10px 8px 0;
        img {
            max-width: 100%;
        }
        &:nth-child(4n) {
            margin-right: 0;
        }
        @media only screen and (max-width: 1200px) {
             max-width:calc( 25% - 4px);
            height: auto;
            margin-right: 5px;
            margin-bottom: 1px;
            &:nth-child(4n) {
                margin-right: 0;
            }
        }
        @media only screen and (max-width: 991px) {
            margin-bottom: 4px;
           
        }
    }
    @media only screen and (max-width: 1200px) {
        max-width: 380px;
    }
}


/* f-footer-copyright2 */

.f-footer-copyright2 {
    padding: 30px 15px;
    border-top: 2px solid #ff5a00;
    text-align: center;
    span {
        color: #ccc;
        font-size: 14px;
        line-height: 28px;
        letter-spacing: .5px;
    }
}

.f-tweeter-widget,
.f-post-widget {
    @media only screen and (max-width: 991px) {
        margin-bottom: 50px;
    }
}

.widget_pages,
.f-menu-widget {
    margin-bottom: 20px;
    ul {
        margin-top: 15px;
        a {
            color: #ccc;
            font-size: 14px;
            letter-spacing: .5px;
            line-height: 35px;
            transition: color .3s ease;
            &:hover {
                color: #ff5a00;
            }
        }
    }
}

.f-info-widget {
    margin-bottom: 10px;
    .full-logo {
        margin-top: 5px;
        margin-bottom: 20px;
    }
    p {
        color: #ccc;
        line-height: 28px;
        max-width: 270px;
        letter-spacing: .5px;
    }
    .nav-menu__social {
        margin-top: 50px;
        display: block;
        text-align: left;
        @media only screen and (max-width: 992px) {
            margin-bottom: 20px;
            margin-top: 20px;
        }
    }
}

.f-footer2_style2 {
    .f-info-widget p {
        max-width: 100%;
    }
    .nav-menu__social {
        text-align: center;
    }
    .f-info-widget,
    h5 {
        text-align: center;
    }
    .f-instagram-widget__wrap {
        @media only screen and (max-width: 991px) {
            margin-left: auto;
            margin-right: auto;
        }
    }
}


