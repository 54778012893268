.f-default-block {
    position: relative;
    height: 100vh;
    &:after {
        position: absolute;
        content: '';
        display: inline-block;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        background-color: rgba(51, 51, 51, .9);
    }
}

.f-default-block__content-wrap {
    position: relative;
    z-index: 2;
    top: 50%;
    transform: translateY(-50%);
    text-align: right;
    header {
        text-align: right;
        display: inline-block;
        max-width: 510px;
        padding-right: 190px;
        @media only screen and (max-width: 991px) {
            max-width: 100%;
            padding-right: 0;
            text-align: center;
            display: block;
            margin-bottom: 30px;
        }
        @media only screen and (max-width:991px) {
            margin-bottom: 0;
        }
    }
    .f-head-h1 {
        color: white;
        line-height: 67px;
    }
    .f-head-h5 {
        color: #ccc;
        line-height: 67px;
        font-weight: 100;
        letter-spacing: .5px;
        @media only screen and (max-width: 767px) {
            line-height: 1;
        }
    }
    .f-subscribe {
        text-align: left;
        margin-top: 65px;
        padding-left: 10px;
        @media only screen and (max-width: 480px) {
            margin-top: 10px;
            text-align: center;
            .f-subscribe__info {
                margin-bottom: 10px;
            }
        }
        &:before {
            display: none;
        }
    }
    .f-subscribe__form {
        padding-left: 0;
        margin-top: 20px;
    }
}

.f-count-down-day {
    color: white;
    font-family: 'Poppins', sans-serif;
    font-size: 264px;
    font-weight: 500;
    line-height: 1;
    text-align: left;
    span {
        color: #ccc;
        font-size: 60px;
        letter-spacing: 4px;
        padding-left: 10px;
        font-weight: 400;
        line-height: 86px;
        font-family: 'Poppins', sans-serif;
        vertical-align: top;
    }
    @media only screen and (max-width:991px) {
        font-size: 100px;
        text-align: center;
    }
}

.f-count-down-hour-wrap {
    text-align: left;
    color: white;
    margin-top: 25px;
    font-family: 'Poppins', sans-serif;
    font-size: 48px;
    font-weight: 500;
    padding-left: 5px;
    @media only screen and (max-width: 991px) {
        font-size: 20px;
        line-height: 1;
        text-align: center;
    }
    @media only screen and (max-width:768px) {
        margin-top: 0;
    }
    &:after {
        content: '';
        display: table;
        clear: both;
    }
    > div {
        float: left;
        width: 33%;
        display: inline-block;
        @media only screen and (max-width: 768px) {
            display: block;
            font-size: 14px;
        }
    }
    span {
        color: #ccc;
        font-size: 16px;
        font-family: 'Poppins', sans-serif;
        font-weight: 400;
    }
}
