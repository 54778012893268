.f-widgets,
.f-posts-widget,
.f-instagram-widget,
.widget_categories {
    margin-bottom: 60px;
    .f-head-h4 {
        margin-bottom: 25px;
        position: relative;
        line-height: 24px;
        letter-spacing: .5px;
        &:after {
            content: '';
            position: absolute;
            width: 160px;
            left: 0;
            bottom: -15px;
            height: 2px;
            background-color: #ff5a00;
        }
    }
}

.f-widgets {
    .f-instagram-widget__wrap {
        margin-top: 60px;
        a {
            margin: 0 7px 7px 0;
            float: left;
        }
    }
}

.widget_categories {
    li {
        padding-top: 15px;
        padding-bottom: 15px;
        border-bottom: 1px solid #f5f5f5;
        &:last-child {
            border: none;
        }
        &:after {
            clear: both;
            content: '';
            display: table;
        }
        &:hover {
            a,
            span {
                color: #ff5a00;
            }
            color:#ff5a00;
        }
    }
    li,
    span,
    a {
        color: grey;
        font-size: 14px;
        line-height: 20px;
        letter-spacing: .6px;
        transition: color .3s ease;
    }
    span {
        float: right;
        &:after {
            content: ')';
            display: inline-block;
            font-family: 'Lato', sans-serif;
            padding-left: 3px;
        }
        &:before {
            padding-right: 3px;
            content: '(';
            display: inline-block;
            font-family: 'Lato', sans-serif;
        }
    }
}

.f-post-item__info {
    padding-left: 15px;
    width: 70%;
    margin-bottom: 30px;
    .f-head-h5,
    a {
        display: block;
        color: #333330;
        font-family: 'Poppins', sans-serif;
        font-size: 16px;
        font-weight: 600;
        line-height: 25px;
        letter-spacing: .3px;
        vertical-align: top;
        transition: color .3s ease;
        &:hover {
            color: #ff5a00;
        }
    }
    span {
        color: #999;
        font-family: 'Lato', sans-serif;
        font-size: 12px;
        font-weight: 400;
        line-height: 27px;
    }
}

.f-post-item {
    margin-bottom: 25px;
    @media only screen and (max-width: 992px) {
        max-width: 380px;
    }
    &:after {
        clear: both;
        content: '';
        display: table;
    }
}

.f-post-item__img {
    width: 30%;
    img {
        max-width: 100%;
    }
}

.f-post-item__info,
.f-post-item__img {
    float: left;
    display: inline-block;
}

.f-post-item_big {
    margin-bottom: 0;
    .f-post-item__info,
    .f-post-item__img {
        float: none;
        width: 100%;
        padding-left: 0;
    }
}
