.f-isotope-wrap {
    max-width: 1440px;
    margin-left: auto;
    margin-right: auto;
}

.grid-sizer,
.grid-item {
    width: 16.66666%;
    box-sizing: border-box;
    height: 240px;
    @media only screen and (max-width: 768px) {
        width: 33.333%;
    }
    @media only screen and (max-width: 600px) {
        width: 100%;
    }
}


/* 2 columns wide */

.grid-item_width2 {
    width: 33.33333%;
    @media only screen and (max-width: 768px) {
        width: 66.666%;
    }
    @media only screen and (max-width: 600px) {
        width: 100%;
        margin: 0;
    }
}

.grid-item_height2 {
    height: 480px;
}

.filter-button-group {
    padding: 37px 80px;
    text-align: left;
    @media only screen and (max-width: 767px) {
        padding: 15px;
    }
    a {
        float: right;
        vertical-align: middle;
        color: white;
        font-family: 'Poppins', sans-serif;
        font-size: 14px;
        font-weight: 500;
        line-height: 50px;
        margin-top: 5px;
        @media only screen and (max-width: 860px) {
            display: block;
            float: none;
            max-width: 360px;
            margin-left: auto;
            margin-right: auto;
        }
    }
    button {
        display: inline-block;
        font-family: 'Lato', sans-serif;
        font-size: 14px;
        font-weight: 700;
        line-height: 55px;
        text-transform: uppercase;
        letter-spacing: .7px;
        padding-right: 60px;
        word-spacing: 5px;
        padding-left: 0;
        color: grey;
        background-color: transparent;
        border: none;
        outline: none;
        transition: color .3s ease;
        &:hover,
        &:focus {
            background-color: transparent;
            border: none;
            outline: none;
            color: #ff5a00;
        }
        &.active {
            color: #ff5a00;
        }
        @media only screen and (max-width: 991px) {
            padding-right: 20px;
        }
    }
    @media only screen and (max-width: 860px) {
        text-align: center;
        button {
            padding-left: 20px;
            padding-right: 20px;
        }
    }
}

// isotope style2
.f-isotope-wrap_style2 {
    text-align: center;
    max-width: 1800px;
    margin-left: auto;
    margin-right: auto;
    .grid-sizer,
    .grid-item {
        position: relative;
        width: 23%;
        box-sizing: border-box;
        height: 410px;
        margin: 1%;
        @media only screen and (max-width: 1500px) {
            width: 31%;
        }
        @media only screen and (max-width: 1500px) {
            width: 48%;
        }
        .f-isotope__content {
            padding: 45px 50px;
            position: absolute;
            top: 20px;
            text-align: left;
            bottom: 20px;
            right: 20px;
            left: 20px;
            background-color: rgba(255, 90, 0, .6);
            opacity: 0;
            transition: opacity .3s ease;
            p {
                position: relative;
                width: 100%;
                transition: all .2s ease;
                color: white;
                font-weight: 700;
                line-height: 24px;
                opacity: 0;
                letter-spacing: .5px;
                margin-left: -100px;
            }
            .f-head-h5 {
                position: relative;
                width: 100%;
                transition: all .5s ease;
                color: white;
                font-weight: 600;
                line-height: 32px;
                letter-spacing: 1px;
                opacity: 0;
                margin-left: 100px;
            }
            i {
                opacity: 0;
                transition: all .6s ease;
                color: white;
                display: inline-block;
                font-size: 24px;
                width: 67px;
                height: 67px;
                line-height: 67px;
                background-color: #ff5a00;
                font-weight: 400;
                position: absolute;
                bottom: 50px;
                right: 50px;
                text-align: center;
            }
            &:hover {
                opacity: 1;
                p,
                .f-head-h5,
                i {
                    opacity: 1;
                    margin: 0;
                }
                i {}
            }
        }
        @media only screen and (max-width: 768px) {
            width: 48%;
        }
        @media only screen and (max-width: 600px) {
            width: 100%;
            margin: 0;
        }
    }
}

.button-group {
    text-align: center;
    padding: 15px 0;
    background-color: transparent;
    button {
        padding: 0 23px;
        line-height: 1;
        letter-spacing: .5px;
        word-spacing: 1px;
 
        @media only screen and (max-width: 992px) {
            padding-top: 30px;
        }
    }
}

// isotope style3
.f-isotope-wrap_style3 {
    text-align: center;
    max-width: 1440px;
    margin-left: auto;
    margin-right: auto;
    .grid-sizer,
    .grid-item {
        position: relative;
        width: 100%;
        box-sizing: border-box;
        height: 600px;
        @media only screen and (max-width: 768px) {
            height: 300px;
        }
        &:after {
            content: '';
            display: inline-block;
            position: absolute;
            top: 20px;
            bottom: 20px;
            right: 20px;
            left: 20px;
            background-color: rgba(255, 90, 0, .6);
            transition: opacity .3s ease;
            opacity: 0;
        }
        &:hover {
            &:after {
                opacity: 1;
            }
        }
        .f-isotope__content-body {
            text-align: left;
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            display: inline-block;
            min-width: 500px;
            margin-left: -60px;
            @media only screen and (max-width: 600px) {
                width: 100%;
                min-width: 1px;
                margin-left: 0;
                left: 0;
                text-align: center;
            }
        }
        .f-isotope__content {
            position: absolute;
            z-index: 3;
            display: block;
            text-align: center;
            opacity: 1;
            width: 100%;
            height: 100%;
            p {
                position: relative;
                width: 100%;
                transition: all .2s ease;
                color: white;
                font-weight: 700;
                line-height: 24px;
                opacity: 0;
                letter-spacing: .5px;
                margin-left: -100px;
            }
            .f-head-h5 {
                position: relative;
                width: 100%;
                transition: all .5s ease;
                color: white;
                font-weight: 600;
                line-height: 32px;
                letter-spacing: 1px;
                opacity: 0;
                margin-left: 100px;
                @media only screen and (max-width: 600px) {
                    margin-bottom: 20px;
                }
            }
            i {
                opacity: 0;
                transition: all .6s ease;
                color: white;
                display: inline-block;
                font-size: 24px;
                width: 67px;
                height: 67px;
                line-height: 67px;
                background-color: #ff5a00;
                font-weight: 400;
                bottom: auto;
                right: auto;
                text-align: center;
                position: absolute;
                left: -95px;
                top: 0;
                @media only screen and (max-width: 600px) {
                    position: static;
                }
            }
            &:hover {
                opacity: 1;
                p,
                .f-head-h5,
                i {
                    opacity: 1;
                    margin: 0;
                }
            }
        }
    }
}

// isotope style4
.f-isotope-wrap_style4 {
    max-width: 100%;
    width: 100%;
    .f-isotope__content {
        position: absolute;
        width: 100%;
        height: 100%;
    }
    .grid-sizer,
    .grid-item {
        width: 25%;
        box-sizing: border-box;
        height: 480px;
        @media only screen and (max-width: 1200px) {
            height: 240px;
        }
        @media only screen and (max-width: 768px) {
            width: 50%;
        }
        @media only screen and (max-width: 600px) {
            width: 100%;
        }
    }
    .grid-item_width2 {
        width: 50%;
        @media only screen and (max-width: 768px) {
            width: 100%;
        }
        @media only screen and (max-width: 600px) {
            width: 100%;
        }
    }
    .grid-item_height2 {
        height: 960px;
        @media only screen and (max-width: 1200px) {
            height: 480px;
        }
    }
}

// isotope style 5
.f-isotope-wrap_style5 {
    max-width: 100%;
    width: 100%;
    &.padding-lr-80 {
        padding-left: 80px;
        padding-right: 80px;
        @media only screen and (max-width: 1200px) {
            padding-left: 15px;
            padding-right: 15px;
        }
    }
    .f-isotope__content {
        position: absolute;
        width: 100%;
        height: 100%;
    }
    .grid-sizer,
    .grid-item {
        width: 25%;
        box-sizing: border-box;
        height: 450px;
        @media only screen and (max-width: 1200px) {
            height: 225px;
        }
        @media only screen and (max-width: 768px) {
            width: 50%;
        }
        @media only screen and (max-width: 600px) {
            width: 100%;
        }
    }
    .grid-item_width2 {
        width: 50%;
        @media only screen and (max-width: 768px) {
            width: 100%;
        }
        @media only screen and (max-width: 600px) {
            width: 100%;
        }
    }
    .grid-item_height2 {
        height: 900px;
        @media only screen and (max-width: 1200px) {
            height: 450px;
        }
    }
}
// isotope style 5
.f-isotope-wrap_style6 {
    max-width: 100%;
    width: 100%;

        .f-isotope__content {
            padding: 45px 50px;
            position: absolute;
            top: 20px;
            text-align: left;
            bottom: 20px;
            right: 20px;
            left: 20px;
            background-color: rgba(255, 90, 0, .6);
            opacity: 0;
            transition: opacity .3s ease;
              @media only screen and (max-width: 992px) {
          padding: 15px;
        }
            p {
                position: relative;
                width: 100%;
                transition: all .2s ease;
                color: white;
                font-weight: 700;
                line-height: 24px;
                opacity: 0;
                letter-spacing: .5px;
                margin-left: -100px;
            }
            .f-head-h5 {
                position: relative;
                width: 100%;
                transition: all .5s ease;
                color: white;
                font-weight: 600;
                line-height: 32px;
                letter-spacing: 1px;
                opacity: 0;
                margin-left: 100px;
            }
            i {
                opacity: 0;
                transition: all .6s ease;
                color: white;
                display: inline-block;
                font-size: 24px;
                width: 67px;
                height: 67px;
                line-height: 67px;
                background-color: #ff5a00;
                font-weight: 400;
                position: absolute;
                bottom: 50px;
                right: 50px;
                text-align: center;
                  @media only screen and (max-width: 992px) {
            bottom: 15px;
            right: 15px;
        }
            }
            &:hover {
                opacity: 1;
                p,
                .f-head-h5,
                i {
                    opacity: 1;
                    margin: 0;
                }
                i {}
            }
        }
    .grid-sizer,
    .grid-item {
        width: 33.333333%;
        box-sizing: border-box;
        height: 500px;
        @media only screen and (max-width: 1200px) {
            height: 300px;
        }

        @media only screen and (max-width: 600px) {
            width: 100%;
        }
    }

}

.f-isotope-wrap_white-border {
    padding-left: 60px;
    padding-right: 60px;
    @media only screen and (max-width: 1200px) {
        padding-left: 5px;
        padding-right: 5px;
    }
    .grid-item {
        box-sizing: border-box;
        display: block;
        border: 20px solid #fff;
        @media only screen and (max-width: 1200px) {
            border-width: 5px;
        }
    }
}

.f-isotope-wrap_full-style {
    .grid-item {
        width: 100%;
        height: 500px;
        .f-isotope__content {
            padding: 40px 25px;
            i {
                right: 30px;
                bottom: 30px;
            }
        }
        @media only screen and (max-width: 992px) {
            height: 350px;
        }
        @media only screen and (max-width: 600px) {
            margin-bottom: 10px;
        }
    }
}


.button-group_style2{

    padding: 37px 75px;
    background-color: #f2f2f2;
    text-align: left;
    @media only screen and (max-width: 767px) {
        padding: 15px;
    }


 button {
    display: inline-block;
    font-family: 'Lato', sans-serif;
    font-size: 14px;
    font-weight: 700;
    line-height: 55px;
    text-transform: uppercase;
    letter-spacing: .7px;
    padding-right: 55px;
    word-spacing: 5px;
    padding-left: 0;
    color: grey;
    background-color: transparent;
    border: none;
    outline: none;
    -webkit-transition: color .3s ease;
    transition: color .3s ease;
}
}