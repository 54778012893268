/* fonts */

*,
h1,
h2,
h3,
h4,
h5,
h6 {
    font-family: 'Poppins', sans-serif;
}

p,
span,
li,
a {
    font-family: 'Lato', sans-serif;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    margin-top: 0;
    margin-bottom: 0;
}

h1 {
    color: #333;
    font-size: 48px;
    font-weight: 900;
}

h2 {
    color: #333;
    font-size: 36px;
    font-weight: 900;
}

h3 {
    color: #333;
    font-size: 24px;
    font-weight: 900;
}

h4 {
    color: #333;
    font-size: 20px;
    font-weight: 600;
}

h5 {
    color: #333;
    font-size: 16px;
    font-weight: 600;
    line-height: 28px;
}

h6 {
    font-size: 14px;
    color: #595959;
    font-weight: 600;
    text-transform: uppercase;
}

p {
    font-size: 14px;
    font-weight: 400;
    line-height: 25px;
    color: grey;
}

textarea,
input {
    color: #999;
    font-family: 'Lato', sans-serif;
    font-size: 14px;
    border: none;
    outline: none;
    &:hover,
    &:focus {
        border: none;
        outline: none;
    }
}

button {
    color: white;
    font-family: 'Poppins', sans-serif;
    font-size: 14px;
    border: none;
    outline: none;
    &:hover,
    &:focus {
        border: none;
        outline: none;
    }
}

@media only screen and (max-width: 768px) {
    h1 {
        font-size: 36px;
    }
    h2 {
        font-size: 30px;
    }
}
