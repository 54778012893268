.f-pagination-wrap {
    text-align: center;
    display: block;
    &:after{
        clear: both;
        display: table;
        content: '';
    }
}

.pagination {
    a,
    span {
        font-family: 'Lato', sans-serif;
        font-size: 20px;
        font-weight: 700;
        line-height: 44px;
        text-transform: uppercase;
        transition: color .3s ease;
        display: inline-block;
        text-align: center;
        &:hover {
            color: #ff5a00;
        }
    }
    a {
        color: #999;
    }
    span {
        color: #999;
    }
    .current {
        color: #333;
    }
    .prev,
    .next {
        width: 44px;
        height: 44px;
        margin-left: 20px;
        margin-right: 20px;
        background-color: #333;
        color: #fff;
        transition: background .3s ease;
        &:hover {
            background-color: #ff5a00;
            color: #fff;
        }
    }
    @media only screen and (max-width: 767px) {
        .prev {
            margin-left: 0;
            right: 10px;
        }
        .next {
            margin-right: 0;
            margin-left: 10px;
        }
    }
}

.page-numbers {
    padding: 0 15px;
    @media only screen and (max-width: 767px) {
        padding: 0 5px;
    }
}
