.f-single-post-items {
    height: 800px;
    overflow: hidden;
    @media only screen and (max-width: 991px) {
        height: 500px;
    }
}

.f-single-post-items_style2 {
    padding-top: 120px;
    height: 665px;
    width: 100%;
    max-width: 1440px;
    margin-left: auto;
    margin-right: auto;
    @media only screen and (max-width: 1200px) {
        padding-top: 50px;
        height: 500px;
    }
    @media only screen and (max-width: 991px) {
        padding-top: 0;
    }
}

.f-single-post-item {
    height: 100%;
    .f-audio-wrap,
    .f-video-wrap,
    .f-single-project__slide {
        height: 100%;
    }
    .swiper-pagination-fraction,
    .swiper-pagination-custom,
    .swiper-container-horizontal > .swiper-pagination-bullets {
        bottom: 35px;
    }
    .swiper-container-horizontal > .swiper-pagination-bullets .swiper-pagination-bullet {
        margin: 0 15px;
    }
}

.f-single-post__user {
    &:after {
        clear: both;
        content: '';
        display: table;
    }
}

.f-single-post__user-logo {
    width: 60px;
    text-align: left;
    img {
        max-width: 60px;
    }
}

.f-single-post__user-info {
    float: left;
    padding-left: 25px;
    width: calc( 100% - 60px);
}

.f-single-post__user-logo,
.f-single-post__user-info {
    float: left;
}

.f-comment {
    max-width: 920px;
    width: 100%;
    background-color: #fafafa;
    position: relative;
    margin-bottom: 50px;
    &:after {
        clear: both;
        content: '';
        display: table;
    }
    .f-comment-reply-link {
        color: #ff5a00;
        font-family: 'Lato', sans-serif;
        font-size: 14px;
        font-weight: 700;
        line-height: 32px;
        position: absolute;
        top: 35px;
        right: 55px;
    }
}

.f-comment_style2 {
    margin-left: 170px;
    @media only screen and (max-width: 1199px) {
        margin-left: 25px;
    }
}

.f-comment-text,
.f-person-img {
    float: left;
}

.f-comment-text {
    width: calc(100% - 100px);
    padding-left: 80px;
    padding-top: 35px;
    padding-bottom: 45px;
    @media only screen and (max-width: 768px) {
        padding-left: 30px;
    }
    @media only screen and (max-width: 600px) {
        padding: 30px 15px;
        width: 100%;
    }
    p {
        line-height: 30px;
        margin-top: 5px;
        margin-bottom: 15px;
        letter-spacing: .5px;
    }
}

.f-author {
    color: #333;
    font-family: 'Poppins', sans-serif;
    font-size: 16px;
    font-weight: 600;
    line-height: 32px;
    transition: color .3s ease;
    &:hover {
        color: #ff5a00;
    }
    @media only screen and (max-width: 600px) {
        display: block;
        text-align: center;
    }
}

.f-person-img {
    width: 100px;
    img {
        max-width: 100px;
    }
    @media only screen and (max-width: 600px) {
        width: 100%;
        text-align: center;
        padding-top: 30px;
    }
}
