.f-start-block {
    height: calc(100vh - 100px);
    position: relative;
    &:after {
        content: '';
        display: inline-block;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        opacity: 0.65;
        background-color: #ff5a00;
    }
    @media only screen and (max-width: 1200px) {
        height: calc(100vh - 70px);
    }
}

.f-start-block__content-wrap {
    position: relative;
    padding-left: 110px;
    left: 50%;
    z-index: 2;
    padding-top: 56vh;
    .f-head-h1 {
        color: white;
        font-size: 72px;
        letter-spacing: 3px;
        line-height: 1;
        @media only screen and (max-width: 768px) {
            font-size: 42px;
        }
    }
    p {
        margin-top: 25px;
        margin-bottom: 40px;
        color: white;
        line-height: 30px;
        font-size: 14px;
        letter-spacing: 1px;
        font-family: 'Poppins', sans-serif;
        max-width: 410px;
    }
    @media only screen and (max-width: 1600px) {
        padding-left: 0;
        padding-top: 50vh;
    }
    @media only screen and (max-width: 1200px) {
        left: 0;
        right: 0;
        margin: auto;
        padding-top: 40vh;
        text-align: center;
        p {
            margin-left: auto;
            margin-right: auto;
        }
    }
}

// full-windows-block
.f-start-block_style2 {
    height: 100vh;
    .f-start-block__content-wrap {
        left: 61%;
        padding-top: 62vh;
        @media only screen and (max-width: 1600px) {
            padding-left: 0;
            padding-top: 0;
            padding-bottom: 0;
            left: 400px;
            top: 50%;
            transform: translateY(-50%);
        }
        @media only screen and (max-width: 1200px) {
            left: 0;
            padding-left: 300px;
            right: 0;
            margin: auto;
            text-align: center;
            p {
                margin-left: auto;
                margin-right: auto;
            }
        }
        @media only screen and (max-width: 1200px) {
            padding-left: 0;
        }
    }
}
