.f-about {
    position: relative;
    &:after {
        content: '';
        display: inline-block;
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        background: url('../img/fuller_svg_template_good.svg') 0 0 no-repeat;
    }
    @media only screen and (max-width: 991px) {
        background: none;
    }
    &__text {
        position: relative;
        z-index: 2;
        .f-head-h2 {
            line-height: 36px;
            letter-spacing: 0.5px;
        }
        p {
            margin-top: 25px;
            line-height: 25px;
            letter-spacing: .6px;
            margin-bottom: 55px;
        }
    }
    &__img {
        position: relative;
        z-index: 2;
        box-sizing: border-box;
        display: inline-block;
        max-width: 420px;
        img {
            position: relative;
            max-width: 370px;
            bottom: 85px;
            left: 100px;
        }
        @media only screen and (max-width: 991px) {
            display: none;
        }
    }
    &-content {
        position: relative;
        z-index: 2;
        text-align: center;
        img {
            display: inline-block;
            margin-bottom: 65px;
            max-width: 100%;
        }
        .f-head-h3 {
            letter-spacing: .5px;
            margin-bottom: 10px;
        }
        p {
            max-width: 630px;
            margin-left: auto;
            margin-right: auto;
        }
    }
}


/* f-about1_style2 */

.f-about1_style2 {
    .f-head-h2 {
        font-weight: 900;
    }
    .f-about__img {
        text-align: center;
        max-width: 100%;
        width: 100%;
        img {
            position: static;
        }
    }
}

.f-about1_style3 {
    &:after {
        background: url('../img/about_mask.svg') center no-repeat;
        background-size: cover;
    }
    header {
        position: relative;
        z-index: 2;
    }
}

.f-about2 {
    .f-head-h2 {
        &:after {
            display: none;
        }
    }
    header {
        .f-head-h2 {
            color: #333;
            font-size: 36px;
            line-height: 60px;
            letter-spacing: 4px;
            text-transform: uppercase;
            font-weight: 900;
            margin-bottom: 10px;
            @media only screen and (max-width: 767px) {
                line-height: 1;
                font-size: 30px;
            }
        }
        .f-head-h5 {
            color: #ff5a00;
            font-size: 16px;
            letter-spacing: 2px;
            text-transform: uppercase;
        }
        p {
            line-height: 25px;
            letter-spacing: .5px;
            max-width: 630px;
            margin-left: auto;
            margin-right: auto;
        }
    }
    &_style2 {
        header {
            .f-head-h2 {
                text-transform: none;
                float: none;
                letter-spacing: 1px;
            }
        }
        .f-skills__item {
            &:nth-child(4) {
                padding-left: 100px;
                i {
                    left: 0;
                }
            }
            &:nth-child(5) {
                padding-left: 115px;
                i {
                    left: 15px;
                }
            }
        }
        .f-skills__item_3col {
            &:nth-child(-n+100) {
                padding-left: 100px;
                i {
                    left: 0;
                }
                @media only screen and (max-width: 480px) {
                    padding-left: 0;
                }
            }
        }
    }
}
