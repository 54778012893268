.f-full-swiper__item {
    height: calc(100vh - 195px);
    @media only screen and (max-width: 991px) {
        height: calc(100vh - 165px);
    }
    &:hover {
        .f-full-swiper__content {
            opacity: 1;
        }
    }
}

.f-full-swiper__item_hide {
    .f-full-swiper__content {
        opacity: 0;
        transition: opacity .3s ease;
    }
}

.f-full-screen-swiper {
    .swiper-slide {
        margin-right: 0 !important;
    }
    .swiper-button-prev {
        left: 80px;
        @media only screen and (max-width: 992px) {
            left: 15px;
        }
    }
    .swiper-button-next {
        right: 80px;
        @media only screen and (max-width: 992px) {
            right: 15px;
        }
    }
    .swiper-button-prev,
    .swiper-button-next {
        &:hover,
        &.swiper-button-disabled {
            background-color: #333;
            opacity: 1;
        }
    }
}

.f-full-swiper__content {
    background-color: rgba(255, 90, 0, .6);
    max-width: 510px;
    box-sizing: border-box;
    position: absolute;
    left: 0;
    right: 0;
    bottom: 80px;
    margin: auto;
    padding: 50px 30px 50px 105px;
    i {
        display: inline-block;
        width: 67px;
        height: 67px;
        background-color: #ff5a00;
        color: white;
        font-size: 24px;
        line-height: 67px;
        text-align: center;
        float: left;
    }
    p {
        color: white;
        line-height: 32px;
        letter-spacing: 1px;
    }
    .f-head-h5 {
        color: white;
        line-height: 32px;
        letter-spacing: 1px;
    }
    @media only screen and (max-width: 991px) {
        width: 100%;
        max-width: 100%;
        bottom: 0;
    }
    @media only screen and (max-width: 480px) {
        padding: 15px;
        i {
            display: none;
        }
    }
}

.f-full_text-wrap {
    float: left;
    padding-left: 20px;
}
