/*-------------------------------------------------------------------------------------------------------*/
/*This is main CSS file that contains custom style rules used in this template*/
/*-------------------------------------------------------------------------------------------------------*/
/* Template Name: Fuller.*/
/* Version: 1.0 Initial Release*/
/* Build Date: 02-03-2016*/
/* Author: */
/* Website: */
/* Copyright: (C) 2016 */
/*------------------------------------------------------------------------------------------------------*/


/*--------------------------------------------------------*/
/* TABLE OF CONTENTS: */
/*--------------------------------------------------------*/
/*--------------------------------------------------------
Global  settings
Text settings
Headers
start-swiper
about
feature
portfolio
choose-me
testimonial
blog
subscribefooter
start-block
portfolio-slider
portfolio-slider
content-block
blog-posts
isotope
count
contact
contact-form
partrers
full-screen-swiper
single-progect
video-wrap
audio-wrap
full-windows-block
team
skills
full-slider
blog-widgets
single-post
coming-soon
map
services
404
pagination
buttons
start-header
popUp
Paddings and margins
--------------------------------------------------------*/



/*---------------------------------------------------------------------------------------*/
/* Global  settings */
/*--------------------------------------------------------------------------------------*/
@import "_init.scss";
/*---------------------------------------------------------------------------------------*/
/* preload */
/*--------------------------------------------------------------------------------------*/
@import "_preload.scss";
/*--------------------------------------------------------------------------------------*/
/* Text settings */
/*--------------------------------------------------------------------------------------*/
@import "_fonts.scss";
/*--------------------------------------------------------------------------------------*/
/* Headers */
/*--------------------------------------------------------------------------------------*/
@import "_menus.scss";
/*--------------------------------------------------------------------------------------*/
/* start-swiper*/
/*--------------------------------------------------------------------------------------*/
@import "_start-swiper.scss";
/*--------------------------------------------------------------------------------------*/
/* f-about*/
/*--------------------------------------------------------------------------------------*/
@import "_about.scss";
/*--------------------------------------------------------------------------------------*/
/* f-feature */
/*--------------------------------------------------------------------------------------*/
@import "_feature.scss";
/*--------------------------------------------------------------------------------------*/
/* portfolio*/
/*--------------------------------------------------------------------------------------*/
@import "_portfolio.scss";
/*--------------------------------------------------------------------------------------*/
/* choose-me*/
/*--------------------------------------------------------------------------------------*/
@import "_choose-me.scss";
/*--------------------------------------------------------------------------------------*/
/* testimonial*/
/*--------------------------------------------------------------------------------------*/
@import "_testimonial.scss";
/*--------------------------------------------------------------------------------------*/
/* blog*/
/*--------------------------------------------------------------------------------------*/
@import "_blog.scss";
/*--------------------------------------------------------------------------------------*/
/* subscribe*/
/*--------------------------------------------------------------------------------------*/
@import "_subscribe.scss";
/*--------------------------------------------------------------------------------------*/
/* footer*/
/*--------------------------------------------------------------------------------------*/
@import "_footer.scss";
/*--------------------------------------------------------------------------------------*/
/* Home 2 start-block*/
/*--------------------------------------------------------------------------------------*/
@import "_start-block.scss";
/*--------------------------------------------------------------------------------------*/
/* Home 2 portfolio-slider*/
/*--------------------------------------------------------------------------------------*/
@import "_portfolio-slider.scss";
/*--------------------------------------------------------------------------------------*/
/* Home 2 content-block */
/*--------------------------------------------------------------------------------------*/
@import "_content-block.scss";
/*--------------------------------------------------------------------------------------*/
/* Home 2 blog-posts */
/*--------------------------------------------------------------------------------------*/
@import "_blog-posts.scss";
/*--------------------------------------------------------------------------------------*/
/* Home 3 Isotope */
/*--------------------------------------------------------------------------------------*/
@import "_isotope.scss";
/*--------------------------------------------------------------------------------------*/
/* Home 3 Isotope */
/*--------------------------------------------------------------------------------------*/
@import "_count.scss";
/*--------------------------------------------------------------------------------------*/
/* Contact */
/*--------------------------------------------------------------------------------------*/
@import "_contact.scss";
/*--------------------------------------------------------------------------------------*/
/* Contact-form */
/*--------------------------------------------------------------------------------------*/
@import "_contact-form.scss";
/*--------------------------------------------------------------------------------------*/
/* partrers*/
/*--------------------------------------------------------------------------------------*/
@import "_partrers.scss";
/*--------------------------------------------------------------------------------------*/
/* full-screen-swiper*/
/*--------------------------------------------------------------------------------------*/
@import "_full-screen-swiper.scss";
/*--------------------------------------------------------------------------------------*/
/* single-project*/
/*--------------------------------------------------------------------------------------*/
@import "_single-progect.scss";
/*--------------------------------------------------------------------------------------*/
/* video-banner*/
/*--------------------------------------------------------------------------------------*/
@import "_video-wrap.scss";
/*--------------------------------------------------------------------------------------*/
/* audio-banner*/
/*--------------------------------------------------------------------------------------*/
@import "_audio-wrap.scss";
/*--------------------------------------------------------------------------------------*/
/* single project start block*/
/*--------------------------------------------------------------------------------------*/
@import "_full-windows-block.scss";
/*--------------------------------------------------------------------------------------*/
/* team*/
/*--------------------------------------------------------------------------------------*/
@import "_team.scss";
/*--------------------------------------------------------------------------------------*/
/* skills*/
/*--------------------------------------------------------------------------------------*/
@import "_skills.scss";
/*--------------------------------------------------------------------------------------*/
/* full page sliders*/
/*--------------------------------------------------------------------------------------*/
@import "_full-slider.scss";
/*--------------------------------------------------------------------------------------*/
/*  blog widgets*/
/*--------------------------------------------------------------------------------------*/
@import "_blog-widgets.scss";
/*--------------------------------------------------------------------------------------*/
/*  single-post*/
/*--------------------------------------------------------------------------------------*/
@import "_single-post.scss";
/*--------------------------------------------------------------------------------------*/
/*  coming soon*/
/*--------------------------------------------------------------------------------------*/
@import "_coming-soon.scss";
/*--------------------------------------------------------------------------------------*/
/*  coming soon*/
/*--------------------------------------------------------------------------------------*/
@import "_map.scss";
/*--------------------------------------------------------------------------------------*/
/*  services*/
/*--------------------------------------------------------------------------------------*/
@import "_services.scss";

/*--------------------------------------------------------------------------------------*/
/*  404*/
/*--------------------------------------------------------------------------------------*/
@import "_404.scss";
/*--------------------------------------------------------------------------------------*/
/* Pagination */
/*--------------------------------------------------------------------------------------*/
@import "_pagination.scss";
/*--------------------------------------------------------------------------------------*/
/* Buttons */
/*--------------------------------------------------------------------------------------*/
@import "_buttons.scss";
/*--------------------------------------------------------------------------------------*/
/* Start header */
/*--------------------------------------------------------------------------------------*/
@import "_start-header.scss";
/*--------------------------------------------------------------------------------------*/
/* Pop Up */
/*--------------------------------------------------------------------------------------*/
@import "_popUp.scss";

/*--------------------------------------------------------------------------------------*/
/* Paddings and margins*/
/*--------------------------------------------------------------------------------------*/
@import "_paddMarg.scss";
/*--------------------------------------------------------------------------------------*/









