.f-start-header {
    text-align: center;
    .f-head-h1 {
        letter-spacing: 1px;
        line-height: 1;
    }
    li {
        color: #595959;
        text-transform: uppercase;
        font-size: 14px;
        letter-spacing: .3px;
    }
    a {
        color: #bfbfbf;
        font-size: 14px;
        line-height: 1;
        text-transform: uppercase;
    }
}

.breadcrumb>li+li:before {
    padding: 0 1px;
}

.breadcrumb {
    background-color: transparent;
    margin-top: 10px;
    > li {
        text-transform: uppercase;
        font-size: 14px;
        letter-spacing: .3px;
        color: #bfbfbf;
        font-weight: 700;
        &.active {
            color: #595959;
        }
    }
    a {
        color: #bfbfbf;
        font-size: 14px;
        line-height: 1;
        text-transform: uppercase;
    }
}

.f-start-header_style2 {
    .f-head-h1 {
        letter-spacing: 0;
        font-weight: 900;
    }
}
