.f-audio-wrap {
    position: relative;
    height: 550px;
}

.f-audio {
    bottom: 35px;
    max-width: 1170px;
    width: 100%;
    position: absolute;
    left: 0;
    right: 0;
    margin: auto;
    iframe {
        height: 150px;
        width: 100%;
    }
}
