.f-testimonial__item {
    padding: 80px 0 75px;
    position: relative;
    text-align: center;
    &:after {
        content: '';
        display: inline-block;
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        opacity: 0.9;
        background-color: #333;
    }
    @media only screen and (max-width: 991px) {
        padding: 50px 0;
    }
}

.f-testimonial__user {
    position: relative;
    z-index: 2;
    display: inline-block;
    &:after {
        content: '';
        display: table;
        clear: both;
    }
}

.f-testimonial__user-logo {
    float: left;
    // margin: 0 5px;
    img {
        max-width: 60px;
        max-height: 60px;
    }
}

.f-testimonial__user-info {
    text-align: left;
    margin: 10px 15px 0 30px;
    float: left;
    font-weight: 600;
    .f-head-h5 {
        color: #fff;
        letter-spacing: 1px;
        line-height: 25px;
    }
    span {
        color: #fff;
        font-size: 12px;
        line-height: 25px;
        letter-spacing: .5px;
    }
}

.f-testimonial__text {
    position: relative;
    margin-top: 40px;
    margin-bottom: 10px;
    z-index: 2;
    p {
        color: #ccc;
        font-size: 14px;
        line-height: 25px;
        max-width: 735px;
        display: inline-block;
        letter-spacing: .6px;
        @media only screen and (max-width: 991px) {
            max-width: 500px;
        }
        @media only screen and (max-width: 767px) {
            max-width: 100%;
            padding-left: 60px;
            padding-right: 60px;
        }
    }
}

.f-testimonial__rating {
    position: relative;
    z-index: 2;
    i {
        padding: 0 2px;
        font-size: 20px;
    }
}


/* testimonial_style2*/

.swiper-pagination-bullet {
    width: 15px;
    height: 15px;
    margin: 0 15px;
    background-color: #ccc;
    opacity: 1;
}

.swiper-pagination-bullet-active {
    background-color: #ff5a00;
}

.f-testimonial_style2 {
    position: relative;
    &:after {
        content: '';
        opacity: 0.9;
        background-color: #333;
        display: inline-block;
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
    }
    .f-head-h2 {
        line-height: 1;
    }
    .f-head-h5 {
        color: #ff5a00;
        font-family: 'Lato', sans-serif;
        font-size: 16px;
        margin-top: 15px;
        margin-bottom: 20px;
        text-transform: uppercase;
        letter-spacing: 2.2px;
    }
    p {
        letter-spacing: .5px;
        color: #ccc;
    }
    .swiper-pagination {
        text-align: left;
        margin-left: -50%;
        bottom: 0;
        @media only screen and (max-width: 991px) {
            margin-left: 0;
            position: absolute;
            text-align: center;
            width: 100%;
            bottom: -50px;
        }
    }
}

.swiper-container_style-arrow2 {
    .swiper-button-prev {
        left: 0;
    }
    .swiper-button-next {
        right: 0;
    }
}

.f-testimonial__item_style2 {
    background-color: white;
    padding: 50px;
    &:after {
        display: none;
    }
    .f-testimonial__user {
        display: block;
    }
    .f-testimonial__user-logo {
        margin: 0;
        margin-right: 25px;
        text-align: left;
        img {
            max-width: 70px;
            max-height: 70px;
            width: 70px;
            height: 70px;
        }
    }
    .f-testimonial__user-info {
        max-width: 180px;
        width: auto;
        margin: 0;
        float: none;
        display: table;
        min-height: 70px;
       .f-head-h5 {
            margin: 0;
            margin-top: 10px;
            display: table-cell;
            color: #333;
            text-transform: none;
            vertical-align: middle;
            font-size: 16px;
        }
    }
    .f-testimonial__text {
        text-align: left;
        margin-top: 25px;
        margin-bottom: 15px;
        @media only screen and (max-width: 767px) {
            p {
                padding-left: 0;
                padding-right: 0;
            }
        }
    }
    .f-testimonial__rating {
        margin-top: 10px;
        text-align: left;
    }
}

.f-testimonial__item_style3 {
    text-align: center;
    &:after {
        display: none;
    }
    .f-head-h2 {
        color: #333;
        letter-spacing: 1px;
    }
    .f-testimonial__text {
        margin-top: 30px;
        margin-bottom: 30px;
        p {
            color: grey;
            max-width: 750px;
        }
    }
    .f-testimonial__user-info {
        .f-head-h5 {
            color: #333;
        }
        span {
            color: grey;
        }
    }
    .f-testimonial__user {
        margin-top: 80px;
    }
}
