.f-skills {
    &:after {
        clear: both;
        content: '';
        display: table;
    }
}

.f-skills__item {
    padding-left: 95px;
    position: relative;
    float: left;
    width: 50%;
    margin-bottom: 55px;
    &:nth-child(2n) {
        padding-left: 115px;
        i {
            left: 15px;
        }
    }
    i {
        position: absolute;
        top: 0;
        left: 0;
        background-color: #ff5a00;
        display: inline-block;
        width: 70px;
        height: 70px;
        text-align: center;
        line-height: 70px;
        color: white;
        font-size: 40px;
    }
    .f-head-h5 {
        line-height: 20px;
        letter-spacing: .4px;
        margin-bottom: 5px;
    }
    p {
        letter-spacing: .6px;
        max-width: 275px;
    }
    @media only screen and (max-width: 991px) {
        padding-left: 95px;
        padding-right: 15px;
    }
    @media only screen and (max-width: 767px) {
        width: 100%;
        padding-right: 0;
        p {
            max-width: 100%;
        }
        &:nth-child(2n) {
            padding-left: 95px;
            i {
                left: 0;
            }
        }
    }
    @media only screen and (max-width: 480px) {
        &:nth-child(2n) {
            padding-left: 0;
        }
        padding-left: 0;
        text-align: center;
        i {
            margin-bottom: 20px;
            position: static;
        }
    }
}

.f-choose-me-img-wrap{
    position: relative;
    width: 100%;
    @media only screen and (max-width: 1199px) {
        display: none;
    }
}
.f-choose-me__img {
    position: absolute;
    top: -30px;
    right: -30px;
    height: 670px;
    width: 405px;
    border: 25px solid #ff5a00;
    box-sizing: border-box;
    @media only screen and (max-width: 1200px) {
        right: -150px;
    }
    @media only screen and (max-width: 991px) {
        display: none;
    }
    img {
        position: absolute;
        bottom: 55px;
        left: -220px;
        max-height: 405px;
        // @media only screen and (max-width: 1600px) {
        //     left: -110px;
        // }
        // @media only screen and (max-width: 1300px) {
        //     left: -50px;
        // }
    }
}

.f-choose-me__img_style2 {
    float: right;
    top: -235px;
    border-top: none;
    height: 645px;
    right: auto;
    left:100%;
    border-right: none;
    @media only screen and (max-width: 1600px) {
        left: 75%;
    }
}

.f-skills__item_3col {
    width: 33%;
    &:nth-child(3n) {
        padding-left: 130px;
        i {
            left: 30px;
        }
    }
    @media only screen and (max-width: 992px) {
        width: 100%;
        max-width: 600px;
        margin-left: auto;
        margin-right: auto;
        float: none;
        &:nth-child(2n),
        &:nth-child(3n) {
            padding-left: 100px;
            i {
                left: 0;
            }
        }
        p {
            max-width: 100%;
        }
    }
    @media only screen and (max-width: 992px) {
        &:nth-child(2n),
        &:nth-child(3n) {
            padding-left: 0;
        }
    }
}

.f-choose-me_style2 {
    .f-choose-me__img {
        top: -50px;
        right: auto;
        left: calc(100% + 85px);
        width: 295px;
        background-color: #ff5a00;
         @media only screen and (max-width: 1600px) {
                left: 100%;
            }
        img {
            bottom: auto;
            top: 25px;
            left:calc( -100% - 40px);
            @media only screen and (max-width: 1600px) {
                left: auto;right: 0;
            }
        }
    }
}

.f-choose-me__description {
    p {
        letter-spacing: .6px;
        max-width: 800px;
    }
}
