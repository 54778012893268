.swiper-button-prev {
    left: 60px;
    &:before {
        content: "\f3d2";
        display: inline-block;
        font-family: "Ionicons";
        font-size: 20px;
        line-height: 44px;
        color: white;
    }
    @media only screen and (max-width: 991px) {
        left: 15px;
    }
}

.swiper-button-next {
    right: 60px;
    &:before {
        content: "\f3d1";
        display: inline-block;
        font-family: "Ionicons";
        font-size: 20px;
        line-height: 44px;
        color: white;
    }
    @media only screen and (max-width: 991px) {
        right: 15px;
    }
}

.swiper-button-prev,
.swiper-button-next {
    z-index: 3;
    width: 40px;
    height: 44px;
    background-color: #ff5a00;
    background-image: none;
    text-align: center;
    transition: background .3s ease;
    &.swiper-button-disabled {
        cursor: pointer;
        opacity: 1;
    }
    transition: opacity .3s ease;
    &:hover {
        background-color: #333;
        &.swiper-button-disabled {
            cursor: pointer;
            opacity: .7;
        }
    }
}

.f-start-swiper {
    width: 100%;
    height: 100vh;
    @media only screen and (max-width: 992px) {
        height: calc(100vh - 70px);
    }
    .swiper-pagination {
        text-align: right;
        position: absolute;
        left: auto;
        width: 50%;
        right: 0;
        padding: 0 75px;
        bottom: 40px;
        @media only screen and (max-width: 600px) {
            width: 100%;
            text-align: center;
        }
    }
    .swiper-pagination-bullet {
        border-radius: 0;
        transition: height .3s ease;
        width: 2px;
        height: 15px;
        background-color: #ccc;
        @media only screen and (max-width: 991px) {
            opacity: 1;
            width: 15px;
            height: 15px;
            border-radius: 7px;
            transition: width .3s ease;
            background-color: rgba(51,51,51,.5);
        }
    }
    .swiper-pagination-bullet-active {
        border-radius: 0;
        width: 2px;
        height: 45px;
        background-color: #ff5a00;
        @media only screen and (max-width: 991px) {
            opacity: 1;
            border-radius: 7px;
            background-color: rgba(51,51,51,1);
            width: 30px;
            height: 15px;
        }
    }
}

.swiper-container {
    height: 100%;
}

.f-start-swiper__item {
    height: 100%;
    position: relative;
    &:before {
        content: '';
        display: inline-block;
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        opacity: 0.9;
        background-color: #333;
    }
    &:after {
        content: '';
        display: inline-block;
        z-index: 2;
        width: 65%;
        height: 85%;
        bottom: 0;
        left: 0;
        position: absolute;
        background-color: rgba(255, 90, 0, .6);
    }
    @media only screen and (max-width: 992px) {
        &:before {
            display: none;
        }
        &:after {
            width: 100%;
            height: 100%;
        }
    }
}

.f-start-swiper__content {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    z-index: 3;
}

.f-start-swiper__content-wrap {
    font-weight: 600;
    padding: 40px 40px 40px 200px;
    position: relative;
    .f-view-more-btn{
        &:after{
            bottom: -15px;
        }
    }
    .f-head-h1 {
        &:before {
            content: '';
            display: inline-block;
            width: 120px;
            height: 2px;
            position: absolute;
            left: -200px;
            top: 30px;
            background-color: #fff;
        }
        max-width: 600px;
        font-size: 72px;
        line-height: 72px;
        color: white;
        letter-spacing: 5px;
        position: relative;
        @media only screen and (max-width: 768px) {
            font-size: 42px;
            &:before {
                display: none;
            }
        }
    }
   .f-head-h3 {
        max-width: 600px;
        color: white;
        margin-top: 15px;
        margin-bottom: 24vh;
        font-size: 24px;
        letter-spacing: 1.4px;
        line-height: 37px;
        @media only screen and (max-width: 600px) {
            margin-bottom: 15vh;
        }
    }
    a {
        @media only screen and (max-width: 600px) {
            display: inline-block;
            margin-bottom: 10vh;
        }
    }
    @media only screen and (max-width: 768px) {
        padding: 40px 15px;
    }
}


/* swiper_style2*/

.swiper_style2 {
    max-width: 1760px;
    margin-left: auto;
    margin-right: auto;
    .f-start-block__content-wrap {
        padding-top: 44vh;
        max-width: 950px;
        padding-left: 30vh;
        left: 0;
            @media only screen and (max-width: 1600px) {
                max-width: 600px;
       
    }
       .f-head-h1{
            text-transform: uppercase;
            letter-spacing: 12px;
            font-weight: 400;
        }
        p {
            max-width: 635px;
            line-height: 32px;
            letter-spacing: 0.9px;
            margin-top: 15px;
            margin-bottom: 25px;
        }
        @media only screen and (max-width: 1500px) {
            padding-left: 15vh;
        }
        @media only screen and (max-width: 1200px) {
            padding-left: 15px;
            padding-right: 15px;
        }
    }
    .f-start-swiper__item {
        &:before {
            width: 100%;
            opacity: 0.8;
            background-color: #fafafa;
            @media only screen and (max-width: 1200px) {
                display: none;
            }
        }
        &:after {
            width: 66%;
            opacity: 0.8;
            height: 100%;
            z-index: 1;
            background-color: #ff5a00;
            @media only screen and (max-width: 1200px) {
                width: 100%;
            }
        }
    }
    .swiper-button-next,
    .swiper-button-prev {
        transition: background .3s ease;
        &:hover {
            opacity: 1;
            background-color: #333;
            &.swiper-button-disabled {
                cursor: pointer;
                opacity: 1;
                background-color: #333;
            }
        }
    }
    .swiper-button-prev {
        right: 40px;
        bottom: 0;
        left: auto;
        top: auto;
    }
    .swiper-button-next {
        right: 0;
        bottom: 0;
        left: auto;
        top: auto;
    }
    .swiper-button-disabled {
        cursor: pointer;
        opacity: 1;
        background-color: #333;
    }
    transition: color .3s ease;
    .f-start-swiper__item {
        z-index: 2;
    }
}
