.f-contact-info {
    max-width: 1170px;
    margin-left: auto;
    margin-right: auto;
    header {
        p {
            max-width: 510px;
            margin-top: 10px;
            letter-spacing: .5px;
        }
    }
}

.f-contact-form {}

.f-contact-info-item {
    i {
        color: #ff5a00;
        font-size: 60px;
        font-weight: 400;
        line-height: 35px;
    }
    .f-head-h5 {
        color: #595959;
        margin-top: 20px;
        transition: color .3s ease;
    }
    span {
        color: grey;
        line-height: 28px;
        display: block;
        font-size: 14px;
        letter-spacing: .5px;
        transition: color .3s ease;
    }
    a {
        &:hover {
           .f-head-h5,
            span {
                color: #ff5a00;
            }
        }
    }
    @media only screen and (max-width: 767px) {
        width: 100%;
        text-align: center;
        margin-bottom: 30px;
    }
}
