.f-contact-form {
    header {
        .f-head-h2 {
            font-weight: 900;
            @media only screen and (max-width: 767px) {
                font-size: 30px;
            }
        }
        .f-head-h5 {
            color: #ff5a00;
            font-family: 'Lato', sans-serif;
            font-size: 14px;
            text-transform: uppercase;
            letter-spacing: 3px;
        }
        p {
            max-width: 510px;
            margin-top: 10px;
            letter-spacing: .5px;
        }
    }
    .wpcf7-form {
        p {
            // text-align: left;
        }
    }
}

.f-contact-form_placeholder {}

.wpcf7-form {
    padding: 60px 70px;
    text-align: center;
    @media only screen and (max-width: 767px) {
        padding: 50px 15px;
    }
    &:after {
        content: '';
        display: table;
        clear: both;
    }
    p {
        float: left;
        position: relative;
        text-align: center;
        width: 100%;
        @media only screen and (max-width: 768px) {
            width: 100%;
            margin-top: 0;
        }
        &:nth-child(-n+4) {
            width: 32%;
            display: inline-block;
            margin-right: 2%;
            @media only screen and (max-width: 768px) {
                width: 100%;
                margin-top: 0;
            }
        }
        &:nth-child(4) {
            margin-right: 0;
            @media only screen and (max-width: 768px) {
                width: 100%;
                margin-top: 0;
            }
        }
        display: block;
        input:not([type="submit"]),
        textarea {
            background-color: transparent;
            padding: 15px 0;
            width: 100%;
            border-bottom: 1px solid #e6e6e6;
            letter-spacing: 1.5px;
            position: relative;
            z-index: 2;
        }
        textarea {
            resize: vertical;
            min-height: 155px;
        }
        input:not([type="submit"]) {
            width: 100%;
            margin-bottom: 25px;
        }
        input[type="submit"] {
            margin-left: auto;
            margin-right: auto;
            margin-top: 40px;
            margin-bottom: 20px;
            background-color: #333;
            line-height: 50px;
            padding: 0 35px;
            text-align: center;
            display: inline-block;
            font-size: 14px;
            letter-spacing: .5px;
            color: #fff;
            font-family: 'Poppins', sans-serif;
            transition: opacity .5s ease;
            &:hover {
                opacity: .9;
            }
        }
        span {
            display: block;
        }
    }
    .f-contact-form_placeholder {
        position: absolute;
        top: 5px;
        left: 15px;
        margin: auto;
        font-family: 'Lato', sans-serif;
        display: inline-block;
        text-align: left;
        width: 90%;
        line-height: 50px;
        letter-spacing: 1px;
        cursor: pointer;
        transition: all .5s ease;
        &:after {
            content: '*';
            display: inline-block;
            font-size: 14px;
            color: #ff5a00;
            position: absolute;
        }
    }
    .f-contact-form_placeholder-no {
        &:after {
            display: none;
        }
    }
    .f-filled {
        top: -15px;
        left: 0;
        font-size: 10px;
    }
}

.comment-form {
    padding: 60px 70px;
    @media only screen and (max-width: 767px) {
        padding: 50px 15px;
    }
    &:after {
        content: '';
        display: table;
        clear: both;
    }
    > div {
        position: relative;
        span {
            position: absolute;
            top: 5px;
            left: 15px;
            color: gray;
            font-size: 14px;
            margin: auto;
            font-family: 'Lato', sans-serif;
            display: inline-block;
            text-align: left;
            width: 90%;
            line-height: 50px;
            letter-spacing: 1px;
            cursor: pointer;
            transition: all .5s ease;
            &:after {
                content: '*';
                display: inline-block;
                font-size: 14px;
                color: #ff5a00;
                position: absolute;
            }
        }
        float: left;
        width: 49%;
        margin-right: 2%;
        @media only screen and (max-width: 767px) {
            width: 100%;
            margin-right: 0;
        }
        &:nth-child(2) {
            margin-right: 0;
        }
        &:nth-child(3) {
            width: 100%;
            margin-right: 0;
        }
    }
    input[type="text"] {}
    textarea {
        width: 100%;
    }
    input:not([type="submit"]),
    textarea {
        background-color: transparent;
        padding: 15px 0;
        width: 100%;
        border-bottom: 1px solid #e6e6e6;
        letter-spacing: 1.5px;
        position: relative;
        z-index: 2;
    }
    textarea {
        resize: vertical;
        min-height: 155px;
    }
    input:not([type="submit"]) {
        width: 100%;
        margin-bottom: 25px;
    }
    button[type="submit"] {
        margin-left: auto;
        margin-right: auto;
        margin-top: 40px;
        margin-bottom: 20px;
        background-color: #333;
        line-height: 50px;
        padding: 0 35px;
        text-align: center;
        display: inline-block;
        font-size: 14px;
        letter-spacing: .5px;
        color: #fff;
        font-family: 'Poppins', sans-serif;
        transition: opacity .5s ease;
        &:hover {
            opacity: .9;
        }
    }
    p {}
    .f-filled {
        // margin-bottom: 25px;
        span {
            top: -15px;
            left: 0;
            height: 0;
            font-size: 10px;
        }
    }
}

.wpcf7-form_style2 {
    padding-left: 0;
    padding-right: 0;
    background-color: transparent;
    max-width: 1030px;
    padding-top: 45px;
}

.f-coment-form {
    .wpcf7-form {
        p {
            &:nth-child(-n+4) {
                width: 46%;
                margin-right: 8%;
                text-align: left;
                @media only screen and (max-width: 768px) {
                    width: 100%;
                    margin-right: 0;
                }
            }
            &:nth-child(2n) {
                margin-right: 0;
            }
            &:nth-child(3) {
                width: 100%;
            }
        }
    }
}
