/*-------------------------------------------------------------------------------------------------------------------------------*/


/* Custom paddings/margins */


/*-------------------------------------------------------------------------------------------------------------------------------*/

.padding-lg-lr0 {
    padding-left: 0px;
    padding-right: 0px;
}

$step: 5;
$count: 60;
@for $i from 0 through $count {
    .margin-lg-t#{$step*$i} {
        margin-top: 0px+$step*$i;
    }
}

@for $i from 0 through $count {
    .margin-lg-b#{$step*$i} {
        margin-bottom: 0px+$step*$i;
    }
}

@for $i from 0 through $count {
    .padding-lg-t#{$step*$i} {
        padding-top: 0px+$step*$i;
    }
}

@for $i from 0 through $count {
    .padding-lg-b#{$step*$i} {
        padding-bottom: 0px+$step*$i;
    }
}

@media screen and (max-width: 1199px) {
    .padding-lg-lr0 {
        padding-left: 15px;
        padding-right: 15px;
    }
    @for $i from 0 through $count {
        .margin-md-t#{$step*$i} {
            margin-top: 0px+$step*$i;
        }
    }
    @for $i from 0 through $count {
        .margin-md-b#{$step*$i} {
            margin-bottom: 0px+$step*$i;
        }
    }
    @for $i from 0 through $count {
        .padding-md-t#{$step*$i} {
            padding-top: 0px+$step*$i;
        }
    }
    @for $i from 0 through $count {
        .padding-md-b#{$step*$i} {
            padding-bottom: 0px+$step*$i;
        }
    }
}

@media screen and (max-width: 991px) {
    @for $i from 0 through $count {
        .margin-sm-t#{$step*$i} {
            margin-top: 0px+$step*$i;
        }
    }
    @for $i from 0 through $count {
        .margin-sm-b#{$step*$i} {
            margin-bottom: 0px+$step*$i;
        }
    }
    @for $i from 0 through $count {
        .padding-sm-t#{$step*$i} {
            padding-top: 0px+$step*$i;
        }
    }
    @for $i from 0 through $count {
        .padding-sm-b#{$step*$i} {
            padding-bottom: 0px+$step*$i;
        }
    }
}

@media screen and (max-width: 767px) {
    @for $i from 0 through $count {
        .margin-xs-t#{$step*$i} {
            margin-top: 0px+$step*$i;
        }
    }
    @for $i from 0 through $count {
        .margin-xs-b#{$step*$i} {
            margin-bottom: 0px+$step*$i;
        }
    }
    @for $i from 0 through $count {
        .padding-xs-t#{$step*$i} {
            padding-top: 0px+$step*$i;
        }
    }
    @for $i from 0 through $count {
        .padding-xs-b#{$step*$i} {
            padding-bottom: 0px+$step*$i;
        }
    }
}
