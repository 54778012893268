.f-content-block {
    // overflow: hidden;
    .f-head-h2 {
        font-weight: 600;
        line-height: 50px;
        letter-spacing: 1px;
        @media only screen and (max-width: 767px) {
            line-height: 1;
        }
        &:after {
            display: none;
        }
    }
    p {
        margin-top: 25px;
        line-height: 25px;
        letter-spacing: .6px;
        margin-bottom: 45px;
    }
}

.f-content-block__phone {
    background-size: auto;
    position: absolute;
    right: 0;
    top: -70px;
    img {
        max-width: 100%;
    }
    @media only screen and (max-width: 1200px) {
        left: 0;
        right: 0;
        margin: auto;
        text-align: center;
    }
    @media only screen and (max-width: 767px) {
        display: none;
    }
}

.f-content-block_style2 {
    .f-content-block__phone {
        right: auto;
        left: 0;
        @media only screen and (max-width: 1200px) {
            right: 0;
        }
    }
    .f-header {
        padding-left: 70px;
        @media only screen and (max-width: 1200px) {
            padding-left: 0;
        }
    }
    .f-content-block__phone {
        top: -25px;
    }
}

.f-start-block_style2 {
    height: 100vh;
}
