.f-subscribe {
    position: relative;
    max-width: 1760px;
    margin-left: auto;
    margin-right: auto;
    &:before {
        content: '';
        display: inline-block;
        width: 100%;
        height: 100%;
        opacity: 0.9;
        background-color: #333;
        position: absolute;
        top: 0;
        left: 0;
    }
}

.f-subscribe__info {
    position: relative;
    z-index: 2;
    .f-head-h3 {
        color: white;
        font-size: 24px;
        line-height: 28px;
        letter-spacing: 1px;
    }
    span {
        color: #ccc;
        font-size: 14px;
        line-height: 28px;
        letter-spacing: .7px;
    }
    @media only screen and (max-width: 991px) {
        margin-bottom: 30px;
    }
}
.mc4wp-form {
    margin-top: 5px;
}
.f-subscribe__form,
.mc4wp-form {
    position: relative;
    z-index: 2;
    padding-left: 5px;
    &:after {
        clear: both;
        content: '';
        display: table;
    }
    input[type="email"] {
        float: left;
        background-color: white;
        border-radius: 5px 0px 0px 5px;
        padding: 14px 35px;
        // min-width: 310px;
        width: 70%;
        letter-spacing: .6px;
        @media only screen and (max-width: 1200px) {
            min-width: 0;
            width: 65%;
        }
    }
    input[type="submit"] {
        float: left;
        width: 30%;
        background-color: #ff5a00;
        border-radius: 0px 5px 5px 0px;
        line-height: 45px;
        padding: 0 35px;
        font-size: 15px;
        color:#fff;
        transition: opacity .3s ease;
        letter-spacing: 1px;
        &:hover {
            opacity: .7;
        }
        @media only screen and (max-width: 1200px) {
            padding: 0;
            width: 35%;
        }
    }
    @media only screen and (max-width: 991px) {
        padding-left: 0;
        input {
            padding: 14px 15px;
        }
        button {
            padding: 0 15px;
        }
    }
}


.f-subscribe__form {
    position: relative;
    z-index: 2;
    padding-left: 5px;
    &:after {
        clear: both;
        content: '';
        display: table;
    }
    input {
        float: left;
        background-color: white;
        border-radius: 5px 0px 0px 5px;
        padding: 14px 35px;
        min-width: 310px;
        letter-spacing: .6px;
        @media only screen and (max-width: 1200px) {
            min-width: 0;
            width: 65%;
        }
    }
    button {
        float: left;
        background-color: #ff5a00;
        border-radius: 0px 5px 5px 0px;
        line-height: 45px;
        padding: 0 35px;
        transition: opacity .3s ease;
        letter-spacing: 1px;
        &:hover {
            opacity: .7;
        }
        @media only screen and (max-width: 1200px) {
            padding: 0;
            width: 35%;
        }
    }
    @media only screen and (max-width: 991px) {
        padding-left: 0;
        input {
            padding: 14px 15px;
        }
        button {
            padding: 0 15px;
        }
    }
}
