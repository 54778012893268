.f-about-skills {
    text-align: center;
    p {
        max-width: 800px;
        margin-left: auto;
        margin-right: auto;
        letter-spacing: .5px;
    }
    header {
       .f-head-h5 {
            margin-bottom: 40px;
            line-height: 32px;
        }
    }
    @media only screen and (max-width: 992px) {
        padding-left: 15px;
        padding-right: 15px;
    }
}

.f-about-skills-items {
    text-align: center;
}

.f-about-skills-item {
    margin: 10px 70px;
    width: 100%;
    display: inline-block;
    max-width: 370px;
    text-align: left;
    @media only screen and (max-width: 1200px) {
        margin: 10px;
        max-width: 350px;
    }
   .f-head-h5 {
        text-transform: uppercase;
        letter-spacing: .5px;
    }
}

.f-about-skills-item__progress-bar-wrap {
    background-color: #f2f2f2;
    height: 5px;
    width: 100%;
    margin-top: 40px;
    margin-bottom: 18px;
}

.f-about-skills-item__progress-bar {
    position: relative;
    height: 100%;
    background-color: #ff5a00;
    width: 0;
    transition: width 1s ease;
    span {
        position: absolute;
        top: -40px;
        right: -27px;
        min-width: 55px;
        display: inline-block;
        background-color: #404040;
        color: white;
        font-family: 'Lato', sans-serif;
        font-size: 14px;
        font-weight: 900;
        padding: 0 10px;
        line-height: 30px;
    }
}
