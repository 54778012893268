.mfp-close {
    font-size: 44px;
    font-weight: 100;
    top: 50px!important;
    right: 35px!important;
    @media only screen and (max-width: 1500px) {
        top: 0!important;
        right: 0!important;
    }
}

.mfp-container {
    @media only screen and (max-width: 1500px) {
        overflow-y: auto;
    }
}

// searrch form
.f-pop-up-form {
    max-width: 1000px;
    width: 100%;
    padding-left: 15px;
    padding-right: 15px;
    margin-left: auto;
    margin-right: auto;
    h1 {
        color: white;
        font-size: 60px;
        font-weight: 900;
        position: relative;
        @media only screen and (max-width: 768px) {
            font-size: 42px;
        }
        &:after {
            content: '';
            display: inline-block;
            width: 150px;
            height: 2px;
            background-color: #e6e6e6;
            margin-left: 75px;
            vertical-align: middle;
            @media only screen and (max-width: 768px) {
                display: none;
            }
        }
    }
    h3 {
        color: white;
        font-size: 24px;
        font-weight: 400;
        position: relative;
        &:after {
            content: '';
            display: inline-block;
            width: 150px;
            height: 2px;
            background-color: #e6e6e6;
            margin-left: 40px;
            vertical-align: middle;
            @media only screen and (max-width: 768px) {
                display: none;
            }
        }
    }
    p {
        color: white;
        letter-spacing: .31px;
    }
    input {
        display: block;
        line-height: 50px;
        background-color: transparent;
        width: 100%;
        border-bottom: 1px solid gray;
        color: white;
        font-family: 'Lato', sans-serif;
        font-size: 14px;
        font-weight: 400;
        padding: 0 40px 0 20px;
        transition: color .3s ease;
        letter-spacing: .5px;
        @media only screen and (max-width: 768px) {
            padding-left: 0;
        }
        &:focus {
            border-color: #ff5a00;
        }
    }
    input::-webkit-input-placeholder {
        color: #fff;
        transition: color .3s ease;
    }
    input::-moz-placeholder {
        color: #fff;
        transition: color .3s ease;
    }
    input:-moz-placeholder {
        color: #fff;
        transition: color .3s ease;
    }
    input:-ms-input-placeholder {
        color: #fff;
        transition: color .3s ease;
    }
    input:focus::-webkit-input-placeholder {
        color: #ff5a00;
    }
    input:focus::-moz-placeholder {
        color: #ff5a00;
    }
    input:focus:-moz-placeholder {
        color: #ff5a00;
    }
    input[type='submit'],
    button {
        right: 0;
        top: 0;
        color:transparent;
        bottom: 0;
        line-height: 58px;
        font-size: 24px;
        padding: 0 20px;
        margin: auto;
        font-weight: 100;
        position: absolute;
        background-color: transparent;
        background: transparent url(../img/arrow.png) center center no-repeat;
        background-size: 17px 17px;
        transition: color .3s ease;
        @media only screen and (max-width: 768px) {
            padding-right: 0;
        }
        &:hover,
        &:focus {
            color: #ff5a00;
        }
    }
    form {
        display: block;
        box-sizing: border-box;
        position: relative;
    }
    label {
        width: 100%;
    }
}

// login form
.f-form_style {
    .f-head-h2 {
        color: white;
        line-height: 28px;
        &:after {
            margin-left: 40px;
            position: static;
            vertical-align: middle;
            background-color: grey;
            @media only screen and (max-width: 480px) {
                display: none;
            }
        }
    }
    label {
        display: block;
        position: relative;
        a {
            position: absolute;
            line-height: 50px;
            right: 0;
            top: 0;
            transition: color .3s ease;
            &:hover {
                color: #ff5a00;
            }
        }
        span {
            display: inline-block;
            color: #ccc;
            font-size: 14px;
            font-weight: 400;
            line-height: 57px;
            letter-spacing: .5px;
            @media only screen and (max-width: 768px) {
                line-height: 1;
            }
            &:before {
                margin-right: 20px;
                content: "\f3fd";
                color: transparent;
                display: inline-block;
                text-align: center;
                width: 14px;
                height: 14px;
                border: 1px solid grey;
                line-height: 12px;
                font-size: 14px;
                font-family: "Ionicons";
            }
        }
        input[type=checkbox] {
            display: none;
        }
        input[type=checkbox]:checked + span:before {
            border-color: #ff5a00;
            color: #ff5a00;
        }
    }
    input:not([type='checkbox']) {
        line-height: 50px;
        margin-bottom: 30px;
        background-color: transparent;
        width: 100%;
        border-bottom: 1px solid gray;
        color: white;
        font-family: 'Lato', sans-serif;
        font-size: 14px;
        font-weight: 400;
        padding: 0 15px;
        transition: color .3s ease;
        letter-spacing: 1.9px;
        @media only screen and (max-width: 768px) {
            padding-left: 0;
        }
        &:focus {
            border-color: #ff5a00;
        }
    }
    input::-webkit-input-placeholder {
        color: #fff;
        transition: color .3s ease;
    }
    input::-moz-placeholder {
        color: #fff;
        transition: color .3s ease;
    }
    input:-moz-placeholder {
        color: #fff;
        transition: color .3s ease;
    }
    input:-ms-input-placeholder {
        color: #fff;
        transition: color .3s ease;
    }
    input:focus::-webkit-input-placeholder {
        color: #ff5a00;
    }
    input:focus::-moz-placeholder {
        color: #ff5a00;
    }
    input:focus:-moz-placeholder {
        color: #ff5a00;
    }
    button {
        margin-top: 60px;
    }
}

.f-login-wrap {
    padding: 0 100px;
    @media only screen and (max-width: 1500px) {
        padding: 0;
        max-width: 470px;
        margin-left: auto;
        margin-right: auto;
    }
    input:not([type='checkbox']) {
        padding-right: 70px;
    }
}

.f-registr-wrap {
    max-width: 470px;
    @media only screen and (max-width: 1500px) {
        margin-left: auto;
        margin-right: auto;
    }
}

// newsletter-form
.f-newsletter-form {
    p {
        letter-spacing: .9px;
    }
}

// menu
.f-form {
    display: table;
    max-width: 1170px;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    .main-menu li {
        margin-right: 0;
    }
    nav {
        display: block;
        width: 50%;
        display: table-cell;
        @media only screen and (max-width: 1300px) {
            padding-left: 100px;
        }
        @media only screen and (max-width: 767px) {
            width: 100%;
            padding: 0 15px;
            display: block;
            margin-bottom: 50px;
        }
        ul {
            display: block;
            li {
                display: block;
            }
        }
        .main-menu {
            li {
                > a {
                    transition: color .3s ease;
                    color: white;
                    font-size: 24px;
                    font-weight: 500;
                    line-height: 60px;
                    &:hover {
                        color: #ff5a00;
                    }
                }
                ul {
                    background-color: transparent;
                    position: static;
                    padding: 0;
                    padding-left: 35px;
                }
                &:hover {
                    > .sub-menu {
                        display: none;
                    }
                }
                &.active {
                    >a {
                        color: #ff5a00;
                    }
                    .sub-menu {
                        display: block;
                        a {
                            font-size: 16px;
                            font-weight: 500;
                            line-height: 17px;
                        }
                        .sub-menu {
                            position: static;
                        }
                    }
                }
            }
        }
    }
    .f-pop-up-form {
        width: 50%;
        display: table-cell;
        vertical-align: middle;
        padding-left: 115px;
        padding-top: 15px;
        @media only screen and (max-width: 1300px) {
            padding-left: 0;
            padding-right: 100px;
        }
        @media only screen and (max-width: 767px) {
            width: 100%;
            display: block;
            padding: 0 15px;
        }
    }
}

.main-menu .sub-menu .menu-item-has-children > a:after {
    padding-left: 5px;
}


/* white popup */

.pop-up-hide {
    display: none;
}

.popUp {
    position: fixed;
    display: block;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background-color: rgba(0, 0, 0, .8);
    .f-pop-up-form {
        position: relative;
        background-color: #fff;
        border: 2px solid #ff5a00;
        padding: 55px 100px 80px;
        top: 50%;
        max-width: 1170px;
        transform: translate(0, -50%);
        @media only screen and (max-width: 767px) {
            padding: 30px 15px;
        }
        .f-head-h1 {
            color: #333;
            &:after {
                background-color: #e6e6e6;
            }
        }
        p {
            font-size: 16px;
            color: grey;
            letter-spacing: 0;
        }
        input {
            color: #999;
            border-color: #e6e6e6;
            &::-webkit-input-placeholder {
                /* Chrome/Opera/Safari */
                color: #999;
            }
            &::-moz-placeholder {
                /* Firefox 19+ */
                color: #999;
            }
            &:-ms-input-placeholder {
                /* IE 10+ */
                color: #999;
            }
            &:-moz-placeholder {
                /* Firefox 18- */
                color: #999;
            }
        }
        button {
            color: #999;
        }
    }
}

.f-close-pop-up {
    width: 23px;
    height: 23px;
    cursor: pointer;
    display: inline-block;
    position: absolute;
    top: 50px;
    right: 50px;
    @media only screen and (max-width: 767px) {
        top: 15px;
        right: 15px;
    }
    &:after,
    &:before {
        top: 0;
        bottom: 0;
        margin: auto;
        content: '';
        display: inline-block;
        width: 25px;
        height: 1px;
        background-color: #000;
        transform: rotate(45deg);
        position: absolute;
    }
    &:after {
        transform: rotate(-45deg);
    }
}
